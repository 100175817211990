<template>
    <v-row justify="start" align="end" no-gutters class="pa-0" :class="'w-100'">
        <app-button class="font-weight-medium my-5" @click="onAddNew">
            Add user job
        </app-button>
    </v-row>

    <v-row no-gutters>
        <CareerHistoryTimeline v-if="store.timeline.length" />
        <CareerHistoryEmptyCard v-else class="mt-4" :class="lgAndUp ? 'w-50' : 'w-100'" />
    </v-row>

    <UserJobModal v-if="userJobStore.isCreating || userJobStore.isEditing" :deletable="true" />
</template>

<script setup lang="ts">
    import { useUserJobStore } from '@/store/user-job/store';
    import UserJobModal from '@/components/canvas/user-jobs/UserJobModal.vue';
    import { useDisplay } from 'vuetify';
    import CareerHistoryTimeline from '@/components/profile/career-history/CareerHistoryTimeline.vue';
    import CareerHistoryEmptyCard from '@/components/profile/career-history/CareerHistoryEmptyCard.vue';

    const store = useUserJobStore();
    const userJobStore = useUserJobStore();

    const { lgAndUp } = useDisplay();

    const onAddNew = () => {
        store.addNew();
    };
</script>
