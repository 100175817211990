<template>
    <teleport to="#before-main-container">
        <BeforeMainContent>
            <v-row no-gutters class="justify-center">
                <v-col :cols="hideTutorial ? 12 : 10" class="pa-0">
                    <div class="w-100 text-custom-grey-700 text-paragraph-xs" v-if="sectionTitle">
                        {{ sectionTitle }}
                    </div>
                    <div class="w-100 text-heading-h4 mt-1">{{ title }}</div>
                </v-col>
                <v-col
                    cols="2"
                    class="d-flex align-center justify-end action-column"
                    v-if="!hideTutorial"
                >
                    <app-button variant="outlined" @click="emit('show-tutorial')">
                        Tutorial
                    </app-button>
                </v-col>
            </v-row>
            <slot />
        </BeforeMainContent>
    </teleport>
</template>

<script setup lang="ts">
    import BeforeMainContent from '@/components/layout/BeforeMainContent.vue';

    const emit = defineEmits(['show-tutorial']);

    withDefaults(
        defineProps<{
            title?: string;
            sectionTitle?: string;
            hideTutorial?: boolean;
        }>(),
        {
            title: '',
        },
    );
</script>
