import { allCountries } from 'country-region-data';
import iso6391 from 'iso-639-1';

export enum NZRegion {
    Auckland = 'auckland',
    BayOfPlenty = 'bay-of-plenty',
    Canterbury = 'canterbury',
    Gisborne = 'gisborne',
    HawkesBay = 'hawkes-bay',
    ManawatuWhanganui = 'manawatu-whanganui',
    Marlborough = 'marlborough',
    Nelson = 'nelson',
    Northland = 'northland',
    Otago = 'otago',
    Southland = 'southland',
    Taranaki = 'taranaki',
    Tasman = 'tasman',
    Waikato = 'waikato',
    Wellington = 'wellington',
    WestCoast = 'west-coast',
    Other = 'other',
}

// Manually define the NZ Region options
export const nzRegionOptions = [
    { value: NZRegion.Auckland, title: 'Auckland' },
    { value: NZRegion.BayOfPlenty, title: 'Bay of Plenty' },
    { value: NZRegion.Canterbury, title: 'Canterbury' },
    { value: NZRegion.Gisborne, title: 'Gisborne' },
    { value: NZRegion.HawkesBay, title: 'Hawkes Bay' },
    { value: NZRegion.ManawatuWhanganui, title: 'Manawatu Whanganui' },
    { value: NZRegion.Marlborough, title: 'Marlborough' },
    { value: NZRegion.Nelson, title: 'Nelson' },
    { value: NZRegion.Northland, title: 'Northland' },
    { value: NZRegion.Otago, title: 'Otago' },
    { value: NZRegion.Southland, title: 'Southland' },
    { value: NZRegion.Taranaki, title: 'Taranaki' },
    { value: NZRegion.Tasman, title: 'Tasman' },
    { value: NZRegion.Waikato, title: 'Waikato' },
    { value: NZRegion.Wellington, title: 'Wellington' },
    { value: NZRegion.WestCoast, title: 'West Coast' },
    { value: NZRegion.Other, title: 'Other' },
];

export enum VisaType {
    NzCitizen = 'nz-citizen',
    NzResident = 'nz-resident',
    SkilledMigrant = 'skilled-migrant-visa',
    StraightToResidency = 'straight-to-residency-visa',
    WorkToResident = 'work-to-resident-visa',
    WorkingHoliday = 'working-holiday-visa',
    SeasonalEmployer = 'seasonal-employer-visa',
    PartnerVisa = 'partner-visa',
    Other = 'other',
}

export const visaOptions = [
    { value: VisaType.NzCitizen, title: 'NZ Citizen' },
    { value: VisaType.NzResident, title: 'NZ Resident' },
    { value: VisaType.SkilledMigrant, title: 'Skilled Migrant Visa' },
    { value: VisaType.StraightToResidency, title: 'Straight to Residency Visa' },
    { value: VisaType.WorkToResident, title: 'Work to Resident Visa' },
    { value: VisaType.WorkingHoliday, title: 'Working Holiday Visa' },
    { value: VisaType.SeasonalEmployer, title: 'Seasonal Employer Visa' },
    { value: VisaType.PartnerVisa, title: 'Partner Visa' },
    { value: VisaType.Other, title: 'Other' },
];

export enum Gender {
    Male = 'M',
    Female = 'F',
    Other = 'O',
}

// Manually define the Gender options
export const genderOptions = [
    { title: 'Male', value: Gender.Male },
    { title: 'Female', value: Gender.Female },
    { title: 'Prefer not to say', value: Gender.Other },
];

// Default to empty array if undefined, as it is not working while testing,
const _allCountries = allCountries ?? [];

export const nationalityOptions = _allCountries.map((country) => ({
    title: country[0],
    value: country[1],
}));

const familiarCodes = ['en', 'es', 'fr', 'de', 'zh', 'ja', 'hi', 'ar', 'ru'];

export const familiarLanguageOptions = familiarCodes.map((code) => ({
    title: iso6391.getName(code),
    value: code,
}));

export const otherLanguageOptions = iso6391
    .getAllCodes()
    .filter((c) => !familiarCodes.includes(c))
    .map((code) => ({
        title: iso6391.getName(code),
        value: code,
    }));
