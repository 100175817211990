import { defineStore } from 'pinia';
import { type PlanStoreState, PlanType } from '@/store/plan/common/types';

export const usePlanStore = defineStore({
    id: 'plan-store',
    state: (): PlanStoreState => ({
        preview: null,
    }),
    getters: {
        inPreview(state): boolean {
            return state.preview !== null;
        },
    },
    actions: {
        setPreview(type: PlanType | null): void {
            this.preview = type;
        },
    },
});
