import { defineStore } from 'pinia';
import type {
    CanvasFutureAspirationItem,
    FutureAspirationStoreState,
} from '@/store/future-aspirations/types';
import type { CanvasFutureAspiration } from '@/api/types/canvas/futureAspiration';
import {
    fetchCanvasFutureAspirations,
    updateCanvasFutureAspiration,
} from '@/services/future-aspirations/service';
import { useCanvasStore } from '@/store/canvas/store';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
    setInUpdating,
} from '@/store/common/dataState';
import { cloneDeep, isEqual } from 'lodash';

function makeEmpty(): CanvasFutureAspirationItem {
    return {
        description: '',
        timeframe: '',
        location: '',
        expected_gain: '',
        whats_required: '',
        summary_statement: '',
    };
}

export const useFutureAspirationsStore = defineStore({
    id: 'future-aspirations-store',
    state: (): FutureAspirationStoreState => ({
        original: null,
        value: null,

        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        isDirty(state): boolean {
            return !isEqual(state.value, state.original);
        },
        isAreaComplete(state): boolean {
            return !isEqual(state.value, makeEmpty());
        },
        needsLoading(state): boolean {
            return state.original === null;
        },
    },
    actions: {
        async load() {
            if (!this.needsLoading) {
                console.info('Future aspirations already loaded');
                return;
            }

            console.info('Loading future aspirations ...');

            setInLoading(this);

            try {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();
                const canvasFutureAspirations = await fetchCanvasFutureAspirations(
                    canvasId,
                    accessToken,
                );

                this._setFutureAspiration(canvasFutureAspirations);

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        onSetMyGoal(aspiration: CanvasFutureAspirationItem): void {
            this.value = aspiration;
        },
        clearGoal(): void {
            this.value = this._empty_aspiration();
        },
        async saveProgress(): Promise<void> {
            setInUpdating(this);
            try {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                const updateData = (this.value ? [this.value] : []).map(
                    (aspiration: CanvasFutureAspirationItem) => {
                        return {
                            ...aspiration,
                            // Temporary HACK to Force link between each challenge and the current plan id
                            // We could add a UI artifact to link the challenge to the plan or not, but at this point
                            // it is easier to make every challenge linked to the plan
                            career_plan_id: useCanvasStore().canvas?.career_plan_id,
                        };
                    },
                );

                await updateCanvasFutureAspiration(canvasId, updateData, accessToken);

                const canvasFutureAspirations = await fetchCanvasFutureAspirations(
                    canvasId,
                    accessToken,
                );

                this._setFutureAspiration(canvasFutureAspirations);

                setInIdle(this);
            } catch (error: unknown) {
                console.error(error instanceof Error ? error.message : error);
                setInError(this, error);
            }
        },

        // ###############################
        //
        // Side - effects
        //
        // ###############################
        _setFutureAspiration: function (rawValues: CanvasFutureAspiration[]) {
            const getCurrent = (): CanvasFutureAspirationItem => {
                if (rawValues.length > 1) {
                    throw new Error('only one future aspiration allowed');
                } else if (rawValues.length === 1) {
                    return rawValues[0] ?? makeEmpty();
                }

                return makeEmpty();
            };

            const current = getCurrent();

            this.original = current;
            this.value = cloneDeep(current);
        },
        _empty_aspiration: function () {
            return {
                description: '',
                timeframe: '',
                location: '',
                expected_gain: '',
                whats_required: '',
                summary_statement: '',
            };
        },
    },
});
