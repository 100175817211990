import {
    type GuideEnterEvent,
    GuideEventType,
    type GuideLeaveEvent,
    type GuideQuestionEvent,
    type GuideSuggestionEvent,
    GuideSupportedScreen,
} from '@/store/guide/types';
import { values } from 'lodash';
import { EventFactory } from '@/store/guide/event-factory/eventFactory';
import type { IntroStoreState } from '@/store/home/types';

export class IntroEventFactory extends EventFactory<IntroStoreState> {
    screen = GuideSupportedScreen.Intro;

    public enter(): GuideEnterEvent {
        return this.make<GuideEnterEvent>(GuideEventType.Enter, {
            context: values(this.contextState.values),
        });
    }

    leave(): GuideLeaveEvent {
        return this.make<GuideLeaveEvent>(GuideEventType.Leave, {
            context: values(this.contextState.values),
        });
    }

    question(message: string): GuideQuestionEvent {
        return this.make<GuideQuestionEvent>(GuideEventType.Question, {
            q: message,
        });
    }

    suggestion(): GuideSuggestionEvent {
        return this.make<GuideSuggestionEvent>(GuideEventType.Suggestion, {
            context: values(this.contextState.values),
        });
    }
}
