import { defineStore } from 'pinia';
import type {
    CanvasLearnedExperience,
    ExperienceTimelineJob,
    LearnedExperienceStoreState,
    LearnedExperienceTimelineItem,
} from '@/store/learned-experiences/types';
import { LearnedExperienceItemState } from '@/store/learned-experiences/types';
import type { CanvasUserExperience } from '@/api/types/canvas/learnedExperience';
import {
    createOrUpdateLearnedExperience,
    fetchOrCreateUserExperience,
    removeCanvasLearnedExperience,
} from '@/services/learned-experiences/service';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
} from '@/store/common/dataState';
import { cloneDeep, compact, isEqual } from 'lodash';
import { verify } from '@/store/verify';
import {
    byCreateDateComparator,
    byFromDateComparatorDesc,
    makeEditableLearnedExperience,
    makeNewEmptyEditableLearnedExperience,
    setEditableLearnedExperienceState,
} from '@/store/learned-experiences/utils';
import { useUsersStore } from '@/store/user/store';
import { useCanvasStore } from '@/store/canvas/store';

function makeEmpty(): CanvasUserExperience {
    return {} as CanvasUserExperience;
}

export const useLearnedExperiencesStore = defineStore({
    id: 'learned-experiences-store',
    state: (): LearnedExperienceStoreState => ({
        ...makeDataState(),
        current: null,
        value: makeEmpty(),
        editable: makeEmpty(),
    }),
    getters: {
        ...makeDataStateGetters(),
        summary(state): string | undefined {
            return state.value.description;
        },
        isDirty(state): boolean {
            return !isEqual(state.value, state.editable);
        },
        hasLearnedExperiences(state): boolean {
            return this.learnedExperiences.length > 0;
        },
        timeline(state): LearnedExperienceTimelineItem[] {
            const timelineItems: LearnedExperienceTimelineItem[] = [];
            this.jobsWithExperiences.sort(byFromDateComparatorDesc).forEach((job) => {
                timelineItems.push({
                    ...job,
                    type: 'job',
                });

                job.experiences.forEach((experience) => {
                    timelineItems.push({
                        ...experience,
                        type: 'experience',
                    });
                });
            });

            return timelineItems;
        },
        jobsWithExperiences(state): ExperienceTimelineJob[] {
            const userJobs = useUsersStore().userJobs;
            return userJobs
                .map((job) => {
                    return {
                        ...job,
                        experiences: this.learnedExperiences.filter(
                            (experience) => experience.user_job_id === job.id,
                        ),
                    };
                })
                .filter((job) => job.experiences.length > 0);
        },
        learnedExperiences(state): CanvasLearnedExperience[] {
            return this.editable?.experiences ?? [];
        },
        isAreaStarted(): boolean {
            return this.learnedExperiences.length > 0;
        },
        isAreaComplete(): boolean {
            return this.learnedExperiences.length >= 3;
        },
        needsLoading(state): boolean {
            return !state.value?.id;
        },
    },
    actions: {
        async load() {
            if (!this.needsLoading) {
                console.info('Learned experiences already loaded');
                return;
            }

            console.info('Loading learned experiences ...');

            const { canvasId, accessToken } = await useCanvasStore().makeContext();

            setInLoading(this);

            try {
                const [canvasUserExperience, response] = await fetchOrCreateUserExperience(
                    canvasId,
                    accessToken,
                );

                const value = verify(canvasUserExperience, 'no canvas user experience');

                this.value = value;
                this._setLearnedExperience(value);

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        setEdit(entry: CanvasLearnedExperience): void {
            this.current = makeEditableLearnedExperience(entry);
        },
        cancelEdit(): void {
            this.current = null;
        },
        addLearnedExperience(): void {
            this.current = makeNewEmptyEditableLearnedExperience();
        },
        async saveLearnedExperience(): Promise<void> {
            const { canvasId, user, accessToken } = await useCanvasStore().makeContext();

            verify(this.value, 'No user experience');
            const editableUserExperience = verify(this.editable, 'no editable user experience');

            const form = verify(this.current, 'No current experience');
            try {
                const isUpdating = form.id;
                setEditableLearnedExperienceState(
                    form,
                    isUpdating
                        ? LearnedExperienceItemState.Updating
                        : LearnedExperienceItemState.Creating,
                );

                const learnedExperience = await createOrUpdateLearnedExperience(
                    canvasId,
                    form,
                    accessToken,
                );

                if (isUpdating) {
                    editableUserExperience.experiences = editableUserExperience.experiences.map(
                        (existentEntry: CanvasLearnedExperience) => {
                            return existentEntry.id === learnedExperience.id
                                ? learnedExperience
                                : existentEntry;
                        },
                    );
                    this.current = null;
                } else {
                    editableUserExperience.experiences.push(learnedExperience);
                    this.current = null;
                }

                setEditableLearnedExperienceState(form, LearnedExperienceItemState.Saved);
            } catch (error) {
                console.error(error instanceof Error ? error.message : error);
                setEditableLearnedExperienceState(form, LearnedExperienceItemState.Error);
            }
        },
        async deleteCurrent(): Promise<void> {
            const { accessToken } = await useUsersStore().makeContext();

            const editableUserExperience = verify(this.editable, 'no editable user experience');
            const learnedExperience = verify(this.current, 'No current experience');
            const learnedExperienceId = verify(learnedExperience.id, 'No experience id to delete');
            setEditableLearnedExperienceState(
                learnedExperience,
                LearnedExperienceItemState.Deleting,
            );

            const experience = verify(this.value, 'No experience');

            try {
                if (experience.id) {
                    await removeCanvasLearnedExperience(
                        experience.canvas_id,
                        learnedExperienceId,
                        accessToken,
                    );
                }

                editableUserExperience.experiences = compact(
                    editableUserExperience.experiences.map(
                        (existentEntry: CanvasLearnedExperience) => {
                            return existentEntry.id === learnedExperience.id ? null : existentEntry;
                        },
                    ),
                );

                this.current = null;

                setEditableLearnedExperienceState(
                    learnedExperience,
                    LearnedExperienceItemState.Saved,
                );
            } catch (error) {
                console.error(error instanceof Error ? error.message : error);
                setEditableLearnedExperienceState(
                    learnedExperience,
                    LearnedExperienceItemState.Error,
                );
            }
        },
        // ###############################
        //
        // Side - effects
        //
        // ###############################
        _setLearnedExperience: function (experience: CanvasUserExperience) {
            const clone = cloneDeep(experience);

            if (experience && clone) {
                this.editable = {
                    id: clone.id,
                    canvas_id: clone.canvas_id,
                    experiences: clone.experiences.sort(byCreateDateComparator),
                };
            }
        },
    },
});
