<template>
    <StorySectionStatementNotStarted section="who-i-am" />
</template>

<script setup lang="ts">
    import { useDisplay } from 'vuetify';
    import StorySectionStatementNotStarted from '@/components/story/section/statement/StorySectionStatementNotStarted.vue';

    const { mdAndUp } = useDisplay();
</script>

<style scoped lang="scss">
    .empty-card {
        border-radius: 8px;
        border: 2px dashed var(--Grey-800, #474747);
    }
</style>
