import { type ApiResponse, get, post } from '@/services/apiClient';
import type { Canvas } from '@/api/types/canvas';
import type { CanvasCurrentPlan } from '@/api/types/plan/currentPlan';

export const getCanvasCurrentPlan = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasCurrentPlan>> {
    const request = get<CanvasCurrentPlan>(`canvas/${canvasId}/current-plan/`);
    return request(accessToken);
};

export const postCanvasCurrentPlan = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasCurrentPlan>> {
    const request = post<any, CanvasCurrentPlan>(`canvas/${canvasId}/current-plan/`);
    return request(accessToken, {});
};
