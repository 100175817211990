<template>
    <v-card
        variant="outlined"
        :width="props.width ?? '100%'"
        :height="props.height ?? '100%'"
        class="personal-values-card pa-5 d-flex flex-column border-2-grey-200 rounded-8"
        :class="formattedCategory"
    >
        <div class="pa-0 d-flex">
            <v-icon size="48" class="card-icon">
                {{ icon }}
            </v-icon>
            <v-spacer />
            <v-chip
                label
                class="card-category text-caption-subtitle text-uppercase font-weight-600"
            >
                {{ formattedCategory }}
            </v-chip>
        </div>

        <v-btn
            v-if="props.removable"
            icon="mdi-close"
            class="remove-icon"
            variant="plain"
            size="medium"
            @click="$emit('removed')"
        />

        <v-spacer />

        <div class="text-custom-grey-900 justify-center text-center">
            <div class="text-capitalize text-heading-h1 mb-2">
                {{ props.value.title }}
            </div>
            <div class="mb-1 text-paragraph-md">
                {{ props.value.description }}
            </div>
        </div>

        <v-spacer />

        <v-card-actions class="pl-0 pt-1 w-100">
            <v-chip
                label
                class="card-category text-caption-subtitle text-uppercase font-weight-600"
            >
                <span class="text-use-heading-font">{{ formattedCategory }}</span>
            </v-chip>
            <v-spacer />
            <v-icon size="48" class="card-icon">
                {{ icon }}
            </v-icon>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
    import { type PersonalValue, PersonalValueCategory } from '@/api/types/personalValue';
    import { computed } from 'vue';
    import { useDisplay } from 'vuetify';
    import { getCategoryIcon, standardCategory } from '@/components/canvas/personal-values/upload/util';

    const props = defineProps<{
        width?: number;
        height?: number;
        value: PersonalValue;
        image?: boolean;
        removable?: boolean;
        hideDescription?: boolean;
    }>();

    const { name } = useDisplay();

    const formattedCategory = computed(() => {
        return standardCategory(props.value.category);
    });

    const icon = computed(() => {
        return getCategoryIcon(props.value.category);
    });
</script>

<style scoped lang="scss">
    .remove-icon {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .independence {
        --color: rgb(var(--v-theme-custom-independence-value));
    }

    .ambition {
        --color: rgb(var(--v-theme-custom-ambition-value));
    }

    .understanding {
        --color: rgb(var(--v-theme-custom-understanding-value));
    }

    .adventure {
        --color: rgb(var(--v-theme-custom-adventure-value));
    }

    .creative {
        --color: rgb(var(--v-theme-custom-creative-value));
    }

    .authentic {
        --color: rgb(var(--v-theme-custom-caring-value));
    }

    .personal-values-card {
        .card-icon {
            color: var(--color);
        }

        .card-category {
            color: #262626;
            background: var(--color);

            :deep(.v-chip__content) {
                color: #262626;
                mix-blend-mode: overlay;
            }
        }
    }
</style>
