export function getEnjoymentColor(enjoyment: number | undefined, transparency = 1) {
    const startColor = [247, 247, 247]; // RGB for #7B7B7B
    const endColor = [22, 56, 165]; // RGB for #1638A5
    const value = ((enjoyment ?? 0) + 5) / 10; // Normalize slider value to [0, 1]

    const r = Math.round(startColor[0] + value * (endColor[0] - startColor[0]));
    const g = Math.round(startColor[1] + value * (endColor[1] - startColor[1]));
    const b = Math.round(startColor[2] + value * (endColor[2] - startColor[2]));

    return `rgb(${r}, ${g}, ${b}, ${transparency})`;
}
