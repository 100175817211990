// Customer error classes, which may be used througout the application.

/*
 * A base class for API errors.
 */
export class ApiError extends Error {
    constructor(msg: string) {
        super(msg);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}

/*
 * A class to represent an error in the API authentication process.
 */
export class ApiAuthError extends ApiError {
    constructor(msg: string) {
        super(msg);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ApiAuthError.prototype);
    }
}
