<template>
    <app-selectable-card
        min-height="140"
        min-width="260"
        :title="item.title"
        :description="item.description"
        :isSelected="isSelected"
        :disabled="isDisabled"
        @onSelected="onSelected"
    >
        <template #append-title>
            <ChallengeActionTypeLabel :type="item.action_type" />
        </template>

        <template #extra>
            <div class="pa-0">
                <app-button
                    class="px-0 text-none text-custom-grey-900 font-weight-600 opacity-1"
                    variant="plain"
                    @click="onReadMore"
                >
                    Read more
                </app-button>

                <!--                        :style="{-->
                <!--                        color: #262626;-->
                <!--                        font-size: 14px;-->
                <!--                        font-family: Inter;-->
                <!--                        font-weight: 600;-->
                <!--                        line-height: 22.4px;-->
                <!--                        }-->
                <!--                        "-->
            </div>
        </template>
    </app-selectable-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import ChallengeActionTypeLabel from '@/components/pathway/ui/ChallengeActionTypeLabel.vue';
    import {
        type SelectableChallengeAction,
        useSingleChallengePathStore,
    } from '@/store/challenge-path/singleChallengeStore';
    import {
        type CurrentChallengeAction,
        CurrentChallengeActionState,
    } from '@/api/types/plan/currentChallengeAction';
    import { isCurrentChallengeAction, isSuggestedAction } from '@/store/challenge-path/util';

    const props = defineProps<{
        item: CurrentChallengeAction | SelectableChallengeAction;
    }>();

    const emit = defineEmits(['read-more-click']);

    const onReadMore = (item: SelectableChallengeAction) => {
        emit('read-more-click', item);
    };

    const store = useSingleChallengePathStore();
    const onSelected = (value: boolean) => {
        store.toggleSelected(props.item);
    };

    const isSelected = computed(() => {
        // return store.selected.includes(props.item);
        const action = props.item;

        const found = store.selected.find((a) => {
            if (isCurrentChallengeAction(action) || isSuggestedAction(action)) {
                if (a.type === action.type) {
                    if (isCurrentChallengeAction(a) || isSuggestedAction(a)) {
                        return a.id === action.id;
                    } else {
                        return a.title === action.title && a.description === action.description;
                    }
                }
            } else {
                return a.title === action.title && a.description === action.description;
            }
        });

        return !!found;
    });

    const isDisabled = computed(() => {
        const action = props.item;
        debugger
        return (
            isCurrentChallengeAction(action) &&
            action.state === CurrentChallengeActionState.Completed
        );
    });

    const onMarkAsCompletedClick = () => {
        // TODO mark as completed
    };
</script>

<style scoped lang="scss">
    .action-item-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 28.8px */
        letter-spacing: -0.225px;
    }

    .action-item-description {
        color: rgb(var(--v-theme-custom-neutral-grey-700));
    }

    .action-selected {
        border-radius: 8px;
        border: 2px solid;
        border-color: rgb(var(--v-theme-primary));
    }

    .action-not-selected {
        border-radius: 8px;
        border: 2px solid;
        border-color: rgb(var(--v-theme-custom-neutral-grey-200));
    }

    .action-item-action-type {
        position: absolute;
        right: 20px;
    }
</style>
