<template>
    <SkillListItemForm v-if="isEdit" />
    <AppCard
        v-else
        class="bg-custom-grey-200 border-2-grey-200"
        :editable="true"
        :removable="true"
        @edit-click="emit('edit-click', item)"
        @delete-click="emit('delete-click', item)"
    >
        <template v-slot:title>
            <v-row class="d-flex mr-5 align-center" no-gutters>
                <v-col
                    :cols="isDesktop ? 6 : 12"
                    class="px-0 text-paragraph-lg font-weight-700"
                    @click="onEnableEditClick"
                >
                    <!--                    {{ isCanvasSkill(item) ? '(c)' : '(i)' }}-->
                    <!--                    {{ item.type }}-->
                    {{ item.description }}
                </v-col>
                <v-col v-if="isDesktop" cols="6" class="pa-0 justify-end text-right">
                    <AppSwitch
                        @change="(i) => emit('change-type', { item: item, type: i.value })"
                        :items="switchOptions"
                    />
                </v-col>
                <v-col v-else cols="12" class="pa-0 justify-start d-flex mt-2">
                    <AppSwitch
                        @change="(i) => emit('change-type', { item: item, type: i.value })"
                        :items="switchOptions"
                    />
                </v-col>
            </v-row>
        </template>
    </AppCard>
</template>

<script setup lang="ts">
    import { type Skill, useSkillsStore } from '@/store/skills/store';
    import AppCard from '@/components/common/cards/AppCard.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import AppSwitch from '@/components/buttons/AppSwitch.vue';
    import { SkillType } from '@/store/skills/types';
    import { computed, ref } from 'vue';
    import { isCanvasSkill, isInferredCanvasSkill } from '@/api/types/canvas/skills';
    import SkillListItemForm from '@/components/canvas/skills/SkillListItemForm.vue';

    const props = defineProps<{
        item: Skill;
    }>();

    const { isDesktop } = useIsDesktop();

    const emit = defineEmits(['edit-click', 'delete-click', 'change-type']);

    const store = useSkillsStore();

    const onEnableEditClick = () => {
        store.setEdit(props.item);
    };

    const onTitleClickOutside = async () => {
        await store.updateSkillTitle(props.item, props.item.description);
    };

    const switchOptions = computed(() => {
        return [
            {
                label: 'Learned',
                selected: props.item.type === SkillType.Learned,
                value: SkillType.Learned,
            },
            {
                label: 'Technical',
                selected: props.item.type === SkillType.Technical,
                value: SkillType.Technical,
            },
        ];
    });

    const isEdit = computed(() => {
        if (store.current) {
            if (isCanvasSkill(props.item)) {
                return store.current.id === props.item.id;
            } else if (isInferredCanvasSkill(props.item)) {
                return store.current.id === props.item.id;
            }
        }

        return false;
    });

</script>

<style scoped lang="scss">

</style>