<template>
    <app-guide-inline
        text="I’ve prefilled some recommended approaches and due dates for you. Please take a look and adjust these to suit you and your goals."
        @close="showDummyGuide = false"
        @submit="showDummyGuide = false"
    />
    <app-guide-chat-activator v-if="showDummyGuide" />
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    const showDummyGuide = ref(true);
</script>
