<template>
    <v-row justify="start" align="end" no-gutters class="pa-0" :class="lgAndUp ? 'w-100' : 'w-100'">
        <!--        <app-button class="font-weight-medium" height="36" :disabled="true">-->
        <!--            Summarise experience-->
        <!--        </app-button>-->
        <app-button class="font-weight-medium" @click="onAddNew" height="36">
            Add experience
        </app-button>
    </v-row>

    <v-row no-gutters>
        <LearnedExperienceTimeline v-if="store.timeline.length" />
        <LearnedExperiencesEmptyCard v-else class="mt-4" :class="lgAndUp ? 'w-50' : 'w-100'" />
    </v-row>
    <LearnedExperienceEditItem v-if="store.current !== null" />

    <UserJobModal v-if="userJobStore.isEditing" />

    <ActivityNotification
        :value="store.learnedExperiences.length"
        :area="UserActivityArea.LearnedExperiences"
    />
</template>

<script setup lang="ts">
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
    import LearnedExperienceTimeline from '@/components/canvas/learned-experiences/LearnedExperienceTimeline.vue';
    import LearnedExperienceEditItem from '@/components/canvas/learned-experiences/LearnedExperienceEditItem.vue';
    import { useUserJobStore } from '@/store/user-job/store';
    import UserJobModal from '@/components/canvas/user-jobs/UserJobModal.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import ActivityNotification from '@/components/canvas/notifications/ActivityNotification.vue';
    import { useDisplay } from 'vuetify';
    import LearnedExperiencesEmptyCard from '@/components/canvas/learned-experiences/LearnedExperiencesEmptyCard.vue';

    const store = useLearnedExperiencesStore();
    const userJobStore = useUserJobStore();

    const { lgAndUp, mdAndUp } = useDisplay();

    const onAddNew = () => {
        store.addLearnedExperience();
    };
</script>
