import { useWebSocket, type UseWebSocketOptions, type UseWebSocketReturn } from '@vueuse/core';
import { verify } from '@/store/verify';
import type { GuideEvent } from '@/store/guide/types';
import { GuideSupportedScreen } from '@/store/guide/types';

const wsServerURl = import.meta.env.VITE_WS_SERVER_URL;

export type WebsocketCallbacks = Pick<
    UseWebSocketOptions,
    'onConnected' | 'onDisconnected' | 'onError' | 'onMessage'
> & { onFailed: () => void };

export const makeWebsocket = (
    path: string,
    accessToken: string,
    options: WebsocketCallbacks,
): UseWebSocketReturn<any> => {
    const url = `${wsServerURl}/${path}?token=${accessToken}`;

    console.info('Initialising websocket...');
    return useWebSocket(url, {
        autoReconnect: {
            retries: 10,
            delay: 1000,
            onFailed: options.onFailed,
        },
        heartbeat: {
            message: 'ping',
            interval: 20000,
            pongTimeout: 20000,
        },
        onConnected: options.onConnected,
        onDisconnected: options.onDisconnected,
        onError: options.onError,
        onMessage: options.onMessage,
    });
};

export const useGuideWebsocket = (
    screen: GuideSupportedScreen,
    canvasId: number,
    accessToken: string,
    options: WebsocketCallbacks,
): UseWebSocketReturn<any> => {
    const path = () => {
        switch (screen) {
            case GuideSupportedScreen.PersonalStrengths:
                return 'personal-strengths';
            case GuideSupportedScreen.PersonalValues:
                return 'personal-values';
            case GuideSupportedScreen.CurrentChallenges:
                return 'current-challenges';
            case GuideSupportedScreen.Skills:
                return 'skills';
            case GuideSupportedScreen.FutureAspirations:
                return 'future-aspirations';
            case GuideSupportedScreen.UVP:
                return 'unique-value-proposition';
            case GuideSupportedScreen.Intro:
                return 'intro';
            case GuideSupportedScreen.Story:
                return 'story';
            case GuideSupportedScreen.CurrentPlan:
                return 'current-plan';
            case GuideSupportedScreen.FuturePlan:
                return 'career-plan';
            default:
                throw new Error(`no enter event for screen ${screen}`);
        }
    };

    return makeWebsocket(`canvas/${canvasId}/${path()}/guide`, accessToken, options);
};

export const sendEvent = (websocket: any | null, event: GuideEvent) => {
    const ws = verify(websocket, 'no websocket');

    const json = JSON.stringify(event);

    // @ts-ignore not sure how to fix this fow
    (ws as UseWebSocketReturn<any>).send(json);
};
