<template>
    <teleport to="#guide-teleport-container-avatar">
        <div class="mb-4 mr-4">
            <v-badge
                v-if="store.isClosedWithSuggestions"
                color="custom-grey-500"
                :inline="true"
                :floating="true"
            >
                <template #badge>
                    <span class="badge-closed-with-suggestions" />
                </template>
                <app-guide-avatar size="80" :animate="true" @click="onShowDialog" />
            </v-badge>
            <app-guide-avatar v-else-if="model" size="80" @click="onShowDialog" />
        </div>
    </teleport>
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    import { useGuideStore } from '@/store/guide/store';

    const model = ref(true);

    const store = useGuideStore();

    const onShowDialog = (value: boolean) => {
        store.showDialog();
    };
</script>

<style scoped lang="scss">
    :deep(.v-badge) {
        display: inline-flex !important;
    }

    :deep(.v-badge__badge) {
        min-width: 30px;
        min-height: 30px;
        border-radius: 20px;
        position: relative;
        margin-top: -48px;
        margin-left: -13px;
    }

    .badge-closed-with-suggestions {
        color: white;
    }

    .badge-closed-with-suggestions:after {
        content: '';
        animation: spin 3s linear infinite;
    }
</style>
