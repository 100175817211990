<template>
    <v-card
        min-width="100"
        :color="cardColor"
        min-height="80"
        elevation="0"
        class="rounded-4 pa-6 ma-0 learned-experience-list-item"
        :class="editable ? 'cursor-pointer' : 'no-cursor-pointer'"
        @click="editable ? store.setEdit(props.item) : false"
    >
        <v-card-item class="pa-0 ml-6">
            <span class="mx-0 font-weight-600 text-subtitle-1">
                {{ props.item.title }}
            </span>
            <template v-slot:append>
                <v-icon class="align-start mr-2" v-if="editable"> mdi-pencil</v-icon>
            </template>
        </v-card-item>
        <v-card-text class="ml-6 my-0 pa-0 text-caption text-custom-grey-600">
            {{ props.item.description }}
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { type CanvasLearnedExperience } from '@/store/learned-experiences/types';
    import { getEnjoymentColor } from '@/components/canvas/learned-experiences/util';
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';

    const props = withDefaults(
        defineProps<{
            item: CanvasLearnedExperience;
            editable?: boolean;
        }>(),
        { editable: true },
    );

    const store = useLearnedExperiencesStore();

    const cardColor = computed(() => {
        return getEnjoymentColor(props.item.enjoyment, 0.2);
    });
</script>
