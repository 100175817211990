<template>
    <app-guide-modal-multiple-steps
        :model-value="modelValue"
        @update:model-value="(v) => emit('update:modelValue', v)"
        section-name="Personality"
        action-text="Let's go"
        :steps="2"
    >
        <template v-slot:title>Welcome to Personality!</template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    Have you undertaken a Personality Test recently? We recommend you do as its a
                    rich source of insight into the way you are biologically wired.
                </p>
                <p>
                    We favour the Myers Briggs Type Indicator test as its one of the most
                    scientifically robust. If you know your type great! If not you can take the free
                    test
                    <a target="_blank" href="https://www.16personalities.com/personality-types">
                        here</a
                    >. It will only take 10 minutes and will reveal some rich insights.
                </p>
            </div>
            <div v-if="step === 2">
                <p>
                    The Myers Briggs Type Indicator categorizes individuals into one of 16
                    personality types based on preferences for introversion / extroversion, sensing
                    / intuition, thinking / feeling, and judging / perceiving.
                </p>
                <p>
                    Knowing your personality type can help navigate you to the work and teams that
                    best suit your innate style.
                </p>
            </div>
        </template>
    </app-guide-modal-multiple-steps>
</template>
<script setup lang="ts">
    import AppGuideModalMultipleSteps from '@/components/guide/modal/AppGuideModalMultipleSteps.vue';

    const props = defineProps<{
        modelValue: boolean;
    }>();

    const emit = defineEmits(['update:modelValue']);
</script>
