<template>
    <app-button
        color="primary"
        variant="flat"
        :to="props.to"
        :prepend-icon="props.prependIcon"
        :append-icon="props.appendIcon"
        :loading="props.loading"
        :disabled="props.disabled ?? false"
    >
        {{ props.text ?? 'Save' }}
    </app-button>
</template>

<script setup lang="ts">
    import type { RouteLocationRaw } from 'vue-router';

    const props = defineProps<{
        prependIcon?: string;
        appendIcon?: string;
        disabled?: boolean;
        text?: string;
        to?: RouteLocationRaw;
        loading?: boolean;
    }>();
</script>
