import type { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';

export type GetAccessToken = (options?: GetTokenSilentlyOptions) => Promise<string>;

/**
 * This state helps to DRY and prevent buggy client code when authenticating with the API.
 *
 * Context: Auth0 provides us with a function to generate a JWT accessToken (getAccessTokenSilently)
 * that we use in our API to authenticate the requests.
 *
 * At the moment most of the pinia stores rely on this 'accessToken (string) being passed ad-hoc
 * when a request needs to done.
 *
 * e.g.:
 * ```async save(user: User, accessToken: string): Promise<void> { ```
 *
 * If we want to avoid passing this accessToken argument everywhere, we can add it as part of the store state.
 * What its important to note, is that we need to keep the function that generates the token instead of just
 * the token, as this token can expire, and then we will have requests that fail to authenticate.
 *
 * From Auth0 documentation, we are running with the cache-mode: 'on', which means that Auth0 makes the decision
 * to fetch a new JWT or not.
 * @see {@link GetTokenSilentlyOptions}
 */
export type WithAccessToken = {
    _getAccessToken: GetAccessToken | null;
};

export const makeAccessTokenState = (): WithAccessToken => {
    return {
        _getAccessToken: null,
    };
};

export const setAccessTokenGenerator = (
    storeState: WithAccessToken,
    accessToken: GetAccessToken,
) => {
    storeState._getAccessToken = accessToken;
};
export const getAccessTokenSilentlyOrFail = async (
    storeState: WithAccessToken,
): Promise<string> => {
    const fn = storeState._getAccessToken;
    if (fn) {
        const token = await fn();

        if (token) {
            return token;
        } else {
            throw new Error('Could not generate access token');
        }
    } else {
        throw new Error('No access token generator');
    }
};
