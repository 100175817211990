<template>
    <app-page>
        <app-page-header title="Challenge pathway" @show-tutorial="showTutorial = true" />
        <ChallengePathTutorial v-model="showTutorial" />

        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="Select a challenge">
                    Choose a challenge so that we can build a plan of activities to help you solve
                    it.

                    <br />
                    <br />
                    We suggest you start with something easy.
                </app-page-content-intro>
            </template>

            <v-container class="pa-0" v-if="store.inProgressChallenges.length">
                <div class="text-h5 font-weight-bold">In progress</div>
                <v-card variant="flat" class="pa-0">
                    <v-card-text class="pa-0 mt-5">
                        <PathwayChallengeListItem
                            v-for="(item, index) in store.inProgressChallenges"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>

            <v-container class="pa-0 mt-8" v-if="store.notStartedChallenges.length">
                <div class="text-h5 font-weight-bold">Not started</div>
                <v-card variant="flat" class="pa-0">
                    <v-card-text class="pa-0 mt-5">
                        <PathwayChallengeListItem
                            v-for="(item, index) in store.notStartedChallenges"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>

            <v-container class="pa-0 mt-8" v-if="store.completedChallenges.length">
                <div class="text-h5 font-weight-bold">Completed</div>
                <v-card variant="flat" class="pa-0">
                    <v-card-text class="pa-0 mt-5">
                        <PathwayChallengeListItem
                            v-for="(item, index) in store.completedChallenges"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>
        </app-page-content>

        <app-page-footer>
            <template v-slot:actions>
                <CanvasNavOneButton text="Back to story" :to="storyRoute" />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.ChallengePath" />
    </app-page>
    <ChallengePathGuide />
</template>

<script setup lang="ts">
    import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

    import { useCanvasStore } from '@/store/canvas/store';
    import { useChallengePathStore } from '@/store/challenge-path/challengePathStore';

    import { UserActivityArea } from '@/api/types/userActivity';
    import CanvasNavOneButton from '@/components/canvas/CanvasNavOneButton.vue';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { currentChallengeRoute, storyRoute } from '@/router/routes';
    import ChallengePathTutorial from '@/components/pathway/ChallengePathTutorial.vue';
    import { useActivityStore } from '@/store/activity/store';
    import AppPageContentIntro from '@/components/common/app-page/AppPageContentIntro.vue';
    import PathwayChallengeListItem from '@/components/pathway/PathwayChallengeListItem.vue';
    import ChallengePathGuide from '@/components/pathway/ChallengePathGuide.vue';

    const store = useChallengePathStore();
    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();

    await canvasStore.load();
    await store.load();

    const showTutorial = ref(false);

    onMounted(async () => {
        const noChallengesStarted =
            store.notStartedChallenges.length &&
            !store.inProgressChallenges.length &&
            !store.completedChallenges.length;
        if (!store.hasChallenges || noChallengesStarted) {
            showTutorial.value = true;
        }

        await activityStore.addEntryActivity(UserActivityArea.ChallengePath);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.ChallengePath);
    });

    const progress = computed(() => {
        const completed = store.completedChallenges.length;
        const inProgress = store.inProgressChallenges.length;
        const notStarted = store.notStartedChallenges.length;

        // make a progress percentage counting completed as full, and in progress as half
        return ((completed + inProgress / 2) / (completed + inProgress + notStarted)) * 100;
    });
</script>
