import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { last } from 'lodash';
import type { UniqueValuePropositionStoreState } from '@/store/unique-value-proposition/types';
import type { CanvasUniqueValueProposition } from '@/api/types/canvas/uniqueValueProposition';
import { fetchCanvasUniqueValuePropositions } from '@/services/unique-value-proposition/service';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
} from '@/store/common/dataState';
import { useCanvasStore } from '@/store/canvas/store';

export const useUVPStore = defineStore({
    id: 'unique-value-proposition-store',
    state: (): UniqueValuePropositionStoreState => ({
        values: [],
        statement: '',
        currentPersonalIndex: 0,
        currentLearnedIndex: 0,
        currentTechnicalIndex: 0,

        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        hasStatement(state): boolean {
            return !!this.currentUVP?.statement;
        },
        currentUVP(state): CanvasUniqueValueProposition | null {
            return last(this.values) ?? null;
        },
        needsLoading(state): boolean {
            return state.values.length === 0;
        },
    },
    actions: {
        async load() {
            if (!this.needsLoading) {
                console.info('Unique value proposition already loaded');
                return;
            }

            const { accessToken, canvasId } = await useCanvasStore().makeContext();

            console.info('Loading unique value proposition...');

            setInLoading(this);

            try {
                const canvasUVPs = await fetchCanvasUniqueValuePropositions(canvasId, accessToken);
                this._setValues(canvasUVPs);

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },

        // ###############################
        //
        // Side - effects
        //
        // ###############################
        _setValues: function (rawValues: CanvasUniqueValueProposition[]) {
            this.values = rawValues.map((item) => {
                const vals = {
                    ...item,
                    uuid: uuidv4(),
                };
                console.log('UVP:::: ' + item.statement);
                return vals;
            });
            if (rawValues.length > 0) {
                const latestUVP = rawValues[rawValues.length - 1];
                this.statement = latestUVP.statement;
                console.log('LATEST UVP:::: ' + latestUVP.statement);
            }
        },
    },
});
