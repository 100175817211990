<template>
    <div v-if="displayAction && inlineGuideStore.hasMessage && actionValue">
        <app-guide-inline
            v-if="
                isNavigationGuideActionKey(actionValue) ||
                actionValue === InlineGuideActionName.ActivitiesComplete
            "
            :title="inlineGuideStore.title"
            :text="inlineGuideStore.content"
            @close="clear"
            @submit="click"
            :action-text="actionLabel"
        />
        <UploadCurriculumGuideInline
            v-else-if="
                actionValue === InlineGuideActionName.UploadCv ||
                actionValue === InlineGuideActionName.ReTryUploadCv
            "
            @after-upload="click"
            :title="inlineGuideStore.title"
            :text="inlineGuideStore.content"
            class="w-100"
        />
    </div>
</template>

<script setup lang="ts">
    import { computed, onMounted } from 'vue';
    import { useInlineGuideStore } from '@/store/inline-guide/store';
    import { useRouter } from 'vue-router';
    import {
        careerHistoryRoute,
        currentChallengeRoute,
        futureAspirationRoute,
        learnedExperienceRoute,
        personalityTypeRoute,
        personalStrengthRoute,
        personalValuesRoute,
        skillsRoute,
    } from '@/router/routes';
    import { InlineGuideActionName, isNavigationGuideActionKey } from '@/store/inline-guide/types';
    import UploadCurriculumGuideInline from '@/components/canvas/learned-experiences/UploadCurriculumGuideInline.vue';
    import type { InlineGuideActionMap } from '@/components/inline-guide/types';

    const router = useRouter();

    const props = withDefaults(
        defineProps<{
            /** Only show the guide for these actions (if provided). */
            only?: InlineGuideActionName[];
        }>(),
        {
            only: undefined,
        },
    );

    const displayAction = computed(() => {
        if (!props.only) return true;
        if (!actionValue.value) return false;
        return props.only.includes(actionValue.value);
    });

    const inlineGuideStore = useInlineGuideStore();
    onMounted(async () => {
        await inlineGuideStore.getStoryMessage();
    });

    const clear = () => {
        inlineGuideStore.clear();
    };

    const actionMap: InlineGuideActionMap = {
        //
        // Void actions
        //
        [InlineGuideActionName.ActivitiesComplete]: {
            type: 'void',
            label: 'Dismiss',
        },
        //
        // CV related actions with callbacks
        //
        [InlineGuideActionName.UploadCv]: {
            type: 'callback',
            callback: async () => {
                await inlineGuideStore.getStoryMessage();
            },
            label: 'Dismiss',
        },
        [InlineGuideActionName.ReTryUploadCv]: {
            type: 'callback',
            callback: async () => {
                await inlineGuideStore.getStoryMessage();
            },
            label: 'Dismiss',
        },
        //
        // Navigation related actions
        //
        [InlineGuideActionName.GotoCareerHistory]: {
            type: 'navigation',
            route: careerHistoryRoute,
            label: 'Go to my Career History',
        },
        [InlineGuideActionName.GotoValues]: {
            type: 'navigation',
            route: personalValuesRoute,
            label: 'Go to my Values',
        },
        [InlineGuideActionName.GotoPersonalityType]: {
            type: 'navigation',
            route: personalityTypeRoute,
            label: 'Go to my Personality Type',
        },
        [InlineGuideActionName.GotoLearnedExperiences]: {
            type: 'navigation',
            route: learnedExperienceRoute,
            label: 'Go to my Learned Experiences',
        },
        [InlineGuideActionName.GotoSkills]: {
            type: 'navigation',
            route: skillsRoute,
            label: 'Go to my Skills',
        },
        [InlineGuideActionName.GotoPersonalStrength]: {
            type: 'navigation',
            route: personalStrengthRoute,
            label: 'Go to my personal strengths',
        },
        [InlineGuideActionName.GotoCurrentChallenges]: {
            type: 'navigation',
            route: currentChallengeRoute,
            label: 'Go to my Current Challenges',
        },
        [InlineGuideActionName.GotoFutureAspiration]: {
            type: 'navigation',
            route: futureAspirationRoute,
            label: 'Go to my Future Aspiration',
        },
    } as const;

    const actionValue = computed(() => inlineGuideStore.action ?? null);

    const click = async () => {
        const value = action.value;
        if (!value) return;

        if (value.type === 'void') {
            clear();
        } else if (value.type === 'callback') {
            clear();
            await value.callback();
        } else if (value.type === 'navigation') {
            clear();
            await router.push(value.route);
        } else {
            console.error('Unknown action type');
        }
    };

    const action = computed(() => {
        return actionValue.value ? actionMap[actionValue.value] : null;
    });

    const actionLabel = computed(() => action.value?.label || 'Dismiss');
</script>
