<template>
    <!--
        TODO: The error should be renamed to notifications
        It used to be part of the layout, but since it is implemented with a v-snackbar
        and its position is absolute, it could be taken out of this component.
     -->
    <slot name="errors" />

    <v-container class="pa-0 w-100" fluid v-if="mdAndUp">
        <!-- The padding tries to compensate for a         -->
        <v-footer
            height="95"
            :app="!noApp"
            :class="`${hasGuideSlot ? '' : ' pb-5 pt-5 '} ${hasActionsSlot ? ' border-t-sm ' : ''}`"
        >
            <v-row no-gutters v-if="hasGuideSlot && hasActionsSlot">
                <!--
                    The idea here is that the maximum the actions take is 10 columns.
                    The guide will be in the far right
                -->
                <v-col
                    class="mt-4"
                    :md="max10Columns(props.md)"
                    :lg="max10Columns(props.lg)"
                    :xl="max10Columns(props.xl)"
                >
                    <slot name="actions" />
                </v-col>
                <v-spacer v-if="hasGuideSlot" />
                <v-col cols="1" class="text-left" v-if="hasGuideSlot">
                    <div class="">
                        <slot name="guide" />
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters v-else-if="hasActionsSlot">
                <v-col :md="props.md" :lg="props.lg" :xl="props.xl">
                    <slot name="actions" />
                </v-col>
            </v-row>
            <v-row no-gutters v-else-if="hasGuideSlot">
                <div class="guide-button-fixed">
                    <v-col>
                        <slot name="guide" />
                    </v-col>
                </div>
            </v-row>
        </v-footer>
    </v-container>
    <v-container class="px-0 d-flex flex-column" fluid v-else>
        <v-row no-gutters justify="end" v-if="hasGuideSlot">
            <v-col align="end">
                <slot name="guide" />
            </v-col>
        </v-row>
        <v-row no-gutters v-if="hasActionsSlot">
            <v-col>
                <slot name="actions" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
    import { useDisplay } from 'vuetify';
    import { computed, useSlots } from 'vue';

    const { mdAndUp } = useDisplay();

    const props = defineProps<{
        noApp?: boolean;
        md?: string | number | undefined;
        lg?: string | number | undefined;
        xl?: string | number | undefined;
    }>();

    const slots = useSlots();

    const max10Columns = (col: string | number | undefined) => {
        if (col) {
            return Math.min(Number(col), hasGuideSlot.value ? 10 : 11);
        }

        return 10;
    };
    const hasGuideSlot = computed(() => {
        return !!slots['guide'];
    });

    const hasActionsSlot = computed(() => {
        return !!slots['actions'];
    });
</script>

<style scoped lang="scss">
    .guide-button-fixed {
        position: fixed;
        bottom: 40px;
        right: 40px;
    }

    footer {
        padding-left: calc(var(--desktop-layout-horizontal-gap) * 1px) !important;
        padding-right: calc(var(--desktop-layout-horizontal-gap) * 1px) !important;
    }
</style>
