<template>
    <app-guide-modal-multiple-steps
        :model-value="modelValue"
        @update:model-value="(v) => emit('update:modelValue', v)"
        section-name="Personal Values"
        action-text="Let's go"
        :steps="2"
    >
        <template v-slot:title>Choose your Core or Growth values</template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    Your CORE values are the values that guide your life decisions, and generally
                    don't change. Your GROWTH values are those you'd like to grow into and adopt
                    more in your future.
                </p>
                <p>
                    This is a tricky activity! Take your time and choose carefully, as you can only
                    hold 6 CORE values and 6 GROWTH values. It's a process of what matters most, to
                    you now, in your working life.
                </p>
            </div>
            <div v-else-if="step === 2">
                <p>
                    Click the left or right button to scroll through the value cards, and click the
                    “Add as Core” or “Add as Growth” buttons to add them to your stack.
                </p>
                <p>
                    Feel free to re-arrange after you’ve added them by dragging and dropping. And if
                    you’ve decided a value is no longer important, just remove it.
                </p>
                <p>
                    You can come back and re-do this activity anytime - so the values you don't
                    choose now, you can select later if things change.
                </p>
            </div>
        </template>
    </app-guide-modal-multiple-steps>
</template>
<script setup lang="ts">
    import AppGuideModalMultipleSteps from '@/components/guide/modal/AppGuideModalMultipleSteps.vue';

    const props = defineProps<{
        modelValue: boolean;
    }>();

    const emit = defineEmits(['update:modelValue']);
</script>
