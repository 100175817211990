<template>
    <v-textarea
        ref="input"
        :model-value="props.value"
        :disabled="props.disable"
        :readonly="props.readonly"
        @input="$emit('update:modelValue', $event.target.value)"
        variant="outlined"
        class="add-free-form"
    />
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    const props = defineProps<{
        value?: string;
        disable?: boolean;
        readonly?: boolean;
    }>();

    const input = ref(null);
</script>

<style scoped lang="scss">
    .add-free-form :deep(.v-field) {
        border-left: 2px solid;
        border-top: 2px solid;
        border-right: 4px solid;
        border-bottom: 4px solid;
        border-color: rgb(var(--v-theme-custom-purple-mid));
    }
</style>
