<template>
    <v-container class="ma-0 before-pdf-break-page story-section-container" fluid :class="cssClass">
        <v-row
            class="w-100 pa-0"
            no-gutters
            variant="flat"
            :color="backgroundColor"
            :class="responsiveClass"
        >
            <v-col :cols="isDesktop ? 3 : 12" class="pa-0">
                <v-card :color="backgroundColor" variant="flat">
                    <v-row no-gutters>
                        <!-- TODO: review image height -->
                        <img :src="props.section.image" class="w-50 h-100" />
                    </v-row>
                    <div
                        class="text-heading-h1 font-weight-700 w-100"
                        :class="isDesktop ? 'mt-10' : 'mt-5'"
                    >
                        {{ props.section.title }}
                    </div>
                    <div
                        class="text-paragraph-lg text-left w-100"
                        :class="isDesktop ? 'mt-2' : 'mt-5'"
                    >
                        {{ props.section.description }}
                    </div>
                </v-card>
            </v-col>
            <v-spacer v-if="isDesktop" />
            <v-col :cols="isDesktop ? 8 : 12">
                <!-- TODO: At the moment we do not have a state on the section content -->
                <StorySectionStatementInProgress v-if="section.item?.isProcessing" :section="section" />
                <StorySectionStatement
                    v-else-if="section.statement"
                    :section="section"
                    @on-feedback="onFeedback"
                />
                <StorySectionStatementNotStarted v-else />

                <StorySectionActivities class="ma-0" :items="props.section.activities" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { computed } from 'vue';
    import StorySectionStatement from '@/components/story/section/statement/StorySectionStatement.vue';
    import StorySectionActivities from '@/components/story/section/activity/StorySectionActivities.vue';
    import StorySectionStatementNotStarted from '@/components/story/section/statement/StorySectionStatementNotStarted.vue';
    import StorySectionStatementInProgress from '@/components/story/section/statement/StorySectionStatementInProgress.vue';
    import { useStoryStore } from '@/store/story/store';
    import { StorySectionId, type StorySectionItem } from '@/store/story/types';

    const { responsiveClass, isDesktop } = useIsDesktop();

    const props = defineProps<{
        section: StorySectionItem;
    }>();

    const cssClass = computed(() => {
        return {
            [responsiveClass.value]: true,
            [props.section.id]: true,
        };
    });

    const backgroundColor = computed(() => {
        switch (props.section.id) {
            case StorySectionId.WhatIOffer:
                return 'secondary-blue-light';
            case StorySectionId.WhoIAm:
                return 'secondary-purple-light';
            case StorySectionId.WhatIAmWorkingOn:
                return 'secondary-pink-light';
            default:
                throw new Error('Invalid section type');
        }
    });

    const store = useStoryStore();
    const onFeedback = async (value: boolean) => {
        if (!props.section.item) {
            return;
        }

        await store.saveFeedback(props.section.id, props.section.item, value);
    };
</script>

<style scoped lang="scss">
    .what-i-offer {
        --section-background: rgb(var(--v-theme-secondary-blue-light));
    }

    .who-i-am {
        --section-background: rgb(var(--v-theme-secondary-purple-light));
    }

    .what-i-am-working-on {
        --section-background: rgb(var(--v-theme-secondary-pink-light));
    }

    .story-section-container {
        border-radius: 30px;
        background: var(--section-background);

        &.desktop {
            padding: 80px;
        }

        &.mobile {
            padding: 32px 20px;

            > .v-row {
                gap: 40px;
            }
        }
    }
</style>
