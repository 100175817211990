<template>
    <StorySectionActivityInlineList
        :label="store.mbtiType?.value ?? ''"
        :items="[description]"
        class="w-100"
    />
</template>

<script setup lang="ts">
    import { usePersonalityTypeStore } from '@/store/personality-type/store';
    import StorySectionActivityInlineList from '@/components/story/section/activity/StorySectionActivityInlineList.vue';
    import { computed } from 'vue';
    import { PERSONALITY_TYPES_DEFINITION } from '@/store/personality-type/utils';
    import type { StoryActivity } from '@/store/story/types';

    const props = defineProps<{
        item: StoryActivity;
    }>();

    const store = usePersonalityTypeStore();

    const description = computed(() => {
        const value = store.mbtiType?.value;
        if (value) {
            return PERSONALITY_TYPES_DEFINITION[value].description ?? '';
        }

        return '';
    });
</script>
