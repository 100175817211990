import type { EditableInferredSkillItem, EditableSkillItem } from '@/store/skills/types';
import { SkillType } from '@/store/skills/types';
import type { CanvasSkill, InferredCanvasSkill } from '@/api/types/canvas/skills';
import type { Skill } from '@/store/skills/store';

export function filterByType(selection: Skill[], type: SkillType): Skill[] {
    return selection.filter((selection: Skill) => {
        return selection.type === type;
    });
}

export function filterCanvasSkillsByType(selection: CanvasSkill[], type: SkillType): CanvasSkill[] {
    return selection.filter((selection: CanvasSkill) => {
        return selection.type === type;
    });
}

export const byDescriptionAlphabetically = (a: Skill, b: Skill) => {
    return a.description.localeCompare(b.description);
};

export enum SkillItemState {
    New = 'new',
    Edit = 'edit',
    Creating = 'creating',
    Deleting = 'deleting',
    Updating = 'updating',
    Error = 'error',
    Saved = 'saved',
}

export function setEditableSkillState(experience: EditableSkillItem, state: SkillItemState): void {
    experience.prevStatus = experience.status;
    experience.status = state;
}

export function makeNewSkill(type?: SkillType): EditableSkillItem {
    return {
        type: type ?? null,
        description: '',
        status: SkillItemState.New,
        prevStatus: null,
    };
}

export function makeEditableSkillItem(skill: CanvasSkill): EditableSkillItem {
    return {
        id: skill.id,
        type: skill.type,
        description: skill.description,
        inferred_skill_id: skill.inferred_skill_id,
        prevStatus: null,
        status: SkillItemState.Edit,
    };
}

export function makeEditableInferredSkill(skill: InferredCanvasSkill): EditableInferredSkillItem {
    return {
        id: skill.id,
        type: skill.type,
        description: skill.description,
        canvas_id: skill.canvas_id,
        state: skill.state,

        prevStatus: null,
        status: SkillItemState.Edit,
    };
}
