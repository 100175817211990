<template>
    <PersonalValuesSecondStep />
</template>

<script setup lang="ts">
    import { onBeforeUnmount } from 'vue';
    import { usePersonalValuesStore } from '@/store/personal-values/store';
    import { useCanvasStore } from '@/store/canvas/store';
    import PersonalValuesSecondStep from '@/components/canvas/personal-values/upload/second-step/PersonalValuesSecondStep.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import { useActivityStore } from '@/store/activity/store';

    const canvasStore = useCanvasStore();
    const store = usePersonalValuesStore();
    const activityStore = useActivityStore();

    await activityStore.load();
    await canvasStore.load();
    await store.load();

    await activityStore.addEntryActivity(UserActivityArea.PersonalValues);

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.PersonalValues);
    });
</script>

<style scoped lang="scss">
    :deep(.category-independence),
    :deep(.category-independence .v-progress-linear__background) {
        background: var(--pv-independence-background);
    }

    :deep(.category-adventure),
    :deep(.category-adventure .v-progress-linear__background) {
        background: var(--pv-adventure-background);
    }

    :deep(.category-caring),
    :deep(.category-caring .v-progress-linear__background) {
        background: var(--pv-virtue-background);
    }

    :deep(.category-ambition),
    :deep(.category-ambition .v-progress-linear__background) {
        background: var(--pv-ambition-background);
    }

    :deep(.category-understanding),
    :deep(.category-understanding .v-progress-linear__background) {
        background: var(--pv-understanding-background);
    }

    :deep(.category-creative),
    :deep(.category-creative .v-progress-linear__background) {
        background: var(--pv-creative-background);
    }

    :deep(.category-independence),
    :deep(.category-adventure),
    :deep(.category-understanding) {
        color: rgb(var(--v-theme-custom-grey-900));
    }

    :deep(.category-ambition),
    :deep(.category-caring),
    :deep(.category-creative) {
        color: rgb(var(--v-theme-custom-grey-50));
    }
</style>
