<template>
    <v-dialog
        :model-value="store.isDialogOpen"
        max-width="900"
        :fullscreen="true"
        :scrollable="true"
        :close-on-back="false"
        :content-class="lgAndUp ? 'guide-dialog-large' : ''"
        @update:modelValue="onUpdatedValue"
    >
        <v-card v-if="!store.isInitialised || store.isConnecting">
            <Loader />
        </v-card>
        <GuideChat
            class="fill-height"
            v-if="userStore.user"
            :screen="props.screen"
            @submit="() => store.hideDialog()"
            :user="userStore.user"
        >
            <template v-slot:suggestion-message="{ message }">
                <slot name="suggestion" :message="message" />
            </template>
        </GuideChat>
    </v-dialog>
</template>

<script setup lang="ts">
    import { useGuideStore } from '@/store/guide/store';
    import { useDisplay } from 'vuetify';
    import { useUsersStore } from '@/store/user/store';
    import Loader from '@/components/common/Loader.vue';
    import { useAuth0 } from '@/auth/auth';
    import { GuideSupportedScreen } from '@/store/guide/types';
    import { onBeforeUnmount } from 'vue';
    import GuideChat from '@/components/guide/chat/GuideChat.vue';

    const { lgAndUp } = useDisplay();

    const props = defineProps<{
        screen: GuideSupportedScreen;
    }>();

    const store = useGuideStore();
    const userStore = useUsersStore();

    const { getAccessTokenSilently } = useAuth0();

    await store.connect(props.screen, getAccessTokenSilently);
    await store.onUserEnterScreen(props.screen);

    onBeforeUnmount(() => {
        console.log(`onBeforeUnmount guide dialog ${props.screen}`);
        store.onUserLeaveScreen(props.screen);
        store.stop();
    });

    const onUpdatedValue = (value?: boolean) => {
        if (value === false) {
            store.hideDialog();
        }
    };
</script>

<style lang="scss">
    .guide-dialog-large {
        position: absolute;
        bottom: 0;
        right: 0;
        left: unset !important;
        margin: 0 !important;
    }
</style>
