<template>
    <v-card
        min-height="60"
        min-width="260"
        class="rounded-0 rounded-lg challenge-item border-2-grey-200"
        elevation="0"
        :class="contentClass"
    >
        <v-card-text class="pa-0">
            <v-row no-gutters>
                <v-col :cols="smAndDown ? 8 : 10" class="px-5 py-5">
                    <span class="text-heading-h4 mt-8 cursor-pointer">
                        {{ item.description }}
                    </span>
                    <br />
                    <div class="pt-2" v-if="isInProgress">
                        <v-progress-linear
                            :model-value="progress"
                            color="secondary-orange"
                            height="8"
                            :rounded="true"
                            class="w-100"
                        ></v-progress-linear>
                    </div>
                </v-col>
                <v-col
                    :cols="smAndDown ? 4 : 2"
                    class="d-flex align-center justify-center action-column"
                >
                    <app-button
                        class="px-0 fill-height w-100 text-none text-custom-grey-900 font-weight-600 opacity-1 rounded-0"
                        v-if="isInProgress"
                        variant="flat"
                        color="primary"
                        :ripple="false"
                        @click="onUpdateInProgressClick"
                        >Update
                    </app-button>
                    <app-button
                        class="px-0 fill-height w-100 text-none text-custom-grey-900 font-weight-600 opacity-1 rounded-0"
                        v-else-if="notStarted"
                        variant="flat"
                        color="primary"
                        :ripple="false"
                        @click="onStartClick"
                        >Start
                    </app-button>
                    <v-icon v-else-if="isCompleted">mdi-check</v-icon>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import {
        type CanvasCurrentChallenge,
        CurrentChallengeState,
    } from '@/api/types/canvas/currentChallenge';
    import { useRouter } from 'vue-router';
    import { challengeUpdateProgressRoute, challengeStartRoute } from '@/router/routes';
    import { useDisplay } from 'vuetify';

    const router = useRouter();

    const { smAndDown } = useDisplay();

    const props = withDefaults(
        defineProps<{
            item: CanvasCurrentChallenge;
            isEditable: boolean;
        }>(),
        { isEditable: true },
    ); // set the isEditable prop true by default

    const contentClass = computed(() => {
        if (isCompleted.value) {
            return 'challenge-item-completed';
        } else if (notStarted.value) {
            return 'challenge-item-not-started';
        }
        return 'challenge-item-in-progress';
    });

    const isInProgress = computed(() => {
        return props.item.state === CurrentChallengeState.InProgress;
    });

    const notStarted = computed(() => {
        return props.item.state === CurrentChallengeState.New;
    });

    const isCompleted = computed(() => {
        return props.item.state === CurrentChallengeState.Completed;
    });

    const progress = computed(() => {
        const total = props.item.actions.length;
        const completedItems = props.item.actions.filter((item) => {
            return item.state === 'completed';
        });

        return (completedItems.length * 100) / total;
    });

    const onUpdateInProgressClick = () => {
        router.push(challengeUpdateProgressRoute(props.item.id));
    };
    const onStartClick = async () => {
        router.push(challengeStartRoute(props.item.id));
    };
</script>

<style scoped lang="scss">
    .challenge-item {
        .action-column {
            height: 100%;
            position: absolute;
            padding-left: 0;
            top: 0;
            right: 0;
            padding-top: 16px;

            :deep(.v-btn__content) {
                letter-spacing: -0.175px;
            }
        }

        &.challenge-item-in-progress,
        &.challenge-item-not-started {
            .action-column {
                border-left: 1px solid rgba(var(--v-theme-custom-grey-900), 0.1);
            }
        }

        &.challenge-item-completed {
            .action-column {
                border-left: 1px solid rgba(#fff, 0.2);
            }
        }
    }

    .challenge-item-in-progress {
        &.v-card {
            background-color: white;
            color: rgb(var(--v-theme-custom-neutral-900));
        }
    }

    .challenge-item-not-started {
        &.v-card {
            background-color: white;
            color: rgb(var(--v-theme-custom-neutral-900));
        }
    }

    .challenge-item-completed {
        &.v-card {
            background-color: white;
            color: rgb(var(--v-theme-custom-neutral-900));
        }
    }
</style>
