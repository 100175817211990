<template>
    <v-card elevation="0" class="text-custom-grey-900">
        <v-card-title
            class="text-heading-h4 font-weight-700 text-wrap px-0 py-2"
            v-if="curriculums.length"
        >
            Existing CV's
        </v-card-title>

        <v-card-text class="px-0 text-wrap text-paragraph-md">
            <span class="px-0 pt-5 pb-3">
                <CurriculumVitaeItem
                    :file="cv"
                    :key="index"
                    v-for="(cv, index) in curriculums"
                    class="mb-4"
                />
            </span>
        </v-card-text>

        <v-card-title class="text-heading-h4 font-weight-700 text-wrap px-0 py-2">
            {{ curriculums.length ? 'Add your latest CV' : 'Add your CV' }}
        </v-card-title>

        <v-card-text class="px-0 text-wrap text-paragraph-md">
            <p class="mb-8">
                Sharing your CV serves as a starting point to identify your skills, capabilities,
                and experiences. Don’t worry if it’s not up to date! This helps us understand your
                career history and goals.
            </p>

            <span class="px-0 pt-5 pb-3">
                <CurriculumVitaeInput />
            </span>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import CurriculumVitaeInput from '@/components/profile/curriculum/CurriculumVitaeInput.vue';
    import { computed } from 'vue';
    import { useUserProfileStore } from '@/store/profile/store';
    import CurriculumVitaeItem from '@/components/profile/curriculum/CurriculumVitaeItem.vue';

    const store = useUserProfileStore();

    const curriculums = computed(() => {
        return (store.userInputs?.curriculums ?? []).map((cv) => {
            return new File([], cv.filename, {
                type: cv.content_type,
                lastModified: new Date(cv.updated_at).getTime(),
            });
        });
    });
</script>
