<template>
    <MainContent>
        <v-card elevation="0" class="text-custom-grey-900">
            <v-card-title class="text-h5 font-weight-bold text-wrap px-0 py-2">
                Introducing Alex
            </v-card-title>

            <v-container class="pa-0 text-wrap text-paragraph-md">
                <v-banner v-if="!smAndDown">
                    <template #prepend>
                        <app-guide-avatar size="210" />
                    </template>
                    <v-banner-text class="text-h5">
                        Hi, I’m Alex. Im your Career Guide and I’ve been trained by ACTVO's Founder
                        Melissa Jenner.
                    </v-banner-text>
                </v-banner>
                <v-banner v-if="smAndDown">
                    <template #prepend>
                        <app-guide-avatar size="60" />
                    </template>
                    <v-banner-text class="text-subtitle-1">
                        Hi, I’m Alex. Im your Career Guide and I’ve been trained by ACTVO's Founder
                        Melissa Jenner.
                    </v-banner-text>
                </v-banner>
                <p class="mt-4 mb-4 text-left text-body-2">
                    My role is to support you through this application, give you general career
                    advice and connect you with the resources you need to activate your ambition. I
                    rely on a proven methodology that underpins my advice, that has been used with
                    1000s of executives just like you. So feel free to call on me regularly with any
                    questions or concerns about your career and the information you are gathering
                    here!
                </p>
                <p class="mt-4 mb-4 text-left text-body-2">
                    I’m not great at general life advice though, sorry. You’re better talking to a
                    friend or another chat platform for that 😉
                </p>
                <v-banner class="alex-banner" v-if="!smAndDown">
                    <template #prepend>
                        <app-guide-avatar size="90" />
                    </template>
                    <v-banner-text class="text-subtitle-1">
                        You’ll know when I’m around as you will see this icon - just click on me,
                        and lets get chatting!
                    </v-banner-text>
                </v-banner>
                <v-banner class="alex-banner" v-if="smAndDown">
                    <template #prepend>
                        <app-guide-avatar size="50" />
                    </template>
                    <v-banner-text class="text-caption">
                        You’ll know when I’m around as you will see this icon - just click on me,
                        and lets get chatting!
                    </v-banner-text>
                </v-banner>
                <p class="mt-4 mb-4 text-left text-body-2">
                    I remember everything you tell me, and I’m good at connecting the dots between
                    multiple pieces of information, and things we know work well to help people at
                    your stage in your career to progress.
                </p>
                <p class="mt-4 mb-4 text-left text-body-2">
                    All conversations we have are private, and are not shared with your
                    organisation. My goal is to support you personally and help you to achieve your
                    ambition.
                </p>
                <v-divider></v-divider>
                <p class="my-4 ml-16 text-justify text-caption">
                    Alex is a chat-bot, built & owned by Career Concierge Ltd, and hosted on the
                    Azure Open AI platform. Alex is trained with strict parameters and prompts and
                    is not suitable for general Q&A. All data shared with Alex is confidential and
                    not shared with anyone outside of our organisation, or the organisation linked
                    to you, in anyway. Our servers are private, and information is not shared openly
                    on the Azure platform. If you’d like more information on our application, its
                    ethics or transparency on how we use your information, first read our Terms &
                    Conditions and Privacy Policy, or contact us at support@actvo.ai.
                </p>
            </v-container>
        </v-card>
        <PageActivity :area="UserActivityArea.Intro" />
    </MainContent>
</template>

<script setup lang="ts">
    import MainContent from '@/components/layout/MainContent.vue';
    import { useDisplay } from 'vuetify';
    import { onBeforeUnmount, onMounted } from 'vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import { useCanvasStore } from '@/store/canvas/store';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';

    const { smAndDown } = useDisplay();

    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();

    onMounted(async () => {
        await canvasStore.load();
        await activityStore.addEntryActivity(UserActivityArea.GuideOverview);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.GuideOverview);
    });
</script>

<style scoped lang="scss">
    .alex-banner {
        border-left: 8px solid rgb(var(--v-theme-custom-green-light));
    }
</style>
