import { createCurriculumVitae, removeCurriculumVitae } from '@/services/profile/service';
import { verify } from '@/store/verify';
import type { User } from '@/api/types/user';

import type { FileInput } from '@/store/profile/types';
import { FileTypeAction } from '@/store/profile/types';
import type { CurriculumVitae } from '@/api/types/curriculumVitae';

export async function createCurriculum(fileInput: FileInput, user: User, accessToken: string) {
    const curriculumFile = verify(fileInput?.file, 'cannot upload without a file');

    const formData = new FormData();
    formData.append('upload_file', curriculumFile);

    return await createCurriculumVitae(user.id, formData, accessToken);
}

/**
 *
 * Creates (if just uploaded) or delete (an existing) CV
 * @deprecated no ability to delete CVs for now
 */
export const updatedCurriculum = async (
    fileInput: FileInput,
    user: User,
    accessToken: string,
    curriculumId?: number,
): Promise<CurriculumVitae | null> => {
    if (fileInput.action === FileTypeAction.New) {
        return await createCurriculum(fileInput, user, accessToken);
    } else if (fileInput.action === FileTypeAction.Delete) {
        if (curriculumId) {
            await removeCurriculumVitae(user.id, curriculumId, accessToken);
        } else {
            console.info('no curriculum id to delete');
        }

        return null;
    }

    throw new Error('could not update cv');
};
