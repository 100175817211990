<template>
    <app-button
        class="px-4 py-2"
        :color="color ?? 'primary'"
        :variant="variant ?? 'flat'"
        append-icon="mdi-plus"
        @click="emit('click')"
        :loading="props.loading"
        :disabled="props.disabled ?? false"
        :to="to"
    >
        <slot></slot>
    </app-button>
</template>

<script setup lang="ts">
    import type { RouteLocationRaw } from 'vue-router';

    const emit = defineEmits(['click']);

    const props = defineProps<{
        color?: string;
        variant?: string;
        appendIcon?: string;
        disabled?: boolean;
        to?: RouteLocationRaw;
        loading?: boolean;
    }>();
</script>

<style scoped>
    :deep(.v-btn__prepend) {
        font-size: 20px;
        margin-right: 0px;
    }
</style>
