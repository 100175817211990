import type { PersonalValue } from '@/api/types/personalValue';
import type {
    PersonalValueSelectionSubType,
    PersonalValueSelectionType,
} from '@/api/types/canvas/personalValue';

import { type UseWebSocketReturn } from '@vueuse/core';
import type { PersonalValueSelection } from '@/store/personal-values/types';
import type { WithAccessToken } from '@/store/common/accessTokenState';

enum Type {
    Enter = 'enter',
    Selection = 'selection',
    Question = 'question',
    Leave = 'leave',
    Suggestion = 'suggestion',
    Message = 'message',
}

export { Type as GuideEventType };

enum Screen {
    CurrentChallenges = 'current-challenges',
    ChallengePath = 'challenge-path',
    CurrentPlan = 'current-plan', //work plan
    FutureAspirations = 'future-aspirations',
    FuturePlan = 'future-plan', //career plan
    Intro = 'intro',

    LearnedExperiences = 'learned-experiences',
    Plan = 'plan',
    PersonalValues = 'personal-values',
    PersonalStrengths = 'personal-strengths',
    Skills = 'skills',
    Story = 'story',
    UVP = 'unique-value-proposition',
}

export { Screen as GuideSupportedScreen };

type GuideEventBasic<T extends Type, V extends any> = {
    uuid: string;
    type: T;
    value: V;

    // TODO: move outside of the api interface
    /** Client side state */
    /**
     * This flag is used to know if the user has been displayed the message already,
     * so we do not do the 'typewriting' multiple times for the same message
     */
    displayed?: boolean;
};

// ==========================================
//
// Generic events
//
// ==========================================

export type GuideEnterEvent = GuideEventBasic<Type.Enter, any>;

export type GuideLeaveEvent = GuideEventBasic<Type.Leave, any>;

export type GuideSuggestionEvent = GuideEventBasic<Type.Suggestion, any>;

export type GuideSelectionEvent = GuideEventBasic<Type.Selection, any>;

export type GuideQuestionEvent = GuideEventBasic<Type.Question, { q: string }>;

export type GuideMessageEvent = GuideEventBasic<Type.Message, GuideMessageData>;

export type GuideMessageData = {
    chat_id: number;
    guide_message_id: number | null;
    role: string;
    content: string | GuideSuggestionMessageContent;
    id: string;
    created_at: Date;
    sender_id: string;
    history: boolean;
    type: MessageType;
    priority: MessagePriority;
};

// ==========================================
//
// Personal Values
//
// ==========================================

type GuideEventPersonalValueSelection<V extends any> = GuideEventBasic<Type.Selection, V>;

export type GuidePersonalValueSelectionTypeEvent = GuideEventPersonalValueSelection<{
    context: PersonalValueSelection[];
    selected: PersonalValue;
    value: PersonalValueSelectionType;
}>;

export type GuidePersonalValueSelectionSubTypeEvent = GuideEventPersonalValueSelection<{
    context: PersonalValueSelection[];
    selected: PersonalValue;
    value: PersonalValueSelectionSubType;
}>;

export type GuidePersonalValuesEvent =
    | GuidePersonalValueSelectionTypeEvent
    | GuidePersonalValueSelectionSubTypeEvent;

export type GuideEvent =
    | GuideEnterEvent
    | GuideLeaveEvent
    | GuideSuggestionEvent
    | GuideQuestionEvent
    | GuideMessageEvent
    | GuidePersonalValuesEvent;

enum MessageType {
    Text = 'text',
    Suggestion = 'suggestion',
}

export { MessageType as GuideEventResponseMessageType };

export enum WebsocketStatus {
    Connecting = 'CONNECTING',
    Open = 'OPEN',
    Closed = 'CLOSED',
}

enum MessagePriority {
    Lowest = 0,
    Low = 3,
    Normal = 6,
    High = 9,
    Highest = 12,
}

export { MessagePriority as GuideMessagePriority };

export enum GuideDialogState {
    Closed,
    Open,
    /**
     * A state that indicates that the guide has some suggestions that want to share with the user,
     * but they are not that import to interrupt the user flow
     */
    ClosedWithSuggestions,
}

export type UUID = string;
export type GuideEventsMap = Record<UUID, GuideEvent>;

// ChatMessage
export type GuideMessage = {
    _id: string;
    content: any;
    senderId: string;
    timestamp: string;
    date: string;

    /**
     *  A flag to mark if the message has to be animated
     *  At the moment is not being used as it has been difficult to scroll to the animated message using the
     *  <vue-advanced-chat> library*/
    animate: boolean;
    ctx?: GuideEvent;
};

export const ALEX = {
    username: 'Alex',
    id: '99999999',
};
export const GUIDE_MESSAGE_INTRO_ID = 'intro-message';

export type GuideStoreState = {
    ws: UseWebSocketReturn<any> | null;

    messages: GuideMessage[];
    messagesLoaded: boolean;
    state: GuideDialogState;
    question: '';
    screen: Screen | null;
} & WithAccessToken;

// TODO this should return a type and be a type guard, instead of a boolean
export const isSuggestionMessage = (message: GuideMessage): boolean => {
    return (message.ctx as unknown as GuideMessageEvent)?.value?.type === MessageType.Suggestion;
};

export type GuideSuggestionMessageContent = {
    title: string;
    description: string;
};
