<template>
    <div>
        <app-guide-inline
            v-if="firstProfileView"
            title="Your Profile"
            text="Please set-up your profile so we know who you are and a bit about you"
            @close="showDummyGuide = false"
            @submit="showDummyGuide = false"
        />
        <app-guide-inline
            v-else-if="!isProfileComplete"
            title="Complete your profile!"
            text="Please set-up your profile with some key information, we need to know to get you started."
            @close="showDummyGuide = false"
            @submit="showDummyGuide = false"
        />

        <app-guide-chat-activator
            v-if="(firstProfileView || !isProfileComplete) && showDummyGuide"
        />
    </div>
    <!--
        Note: There is no specific guide for this page, so we make the avatar dissapear as soon as the message is gone
        TODO: Make: guide for this page
     -->
    <!--    <app-guide-chat-dialog :screen="GuideSupportedScreen.Story" />-->
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { useUsersStore } from '@/store/user/store';
    import { useUserProfileStore } from '@/store/profile/store';

    const usersStore = useUsersStore();
    const store = useUserProfileStore();

    const showDummyGuide = ref(true);

    const firstProfileView = computed(() => {
        return !usersStore.hasProfileBeenSet;
    });

    const isProfileComplete = computed(() => {
        return store.isComplete;
    });
</script>
