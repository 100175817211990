import type { SkillType } from '@/store/skills/types';

export enum InferredRecordState {
    Inferred = 'inferred',
    Accepted = 'accepted',
    Denied = 'denied',
}

export type BaseSkill = {
    id: number;
    canvas_id: number;
    description: string;
    type: SkillType;
};

export type CanvasSkill = BaseSkill & {
    inferred_skill_id?: number;
};

export type InferredCanvasSkill = BaseSkill & {
    state: InferredRecordState;
};

export const isInferredCanvasSkill = (skill: unknown): skill is InferredCanvasSkill => {
    const asInferredSkill = skill as InferredCanvasSkill;
    return (
        asInferredSkill.type !== undefined &&
        asInferredSkill.description !== undefined &&
        asInferredSkill.state !== undefined
    );
};

export const isCanvasSkill = (skill: unknown): skill is CanvasSkill => {
    const asCanvasSkill = skill as CanvasSkill;

    return (
        asCanvasSkill.type !== undefined &&
        asCanvasSkill.description !== undefined &&
        !isInferredCanvasSkill(skill)
    );
};

export type CanvasSkillCreateData = Pick<CanvasSkill, 'type' | 'description'>;
