import { type ApiResponse, get, post } from '@/services/apiClient';
import type { Canvas } from '@/api/types/canvas';
import type { CanvasCareerPlan } from '@/api/types/plan/careerPlan';

export const getCanvasCareerPlan = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasCareerPlan>> {
    const request = get<CanvasCareerPlan>(`canvas/${canvasId}/career-plan/`);
    return request(accessToken);
};

export const postCanvasCareerPlan = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasCareerPlan>> {
    const request = post<any, CanvasCareerPlan>(`canvas/${canvasId}/career-plan/`);
    return request(accessToken, {});
};
