<template>
    <v-container class="pa-0">
        <v-row no-gutters v-click-outside="onClickOutsideEditList">
            <v-col cols="12" class="mb-4" v-for="(item, index) in skillsToDisplay" :key="index">
                <SkillsListItemNew
                    :item="item"
                    @edit-click="onEdit"
                    @delete-click="onDelete"
                    @change-type="onChangeType"
                />
            </v-col>
            <v-col cols="12" class="mb-4">
                <SkillListItemForm v-if="store.isAddingNew" />
            </v-col>
        </v-row>
        <v-row no-gutters justify="start">
            <app-plus-button @click="store.addSkill(null)">Add another skill</app-plus-button>
        </v-row>
    </v-container>

    <ActivityNotification :value="store.confirmedSkills.length" :area="UserActivityArea.Skills" />
</template>

<script setup lang="ts">
    import { type Skill, useSkillsStore } from '@/store/skills/store';
    import SkillsListItemNew from '@/components/canvas/skills/SkillsListItemNew.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import ActivityNotification from '@/components/canvas/notifications/ActivityNotification.vue';
    import { computed } from 'vue';
    import {
        InferredRecordState,
        isCanvasSkill,
        isInferredCanvasSkill,
    } from '@/api/types/canvas/skills';
    import SkillListItemForm from '@/components/canvas/skills/SkillListItemForm.vue';
    import { verify } from '@/store/verify';
    import type { SkillType } from '@/store/skills/types';

    const store = useSkillsStore();

    const onChangeType = ({ item, type }: { item: Skill; type: string }) => {
        store.updateSkillType(item, verify(type as SkillType, 'no type'));
    };

    const onEdit = (item: Skill) => {
        store.setEdit(item);
    };

    const onDelete = async (item: Skill) => {
        await store.removeSkill(item);
    };

    const skillsToDisplay = computed(() => {
        return store.allSkills.filter((skill) => {
            if (isCanvasSkill(skill)) {
                return skill;
            } else if (isInferredCanvasSkill(skill)) {
                return skill.state === InferredRecordState.Inferred;
            }
        });
    });

    const onClickOutsideEditList = async () => {
        if (store.isAddingNew) {
            return;
        } else {
            store.cancelEdit();
        }
    };
</script>

<style scoped lang="scss">
    .skills-divider {
        background: rgba(251, 134, 37, 1);
    }
</style>
