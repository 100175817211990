<template>
    <v-dialog width="500" v-model="model" :persistent="true">
        <v-card class="fill-height pa-4">
            <v-card-title class="text-h5 text-wrap px-2">
                You are about to navigate away
            </v-card-title>
            <v-card-text class="text-paragraph-md text-wrap px-2">
                Your changes will be lost if you do not save them
            </v-card-text>
            <v-spacer />
            <v-card-actions class="px-2 mt-5">
                <app-button @click="discardChanges">Don't save</app-button>
                <v-spacer />
                <app-button @click="onCancel">Cancel</app-button>
                <SubmitButton text="Save" @click="onSave" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script setup lang="ts">
    import { type Ref, ref, type UnwrapRef } from 'vue';
    import {
        onBeforeRouteLeave,
        type RouteLocationNormalized,
        type RouteLocationRaw,
        useRouter,
    } from 'vue-router';
    import SubmitButton from '@/components/footer-actions/SubmitButton.vue';

    const props = defineProps<{
        value: boolean;
    }>();

    const emit = defineEmits(['on-discard-changes', 'on-submit']);

    const model = ref(false);
    const router = useRouter();

    const navigatingAway: Ref<UnwrapRef<RouteLocationNormalized | null>> = ref(null);

    // /**
    //  * The current browser mechanisms to prevent reload or close a tab with changes are a bit intrusive and
    //  * not as standardised as expected. See the code commented above
    //  *
    //  * This will show a very primitive alert saying:
    //  *  ------------------------------------------------------------
    //  *  | Reload site?
    //  *  |
    //  *  | Changes that you made may not be saved.
    //  *  |
    //  *  |                   Cancel / Reload
    //  *  ------------------------------------------------------------
    //  *
    //  *  But it does not provide any mechanism to hide the alert and show our custom dialog. So, I think for
    //  *  now is better to comment the implementation
    //  */
    //
    // onBeforeMount(() => {
    //     window.addEventListener('beforeunload', onBeforeUnload);
    // });
    //
    // onBeforeUnmount(() => {
    //     window.removeEventListener('beforeunload', onBeforeUnload);
    // });
    //
    // const onBeforeUnload = (event: any) => {
    //     if (props.value) {
    //         // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    //         event.preventDefault();
    //         event.returnValue = 'truth value';
    //
    //         // in theory returning undefined should hide the dialog, but locally in chrome did not work
    //         return undefined;
    //
    //     }
    //
    //     return;
    // };

    /**
     * Note:
     * onBeforeRouteLeave is a lifecycle method purely from vue-router. This means it only works
     * while internally navigating as the vue router makes use of the browser history api
     */
    onBeforeRouteLeave((to, from) => {
        const isNavigatingAway = navigatingAway.value && to.name === navigatingAway.value.name;
        if (isNavigatingAway) {
            // this is the router.push(navigatingAway.value as RouteLocationRaw) from before
            return true;
        }

        if (props.value) {
            model.value = true;
            navigatingAway.value = to;

            return false;
        } else {
            return true;
        }
    });

    const discardChanges = () => {
        router.push(navigatingAway.value as RouteLocationRaw);
        emit('on-discard-changes');
    };

    const onCancel = () => {
        model.value = false;
        navigatingAway.value = null;
    };

    const onSave = () => {
        model.value = false;
        navigatingAway.value = null;

        emit('on-submit');
    };
</script>

<style lang="scss"></style>
