import { type ApiResponse, get, delete_ } from '@/services/apiClient';
import type { CanvasUniqueValueProposition } from '@/api/types/canvas/uniqueValueProposition';

export const getCanvasUniqueValuePropositions = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasUniqueValueProposition[]>> {
    const request = get<CanvasUniqueValueProposition[]>(
        `canvas/${canvasId}/unique-value-proposition/`,
    );
    return request(accessToken);
};

export const deleteCanvasUniqueValueProposition = function (
    canvasId: number,
    uniqueValuePropositionId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasUniqueValueProposition>> {
    const request = delete_<CanvasUniqueValueProposition>(
        `canvas/${canvasId}/unique-value-proposition/${uniqueValuePropositionId}/`,
    );
    return request(accessToken);
};
