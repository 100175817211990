<template>
    <v-card
        elevation="0"
        :color="inModal ? 'secondary-blue' : ''"
        :class="inModal ? 'text-white' : 'text-custom-grey-900'"
    >
        <v-card-title class="text-h5 font-weight-bold text-wrap px-0 py-2">
            Welcome to Actvo!
        </v-card-title>

        <p class="mt-3 text-left">
            Actvo is a personal and private space for you to plan and activate your career goals.

            <br />
            <br />
            Here’s how it works:
        </p>

        <v-img :src="howDiagram" width="380" height="380" />

        <v-container class="pa-0 text-left" :class="smAndDown ? '' : 'mt-6'">
            <div class="text-h5 font-weight-600 mb-3 mt-4" :class="smAndDown ? '' : 'mt-6'">
                Set (& re-set) your Story
            </div>
            <p>
                Set out to learn about yourself and the unique value that you offer the workforce.
                We will guide you through the essentials of telling the Story of You, so you can
                feel confident to get what you want.
            </p>
            <v-divider
                :thickness="4"
                color="#d9d9d9"
                class="mt-3 mb-5 border-opacity-100"
                style="width: 50px"
            />
            <div class="text-h5 font-weight-600 mb-3" :class="smAndDown ? '' : 'mt-6'">
                Share your Story
            </div>
            <p>
                Download and share your unique story with friends, colleagues, your HR team or
                manager, so they can support you to achieve your goals.
            </p>
            <v-divider
                :thickness="4"
                color="#d9d9d9"
                class="mt-3 mb-5 border-opacity-100"
                style="width: 50px"
            />
            <div class="text-h5 font-weight-600 mb-3" :class="smAndDown ? '' : 'mt-6'">
                Solve everyday Challenges
            </div>
            <p>
                Work can be hard. Here’s where we support you to overcome personal challenges by
                offering guidance, advice and linking you to world-leading resources.
            </p>
            <v-divider
                :thickness="4"
                color="#d9d9d9"
                class="mt-3 mb-5 border-opacity-100"
                style="width: 50px"
            />
            <div class="text-h5 font-weight-600 mb-3" :class="smAndDown ? '' : 'mt-6'">
                Craft (& activate) your Career Plan
            </div>
            <p>
                There’s so much opportunity to work how you want - craft a career plan that will
                suit your goals, values and needs with our help! We make career planning easy.
            </p>
            <v-divider
                :thickness="4"
                color="#d9d9d9"
                class="mt-3 mb-5 border-opacity-100"
                style="width: 50px"
            />
            <div class="text-h5 font-weight-600 mb-3" :class="smAndDown ? '' : 'mt-6'">
                Chat with your Guide
            </div>
            <p>
                Your AI powered Guide Alex is here to help 24/7, 365 days a year. Trained by us, and
                using underlying world-leading technologies such as Chat GPT & Bard - your guide is
                tuned into a world of resources, and is designed to remember and respond to your
                personal needs. All chats are private, secure & confidential.
            </p>
        </v-container>
    </v-card>
</template>

<script setup lang="ts">
    import howDiagram from '@/assets/images/how-diagram.png';
    import { useDisplay } from 'vuetify';

    withDefaults(
        defineProps<{
            inModal?: boolean;
        }>(),
        { inModal: false },
    );

    const { smAndDown } = useDisplay();
</script>
