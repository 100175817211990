<template>
    <SaveProgressButtons
        :close-text="closeText"
        :close-to="closeTo"
        :submit-text="props.title ?? 'Save and return to story'"
        :submit-disabled="props.submitDisabled"
        :submit-tooltip="props.submitTooltip"
        :submit-hide="props.submitHide"
        :submit-loading="props.submitLoading"
        :full-width="fullWidth"
    />
</template>
<script setup lang="ts">
    import SaveProgressButtons from '@/components/footer-actions/SaveProgressButtons.vue';
    import { storyRoute } from '@/router/routes';

    const props = withDefaults(
        defineProps<{
            closeText?: string;
            closeTo?: any;
            title?: string;
            submitDisabled?: boolean;
            submitTooltip?: string;
            submitHide?: boolean;
            submitLoading?: boolean;
            fullWidth?: boolean;
        }>(),
        {
            closeText: 'Back to my story',

            // ts-ignore because the type is not compatible with the DeclaredRoute type
            // closeTo: storyRoute,
            closeTo: storyRoute,
        },
    );
</script>
