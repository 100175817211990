import type { PersonalValue } from '@/api/types/personalValue';
import { HttpStatusCode } from 'axios';
import {
    getCanvasPersonalValues,
    getPersonalValues,
    putCanvasPersonalValues,
} from '@/services/personal-values/api';
import type {
    CanvasPersonalValue,
    CanvasPersonalValuesUpdateData,
} from '@/api/types/canvas/personalValue';

export async function fetchPersonalValues(accessToken: string): Promise<PersonalValue[]> {
    console.info('Fetching personal values...');
    const response = await getPersonalValues(accessToken);

    if (response.data) {
        console.info('Personal values fetched...');
        return response.data;
    } else {
        throw new Error(response.error?.message);
    }
}

export async function fetchCanvasPersonalValues(
    canvasId: number,
    accessToken: string,
): Promise<CanvasPersonalValue[]> {
    console.info('Fetching canvas personal values...');
    const response = await getCanvasPersonalValues(canvasId, accessToken);

    if (response.data) {
        console.info('Canvas personal values fetched...');
        return response.data;
    } else {
        throw new Error(response.error?.message);
    }
}

export async function updateCanvasPersonalValues(
    canvasId: number,
    updateData: CanvasPersonalValuesUpdateData,
    accessToken: string,
): Promise<void> {
    console.info('Updating canvas personal values...');

    const updateResponse = await putCanvasPersonalValues(canvasId, updateData, accessToken);

    if (updateResponse.data && updateResponse.status === HttpStatusCode.Ok) {
        console.info('Canvas personal values updated successfully...');
        return updateResponse.data;
    } else if (updateResponse.status === HttpStatusCode.UnprocessableEntity) {
        console.info('Likely we are missing some of the required data for the creation...');
        throw new Error(updateResponse.error?.message);
    } else if (updateResponse.error) {
        console.warn('Canvas personal values update failed to create...');
        throw new Error(updateResponse.error?.message);
    } else {
        console.warn('Could not update canvas');
    }
}
