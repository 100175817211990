import { callExternalApi } from '@/services/externalApi';

const apiServerUrl = import.meta.env.VITE_API_SERVER_URL;

export type ApiResponse<T extends any> = {
    data: T | null;
    error: null | { message: string } | { message: any };
    status: number | null;
};

const DEFAULT_CONTENT_TYPE = 'application/json';
const FILE_CONTENT_TYPE = 'multipart/form-data';

function headers(accessToken: string, payload?: any) {
    return {
        'content-type': payload instanceof FormData ? FILE_CONTENT_TYPE : DEFAULT_CONTENT_TYPE,
        Authorization: `Bearer ${accessToken}`,
    };
}

function url(path: string) {
    return `${apiServerUrl}/${path}`;
}

export const get = <T extends any>(
    path: string,
): ((accessToken: string) => Promise<ApiResponse<T>>) => {
    return async (accessToken: string): Promise<ApiResponse<T>> => {
        const config = {
            url: url(path),
            method: 'GET',
            headers: headers(accessToken),
        };

        const { data, error, status } = await callExternalApi<T>({ config });

        return {
            data: (data as T) || null,
            error,
            status,
        };
    };
};

export const post = <T extends any, TR extends any>(
    path: string,
): ((accessToken: string, payload?: T) => Promise<ApiResponse<TR>>) => {
    return async (accessToken: string, payload?: T): Promise<ApiResponse<TR>> => {
        const config = {
            url: url(path),
            method: 'POST',
            headers: headers(accessToken, payload),
            data: payload,
        };
        const { data: createdData, error, status } = await callExternalApi<T>({ config });

        return {
            // TODO: Fix this type
            data: (createdData as unknown as TR) ?? null,
            error,
            status,
        };
    };
};

export const put = <T extends any, TR extends any>(
    path: string,
): ((accessToken: string, payload?: T) => Promise<ApiResponse<TR>>) => {
    return async (accessToken: string, payload?: T): Promise<ApiResponse<TR>> => {
        const config = {
            url: url(path),
            method: 'PUT',
            headers: headers(accessToken),
            data: payload,
        };

        const { data: updateData, error, status } = await callExternalApi<T>({ config });

        return {
            // TODO: Fix this type
            data: (updateData as unknown as TR) ?? null,
            error,
            status,
        };
    };
};

export const delete_ = <T extends any>(
    path: string,
): ((accessToken: string) => Promise<ApiResponse<any>>) => {
    return async (accessToken: string): Promise<ApiResponse<any>> => {
        const config = {
            url: url(path),
            method: 'DELETE',
            headers: headers(accessToken),
        };

        const { data: data, error, status } = await callExternalApi<T>({ config });

        return {
            data: data ?? null,
            error,
            status,
        };
    };
};
