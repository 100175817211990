import type { ISO8601 } from '@/lib/dates';
import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
import { PlanActionItemType } from '@/store/plan/common/types';

export enum CurrentChallengeActionState {
    New = 'new',
    InProgress = 'in-progress',
    Completed = 'completed',
}

export type ActionFrequency = 'daily' | 'weekly' | 'monthly';

export type ActionDifficulty = 'easy' | 'medium' | 'hard';

export enum ActionType {
    UserCreated = 'user_created',
    Suggested = 'suggested'
}

export interface CurrentChallengeAction {
    id: number;
    type: ActionType.UserCreated;
    current_challenge_id: number;
    title: string;
    description?: string;
    long_description?: string;
    links?: string;
    due_date: ISO8601;
    action_type: PlanActionItemType;
    state: CurrentChallengeActionState;

    frequency: ActionFrequency;
    duration_min: number;
    duration_max?: number;
    difficulty?: ActionDifficulty;

    created_at: Date;
    updated_at: Date;
}

export type CurrentChallengeActionCreateData = Pick<
    CurrentChallengeAction,
    'title' | 'description' | 'due_date' | 'action_type'
>;

export type CurrentChallengeActionUpdateData = Pick<
    CurrentChallengeAction,
    'title' | 'description' | 'due_date' | 'action_type'
>;

export type CurrentChallengeStateUpdateData = Pick<CanvasCurrentChallenge, 'state'>;

export type CurrentChallengeActionSaveReturnValue = Pick<
    CurrentChallengeAction,
    'title' | 'description' | 'due_date' | 'action_type'
>;

export enum SuggestedChallengeActionState {
    Suggested = 'suggested',
    Accepted = 'accepted',
    Denied = 'denied',
}

export type SuggestedChallengeAction = {
    id: number
    type: ActionType.Suggested;
    current_challenge_id: number;
    title: string;
    description?: string;
    long_description?: string;
    links?: string;
    due_date: ISO8601;
    state: SuggestedChallengeActionState;

    action_type: PlanActionItemType;
    frequency: ActionFrequency;
    duration_min: number;
    duration_max?: number;

    difficulty?: ActionDifficulty;
    created_at: Date;
    updated_at: Date;
};

export type ChallengeActionCreateData = Omit<CurrentChallengeAction, 'id'>;
export type ChallengeActionUpdateData = CurrentChallengeAction;
