import { defineStore } from 'pinia';
import { type ComponentPublicInstance, ref } from 'vue';

/**
 * A store for top-level error handling
 */
export const useAppErrorStore = defineStore('app-error', () => {
    const hasError = ref(false);

    function handleError(error: unknown): void {
        if (error instanceof Error) {
            console.error('App error:\n%s', error.stack ?? error);
        } else {
            console.error("App error '%s':", error);
        }
        hasError.value = true;
    }

    return {
        /** The error flag: true if an unexpected error has occurred */
        hasError,
        /** Handle an error that originates from Vue, setting the error flag */
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        handleVueError: (
            error: unknown,
            instance: ComponentPublicInstance | null,
            info: string,
        ): void => {
            console.error(
                // `Vue Error: \nComponent: ${instance ? instance.toString() : ''}\nInfo: ${info}`,
                `Vue Error: Info: ${info}`,
            );
            handleError(error);
        },
        /**
         * Catch errors that are thown by an async function, setting the error state
         */
        catchErrors: async (action: () => Promise<void>): Promise<void> => {
            try {
                await action();
            } catch (error) {
                handleError(error);
            }
        },
    };
});
