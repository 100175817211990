<template>
    <StorySectionActivityInlineList label="Core values" :items="coreItems" class="w-100" />

    <StorySectionActivityInlineList label="Growth values" :items="growthItems" />
</template>

<script setup lang="ts">
    import { usePersonalValuesStore } from '@/store/personal-values/store';
    import { computed } from 'vue';
    import StorySectionActivityInlineList from '@/components/story/section/activity/StorySectionActivityInlineList.vue';
    import { capitalize } from 'lodash';
    import type { StoryActivity } from '@/store/story/types';

    const props = defineProps<{
        item: StoryActivity;
    }>();

    const store = usePersonalValuesStore();

    const coreValues = computed(() => {
        return store.selectedCoreValues.map((i) => {
            return i.personalValue;
        });
    });

    const growthValues = computed(() => {
        return store.selectedGrowthValues.map((i) => {
            return i.personalValue;
        });
    });

    const coreItems = computed(() => {
        return coreValues.value.map((item) => capitalize(item.title));
    });

    const growthItems = computed(() => {
        return growthValues.value.map((item) => capitalize(item.title));
    });
</script>
