<template>
    <!--
        TODO: The error should be renamed to notifications
        It used to be part of the layout, but since it is implemented with a v-snackbar
        and its position is absolute, it could be taken out of this component.
     -->
    <slot name="errors" />

    <v-container class="app-page-footer pa-0 w-100" fluid :class="cssClasses">
        <v-footer height="95" :app="true" class="py-5 border-t-sm">
            <v-row no-gutters class="w-100" v-if="hasActionsSlot">
                <v-col class="" cols="12">
                    <slot name="actions" />
                </v-col>
            </v-row>
        </v-footer>
    </v-container>
</template>
<script setup lang="ts">
    import { useDisplay } from 'vuetify';
    import { computed, useSlots } from 'vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { mdAndUp } = useDisplay();

    const props = defineProps<{
        noApp?: boolean;
        md?: string | number | undefined;
        lg?: string | number | undefined;
        xl?: string | number | undefined;
    }>();

    const { responsiveClass } = useIsDesktop();

    const slots = useSlots();

    const hasActionsSlot = computed(() => {
        return !!slots['actions'];
    });

    const cssClasses = computed(() => {
        return {
            [responsiveClass.value]: true,
        };
    });
</script>

<style scoped lang="scss">
    .desktop {
        --footer-padding-left: calc(var(--desktop-layout-horizontal-gap) * 1px) !important;
        --footer-padding-right: calc(var(--desktop-layout-horizontal-gap) * 1px) !important;
    }

    .mobile {
        --footer-padding-left: 20px !important;
        --footer-padding-right: 20px !important;
    }

    .app-page-footer {
        footer {
            padding-left: var(--footer-padding-left);
            padding-right: var(--footer-padding-right);
        }
    }
</style>
