import { type ApiResponse, get, post } from '@/services/apiClient';
import type { CountMetric, CreateUserActivityData, UserActivity } from '@/api/types/userActivity';

export const getUserActivityCompleted = function (
    userId: number,
    accessToken: string,
): Promise<ApiResponse<UserActivity[]>> {
    const request = get<UserActivity[]>(`activity/${userId}/completed`);
    return request(accessToken);
};

export const getUserActivity = function (
    userId: number,
    accessToken: string,
): Promise<ApiResponse<UserActivity>> {
    const request = get<UserActivity>(`activity/${userId}`);
    return request(accessToken);
};

export const postUserActivity = function (
    userId: number,
    updateData: CreateUserActivityData,
    accessToken: string,
): Promise<ApiResponse<UserActivity>> {
    const request = post<CreateUserActivityData, UserActivity>(`activity/${userId}`);
    return request(accessToken, updateData);
};

export const postUserMetric = function (
    userId: number,
    countMetric: CountMetric,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = post<CountMetric, void>(`activity/metric/user/${userId}`);
    return request(accessToken, countMetric);
};

export const postCanvasMetric = function (
    canvasId: number,
    countMetric: CountMetric,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = post<CountMetric, void>(`activity/metric/canvas/${canvasId}`);
    return request(accessToken, countMetric);
};
