<template>
    <v-footer color="custom-purple-deep" :app="false">
        <div class="py-10">
            <v-list-item :to="homeRoute" class="text-none text-white router-link my-2">
                Home
            </v-list-item>
            <v-list-item :to="privacyRoute" class="text-none text-white router-link my-2">
                Privacy Policy
            </v-list-item>
            <v-list-item :to="termsRoute" class="text-none text-white router-link my-2">
                Terms and Conditions
            </v-list-item>
            <v-list-item :to="alexRoute" class="text-none text-white router-link my-2">
                Introducing Alex
            </v-list-item>
            <v-list-item :to="howDoesItWork" class="text-none text-white router-link my-2">
                How does it work
            </v-list-item>

            <v-list-item v-if="!isDesktop">
                <v-img :src="logoSrc" :height="60" :width="92" />
            </v-list-item>
        </div>

        <div class="desktop-logo-in-footer" v-if="isDesktop">
            <v-img :src="logoSrc" :height="60" :width="92" />
        </div>
    </v-footer>
</template>

<script setup lang="ts">
    import { alexRoute, homeRoute, howDoesItWork, privacyRoute, termsRoute } from '@/router/routes';
    import logoSrc from '@/assets/images/logo/actvo-footer-logo-transparent.svg';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { isDesktop } = useIsDesktop();
</script>

<style scoped lang="scss">
    .router-link {
        text-decoration-line: none;
    }

    .desktop-logo-in-footer {
        position: absolute;
        right: calc(var(--desktop-layout-horizontal-gap) * 1px);
        margin-right: var(--desktop-layout-horizontal-gap) px;
    }

    :deep(.v-list-item__overlay) {
        display: none;
    }
</style>
