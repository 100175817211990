import { User as Auth0User } from '@/auth/auth';
import {
    type ChangePasswordResponse,
    createUser,
    getCurrentUser,
    postChangePasswordTicket,
    putUser,
} from '@/services/user/api';
import type { User, UserCreateData, UserUpdateData } from '@/api/types/user';
import { HttpStatusCode } from 'axios';
import { create, update } from '@/services/base';
import { verify } from '@/store/verify';
import { ApiAuthError } from '@/errors';

export async function fetchMeUser(accessToken: string): Promise<User> {
    console.info('Fetching me user...');
    const response = await getCurrentUser(accessToken);

    if (response.data) {
        console.info('User fetched...');
        return response.data;
    } else {
        throw new Error(response.error?.message);
    }
}

export async function fetchOrCreateUser(
    auth0User: Auth0User,
    accessToken: string,
): Promise<User | null> {
    console.info('Getting user...');
    const response = await getCurrentUser(accessToken);
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ApiAuthError('Unauthorized');
    } else if (response.data) {
        console.info('User loaded on first round...');
        return response.data;
    } else if (response.status !== HttpStatusCode.NotFound) {
        throw new Error(response.error?.message);
    } else {
        console.info(`User not found, proceed to create user with email: ${auth0User.email}`);

        if (!auth0User.email) {
            throw new Error('no email from authenticated user');
        }

        if (!auth0User.sub) {
            throw new Error('no subject from authenticated user');
        }

        const userCreateData: UserCreateData = {
            email: auth0User.email,
            auth0_id: auth0User.sub,
            first_name: auth0User.given_name,
            last_name: auth0User.family_name,
        };

        const [createdUser, _response] = await create(
            'create-user',
            async () => await createUser(accessToken, userCreateData),
        );

        return createdUser;
    }

    throw new Error('Could not get/create user');
}

export async function updateUser(
    userId: number,
    updateData: UserUpdateData,
    accessToken: string,
): Promise<void> {
    console.info('Updating user...');
    return update('user-update', async () => await putUser(userId, updateData, accessToken));
}

export async function createChangePasswordLink(
    userId: number,
    accessToken: string,
): Promise<string> {
    const [changePassword, response] = await create<ChangePasswordResponse>(
        'create-change-password-link',
        async () => await postChangePasswordTicket(userId, accessToken),
    );

    return verify(changePassword.redirect_to, 'no url to redirect to and change the password');
}
