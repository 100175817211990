<template>
    <app-button
        :size="size"
        :prepend-icon="icon"
        @click="emit('click')"
        :variant="variant"
        :disabled="disabled"
        :color="color"
    />
</template>

<script setup lang="ts">
    const props = withDefaults(
        defineProps<{
            size?: 'x-small' | 'small' | 'default' | 'large' | 'x-large';
            icon: string;
            color?: string;
            variant?: 'outlined' | 'flat' | 'text' | 'elevated' | 'tonal' | 'plain';
            disabled?: boolean;
        }>(),
        { size: 'x-small', color: 'primary', variant: 'flat', disabled: false },
    );

    const emit = defineEmits(['click']);
</script>

<style scoped lang="scss">
    :deep(.v-btn__prepend) {
        font-size: 18px !important;
        margin-right: -2px;
    }

    button.v-btn--variant-outlined.text-white {
        border: 1px solid white;
    }
</style>
