<template>
    <v-card class="error-screen" v-if="appError.hasError" color="primary">
        <img src="http://localhost:5173/src/assets/actvo-logo.png" width="200" />
        <v-card-text class="h4 w-100">
            <div>
                <v-icon size="40" icon="">mdi-alert-circle</v-icon>
                Unexpected application error. Please <a href="/" target="_blank"> reload </a> the
                page.
            </div>
            <br />
            <div class="mt-16 text-caption">
                If the error persist, please<a href="mailto:support@actvo.ai"> contact </a> support
                for assistance.
            </div>
        </v-card-text>
    </v-card>
    <slot v-else></slot>
</template>

<script setup lang="ts">
    import { useAppErrorStore } from '@/store/appErrorStore';
    // import { onErrorCaptured } from 'vue';

    const appError = useAppErrorStore();

    // onErrorCaptured((error) => {
    //     appError.catchErrors(error);
    // });
</script>

<style scoped>
    .error-screen {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        padding: 40px;
        text-align: center;

        img {
            position: absolute;
            left: 40px;
            top: 40px;
        }
    }
</style>
