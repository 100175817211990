import type { InferredRecordState } from '@/api/types/canvas/skills';

import type { ISO8601 } from '@/lib/dates';

type UserJobBase = {
    id: number;
    user_id: number;

    title: string;
    company_name: string;

    from_date?: ISO8601;
    to_date?: ISO8601;

    created_at: string;
    updated_at: string;
};

export type InferredUserJob = UserJobBase & {
    state: InferredRecordState;
};

export type UserJob = UserJobBase & {
    inferred_job_id?: number;
};

export const isInferredUserJob = (userJob: unknown): userJob is InferredUserJob => {
    const asInferredSkill = userJob as InferredUserJob;
    return (
        asInferredSkill.title !== undefined &&
        asInferredSkill.company_name !== undefined &&
        asInferredSkill.state !== undefined
    );
};

export const isUserJob = (userJob: unknown): userJob is UserJob => {
    const asUserJob = userJob as UserJob;

    return (
        asUserJob.title !== undefined &&
        asUserJob.company_name !== undefined &&
        !isInferredUserJob(userJob)
    );
};

export type UserJobCreate = Pick<UserJob, 'title' | 'company_name' | 'from_date' | 'to_date'>;

export type InferredUserJobUpdateData = Pick<
    InferredUserJob,
    'title' | 'company_name' | 'from_date' | 'to_date'
> & {
    state: InferredRecordState;
};
