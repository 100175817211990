import { defineStore } from 'pinia';
import { makeDataState } from '@/store/common/dataState';
import { useAppErrorStore } from '@/store/appErrorStore';
import { useCanvasStore } from '@/store/canvas/store';
import { fetchOrCreateCanvasCurrentPlan } from '@/services/plan/current/service';
import { HttpStatusCode } from 'axios';
import type { ChallengePathwayStoreState } from '@/store/challenge-path/types';
import {
    type CanvasCurrentChallenge,
    CurrentChallengeState,
} from '@/api/types/canvas/currentChallenge';
import type { CanvasCurrentPlan } from '@/api/types/plan/currentPlan';

export const useChallengePathStore = defineStore({
    id: 'challenge-path',
    state: (): ChallengePathwayStoreState => ({
        _plan: null,
        ...makeDataState(),
    }),
    getters: {
        hasChallenges(): boolean {
            return this.challenges.length > 0;
        },
        challenges(): CanvasCurrentChallenge[] {
            return this._plan?.challenges ?? [];
        },
        inProgressChallenges(): CanvasCurrentChallenge[] {
            return this.challenges.filter(
                (challenge) => challenge.state === CurrentChallengeState.InProgress,
            );
        },
        notStartedChallenges(): CanvasCurrentChallenge[] {
            return this.challenges.filter(
                (challenge) => challenge.state === CurrentChallengeState.New,
            );
        },
        completedChallenges(): CanvasCurrentChallenge[] {
            return this.challenges.filter(
                (challenge) => challenge.state === CurrentChallengeState.Completed,
            );
        },
    },
    actions: {
        async load() {
            console.info('Loading current plan...');
            await useAppErrorStore().catchErrors(async () => {
                const { canvas, canvasId, accessToken } = await useCanvasStore().makeContext();
                const [plan, response] = await fetchOrCreateCanvasCurrentPlan(canvas, accessToken);

                if (response.status === HttpStatusCode.Created) {
                    // Manually trigger a reload of the user resource, to keep FKs updates
                    await useCanvasStore().synchroniseCanvas(canvasId, accessToken);
                } else {
                    console.log('No need to synchronise user');
                }

                this._setPlan(plan);
            });
        },
        _setPlan: function (plan: CanvasCurrentPlan | null) {
            this._plan = plan
                ? {
                      ...plan,
                      challenges: (plan?.challenges ?? []).map((challenge) => {
                          return {
                              ...challenge,
                              // is_started: challenge.actions.length > 0,
                              // is_completed:
                              //     challenge.actions.length > 0 &&
                              //     every(challenge.actions, (action) => action.is_completed),
                          };
                      }),
                  }
                : null;
        },
    },
});
