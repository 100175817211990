<template>
    <StorySectionActivityList :items="items" class="fill-height" />
</template>

<script setup lang="ts">
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
    import StorySectionActivityList from '@/components/story/section/activity/StorySectionActivityList.vue';
    import { computed } from 'vue';
    import type { StoryActivity } from '@/store/story/types';

    const props = defineProps<{
        item: StoryActivity;
    }>();

    const store = useLearnedExperiencesStore();

    const items = computed(() => {
        return [
            ...store.timeline
                .map((item) => {
                    if (item.type === 'experience') {
                        return item.description ?? '';
                    } else {
                        return null;
                    }
                })
                .filter((item) => item !== null),
        ];
    });
</script>
