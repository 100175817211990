<template>
    <app-guide-modal-multiple-steps
        :model-value="modelValue"
        @update:model-value="(v) => emit('update:modelValue', v)"
        section-name="Challenge Paths"
        action-text="Start"
        @change-step="(v) => (step = v)"
        :steps="4"
    >
        <template #title="{ step }">{{ title }}</template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    Let me help you solve some of your every day challenges and make your work-life
                    easier.
                </p>
            </div>
            <div v-else-if="step === 2">
                <p>
                    Having an action plan of activities will help you to face your challenges and
                    learn new skills.
                </p>
            </div>
            <div v-else-if="step === 3">
                <p>
                    I’ll help you to select a set of activities that you feel comfortable with, and
                    then come up with a timeline to work through them
                </p>
            </div>
            <div v-else-if="step === 4">
                <p>
                    To make sure you stay on track and feel supported I’ll check in on you throughout.
                </p>
                <p>
                    Remember you can always come back and change your activities or adjust your timeframe.
                </p>
            </div>
        </template>
    </app-guide-modal-multiple-steps>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import AppGuideModalMultipleSteps from '@/components/guide/modal/AppGuideModalMultipleSteps.vue';

    const step = ref(1);

    defineProps<{
        modelValue: boolean;
    }>();

    const emit = defineEmits(['update:modelValue']);

    const title = computed(() => {
        switch (step.value) {
            case 1:
                return 'Let’s face your challenge!';
            case 2:
                return 'Plan of attack';
            case 3:
                return 'Let me guide you';
            case 4:
                return 'I want you to succeed!';
            default:
                return '';
        }
    });
</script>
