import { type ApiResponse, get, post, put, delete_ } from '@/services/apiClient';
import type { CanvasUserExperience } from '@/api/types/canvas/learnedExperience';
import type { CanvasLearnedExperience } from '@/store/learned-experiences/types';

export const getCanvasUserExperience = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasUserExperience>> {
    const request = get<CanvasUserExperience>(`canvas/${canvasId}/user-experience/`);
    return request(accessToken);
};

export const createUserExperience = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasUserExperience>> {
    const request = post<any, CanvasUserExperience>(`canvas/${canvasId}/user-experience`);
    return request(accessToken, {});
};

function learnedExperiencePath(canvasId: number, learnedExperienceId: number) {
    return `canvas/${canvasId}/user-experience/experience/${learnedExperienceId}`;
}

export type CanvasLearnedExperienceUpdateData = Omit<CanvasLearnedExperience, 'id' | 'created_at'>;
export type CanvasLearnedExperienceCreateData = CanvasLearnedExperienceUpdateData;

export const postCanvasLearnedExperience = function (
    canvasId: number,
    createData: CanvasLearnedExperienceCreateData,
    accessToken: string,
): Promise<ApiResponse<CanvasLearnedExperience>> {
    const request = post<CanvasLearnedExperienceCreateData, CanvasLearnedExperience>(
        `canvas/${canvasId}/user-experience/experience/`,
    );
    return request(accessToken, createData);
};

export const putCanvasLearnedExperience = function (
    canvasId: number,
    experienceId: number,
    updateData: CanvasLearnedExperienceUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<CanvasLearnedExperienceUpdateData, any>(
        learnedExperiencePath(canvasId, experienceId),
    );
    return request(accessToken, updateData);
};

export const deleteCanvasLearnedExperience = function (
    canvasId: number,
    experienceId: number,
    accessToken: string,
): Promise<ApiResponse<any>> {
    const request = delete_<CanvasLearnedExperience>(learnedExperiencePath(canvasId, experienceId));
    return request(accessToken);
};

// export async function removeCanvasLearnedExperience(
//     canvasId: number,
//     experienceId: number,
//     accessToken: string,
// ): Promise<any> {
//     return remove('canvas-learned-experience', async () => {
//         return await deleteCanvasLearnedExperience(canvasId, experienceId, accessToken);
//     });
// }
