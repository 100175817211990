<template>
    <app-page>
        <app-page-header title="Career History" :hide-tutorial="true" />

        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="Your career history">
                    Start by recording all your significant jobs you have had in your career, with
                    as much detail as you can.
                </app-page-content-intro>
            </template>

            <v-row class="w-100" no-gutters v-if="!profileStore.hasCurriculum">
                <UploadCurriculumGuideInline />
            </v-row>
            <CareerHistoryContent />
        </app-page-content>

        <app-page-footer>
            <template v-slot:actions>
                <CanvasNavOneButton text="Back to story" :to="storyRoute" />
            </template>
        </app-page-footer>
    </app-page>

    <CareerHistoryGuide />
</template>

<script setup lang="ts">
    import UploadCurriculumGuideInline from '@/components/canvas/learned-experiences/UploadCurriculumGuideInline.vue';
    import { useUserJobStore } from '@/store/user-job/store';
    import CareerHistoryContent from '@/components/profile/career-history/CareerHistoryContent.vue';
    import { useUserProfileStore } from '@/store/profile/store';
    import CanvasNavOneButton from '@/components/canvas/CanvasNavOneButton.vue';
    import { storyRoute } from '@/router/routes';
    import CareerHistoryGuide from '@/components/profile/career-history/CareerHistoryGuide.vue';

    const userJobStore = useUserJobStore();
    const profileStore = useUserProfileStore();

    await userJobStore.load();
</script>
