<template>
    <app-guide-modal-multiple-steps
        :model-value="modelValue"
        section-name="Learned Experiences"
        action-text="Let's go"
        @update:model-value="(v) => emit('update:modelValue', v)"
        :steps="1"
    >
        <template v-slot:title>Welcome back to Learned Experiences!</template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    This activity is valuable to make learned skills and recall memorable
                    experiences - lets help you to complete it!.
                </p>
                <p>
                    To complete this activity, you'll need to add at least three learned
                    experiences. Once you've reached that, you'll be all set!
                </p>
            </div>
        </template>
    </app-guide-modal-multiple-steps>
</template>
<script setup lang="ts">
    import AppGuideModalMultipleSteps from '@/components/guide/modal/AppGuideModalMultipleSteps.vue';

    const props = defineProps<{
        modelValue: boolean;
    }>();

    const emit = defineEmits(['update:modelValue']);
</script>
