import { type ApiResponse, delete_, post, put } from '@/services/apiClient';
import type {
    CurrentChallengeAction,
    CurrentChallengeActionCreateData,
    CurrentChallengeActionUpdateData,
} from '@/api/types/plan/currentChallengeAction';
import type { CurrentChallengeActionFeedback } from '@/api/types/plan/currentChallengeActionFeedback';

function actionPath(canvasId: number, challengeId: number, actionId: number) {
    return `canvas/${canvasId}/pathway/challenge/${challengeId}/action/${actionId}`;
}

export const postPlanCurrentChallengeActions = function (
    canvasId: number,
    challengeId: number,
    createData: CurrentChallengeActionCreateData,
    accessToken: string,
): Promise<ApiResponse<CurrentChallengeAction>> {
    const request = post<CurrentChallengeActionCreateData, CurrentChallengeAction>(
        `canvas/${canvasId}/pathway/challenge/${challengeId}/action/`,
    );
    return request(accessToken, createData);
};

export const postChallengePathActionsSuggestions = function (
    canvasId: number,
    challengeId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = post<{}, void>(
        `canvas/${canvasId}/pathway/challenge/${challengeId}/action/suggestions/`,
    );
    // TODO: add a method of that indicates how in depth the suggestions should be
    //       e.g.: include personality type
    //       e.g.: include core values
    return request(accessToken, {});
};

export const putPlanCurrentChallengeAction = function (
    canvasId: number,
    challengeId: number,
    actionId: number,
    updateData: CurrentChallengeActionUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<CurrentChallengeActionUpdateData, any>(
        actionPath(canvasId, challengeId, actionId),
    );
    return request(accessToken, updateData);
};

export const deletePlanCurrentChallengeAction = function (
    canvasId: number,
    challengeId: number,
    actionId: number,
    accessToken: string,
): Promise<ApiResponse<any>> {
    const request = delete_<CurrentChallengeAction>(actionPath(canvasId, challengeId, actionId));
    return request(accessToken);
};

export type CurrentChallengeActionFeedbackCreateData = {
    rating: number;
    helpful: boolean;
    description: string | null;
    add_to_learned_experience: boolean;
}

export const postPlanChallengeActionFeedback = function (
    canvasId: number,
    challengeId: number,
    actionId: number,
    createData: CurrentChallengeActionFeedbackCreateData,
    accessToken: string,
): Promise<ApiResponse<CurrentChallengeActionFeedback>> {
    const request = post<CurrentChallengeActionFeedbackCreateData, CurrentChallengeActionFeedback>(
        `canvas/${canvasId}/pathway/challenge/${challengeId}/action/${actionId}/feedback/`,
    );
    return request(accessToken, createData);
};