<template>
    <MainContent md="10" lg="10" xl="10">
        <!--    <v-container class="fill-height pa-0" fluid style="height: 85dvh !important">-->
        <v-container :disabled="true" class="fill-height pa-0 mt-4" fluid>
            <v-row class="w-100" no-gutters>
                <div class="text-h4 font-weight-700 w-100 text-center">My plan</div>
                <div class="text-center my-8 w-100">
                    Let's get you moving! Chose your plan: Either to solve some Immediate
                    Challenges, or work on your future Career Plan
                </div>
            </v-row>

            <v-row class="w-100" no-gutters>
                <v-col :cols="mdAndUp ? 6 : 12">
                    <v-card
                        variant="flat"
                        class="current-challenges-plan-card text-custom-grey-50 pa-0"
                        :class="mdAndUp ? 'mr-6' : ''"
                        width="100%"
                        height="250"
                        @click="store.setPreview(PlanType.Current)"
                    >
                        <v-card-item
                            class="pa-4 text-paragraph-md text-uppercase font-weight-regular text-left"
                        >
                            Work Plan
                        </v-card-item>
                        <v-spacer></v-spacer>
                        <v-card-text class="px-4 plan-card-text" :style="{ width: '85%' }">
                            <span class="text-h5">
                                Let's set some activities that will help you solve some current
                                challenges
                            </span>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col :cols="mdAndUp ? 6 : 12">
                    <v-card
                        variant="flat"
                        class="career-plan-card text-custom-grey-50 pa-0"
                        width="100%"
                        height="250"
                        :class="mdAndUp ? 'ml-6' : 'mt-5'"
                        @click="store.setPreview(PlanType.Career)"
                    >
                        <v-card-item
                            class="pa-4 text-paragraph-md text-uppercase font-weight-regular text-left"
                        >
                            Career Plan
                        </v-card-item>
                        <v-spacer></v-spacer>
                        <v-card-text class="pa-4 plan-card-text w-75">
                            <span class="text-h5">
                                Let's get you started on progressing your career pathway
                            </span>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <PlanItemPreview />

        <PageActivity :area="UserActivityArea.Plan" />
    </MainContent>
</template>

<script setup lang="ts">
    import { onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
    import { useDisplay } from 'vuetify';
    import MainContent from '@/components/layout/MainContent.vue';
    import PlanItemPreview from '@/components/plan/preview/PlanItemPreview.vue';
    import { useCurrentPlanStore } from '@/store/plan/current/store';
    import { useCareerPlanStore } from '@/store/plan/career/store';
    import { useCanvasStore } from '@/store/canvas/store';
    import { PlanType } from '@/store/plan/common/types';
    import { usePlanStore } from '@/store/plan/common/store';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';

    const { mdAndUp } = useDisplay();

    const canvasStore = useCanvasStore();
    const currentPlanStore = useCurrentPlanStore();
    const careerPlanStore = useCareerPlanStore();
    const activityStore = useActivityStore();
    const store = usePlanStore();

    onBeforeMount(() => {
        store.$reset();
        currentPlanStore.$reset();
        careerPlanStore.$reset();
    });

    onMounted(async () => {
        await canvasStore.load();

        await currentPlanStore.load();
        await careerPlanStore.load();

        await activityStore.addEntryActivity(UserActivityArea.Plan);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.Plan);
    });
</script>

<style scoped lang="scss">
    .plan-card-text {
        position: absolute;
        bottom: 0px;
    }

    .current-challenges-plan-card {
        background-color: rgb(var(--v-theme-custom-green-light));
    }

    .career-plan-card {
        background-color: rgb(var(--v-theme-custom-green-deep));
    }
</style>
