import {
    type GuideEnterEvent,
    GuideEventType,
    type GuideLeaveEvent,
    type GuideQuestionEvent,
    type GuideSuggestionEvent,
    GuideSupportedScreen,
} from '@/store/guide/types';
import { values } from 'lodash';
import { EventFactory } from '@/store/guide/event-factory/eventFactory';
import type { CurrentChallengeStoreState } from '@/store/current-challenges/types';

export class CurrentChallengesEventFactory extends EventFactory<CurrentChallengeStoreState> {
    screen = GuideSupportedScreen.CurrentChallenges;

    public enter(): GuideEnterEvent {
        return this.make<GuideEnterEvent>(GuideEventType.Enter, {
            context: values(this.contextState.selected),
        });
    }

    leave(): GuideLeaveEvent {
        return this.make<GuideLeaveEvent>(GuideEventType.Leave, {
            context: values(this.contextState.selected),
        });
    }

    question(message: string): GuideQuestionEvent {
        return this.make<GuideQuestionEvent>(GuideEventType.Question, {
            q: message,
        });
    }

    // selection(): GuideSelectionEvent {
    //     return this.make<GuideSelectionEvent>(
    //         GuideEventType.Selection,
    //         values(this.contextState.selected),
    //     );
    // }

    suggestion(): GuideSuggestionEvent {
        return this.make<GuideSuggestionEvent>(GuideEventType.Suggestion, {
            context: values(this.contextState.selected),
        });
    }
}
