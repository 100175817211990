import type { ActionFrequency } from '@/api/types/plan/currentChallengeAction';
export function formatFrequency(f: ActionFrequency, min: number, max?: number): string {
    const durationMin = min;
    let durationMax: number | undefined | null = max;
    const frequency = f;

    // Handle cases where max is less than min by ignoring max
    if (!!durationMax && durationMax <= durationMin) {
        durationMax = null;
    }

    if (!frequency) {
        return 'No frequency specified';
    }

    let durationText = '';
    switch (frequency) {
        case 'daily':
            if (durationMin && durationMax) {
                durationText = `${durationMin}-${durationMax} minutes`;
            } else if (durationMin) {
                durationText = `${durationMin} minutes`;
            }
            return `Do this daily${durationText ? ` for ${durationText}` : ''}`;

        case 'weekly':
            if (durationMin && durationMax) {
                durationText = `${durationMin}-${durationMax} times a week`;
            } else if (durationMin === 1) {
                durationText = 'once a week';
            } else if (durationMin === 2) {
                durationText = 'twice a week';
            } else if (durationMin) {
                durationText = `${durationMin} times a week`;
            }
            return `Do this${durationText ? ` ${durationText}` : ' weekly'}`;

        case 'monthly':
            if (durationMin && durationMax) {
                durationText = `${durationMin}-${durationMax} times a month`;
            } else if (durationMin === 1) {
                durationText = 'once a month';
            } else if (durationMin === 2) {
                durationText = 'twice a month';
            } else if (durationMin) {
                durationText = `${durationMin} times a month`;
            }
            return `Do this${durationText ? ` ${durationText}` : ' monthly'}`;

        default:
            return 'Frequency format not recognized';
    }
}
