import type { PersonalValueSelection } from '@/store/personal-values/types';
import type { PersonalValue } from '@/api/types/personalValue';
import {
    type CanvasPersonalValue,
    type CanvasPersonalValuesUpdateData,
    PersonalValueSelectionSubType,
    PersonalValueSelectionType,
} from '@/api/types/canvas/personalValue';
import { mapToObjectWith } from '@/lib/map';
import { PersonalValueCategory } from '@/api/types/personalValue';

export function makeUpdateData(
    alreadyTagged: Record<number, PersonalValueSelection>,
): CanvasPersonalValuesUpdateData {
    return Object.values(alreadyTagged);
}

export function filterByType(
    selection: PersonalValueSelection[],
    type: PersonalValueSelectionType,
): PersonalValueSelection[] {
    return selection.filter((selection: PersonalValueSelection) => {
        return selection.type === type;
    });
}

export function filterBySubType(
    selection: PersonalValueSelection[],
    subType?: PersonalValueSelectionSubType,
): PersonalValueSelection[] {
    return selection.filter((selection: PersonalValueSelection) => {
        return selection.sub_type === subType;
    });
}

export function mapCanvasPersonalValuesToStateSelection(
    canonicalValues: Record<string, PersonalValue>,
    canvasPersonalValues: CanvasPersonalValue[],
): Record<string, PersonalValueSelection> {
    return mapToObjectWith<CanvasPersonalValue, PersonalValueSelection>(
        canvasPersonalValues,
        (value: CanvasPersonalValue) => value.personal_value_id.toString(),
        (key: string, value: CanvasPersonalValue): PersonalValueSelection => {
            const canonicalValue = canonicalValues[key];
            return {
                ...value,
                personalValue: canonicalValue,
            };
        },
    );
}
