<template>
    <v-card
        color="custom-grey-100"
        min-height="130"
        min-width="260"
        class="rounded-0 box-shadow-grey"
    >
        <v-card-item class="ml-4 mt-4">
            <span class="text-h5 font-weight-bold text-use-heading-font mt-8">
                {{ props.item.description }}
            </span>
            <template v-slot:append>
                <v-btn
                    icon="mdi-close"
                    class="remove-icon"
                    variant="plain"
                    size="small"
                    @click="$emit('removed')"
                />
            </template>
        </v-card-item>
    </v-card>
</template>

<script setup lang="ts">
    import type { CanvasFutureAspirationItem } from '@/store/future-aspirations/types';

    const props = defineProps<{
        item: CanvasFutureAspirationItem;
    }>();
</script>

<style scoped lang="scss">
    .remove-icon {
        position: absolute;
        top: 8px;
        right: 8px;
    }
</style>
