<template>
    <div class="sorting-control-container mr-5">
        <div class="sorting-control-circle sorting-control-position text-paragraph-sm font-weight-bold text-center">
            {{ position }}
        </div>
        <v-icon
            v-if="!isLast && !hideSortButtons"
            class="sorting-control-circle sorting-control-button cursor-pointer"
            @click="onMoveDown"
        >
            mdi-chevron-down
        </v-icon>
        <v-icon
            v-if="!isFirst && !hideSortButtons"
            class="sorting-control-circle sorting-control-button cursor-pointer"
            @click="onMoveUp"
        >
            mdi-chevron-up
        </v-icon>
        <div v-if="!isLast" class="sorting-control-divider" :class="`${lineColor}`" />
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';

    const props = withDefaults(
        defineProps<{
            position: number;
            total: number;
            hideSortButtons?: boolean;
            lineColor?: 'primary' | 'grey';
        }>(),
        { lineColor: 'primary' },
    );

    const emit = defineEmits(['update:modelValue', 'move-up', 'move-down']);

    const isFirst = computed(() => {
        return props.position === 1;
    });

    const isLast = computed(() => {
        return props.position === props.total;
    });

    const onMoveUp = () => {
        emit('move-up', props.position);
    };

    const onMoveDown = () => {
        emit('move-down', props.position);
    };
</script>

<style scoped lang="scss">
    .sorting-control-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        align-self: stretch;
    }

    .sorting-control-circle {
        cursor: pointer;
        //padding: 0px 8px;
        border-radius: 100px;
        width: 24px; /* The width of the circle */
        height: 24px; /* The height of the circle */
        display: inline-block; /* Ensures the circle behaves like a block */

        &.sorting-control-position {
            background-color: rgb(var(--v-theme-primary)); /* The color of the circle */
        }

        &.sorting-control-button {
            background-color: rgb(
                var(--v-theme-custom-neutral-grey-200)
            ); /* The color of the circle */
        }
    }

    .sorting-control-divider {
        margin-left: 11px;
        margin-right: 11px;
        margin-bottom: 4px;
        width: 2px;
        height: 100%;

        border: 1px dashed;

        &.primary {
            border-color: rgb(var(--v-theme-primary));
        }

        &.grey {
            border-color: rgb(var(--v-theme-custom-neutral-grey-600));
        }

        border-image-slice: 10;

        //background: linear-gradient(167deg, #FFC32B 0%, #FB8625 100%);
    }
</style>
