// Optional: Define a function to generate an empty instance of this interface
import type { CurrentChallengeActionFeedback } from '@/api/types/plan/currentChallengeActionFeedback';
import type { CurrentChallengeAction } from '@/api/types/plan/currentChallengeAction';

export type EditableCurrentChallengeActionFeedback = Pick<
    CurrentChallengeActionFeedback,
    'rating' | 'helpful' | 'description'
> & {
    action: CurrentChallengeAction;
    add_to_learned_experience: boolean;
};

export function makeEmptyCurrentChallengeActionFeedback(
    action: CurrentChallengeAction,
): EditableCurrentChallengeActionFeedback {
    return {
        action,
        rating: 5, // Initial rating (can be updated)
        helpful: true, // Default helpful flag
        description: '', // No description initially
        add_to_learned_experience: false, // Default to not add to learned experience
    };
}
