import { getCanvasStory, putStoryFeedback } from '@/services/story/api';
import type { ApiStory } from '@/api/types/canvas/apiStory';
import { fetch, update } from '@/services/base';
import type { StorySectionId } from '@/store/story/types';

export async function fetchStory(canvasId: number, accessToken: string): Promise<ApiStory> {
    return fetch('story', async () => await getCanvasStory(canvasId, accessToken));
}

export type StoryFeedbackUpdateData = {
    section: StorySectionId;
    feedback: boolean;
};

export async function updateStorySectionFeedback(
    canvasId: number,
    updateData: StoryFeedbackUpdateData,
    accessToken: string,
): Promise<void> {
    return update(
        `story-feedback-${updateData.section}`,
        async () => await putStoryFeedback(canvasId, updateData, accessToken),
    );
}
