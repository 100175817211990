import type { Canvas } from '@/api/types/canvas';
import { getCanvasCareerPlan, postCanvasCareerPlan } from '@/services/plan/career/api';
import type { ApiResponse } from '@/services/apiClient';
import { create } from '@/services/base';
import type { CanvasCareerPlan } from '@/api/types/plan/careerPlan';
import type { FutureAspirationAction } from '@/api/types/plan/futureAspirationAction';
import {
    createCanvasFutureAspirationAction,
    updateCanvasFutureAspirationAction,
} from '@/services/plan/career/actions/service';
import type { FutureAspirationActionEditFormItem } from '@/store/plan/career/types';

export async function fetchOrCreateCanvasCareerPlan(
    canvas: Canvas,
    accessToken: string,
): Promise<[CanvasCareerPlan | null, ApiResponse<CanvasCareerPlan>]> {
    console.info('Getting career plan...');

    const careerPlanId = canvas.career_plan_id;

    if (careerPlanId) {
        const response = await getCanvasCareerPlan(canvas.id, accessToken);
        if (response.data) {
            console.info('canvas career plan loaded ...');
            return [response.data, response];
        }
    } else {
        console.info(
            `canvas career plan not found, proceed to create career plan for canvas with id: ${careerPlanId}`,
        );

        return await create(
            'canvas-career-plan',
            async () => await postCanvasCareerPlan(canvas.id, accessToken),
        );
    }

    throw new Error('Could not get/create canvas career plan');
}

type FutureAspirationActionSaveReturn = Pick<
    FutureAspirationAction,
    'id' | 'title' | 'due_date' | 'description'
>;

export async function createOrUpdateAction(
    canvasId: number,
    aspirationId: number,
    actionForm: FutureAspirationActionEditFormItem,
    accessToken: string,
): Promise<FutureAspirationActionSaveReturn> {
    const dueDate = new Date(Date.parse(actionForm.due_date));
    const dueDateISO = dueDate.toISOString();
    const payload = { title: actionForm.title, due_date: dueDateISO, type: actionForm.type };
    if (actionForm.id) {
        await updateCanvasFutureAspirationAction(
            canvasId,
            aspirationId,
            actionForm.id,
            payload,
            accessToken,
        );

        return {
            ...payload,
            id: actionForm.id,
        };
    } else {
        const [action, response] = await createCanvasFutureAspirationAction(
            canvasId,
            aspirationId,
            payload,
            accessToken,
        );

        return action;
    }
}
