<template>
    <Loader v-if="!store.isLoaded" />
    <MainContent md="12" lg="12" xl="12" v-else>
        <CurrentChallengesContainer />

        <template v-slot:footer="props">
            <MainContentFooter v-bind="props">
                <template v-slot:errors>
                    <SubmitNotification :store="store" />
                </template>
                <template v-slot:guide>
                    <CurrentPlanGuide v-if="userStore.isLoaded" />
                </template>
                <template v-slot:actions>
                    <CanvasSaveProgressActions
                        @on-submit="onSaveClick"
                        :submit-hide="true"
                        close-text="Back to my Plan"
                        :close-to="planRoute"
                    />
                </template>
            </MainContentFooter>
        </template>

        <PageActivity :area="UserActivityArea.PlanCurrent" />
    </MainContent>
</template>

<script setup lang="ts">
    import { onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import MainContent from '@/components/layout/MainContent.vue';
    import SubmitNotification from '@/components/footer-actions/SubmitNotification.vue';
    import MainContentFooter from '@/components/layout/MainContentFooter.vue';
    import CanvasSaveProgressActions from '@/components/canvas/CanvasSaveProgressActions.vue';
    import Loader from '@/components/common/Loader.vue';
    import CurrentChallengesContainer from '@/components/plan/_LEGACY_current-plan/_LEGACY_CurrentChallengesContainer.vue';
    import { useCurrentPlanStore } from '@/store/plan/current/store';
    import CurrentPlanGuide from '@/components/plan/_LEGACY_current-plan/_LEGACY_CurrentPlanGuide.vue';
    import { useUsersStore } from '@/store/user/store';
    import { UserActivityArea } from '@/api/types/userActivity';
    import { planRoute } from '@/router/routes';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';

    const canvasStore = useCanvasStore();
    const userStore = useUsersStore();

    const store = useCurrentPlanStore();

    const activityStore = useActivityStore();

    onBeforeMount(() => {
        store.$reset();
    });

    onMounted(async () => {
        await canvasStore.load();

        await store.load();

        await activityStore.addEntryActivity(UserActivityArea.PlanCurrent);
    });

    const onSaveClick = async () => {};

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.PlanCurrent);
    });
</script>
