<template>
    <v-dialog
        v-model="show"
        :fullscreen="true"
        :scrollable="true"
        persistent
        content-class="right-side-dialog"
        class="pa-8"
    >
        <v-card color="white" min-height="80" variant="flat" class="rounded-0 pa-4">
            <v-card-item class="pa-2">
                <span class="mx-0 font-weight-600 text-h6"> {{ titleText }} </span>
                <template v-slot:append>
                    <v-icon @click="onEditCancel">mdi-close</v-icon>
                </template>
            </v-card-item>

            <v-card-title class="pa-2 text-subtitle-1 font-weight-regular text-wrap">
                Describe why it was memorable and what you learned about yourself.
            </v-card-title>
            <v-card-text class="mx-0 px-2 text-paragraph-md">
                <!--                <v-radio-group class="mt-4" v-model="jobMode" inline>-->
                <!--                    <v-radio-->
                <!--                        label="My jobs"-->
                <!--                        :value="JobMode.Existing"-->
                <!--                        :disabled="!hasInferredJobs"-->
                <!--                    />-->
                <!--                    <v-radio label="New job" :value="JobMode.NewJob" />-->
                <!--                </v-radio-group>-->

                <v-row v-if="hasInferredJobs" no-gutters>
                    <v-col cols="10">
                        <v-select
                            :model-value="selectedInferredJob"
                            @update:model-value="onJobTitleSelect"
                            :items="userJobOptions"
                            label="Job Title"
                            placeholder="What was the role?"
                            :item-props="selectJobItemProps"
                            persistent-placeholder
                            return-object
                        />
                    </v-col>
                    <v-col cols="2">
                        <app-button
                            v-if="hasInferredJobs"
                            variant="outlined"
                            class="font-weight-light mt-1 ml-1"
                            height="50"
                            :width="isDesktop ? '' : 45"
                            size="x-small"
                            @click="onAddNewJob"
                        >
                            <v-icon>mdi-plus</v-icon>
                            <span v-if="isDesktop">Add another role</span>
                        </app-button>
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    v-else
                    class="text-subtitle-2 font-weight-light mt-4 mb-8 text-wrap"
                >
                    You did not add any roles yet:
                    <app-button
                        variant="outlined"
                        class="font-weight-light ml-2 my-n2 ma-0"
                        @click="onAddNewJob"
                    >
                        Add new role
                    </app-button>
                </v-row>

                <v-text-field
                    variant="outlined"
                    v-model="current!.title"
                    label="Title"
                    persistent-placeholder
                    placeholder="What was the memorable experience?"
                    class=""
                />

                <v-row no-gutters class="mt-4 mb-8">
                    <v-slider
                        v-model="current!.enjoyment"
                        step="1"
                        max="5"
                        min="-5"
                        :persistent-hint="true"
                        hint="Choose a value that best describes how positive or negative the experience was."
                        :color="enjoymentColor"
                        :track-color="enjoymentColor"
                        :track-fill-color="enjoymentColor"
                        thumb-label="always"
                        class="ml-0 enjoyment-slider"
                    >
                        <template v-slot:prepend>
                            <v-icon color="#7B7B7B">mdi-thumb-down</v-icon>
                        </template>
                        <template v-slot:append>
                            <v-icon color="#1638A5">mdi-thumb-up</v-icon>
                        </template>
                        <template v-slot:thumb-label="{ modelValue }">
                            {{ getSliderLabel(modelValue) }}
                        </template>
                    </v-slider>
                </v-row>

                <v-textarea
                    variant="outlined"
                    :counter="true"
                    :persistent-counter="true"
                    rows="6"
                    :rules="[
                        (v) =>
                            (v || '').length <= DESCRIPTION_MAX_LIMIT ||
                            'Description must be 200 characters or less',
                    ]"
                    v-model="current!.description"
                    persistent-placeholder
                    placeholder="Record briefly why this experience was so memorable, and what you learned."
                    label="Description"
                />
            </v-card-text>

            <app-notification
                v-if="
                    current!.status === LearnedExperienceItemState.Error &&
                    (current!.prevStatus === LearnedExperienceItemState.Updating ||
                        current!.prevStatus === LearnedExperienceItemState.Creating)
                "
                title="There was an error"
                message="We could not save your changes at this time. Try again."
                variant="error"
                :animate-to="true"
            />

            <v-card-actions>
                <app-button v-if="current?.id" @click="isDeleting = true">
                    Delete Experience
                </app-button>

                <v-spacer />

                <SubmitButton
                    :text="submitText"
                    :disabled="!isSubmitEnabled"
                    @click="onSaveClick"
                    :loading="
                        current!.status === LearnedExperienceItemState.Updating ||
                        current!.status === LearnedExperienceItemState.Creating ||
                        current!.status === LearnedExperienceItemState.Deleting
                    "
                />
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog width="500" :model-value="isDeleting" :persistent="true">
        <v-card class="fill-height pa-4">
            <v-card-title class="text-h5 text-wrap px-2">
                You are about to delete your experience
            </v-card-title>
            <v-card-text class="text-paragraph-md text-wrap px-2">
                Are you sure you want to delete your <i>'{{ current!.title }}'</i> experience?
            </v-card-text>
            <v-spacer />
            <app-notification
                title="There was an error"
                message="We could not save your changes at this time. Try again."
                variant="error"
                v-if="
                    current!.status === LearnedExperienceItemState.Error &&
                    current!.prevStatus === LearnedExperienceItemState.Deleting
                "
                :animate-to="true"
            />
            <v-card-actions class="px-2 mt-5">
                <app-button @click="isDeleting = false">No thanks</app-button>
                <v-spacer />
                <SubmitButton text="Delete" @click="onDeleteConfirmation" />
            </v-card-actions>
        </v-card>
    </v-dialog>

    <UserJobModal v-if="userJobStore.isCreating" @on-submit-success="onJobCreated" />
</template>

<script setup lang="ts">
    import SubmitButton from '@/components/footer-actions/SubmitButton.vue';
    import { computed, onMounted, ref } from 'vue';
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
    import { storeToRefs } from 'pinia';
    import { verify } from '@/store/verify';
    import { getEnjoymentColor } from '@/components/canvas/learned-experiences/util';
    import type { UserJob } from '@/api/types/userJob';
    import { useUsersStore } from '@/store/user/store';
    import UserJobModal from '@/components/canvas/user-jobs/UserJobModal.vue';
    import { useUserJobStore } from '@/store/user-job/store';
    import AppNotification from '@/components/common/AppNotification.vue';
    import { LearnedExperienceItemState } from '@/store/learned-experiences/types';
    import { byFromDateComparatorDesc } from '@/store/learned-experiences/utils';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const userJobStore = useUserJobStore();
    const show = ref(true);
    const isDeleting = ref(false);

    const DESCRIPTION_MAX_LIMIT = 200;

    enum JobMode {
        Existing = 1,
        NewJob = 2,
    }

    const userStore = useUsersStore();
    const store = useLearnedExperiencesStore();
    const { current } = storeToRefs(store);

    type SelectInferredJob = UserJob;
    const selectedInferredJob = ref<SelectInferredJob | null>(null);

    const hasInferredJobs = computed(() => {
        return userStore.userJobs.length > 0;
    });

    const { isDesktop } = useIsDesktop();

    const jobMode = ref<JobMode | null>(JobMode.Existing);

    onMounted(() => {
        if (hasInferredJobs.value && current.value?.user_job_id) {
            const found = userJobOptions.value.find((job: SelectInferredJob) => {
                return job.id === current.value!.user_job_id;
            });

            if (found) {
                selectedInferredJob.value = found;
            }
        }
    });

    const onJobCreated = (newJob: UserJob) => {
        const found = userJobOptions.value.find((job: SelectInferredJob) => {
            return job.id === newJob.id;
        });

        if (found) {
            onJobTitleSelect(found);
        }

        jobMode.value = JobMode.Existing;
    };

    const userJobOptions = computed(() => {
        return userStore.userJobs.sort(byFromDateComparatorDesc);
    });

    const selectJobItemProps = (item: SelectInferredJob) => {
        return {
            title: item.title,
            subtitle: item.company_name,
        };
    };

    const isSubmitEnabled = computed(() => {
        const value = verify(current.value, 'current.value must exist while editing');

        return (
            value.title?.length !== undefined &&
            value.title?.length > 0 &&
            value.description?.length !== undefined &&
            value.description?.length > 0 &&
            value.description?.length <= DESCRIPTION_MAX_LIMIT &&
            value.enjoyment !== null &&
            !!value.user_job_id
        );
    });

    const isEditExperience = computed(() => {
        return current.value?.id;
    });

    const submitText = computed(() => {
        if (isEditExperience.value) {
            return 'Update experience';
        } else {
            return 'Add experience';
        }
    });

    const titleText = computed(() => {
        if (isEditExperience.value) {
            return 'Update experience';
        } else {
            return 'Add a very memorable experience';
        }
    });

    const enjoymentColor = computed(() => {
        return getEnjoymentColor(current.value?.enjoyment);
    });

    const getSliderLabel = (modelValue: number) => {
        switch (modelValue) {
            case -5:
                return 'Very negative';
            case -4:
                return 'Very negative';
            case -3:
                return 'Moderately negative';
            case -2:
                return 'Moderately negative';
            case -1:
                return 'Slightly negative';
            case 0:
                return 'Neutral';
            case 1:
                return 'Slightly positive';
            case 2:
                return 'Moderately positive';
            case 3:
                return 'Moderately positive';
            case 4:
                return 'Very positive';
            case 5:
                return 'Very positive';
            default:
                return '';
        }
    };

    const onSaveClick = async () => {
        await store.saveLearnedExperience();
    };

    const onDeleteConfirmation = async () => {
        await store.deleteCurrent();

        if (!store.current) {
            isDeleting.value = false;
        }
    };

    const onJobTitleSelect = (value: SelectInferredJob) => {
        selectedInferredJob.value = value;

        const experience = current.value;

        if (experience) {
            experience.user_job_id = value.id;
        }
    };

    const onEditCancel = () => {
        store.cancelEdit();
    };

    const onAddNewJob = () => {
        userJobStore.addNew();
        jobMode.value = JobMode.NewJob;
    };
</script>

<style lang="scss">
    .enjoyment-slider {
        color: linear-gradient(180deg, #ffc32b 0%, #fb8625 100%) !important;
    }

    .v-slider-thumb__label {
        width: max-content;
    }
</style>
