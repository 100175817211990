<template>
    <ContextualInlineGuide
        class="mt-10"
        :only="[
            InlineGuideActionName.UploadCv,
            InlineGuideActionName.GotoCareerHistory,
            InlineGuideActionName.ReTryUploadCv,
        ]"
    />
    <!--
        Note: There is no specific guide for this page, so we make the avatar dissapear as soon as the message is gone
        TODO: Make: guide for this page
     -->
    <app-guide-chat-activator v-if="inlineGuideStore.message !== null" />

    <!--    <app-guide-chat-dialog :screen="GuideSupportedScreen.Story" />-->
</template>

<script setup lang="ts">
    import { InlineGuideActionName } from '@/store/inline-guide/types';
    import ContextualInlineGuide from '@/components/inline-guide/ContextualInlineGuide.vue';
    import { useInlineGuideStore } from '@/store/inline-guide/store';

    const inlineGuideStore = useInlineGuideStore();
</script>
