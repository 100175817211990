<template>
    <v-card class="fill-height d-flex flex-column rounded-0">
        <v-container class="w-100 pa-0">
            <v-row justify="space-around" class="bg-custom-grey-100">
                <v-img :src="props.imageSrc" height="300" />
            </v-row>
        </v-container>

        <v-card-item elevation="0" class="text-custom-grey-900 pa-8">
            <v-card-title class="text-h5 font-weight-bold text-wrap px-4 mb-4">
                {{ props.title }}
            </v-card-title>

            <v-card-text class="text-paragraph-md">
                <slot />
            </v-card-text>
        </v-card-item>

        <v-spacer></v-spacer>

        <v-card-actions class="pa-4">
            <CloseButton variant="plain" :to="planRoute" @click="() => store.setPreview(null)" />
            <v-spacer />

            <SubmitButton :text="props.submitTitle" :to="props.to" :disabled="disabled ?? false" />
        </v-card-actions>
    </v-card>
</template>
<script setup lang="ts">
    import SubmitButton from '@/components/footer-actions/SubmitButton.vue';
    import type { RouteLocationRaw } from 'vue-router';
    import CloseButton from '@/components/footer-actions/CloseButton.vue';
    import { planRoute } from '@/router/routes';
    import { usePlanStore } from '@/store/plan/common/store';

    const props = defineProps<{
        disabled?: boolean;
        imageSrc: string;
        title: string;
        submitTitle: string;
        to?: RouteLocationRaw;
    }>();

    const store = usePlanStore();
</script>
