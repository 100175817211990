<template>
    <app-page>
        <app-page-header title="Skills" @show-tutorial="showTutorial = true" />
        <SkillsGuideModalExplanation v-model="showTutorial" />
        <SkillsGuideModalCompleteReminder v-model="showReminder" v-if="false" />
        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="Identify your different skills">
                    To complete this activity, you’ll need to add at least three skills from
                    Personal & Learned, and one in Technical.

                    <br />
                    <br />
                    Ideally, aim to add at least ten skills overall. A good range to shoot for is
                    around 15 to 20 skills overall.

                    <app-expansion-panels variant="accordion" :items="introSkills" multiple />
                </app-page-content-intro>
            </template>

            <SkillsContent />
        </app-page-content>

        <SkillsGuide />

        <app-page-footer>
            <template v-slot:actions>
                <app-page-footer-save-to-story-actions :dummy="true" />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.Skills" />
    </app-page>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted, ref } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import { useSkillsStore } from '@/store/skills/store';
    import SkillsContent from '@/components/canvas/skills/SkillsContent.vue';
    import SkillsGuide from '@/components/canvas/skills/SkillsGuide.vue';
    import { useUVPStore } from '@/store/unique-value-proposition/store';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import SkillsGuideModalExplanation from '@/components/canvas/skills/guide-modal/SkillsGuideModalExplanation.vue';
    import SkillsGuideModalCompleteReminder from '@/components/canvas/skills/guide-modal/SkillsGuideModalCompleteReminder.vue';

    const showTutorial = ref(false);
    const showReminder = ref(false);

    const activityStore = useActivityStore();
    const canvasStore = useCanvasStore();
    const store = useSkillsStore();
    const uvpStore = useUVPStore();

    await activityStore.load();
    await canvasStore.load();
    await store.load();
    await uvpStore.load();

    await activityStore.addEntryActivity(UserActivityArea.Skills);

    onMounted(() => {
        if (!store.confirmedSkills.length) {
            showTutorial.value = true;
        } else if (!store.isAreaComplete) {
            showReminder.value = true;
        }
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.Skills);
    });

    const introSkills = [
        {
            title: 'Learned skills',
            text: "These are skills you've learned from experience, and normally don't require formal training. They are professional skills such as negotiation, collaboration or rostering.",
        },
        {
            title: 'Technical skills',
            text: 'These are skills that you will have trained in or become certified in and can evidence some current qualifications eg accounting, law or engineering.',
        },
    ];
</script>
