<template>
    <RightSideDialog
        :value="store.inPreview"
        v-if="store.preview"
        @update:modelValue="onUpdatedValue"
    >
        <CareerPlanPreview v-if="store.preview === PlanType.Career" />
        <CurrentPlanPreview v-else-if="store.preview === PlanType.Current" />
    </RightSideDialog>
</template>

<script setup lang="ts">
    import RightSideDialog from '@/components/canvas/preview/RightSideDialog.vue';
    import { usePlanStore } from '@/store/plan/common/store';
    import { PlanType } from '@/store/plan/common/types';
    import CareerPlanPreview from '@/components/plan/preview/CareerPlanPreview.vue';
    import CurrentPlanPreview from '@/components/plan/preview/CurrentPlanPreview.vue';

    const store = usePlanStore();

    const onUpdatedValue = (value?: boolean) => {
        if (value === false) {
            store.setPreview(null);
        }
    };
</script>
