import {
    type GuideEnterEvent,
    GuideEventType,
    type GuideLeaveEvent,
    type GuideQuestionEvent,
    type GuideSuggestionEvent,
    GuideSupportedScreen,
} from '@/store/guide/types';
import { values } from 'lodash';
import { EventFactory } from '@/store/guide/event-factory/eventFactory';
import type { PersonalStrengthsStoreState, SkillStoreState } from '@/store/skills/types';

export class PersonalStrengthsEventFactory extends EventFactory<PersonalStrengthsStoreState> {
    screen = GuideSupportedScreen.PersonalStrengths;

    public enter(): GuideEnterEvent {
        return this.make<GuideEnterEvent>(GuideEventType.Enter, {
            context: values(this.contextState.values),
        });
    }

    leave(): GuideLeaveEvent {
        return this.make<GuideLeaveEvent>(GuideEventType.Leave, {
            context: values(this.contextState.values),
        });
    }

    question(message: string): GuideQuestionEvent {
        return this.make<GuideQuestionEvent>(GuideEventType.Question, {
            q: message,
        });
    }

    // selection(): GuideSelectionEvent {
    //     return this.make<GuideSelectionEvent>(
    //         GuideEventType.Selection,
    //         values(this.contextState.values),
    //     );
    // }

    suggestion(): GuideSuggestionEvent {
        return this.make<GuideSuggestionEvent>(GuideEventType.Suggestion, {
            context: values(this.contextState.values),
        });
    }
}
