<template>
    <v-container class="pa-0 mt-0 mx-0 w-100" fluid :class="isDesktop ? 'pt-12' : 'pt-0'">
        <v-row class="mb-4 justify-center w-100" no-gutters v-if="hasSlot('intro')">
            <v-col :cols="isDesktop ? 4 : 12" :class="isDesktop ? 'pr-10' : ''">
                <slot name="intro" />
            </v-col>

            <v-col :cols="isDesktop ? 8 : 12" :class="isDesktop ? 'pl-10' : ''">
                <slot />
            </v-col>
        </v-row>
        <v-row v-else class="mb-4 justify-center w-100">
            <v-col :cols="isDesktop ? 10 : 12" :class="isDesktop ? '' : ''">
                <slot />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useSlots } from 'vue';

    const { isDesktop } = useIsDesktop();

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>

<style lang="scss" scoped>
    .app-activity-layout {
        margin: 0 auto;
    }
</style>
