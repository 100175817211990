import colors from '@/plugins/vuetify/colors';

const customTheme = {
    dark: false,
    colors: {
        primary: colors['custom-yellow'],
        secondary: colors['secondary-blue'],

        accent: '#b53600',
        error: '#7e0000',
        warning: '#ffc107',
        success: '#7fb500',

        black: colors['custom-neutral-black'],
        // background: '#ffc107',       // Background color
        'on-background': colors['custom-neutral-black'],  // Foreground color
        // 'surface': colors['custom-neutral-grey-200'], // Used for card backgrounds
        ...colors,

    },

};

export default customTheme;
