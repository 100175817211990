<template>
    <v-card color="primary-yellow-light" elevation="0 rounded-8 pa-6" min-height="120">
        <v-card-title class="pa-0 mb-2 text-heading-h2 font-weight-700 text-custom-neutral-black">
            {{ formattedProgress }}% complete
            <v-progress-linear
                :model-value="store.progress"
                color="secondary-orange"
                class="w-100 mt-2"
                height="6"
                :rounded="true"
            ></v-progress-linear>
        </v-card-title>
        <v-card-text
            v-if="store.isCompleted"
            class="text-paragraph-sm font-weight-600 text-custom-neutral-black pa-0"
        >
            Well done completing this challenge!
        </v-card-text>
        <v-card-text
            v-else-if="store.isInProgress"
            class="text-paragraph-sm font-weight-600 text-custom-neutral-black mb-5 pa-0"
        >
            {{ formattedDaysLeft }}
            <!-- TODO: make this work            -->
        </v-card-text>
        <v-card-text
            v-if="store.isInProgress"
            class="text-paragraph-sm text-custom-neutral-black pa-0"
        >
            Update your progress on this challenge by marking which activities you have completed.
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';
    import { computed } from 'vue';

    const store = useSingleChallengePathStore();

    const formattedProgress = computed(() => {
        return Math.round(store.progress);
    });

    const formattedDaysLeft = computed(() => {
        const days = store.daysLeft;
        if (days === null) {
            return `No due date set for this challenge path.`;
        } else if (days > 1) {
            return `${days} days left on this challenge path.`;
        } else if (days === 1) {
            return `1 day left on this challenge path.`;
        } else if (days === 0) {
            return `This challenge path ends today.`;
        } else {
            return `This challenge path has ended.`;
        }
    });
</script>

<style scoped lang="scss">
    .challenge-progress-bar {
        background: var(
            --Primary-Yellow-gradient,
            linear-gradient(167deg, #ffc32b 0%, #fb8625 100%)
        );
    }
</style>
