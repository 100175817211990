import type { Canvas } from '@/api/types/canvas';
import { type ApiResponse, get } from '@/services/apiClient';
import { makeWebsocket, type WebsocketCallbacks } from '@/services/guide/guideClient';
import type { GuideSupportedScreen } from '@/store/guide/types';
import type { User } from '@sentry/vue';
import type { UseWebSocketReturn } from '@vueuse/core';

export interface GuideChat {
    id: number;
    canvas_id: number;
    screen: GuideSupportedScreen;
    canvas: Canvas;
    user: User;
}

export const getGuideChats = (accessToken: string) => {
    const request = get<GuideChat[]>('api/admin/guide-chats');
    return request(accessToken);
};

export const useAdminGuideWebsocket = (
    guideChatId: number,
    accessToken: string,
    options: WebsocketCallbacks,
): UseWebSocketReturn<any> => {
    return makeWebsocket(`api/admin/guide-chats/${guideChatId}/connect`, accessToken, options);
};
