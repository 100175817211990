import {
    deleteCanvasSkill,
    getCanvasSkills,
    getInferredSkills,
    postCanvasSkill,
    putCanvasSkill,
    putInferredSkill,
} from '@/services/skills/api';
import type { CanvasSkill, InferredCanvasSkill } from '@/api/types/canvas/skills';
import { InferredRecordState } from '@/api/types/canvas/skills';
import type {
    CanvasSkillUpdateData,
    EditableSkillItem,
    InferredSkillUpdateData,
} from '@/store/skills/types';
import { create, fetch, remove, update } from '@/services/base';
import { verify } from '@/store/verify';

export async function fetchCanvasSkills(
    canvasId: number,
    accessToken: string,
): Promise<CanvasSkill[]> {
    return fetch('canvas-skills', async () => await getCanvasSkills(canvasId, accessToken));
}

export async function fetchInferredSkills(
    canvasId: number,
    accessToken: string,
): Promise<InferredCanvasSkill[]> {
    return fetch('inferred-skills', async () => await getInferredSkills(canvasId, accessToken));
}

export async function updateCanvasSkill(
    canvasId: number,
    skillId: number,
    updateData: CanvasSkillUpdateData,
    accessToken: string,
): Promise<CanvasSkill> {
    await update(
        'canvas',
        async () => await putCanvasSkill(canvasId, skillId, updateData, accessToken),
    );

    return {
        ...updateData,
        canvas_id: canvasId,
        id: skillId,
    };
}

export async function updateInferredCanvasSkill(
    canvasId: number,
    skillId: number,
    updateData: InferredSkillUpdateData,
    accessToken: string,
): Promise<[InferredCanvasSkill, CanvasSkill | null]> {
    const maybeNewSkill = await update<CanvasSkill | any>(
        'inferred-skill',
        async () => await putInferredSkill(canvasId, skillId, updateData, accessToken),
    );

    const inferredSkill = {
        ...updateData,
        canvas_id: canvasId,
        id: skillId,
    };

    if (updateData.state === InferredRecordState.Accepted) {
        verify(maybeNewSkill, 'Accepted skill should return new canvas skill');
        return [inferredSkill, maybeNewSkill];
    } else {
        return [inferredSkill, null];
    }
}

export function mapFromSkillToApiObject(skill: EditableSkillItem): CanvasSkillUpdateData {
    return {
        description: skill.description,
        type: verify(skill.type, 'no skill type'),
    };
}

export async function createCanvasSkill(
    canvasId: number,
    data: EditableSkillItem,
    accessToken: string,
): Promise<CanvasSkill> {
    const updateData = mapFromSkillToApiObject(data);

    const [canvasSkillItem, _response] = await create(
        'canvas-skill',
        async () => await postCanvasSkill(canvasId, updateData, accessToken),
    );

    return canvasSkillItem;
}

export async function removeSkill(
    canvasId: number,
    skillId: number,
    accessToken: string,
): Promise<any> {
    return remove(
        'canvas-skill',
        async () => await deleteCanvasSkill(canvasId, skillId, accessToken),
    );
}
