import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
import type { CanvasCurrentPlan } from '@/api/types/plan/currentPlan';
import { type EditableCurrentChallenge } from '@/store/plan/current/types';
import { byCreateDateComparator, makeEditableAction } from '@/store/plan/common/utils';

function makeEditableCurrentChallenge(challenge: CanvasCurrentChallenge): EditableCurrentChallenge {
    return {
        id: challenge.id,
        canvas_id: challenge.canvas_id,
        description: challenge.description,
        actions: challenge.actions.map(makeEditableAction),
    };
}

export function makeEditablePlan(plan: CanvasCurrentPlan) {
    return {
        id: plan.id,
        canvas_id: plan.canvas_id,
        challenges: plan.challenges.sort(byCreateDateComparator).map(makeEditableCurrentChallenge),
    };
}
