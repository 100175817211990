<template>
    <v-dialog width="700" :model-value="show" :persistent="true">
        <v-card class="fill-height pa-4">
            <v-card-item class="pa-2">
                <span class="text-h5 text-wrap px-2"> {{ title }} </span>
                <template v-slot:append>
                    <v-icon @click="close">mdi-close</v-icon>
                </template>
            </v-card-item>

            <v-card-text class="mx-0 px-2 text-paragraph-md">
                <v-text-field
                    variant="outlined"
                    v-model="value!.title"
                    label="Job Title"
                    persistent-placeholder
                    placeholder="What was the role?"
                    class=""
                />

                <v-text-field
                    variant="outlined"
                    v-model="value!.companyName"
                    label="Company"
                    persistent-placeholder
                    placeholder="What was the company name?"
                    class=""
                />

                <v-row no-gutters>
                    <v-col>
                        <v-text-field
                            variant="outlined"
                            v-model="value!.fromDate"
                            type="date"
                            label="Start date"
                            persistent-placeholder
                            class="mr-1"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            variant="outlined"
                            v-model="value!.toDate"
                            type="date"
                            label="End date"
                            persistent-placeholder
                            class="ml-1"
                        />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-spacer />
            <v-card-actions class="px-2 mt-5">
                <app-button @click="onDelete" v-if="deletable && value?.id"> Delete </app-button>
                <v-spacer />
                <SubmitButton
                    :disabled="!store.isSubmitEnabled"
                    :text="submitText"
                    @click="onSubmit"
                />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script setup lang="ts">
    import { computed, ref } from 'vue';
    import SubmitButton from '@/components/footer-actions/SubmitButton.vue';
    import { useUserJobStore } from '@/store/user-job/store';
    import { storeToRefs } from 'pinia';

    const store = useUserJobStore();
    const show = ref(true);

    const isDeleting = ref(false);

    const { value } = storeToRefs(store);

    const props = withDefaults(
        defineProps<{
            deletable?: boolean;
        }>(),
        { deletable: false },
    );

    const emit = defineEmits(['close', 'on-submit-success']);

    const close = () => {
        show.value = false;
        store.clear();

        emit('close');
    };

    const onDelete = async () => {
        isDeleting.value = true;

        await store.deleteCurrent();

        if (!store.value) {
            isDeleting.value = false;
        }
    };

    const onSubmit = async () => {
        if (store.isEditing) {
            await store.saveJob();
        } else {
            const created = await store.createNewJob();
            if (created) {
                emit('on-submit-success', created);
            }
        }

        if (!store.isError) {
            close();
        }
    };

    const submitText = computed(() => {
        if (store.isEditing) {
            return 'Update job';
        } else {
            return 'Add job';
        }
    });

    const title = computed(() => {
        if (store.isEditing) {
            return 'Update job';
        } else {
            return 'Add a new job';
        }
    });
</script>
