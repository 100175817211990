<template>
    <app-guide-inline
        v-if="show"
        text="I see that you’re making good progress with your challenges, is there something new that you’d like to focus on?"
        :action-to="currentChallengeRoute"
        action-text="Add more challenges"
        @close="showDummyGuide = false"
        @submit="showDummyGuide = false"
    />
    <app-guide-chat-activator v-if="showDummyGuide" />
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { currentChallengeRoute } from '@/router/routes';
    import { useChallengePathStore } from '@/store/challenge-path/challengePathStore';

    const store = useChallengePathStore();
    const showDummyGuide = ref(true);

    /**
     * This should only show if the user has no challenges left in “Not Started”.
     * Clicking “Add more challenges” will link user to the Challenges activity,
     * where they can add more. New challenges will then become available here to start a challenge pathway
     */
    const show = computed(() => {
        return (
            !store.notStartedChallenges.length &&
            store.inProgressChallenges.length &&
            store.completedChallenges.length
        );
    });
</script>
