<template>
    <v-card
        class="story-uvp text-custom-black pa-8 w-100"
        variant="flat"
        :min-height="hasStatement ? 200 : 160"
    >
        <v-card-item class="text-paragraph-xs pa-0 uppercase">
            My Unique Value Proposition
        </v-card-item>

        <v-card-text v-if="hasStatement" class="mt-5 pa-0">
            <span class="font-weight-bold text-heading-h1">
                {{ formatted_statement }}
            </span>
            <div class="font-weight-regular text-paragraph-sm text-custom-grey-900 mt-5">
                Last updated at: {{ last_updated_date }}
            </div>
        </v-card-text>
        <v-card-text v-else variant="plain" class="mt-5 pa-0">
            <span class="font-weight-bold text-heading-h4">
                This will be generated once you have completed the values, skills, and personality
                type activities.
            </span>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { useUVPStore } from '../../../../../../store/unique-value-proposition/store';
    import { computed } from 'vue';
    import { useDisplay } from 'vuetify';
    import { format } from 'date-fns';

    const store = useUVPStore();
    const { mdAndUp } = useDisplay();

    const formatted_statement = computed(() => {
        if (!store.currentUVP) {
            return '';
        }

        if (!store.currentUVP?.statement) {
            return '';
        }
        console.log('FULL UVP WORKING::');
        const rawStatement = store.currentUVP?.statement;
        console.log(rawStatement);
        //The following formatting should ideally be done by the backend but keeping here for now to demonstrate working
        const adjustedStatement = rawStatement.replace('Step 3: ', '').replace('Step 4: ', '');
        const uvpStatement = adjustedStatement
            .substring(adjustedStatement.lastIndexOf('\n') + 1)
            .trim();
        console.log('UVP ONLY::');
        console.log(uvpStatement);
        return uvpStatement;
    });

    const last_updated_date = computed(() => {
        if (!store.currentUVP) {
            return '';
        }

        return format(new Date(store.currentUVP.created_at), 'dd/MM/yy hh:mm:ss a');
    });

    const hasStatement = computed(() => {
        return useUVPStore().hasStatement;
    });
</script>

<style lang="scss">
    .story-uvp {
        border-radius: 30px !important;
        background: linear-gradient(94.29deg, #ffc32b 0%, #fb8625 100%) !important;
    }
</style>
