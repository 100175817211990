import type { Canvas } from '@/api/types/canvas';
import { getCanvasCurrentPlan, postCanvasCurrentPlan } from '@/services/plan/current/api';
import type { ApiResponse } from '@/services/apiClient';
import { create } from '@/services/base';
import type { CanvasCurrentPlan } from '@/api/types/plan/currentPlan';

export async function fetchOrCreateCanvasCurrentPlan(
    canvas: Canvas,
    accessToken: string,
): Promise<[CanvasCurrentPlan | null, ApiResponse<CanvasCurrentPlan>]> {
    console.info('Getting current plan...');

    const currentPlanId = canvas.challenge_path_id;

    if (currentPlanId) {
        const response = await getCanvasCurrentPlan(canvas.id, accessToken);
        if (response.data) {
            console.info('canvas current plan loaded ...');
            return [response.data, response];
        }
    } else {
        console.info(
            `canvas current plan not found, proceed to create current plan for canvas with id: ${currentPlanId}`,
        );

        return await create(
            'canvas-current-plan',
            async () => await postCanvasCurrentPlan(canvas.id, accessToken),
        );
    }

    throw new Error('Could not get/create canvas current plan');
}

// type CurrentChallengeActionSaveReturn = Pick<
//     CurrentChallengeAction,
//     'id' | 'title' | 'due_date' | 'description' | 'action_type'
// >;

// export async function createOrUpdateAction(
//     canvasId: number,
//     challengeId: number,
//     actionForm: PlanActionEditFormItem,
//     accessToken: string,
// ): Promise<CurrentChallengeActionSaveReturn> {
//     const dueDate = new Date(Date.parse(actionForm.due_date));
//     const dueDateISO = dueDate.toISOString();
//     const payload = { title: actionForm.title, description: actionForm.description || "", action_type: actionForm.action_type || "", due_date: dueDateISO };
//     if (actionForm.id) {
//         await updateCanvasCurrentChallengeAction(
//             canvasId,
//             challengeId,
//             actionForm.id,
//             payload,
//             accessToken,
//         );
//
//         return {
//             ...payload,
//             id: actionForm.id,
//         };
//     } else {
//         const [action, response] = await createCanvasCurrentChallengeAction(
//             canvasId,
//             challengeId,
//             payload,
//             accessToken,
//         );
//
//         return action;
//     }
// }
