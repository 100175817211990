<template>
    <div class="d-flex">
        <label v-if="label" class="text-paragraph-md font-weight-600 mr-4">{{ label }}:</label>
        <ul class="inline-list">
            <li v-for="(item, index) in items" :key="index">
                <div class="text-paragraph-md text-custom-neutral-grey-900">
                    {{ item }}
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const props = defineProps<{
        label?: string;
        items: string[];
    }>();

    const { responsiveClass } = useIsDesktop();

    type ListItem = { type: 'text'; content: string } | { type: 'separator' };
</script>
<style scoped lang="scss">
    ul {
        padding: 0;
        margin: 0;
        display: flex; /* Flexbox displays items inline */
        flex-direction: row; /* Change to row for horizontal alignment */
        flex-wrap: wrap;
        row-gap: 0;
        column-gap: 26px;
        align-items: center;
        align-content: center;
        align-self: stretch;
    }

    ul li::marker {
        padding-right: 16px;
        color: rgb(var(--v-theme-custom-neutral-grey-200)); /* Color for the bullet marker */
    }

    .inline-list li:first-child {
        list-style-type: none;  /* Hides the marker */
    }

    .inline-list li {
        display: list-item;  /* Ensures items stay inline */
    }
</style>
