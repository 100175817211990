export const requiredRule =
    (label = 'This field') =>
    (v: any): true | string => {
        return !!v || `${label} is required`;
    };

export const phoneNumberRules = (v: any) => {
    // Check if there are any spaces in the phone number
    if (/\s/.test(v)) {
        return 'Phone number should not contain spaces';
    }

    // E.164 format regex: starts with a '+' followed by 1-3 digits for the country code, and 1-15 digits for the phone number
    const phoneRegex = /^\+(\d{1,3})(\d{7,15})$/;  // Ensures no spaces and enforces a minimum number of digits

    if (!v) {
        return 'Phone number is required';
    }

    // Validate that the phone number matches the E.164 format without spaces
    if (!phoneRegex.test(v)) {
        return 'Phone number must should start with + and must not contain spaces (e.g., +642112345678)';
    }

    return true;
};

