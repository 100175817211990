<template>
    <v-chip
        class="text-white text-caption text-capitalize font-weight-600 action-label"
        :class="`action-type-label-${mappedType.toLowerCase()}`"
        size="23"
        label
    >
        {{ mappedType }}
    </v-chip>
</template>

<script setup lang="ts">
    import { PlanActionItemType } from '@/store/plan/common/types';
    import { computed } from 'vue';

    const props = defineProps<{
        type: PlanActionItemType;
    }>();

    const mappedType = computed(() => {
        switch (props.type) {
            case PlanActionItemType.ConnectActionType:
                return 'connection';
            case PlanActionItemType.ExperienceActionType:
                return 'experience';
            case PlanActionItemType.NewSkillActionType:
                return 'skill';
            default:
                return 'type';
        }
    });
</script>

<style scoped lang="scss">
    .action-label {
        padding: 2px 8px;
    }
    .action-type-label-experience {
        background-color: rgb(var(--v-theme-secondary-blue));
    }

    .action-type-label-connection {
        background-color: rgb(var(--v-theme-secondary-pink));
    }

    .action-type-label-skill {
        background-color: rgb(var(--v-theme-secondary-orange));
    }
</style>
