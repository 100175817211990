import { defineStore } from 'pinia';
import type { PageActivityStoreState } from '@/store/page-activity/types';
import { addCountMetricUser } from '@/services/activity/service';
import { verify } from '@/store/verify';
import { useUsersStore } from '@/store/user/store';
import {
    type GetAccessToken,
    getAccessTokenSilentlyOrFail,
    setAccessTokenGenerator,
} from '@/store/common/accessTokenState';
import type { UserActivityArea } from '@/api/types/userActivity';
import { useDocumentVisibility, useIdle } from '@vueuse/core';
import { watch } from 'vue';

const TIMEOUT_IDLE = 60 * 1000;

/**
 * This store is in charge of calculating and posting accumulate time in app
 * for a user in different areas/screens.
 *
 * The time is constantly send to the server on different occasions:
 * 1. When the tab gets inactive
 * 2. When the tab is idle. TODO: Use local storage to make multiple tabs work
 * 3. When a user navigates to other route of the application.
 * E.g.: from personal values to personality type
 */
export const usePageActivityStore = defineStore({
    id: 'page-activity-store',
    state: (): PageActivityStoreState => ({
        activeTime: Date.now(),
        isActive: true,
        area: null,
        isIdleWatcher: null,
        isActiveWatcher: null,
        _getAccessToken: null,
    }),
    getters: {},
    actions: {
        start(area: UserActivityArea, getAccessToken: GetAccessToken): void {
            this.area = area;
            setAccessTokenGenerator(this, getAccessToken);

            this._subscribeToBrowserActivity();
            this._subscribeToIdle();
        },
        stop() {
            // Manually stop the watchers
            if (this.isIdleWatcher) {
                this.isIdleWatcher();
            }

            if (this.isActiveWatcher) {
                this.isActiveWatcher();
            }

            this._onUserLeaveArea();
        },
        _restartActiveTime: function () {
            this.activeTime = Date.now();
        },
        _getAccumulatedTimeInMs(): number {
            return Date.now() - this.activeTime;
        },
        _addActiveTimeMetric: async function (
            userId: number,
            area: UserActivityArea,
            accumulatedTime: number,
        ) {
            if (accumulatedTime >= 1000) {
                const accessToken = await getAccessTokenSilentlyOrFail(this);

                const makeMetric = () => {
                    const timeInSeconds = accumulatedTime / 1000;
                    const rounded = Number(timeInSeconds.toFixed(0));
                    return {
                        metric_name: 'time-spent-in-seconds',
                        metric_value: rounded,
                        metric_dimensions: { area: area },
                    };
                };
                await addCountMetricUser(userId, makeMetric(), accessToken);
            } else {
                console.log('avoid logging navigations that take less than a second');
            }
        },
        _subscribeToBrowserActivity(): void {
            const visibility = useDocumentVisibility();

            // The following handler need to be sync (not async),
            // as it relies on the activeTime value.
            this.isActiveWatcher = watch(visibility, this._onVisibilityChange);
        },
        _subscribeToIdle(): void {
            const { idle, reset } = useIdle(TIMEOUT_IDLE);
            reset(); // reset the idle timer

            // The following handler need to be sync (not async),
            // as it relies on the activeTime value.
            this.isIdleWatcher = watch(idle, this._onIdleStateChange);
        },
        _onUserLeaveArea: function () {
            const area = verify(this.area, 'no area');
            const userId = verify(useUsersStore().user?.id, 'no user');
            const accumulatedTimeInMs = this._getAccumulatedTimeInMs();

            if (this.isActive) {
                this._addActiveTimeMetric(userId, area, accumulatedTimeInMs);
            } else {
                console.log(
                    'do not log if it is unmounting and the page is not active. It could be the page crashed',
                );
            }
        },
        _onVisibilityChange(): void {
            const area = verify(this.area, 'no area');
            const userId = verify(useUsersStore().user?.id, 'no user');
            this.isActive = !document.hidden;

            if (this.isActive) {
                this._restartActiveTime();
            } else {
                this._addActiveTimeMetric(userId, area, this._getAccumulatedTimeInMs());
            }
        },
        _onIdleStateChange(isIdle: boolean): void {
            if (this.isActive) {
                const area = verify(this.area, 'no area');
                const userId = verify(useUsersStore().user?.id, 'no user');
                if (isIdle) {
                    const accumulatedTimeInMs = this._getAccumulatedTimeInMs() - TIMEOUT_IDLE;
                    this._addActiveTimeMetric(userId, area, accumulatedTimeInMs);
                } else {
                    this._restartActiveTime();
                }
            } else {
                console.log('Watcher for isIdle while page is inactive does not make sense');
            }
        },
    },
});
