import { type ApiResponse, post, put, delete_ } from '@/services/apiClient';
import type {
    FutureAspirationAction,
    FutureAspirationActionCreateData,
    FutureAspirationActionUpdateData,
} from '@/api/types/plan/futureAspirationAction';

function actionPath(canvasId: number, aspirationId: number, actionId: number) {
    return `canvas/${canvasId}/career-plan/aspiration/${aspirationId}/action/${actionId}`;
}

export const postPlanFutureAspirationActions = function (
    canvasId: number,
    aspirationId: number,
    createData: FutureAspirationActionCreateData,
    accessToken: string,
): Promise<ApiResponse<FutureAspirationAction>> {
    const request = post<FutureAspirationActionCreateData, FutureAspirationAction>(
        `canvas/${canvasId}/career-plan/aspiration/${aspirationId}/action/`,
    );
    return request(accessToken, createData);
};

export const putPlanFutureAspirationAction = function (
    canvasId: number,
    aspirationId: number,
    actionId: number,
    updateData: FutureAspirationActionUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<FutureAspirationActionUpdateData, any>(
        actionPath(canvasId, aspirationId, actionId),
    );
    return request(accessToken, updateData);
};

export const deletePlanFutureAspirationAction = function (
    canvasId: number,
    aspirationId: number,
    actionId: number,
    accessToken: string,
): Promise<ApiResponse<any>> {
    const request = delete_<FutureAspirationAction>(actionPath(canvasId, aspirationId, actionId));
    return request(accessToken);
};
