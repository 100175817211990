<template>
    <app-page>
        <app-page-header title="Personality type" @show-tutorial="showTutorial = true" />
        <PersonalityTypeGuideModalExplanation v-model="showTutorial" />

        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="Let’s find out your type">
                    The Myers Briggs Type Indicator categorizes individuals into one of 16
                    personality types based on preferences for introversion/extroversion,
                    sensing/intuition, thinking/feeling, and judging/perceiving.

                    <br />
                    <app-external-button class="mt-5" href="https://www.16personalities.com/">
                        Read more about MBTI
                    </app-external-button>
                </app-page-content-intro>
            </template>
            <PersonalityTypeContent />
        </app-page-content>

        <app-page-footer>
            <template v-slot:errors>
                <SubmitNotification
                    :store="store"
                    :activity-area="UserActivityArea.PersonalityType"
                />
            </template>
            <template v-slot:actions>
                <app-page-footer-save-to-story-actions
                    @on-submit="onSaveClick"
                    submit-text="Save and return to story"
                    :submit-loading="store.isUpdating"
                    :submit-disabled="!store.isDirty"
                />
                <WarningChangesWillBeLost
                    :value="store.isDirty"
                    @on-discard-changes="onDiscardChanges"
                    @on-submit="onSaveClick"
                />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.PersonalityType" />
    </app-page>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted, ref } from 'vue';
    import SubmitNotification from '@/components/footer-actions/SubmitNotification.vue';
    import WarningChangesWillBeLost from '@/components/footer-actions/WarningChangesWillBeLost.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import PersonalityTypeGuideModalExplanation from '@/components/canvas/personality-type/guide-modal/PersonalityTypeGuideModalExplanation.vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import { useActivityStore } from '@/store/activity/store';
    import { usePersonalityTypeStore } from '@/store/personality-type/store';
    import PersonalityTypeContent from '@/components/canvas/personality-type/PersonalityTypeContent.vue';
    import { useRouter } from 'vue-router';
    import { storyRoute } from '@/router/routes';

    const showTutorial = ref(false);

    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();
    const store = usePersonalityTypeStore();

    await activityStore.load();
    await canvasStore.load();
    await store.load();

    await activityStore.addEntryActivity(UserActivityArea.PersonalityType);

    onMounted(() => {
        if (!store.original) {
            showTutorial.value = true;
        }
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.PersonalityType);
    });

    const onDiscardChanges = async () => {
        // This is done to reset the store, so changes are discarded, so next time the data is loaded from scratch
        // This is only done on stores that are not save on change, and have an explicit save button
        store.$reset();
    };

    const router = useRouter();

    const onSaveClick = async () => {
        await store.save();

        await router.push(storyRoute);
    };
</script>

<style scoped lang="scss">
    .image-container {
        position: absolute;
        right: 30px;
        bottom: 10px;
    }
</style>
