import { defineStore } from 'pinia';
import type { Canvas } from '@/api/types/canvas';
import { type UserContext, useUsersStore } from '@/store/user/store';
import { fetchCanvas, fetchOrCreateCanvas } from '@/services/canvas/service';
import { verify } from '@/store/verify';
import type { CanvasInputItemType } from '@/components/canvas/canvas-input-item/CanvasInputItemType';
import { HttpStatusCode } from 'axios';
import {
    DataState,
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
    setInSyncing,
} from '@/store/common/dataState';

type CanvasStoreState = {
    canvas: Canvas | null;
    state: DataState;
    error: any | null;
    item: CanvasInputItemType | null;
};

export type CanvasContext = UserContext & {
    canvasId: number;
    canvas: Canvas;
};

export const useCanvasStore = defineStore({
    id: 'canvas',
    state: (): CanvasStoreState => {
        return {
            canvas: null,
            item: null,

            ...makeDataState(),
        };
    },
    getters: {
        ...makeDataStateGetters(),
        inPreview(state): boolean {
            return state.item !== null;
        },
        needsLoading(state): boolean {
            return state.canvas === null;
        },
    },
    actions: {
        async load(token?: string) {
            if (!this.needsLoading) {
                console.info('Canvas already loaded');
                return;
            }

            console.info('Loading canvas...');
            setInLoading(this);

            let accessToken;
            if (token) {
                accessToken = token;
            } else {
                // TODO migrate canvas.load(accessToken) to canvas.load()
                const { accessToken: fromContextAccessToken } = await useUsersStore().makeContext();
                accessToken = fromContextAccessToken;
            }

            try {
                const [canvas, response] = await fetchOrCreateCanvas(
                    accessToken,
                    verify(useUsersStore().user, 'No user'),
                );

                if (response.status === HttpStatusCode.Created) {
                    // Manually trigger a reload of the user resource, to keep FKs updates
                    await useUsersStore().synchroniseCurrentUser();
                } else {
                    console.log('No need to synchronise user');
                }

                this.canvas = canvas;

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        async synchroniseCanvas(canvasId: number, accessToken: string) {
            console.info('Syncing canvas...');

            setInSyncing(this);

            try {
                const user = verify(useUsersStore().user, 'No user');
                this.canvas = await fetchCanvas(user.id, canvasId, accessToken);

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        async makeContext(): Promise<CanvasContext> {
            const userContext = await useUsersStore().makeContext();

            const canvas = verify(this.canvas, 'No canvas');
            return {
                canvas: canvas,
                canvasId: canvas.id,
                ...userContext,
            };
        },
    },
});
