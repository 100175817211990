import { type ApiResponse, get, post, delete_, put } from '@/services/apiClient';
import type { CurriculumVitae, CurriculumVitaeCreateData } from '@/api/types/curriculumVitae';
import type { InferredUserJobUpdateData, UserJob, UserJobCreate } from '@/api/types/userJob';

export const getCurriculumVitae = function (
    userId: number,
    curriculumVitaeId: number,
    accessToken: string,
): Promise<ApiResponse<CurriculumVitae>> {
    const request = get<CurriculumVitae>(`users/${userId}/curriculum-vitae/${curriculumVitaeId}`);
    return request(accessToken);
};

export const postCurriculumVitae = function (
    userId: number,
    updateData: CurriculumVitaeCreateData,
    accessToken: string,
): Promise<ApiResponse<CurriculumVitae>> {
    const request = post<CurriculumVitaeCreateData, CurriculumVitae>(
        `users/${userId}/curriculum-vitae/`,
    );
    return request(accessToken, updateData);
};

export const deleteCurriculumVitae = function (
    userId: number,
    curriculumVitaeId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = delete_<void>(`users/${userId}/curriculum-vitae/${curriculumVitaeId}/`);
    return request(accessToken);
};

export const postUserJob = function (
    userId: number,
    updateData: UserJobCreate,
    accessToken: string,
): Promise<ApiResponse<UserJob>> {
    const request = post<UserJobCreate, UserJob>(`users/${userId}/job/`);
    return request(accessToken, updateData);
};

export const putUserJob = function (
    userId: number,
    userJobId: number,
    updateData: UserJobCreate,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<UserJobCreate, void>(`users/${userId}/job/${userJobId}`);
    return request(accessToken, updateData);
};

export const putInferredUserJob = function (
    userId: number,
    userJobId: number,
    updateData: InferredUserJobUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<InferredUserJobUpdateData, void>(
        `users/${userId}/job/inferred/${userJobId}`,
    );
    return request(accessToken, updateData);
};

export const deleteUserJob = function (
    userId: number,
    userJobId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = delete_(`users/${userId}/job/${userJobId}`);
    return request(accessToken);
};
