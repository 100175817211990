import type { DataState } from '@/store/common/dataState';
import type { CanvasUserExperience } from '@/api/types/canvas/learnedExperience';
import type { UserJob } from '@/api/types/userJob';

export enum LearnedExperienceState {
    Inferred = 'inferred',
    UploadedByUser = 'uploaded',
    Accepted = 'accepted',
    Denied = 'denied',
}

export type ExperienceTimelineJob = UserJob & {
    experiences: CanvasLearnedExperience[];
};

export type TimelineItemExperience = CanvasLearnedExperience & { type: 'experience' };
export type TimelineItemJob = ExperienceTimelineJob & {
    type: 'job';
};
export type LearnedExperienceTimelineItem = TimelineItemExperience | TimelineItemJob;

export interface CanvasLearnedExperience {
    id: number;
    user_job_id: number;

    title: string;
    enjoyment: number;

    description?: string;
    state: LearnedExperienceState;

    created_at: string;
}

export enum LearnedExperienceItemState {
    New = 'new',
    Edit = 'edit',
    Creating = 'creating',
    Deleting = 'deleting',
    Updating = 'updating',
    Error = 'error',
    Saved = 'saved',
}

export interface EditableLearnedExperience {
    prevStatus: LearnedExperienceItemState | null;
    status: LearnedExperienceItemState;

    id?: number;
    user_job_id?: number;
    title?: string;
    state: LearnedExperienceState;
    description?: string;
    enjoyment?: number;

    createAt?: string;
}

export type EditableUserJob = {
    id?: number;
    title: string;
    companyName: string;
    fromDate?: string;
    toDate?: string;
};

export interface EditableExperience {
    id: number;
    canvas_id: number;
    description?: string;
    experiences: CanvasLearnedExperience[];
}

export type LearnedExperienceStoreState = {
    current: EditableLearnedExperience | null;
    value: CanvasUserExperience;
    editable: EditableExperience;
    state: DataState;
    error: any | null;
};
