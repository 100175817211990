import type {
    GuideEnterEvent,
    GuideLeaveEvent,
    GuideQuestionEvent,
    GuideSuggestionEvent,
    GuideStoreState,
} from '@/store/guide/types';
import { type GuideEvent, GuideEventType, GuideSupportedScreen } from '@/store/guide/types';
import { v4 as uuidv4 } from 'uuid';

export abstract class EventFactory<T extends any> {
    abstract screen: GuideSupportedScreen;

    constructor(
        protected guideState: GuideStoreState,
        protected contextState: T,
    ) {}

    abstract enter(): GuideEnterEvent;

    abstract leave(): GuideLeaveEvent;

    abstract question(message: string): GuideQuestionEvent;

    abstract suggestion(): GuideSuggestionEvent;

    // abstract selection(): GuideSelectionEvent;

    protected make<T extends GuideEvent>(type: GuideEventType, value: any): T {
        return {
            uuid: uuidv4(),
            type,
            value,
        } as T;
    }
}
