<template>
    <v-row justify="space-around" class="text-h5 font-weight-700" no-gutters> My Work Plan</v-row>

    <v-row justify="space-around" class="text-center my-4" no-gutters>
        Here's the summary of your challenges and your actions to solve them - does this look right?
    </v-row>

    <v-container class="bg-custom-grey-100">
        <v-row justify="space-around" class="text-h6 font-weight-700 mb-8" no-gutters>
            What you are challenged by
        </v-row>

        <v-row no-gutters class="mt-4">
            <v-col
                :cols="lgAndUp ? 4 : md ? 6 : 12"
                v-for="(challenge, index) in store.challenges"
                class="pa-0 mb-16"
            >
                <ChallengesListItem :item="challenge" class="mx-4" />

                <v-row class="mt-8 text-paragraph-md font-weight-700 w-100 text-center" no-gutters>
                    <v-container class="pa-4" :style="{ height: '80px' }">
                        The things you are going to do in order to
                        <span class="font-weight-bold">{{ challenge.description }}</span>
                    </v-container>
                </v-row>

                <PlanActionList
                    :item="challenge"
                    class="mt-6 px-4"
                    @on-add-new="onAddNew"
                    @edit-save-click="
                        (item, actionForm) => onEditSaveClick(challenge, item, actionForm)
                    "
                    @remove-click="(item) => onRemoveClick(challenge, item)"
                />
                <v-divider v-if="smAndDown" class="mt-16 mx-n4" color="custom-purple-deep" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { useDisplay } from 'vuetify';
    import ChallengesListItem from '@/components/plan/_LEGACY_current-plan/_LEGACY_ChallengesListItem.vue';
    import { useCurrentPlanStore } from '@/store/plan/current/store';
    import PlanActionList from '@/components/plan/common/PlanActionList.vue';
    import type { EditableCurrentChallenge } from '@/store/plan/current/types';
    import type { EditablePlanAction, PlanActionEditFormItem } from '@/store/plan/common/types';
    import { useAuth0 } from '@/auth/auth';

    const { lgAndUp, md, smAndDown } = useDisplay();

    const store = useCurrentPlanStore();

    const { getAccessTokenSilently } = useAuth0();

    const onAddNew = (item: EditableCurrentChallenge) => {
        store.addAction(item);
    };

    const onEditSaveClick = async (
        challenge: EditableCurrentChallenge,
        item: EditablePlanAction,
        actionForm: PlanActionEditFormItem,
    ) => {
        const accessToken = await getAccessTokenSilently();
        await store.saveAction(challenge, item, actionForm, accessToken);
    };

    const onRemoveClick = async (
        challenge: EditableCurrentChallenge,
        action: EditablePlanAction,
    ) => {
        const accessToken = await getAccessTokenSilently();
        await store.removeAction(challenge, action, accessToken);
    };
</script>
