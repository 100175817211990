import { type ApiResponse, delete_, get, post, put } from '@/services/apiClient';
import {
    type CanvasSkill,
    type CanvasSkillCreateData,
    type InferredCanvasSkill,
} from '@/api/types/canvas/skills';
import type { CanvasSkillUpdateData, InferredSkillUpdateData } from '@/store/skills/types';

export const getCanvasSkills = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasSkill[]>> {
    const request = get<CanvasSkill[]>(`canvas/${canvasId}/skills/`);
    return request(accessToken);
};

export const postCanvasSkill = function (
    canvasId: number,
    createData: CanvasSkillCreateData,
    accessToken: string,
): Promise<ApiResponse<CanvasSkill>> {
    const request = post<CanvasSkillCreateData, CanvasSkill>(`canvas/${canvasId}/skills/`);
    return request(accessToken, createData);
};

export const putCanvasSkill = function (
    canvasId: number,
    experienceId: number,
    updateData: CanvasSkillUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<CanvasSkillUpdateData, any>(skillPath(canvasId, experienceId));
    return request(accessToken, updateData);
};

export const deleteCanvasSkill = function (
    canvasId: number,
    skillId: number,
    accessToken: string,
): Promise<ApiResponse<any>> {
    const request = delete_<CanvasSkill>(skillPath(canvasId, skillId));
    return request(accessToken);
};

function skillPath(canvasId: number, skillId: number) {
    return `canvas/${canvasId}/skills/${skillId}`;
}

export const getInferredSkills = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<InferredCanvasSkill[]>> {
    const request = get<InferredCanvasSkill[]>(`canvas/${canvasId}/skills/inferred`);
    return request(accessToken);
};

export const putInferredSkill = function (
    canvasId: number,
    experienceId: number,
    updateData: InferredSkillUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<InferredSkillUpdateData, any>(inferredSkillPath(canvasId, experienceId));
    return request(accessToken, updateData);
};

function inferredSkillPath(canvasId: number, skillId: number) {
    return `canvas/${canvasId}/skills/inferred/${skillId}`;
}
