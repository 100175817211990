export function mapToObjectById<T extends { id: number }>(values: T[]): Record<string, T> {
    return values.reduce((a, value) => ({ ...a, [value.id.toString()]: value }), {});
}

export function mapToObjectWith<T extends any, RT extends any>(
    values: T[],
    makeKey: (value: T) => string,
    makeValue: (key: string, value: T) => RT,
): Record<string, RT> {
    return values.reduce((a, value) => {
        const key = makeKey(value);
        return { ...a, [key]: makeValue(key, value) };
    }, {});
}
