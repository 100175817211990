export enum PersonalityTypeMethod {
    /**
     * Myers & Briggs Type Indicator
     * 16 personality types
     *
     * https://www.16personalities.com/personality-types
     */
    MBTI = 'mbti',

    /**
     * 9 personality types
     * https://www.truity.com/blog/enneagram/9-types-enneagram
     */
    Enneagram = 'enneagram',

    /**
     * https://www.discprofiles.com/disc-styles/
     */
    Disc = 'disc',
}

export enum PersonalityTypeValues {
    ESTJ = 'ESTJ',
    ENTJ = 'ENTJ',
    ESFJ = 'ESFJ',
    ENFJ = 'ENFJ',
    ISTJ = 'ISTJ',
    ISFJ = 'ISFJ',
    INTJ = 'INTJ',
    INFJ = 'INFJ',
    ESTP = 'ESTP',
    ESFP = 'ESFP',
    ENTP = 'ENTP',
    ENFP = 'ENFP',
    ISTP = 'ISTP',
    ISFP = 'ISFP',
    INTP = 'INTP',
    INFP = 'INFP',
}

export interface CanvasPersonalityType {
    id: number;
    canvas_id: number;
    method: PersonalityTypeMethod;
    value: PersonalityTypeValues;
}

export type CanvasPersonalityTypeUpdateData = Pick<CanvasPersonalityType, 'method' | 'value'>;
export type CanvasPersonalityTypeCreateData = CanvasPersonalityTypeUpdateData;
