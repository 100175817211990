import type { User } from '@/api/types/user';
import type { Canvas } from '@/api/types/canvas';
import { createCanvas, getCanvas } from '@/services/canvas/api';
import type { ApiResponse } from '@/services/apiClient';
import { create } from '@/services/base';

export async function fetchCanvas(
    userId: number,
    canvasId: number,
    accessToken: string,
): Promise<Canvas | null> {
    console.info('Getting canvas...');
    const response = await getCanvas(userId, canvasId, accessToken);
    if (response.data) {
        console.info('Canvas loaded ...');
        return response.data;
    }

    return null;
}

export async function fetchOrCreateCanvas(
    accessToken: string,
    user: User,
): Promise<[Canvas | null, ApiResponse<Canvas>]> {
    console.info('Getting canvas...');
    const canvasId = user.canvas_id;

    if (canvasId) {
        const response = await getCanvas(user.id, canvasId, accessToken);
        if (response.data) {
            console.info('Canvas loaded ...');
            return [response.data, response];
        }
    } else {
        console.info(
            `Canvas not found, proceed to create canvas for user with email: ${user.email}`,
        );

        return await create('create-canvas', async () => await createCanvas(user.id, accessToken));
    }

    throw new Error('Could not get/create canvas');
}
