import {
    type StorySection,
    type StoryActivity,
    StoryActivityStatus,
    StorySectionState,
} from '@/store/story/types';

export const sectionStatus = (activities: StoryActivity[]): StorySectionState => {
    const notStarted = activities.every(
        (activity) => activity.status === StoryActivityStatus.NotStarted,
    );

    if (notStarted) {
        return StorySectionState.NotStarted;
    } else {
        const inProgress = activities.some(
            (activity) => activity.status === StoryActivityStatus.InProgress,
        );

        if (inProgress) {
            return StorySectionState.InProgress;
        } else {
            return StorySectionState.Completed;
        }
    }
};

export function showFeedbackQuestion(section: StorySection) {
    if (section.feedback === null || section.feedback === undefined) {
        return true;
    }

    return !section.feedback;
}
