<template>
    <span>
        <v-avatar
            color="custom-purple-mid"
            size="38"
            class="ml-n2 pa-5"
            :class="props.clickable ? 'clickable-avatar' : ''"
            @click="onClick"
        >
            <span class="avatar-initials font-weight-600">{{ displayUserInitials }}</span>
        </v-avatar>
    </span>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { getUserInitials } from '@/store/user/util';
    import type { User } from '@/api/types/user';
    import { useRouter } from 'vue-router';
    import { profileRoute } from '@/router/routes';

    const router = useRouter();

    const props = defineProps<{
        user: User | null;
        /**
         * Whether the avatar should be clickable or not.
         * Note: I could not find an easy way of doing this in vuetify, so I added a custom class
         * and some styles
         */
        clickable: boolean;
    }>();

    const displayUserInitials = computed(() => {
        if (props.user) {
            return getUserInitials(props.user);
        } else {
            return 'You';
        }
    });

    const onClick = () => {
        router.push(profileRoute);
    };
</script>

<style scoped lang="scss">
    .clickable-avatar {
        cursor: pointer;
    }

    .avatar-initials {
        font-size: 18px;
    }
</style>
