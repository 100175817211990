import type { PredefinedChallenge } from '@/api/types/predefinedChallenge';
import { getPredefinedChallenges } from '@/services/predefined-challenges/api';

export async function fetchPredefinedChallenges(
    accessToken: string,
): Promise<PredefinedChallenge[]> {
    console.info('Fetching predefined challenges...');
    const response = await getPredefinedChallenges(accessToken);

    if (response.data) {
        console.info('Predefined challenges fetched...');
        return response.data;
    } else {
        throw new Error(response.error?.message);
    }
}
