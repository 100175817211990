import { create, remove, update } from '@/services/base';
import type { ApiResponse } from '@/services/apiClient';
import {
    deletePlanFutureAspirationAction,
    postPlanFutureAspirationActions,
    putPlanFutureAspirationAction,
} from '@/services/plan/career/actions/api';
import type {
    FutureAspirationAction,
    FutureAspirationActionCreateData,
    FutureAspirationActionUpdateData,
} from '@/api/types/plan/futureAspirationAction';

export async function updateCanvasFutureAspirationAction(
    canvasId: number,
    aspirationId: number,
    actionId: number,
    updateData: FutureAspirationActionUpdateData,
    accessToken: string,
): Promise<void> {
    return update(
        'canvas-career-aspiration-action',
        async () =>
            await putPlanFutureAspirationAction(
                canvasId,
                aspirationId,
                actionId,
                updateData,
                accessToken,
            ),
    );
}

export async function createCanvasFutureAspirationAction(
    canvasId: number,
    aspirationId: number,
    data: FutureAspirationActionCreateData,
    accessToken: string,
): Promise<[FutureAspirationAction, ApiResponse<FutureAspirationAction>]> {
    return await create(
        'canvas-career-aspiration-action',
        async () =>
            await postPlanFutureAspirationActions(canvasId, aspirationId, data, accessToken),
    );
}

export async function removeCanvasFutureAspirationAction(
    canvasId: number,
    aspirationId: number,
    actionId: number,
    accessToken: string,
): Promise<any> {
    return remove(
        'canvas-career-aspiration-action',
        async () =>
            await deletePlanFutureAspirationAction(canvasId, aspirationId, actionId, accessToken),
    );
}
