<template>
    <!-- ml-n4 compensates in mobile the padding on the <header />, so it starts next to the left    -->
    <v-list-item
        class="ml-n4"
        height="100%"
        title="My story"
        active-class="active-navigation-link"
        :to="storyRoute"
        :active="isCurrent(storyRoute)"
        :disabled="!userStore.hasProfileBeenSet"
    ></v-list-item>
    <v-tooltip location="top" text="Coming soon...">
        <template v-slot:activator="{ props }">
            <span v-bind="props">
                <v-list-item
                    v-if="showOldPlan"
                    height="100%"
                    title="My pathways"
                    active-class="active-navigation-link"
                    :to="planRoute"
                    :active="isCurrent(planRoute)"
                    :disabled="true || !userStore.hasProfileBeenSet"
                />
            </span>
        </template>
    </v-tooltip>

    <v-list-item
        v-if="showNewPlan"
        height="100%"
        title="My pathways"
        active-class="active-navigation-link"
        :to="challengePathRoute"
        :active="isChallengePath"
        :disabled="!userStore.hasProfileBeenSet"
    ></v-list-item>
</template>

<script setup lang="ts">
    import { useUsersStore } from '@/store/user/store';
    import { challengePathRoute, type DeclaredRoute, planRoute, storyRoute } from '@/router/routes';
    import { useRoute } from 'vue-router';
    import { computed } from 'vue';
    import { RoutesName } from '@/router/routesName';

    const route = useRoute();

    const isCurrent = (r: DeclaredRoute) => {
        return r.name === route.name;
    };
    const isCurrentRouteName = (routeName: string) => {
        return routeName === route.name;
    };

    const userStore = useUsersStore();

    const showNewPlan = userStore.hasCPfeature;
    const showOldPlan = !showNewPlan;

    const isChallengePath = computed(() => {
        return (
            isCurrent(challengePathRoute) ||
            isCurrentRouteName(RoutesName.SingleChallengeStart) ||
            isCurrentRouteName(RoutesName.SingleChallengeUpdateProgress) ||
            isCurrentRouteName(RoutesName.SingleChallengeSequencing)
        );
    });
</script>

<style scoped lang="scss">
    .active-navigation-link {
        :deep(.v-list-item-title) {
            font-weight: 700;
        }

        border-bottom: 2px solid #262626;
    }

    :deep(.v-list-item__overlay) {
        display: none;
    }

    :deep(.v-list-item-title) {
        font-size: 18px;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.045px;
    }
</style>
