<template>
    <v-btn class="text-custom-purple-deep bg-white" variant="outlined" @click="handleLogout">
        Log Out
    </v-btn>
</template>

<script setup lang="ts">
    import { useAuth0 } from '@/auth/auth';

    const { logout } = useAuth0();

    const handleLogout = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
</script>
