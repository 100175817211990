import {
    deleteCurriculumVitae,
    deleteUserJob,
    getCurriculumVitae,
    postCurriculumVitae,
    postUserJob,
    putInferredUserJob,
    putUserJob,
} from '@/services/profile/api';
import type { CurriculumVitae, CurriculumVitaeCreateData } from '@/api/types/curriculumVitae';
import { create, remove, update } from '@/services/base';
import type { InferredUserJob, UserJob, UserJobCreate } from '@/api/types/userJob';
import { type EditableUserJob } from '@/store/learned-experiences/types';
import { verify } from '@/store/verify';
import { InferredRecordState } from '@/api/types/canvas/skills';
import type { User, UserUpdateData } from '@/api/types/user';
import { mapBrowserDateStringToIso } from '@/lib/dates';
import type { UserInputs } from '@/store/profile/types';

export async function fetchCurriculumVitae(
    userId: number,
    curriculumVitaeId: number,
    accessToken: string,
): Promise<CurriculumVitae> {
    console.info('Fetching canvas curriculum vitae...');
    const response = await getCurriculumVitae(userId, curriculumVitaeId, accessToken);

    if (response.data) {
        console.info('User curriculum vitae fetched...');
        return response.data;
    } else {
        throw new Error(response.error?.message);
    }
}

export async function createCurriculumVitae(
    userId: number,
    createData: CurriculumVitaeCreateData,
    accessToken: string,
): Promise<CurriculumVitae> {
    console.info('Creating canvas curriculum vitae...');

    const [curriculumVitae, _response] = await create(
        'create-curriculum-vitae',
        async () => await postCurriculumVitae(userId, createData, accessToken),
    );

    return curriculumVitae;
}

export async function removeCurriculumVitae(
    userId: number,
    curriculumVitaeId: number,
    accessToken: string,
): Promise<void> {
    return remove(
        'curriculum-vitae',
        async () => await deleteCurriculumVitae(userId, curriculumVitaeId, accessToken),
    );
}

function mapUserJobToApiObject(job: EditableUserJob): UserJobCreate {
    const fromDateISO = mapBrowserDateStringToIso(job.fromDate);
    const toDateISO = mapBrowserDateStringToIso(job.toDate);

    return {
        title: verify(job.title, 'no experience.title'),
        company_name: verify(job.companyName, 'no experience.user_job_id'),
        from_date: fromDateISO ?? undefined,
        to_date: toDateISO ?? undefined,
    };
}

export function mapUserUpdateDataToApiObject(inputs: UserInputs): UserUpdateData {
    return {
        first_name: inputs.first_name,
        last_name: inputs.last_name,
        linkedin_profile: inputs.linkedin_profile,
        current_company: inputs.current_company,
        current_company_confidence_rating: inputs.current_company_confidence_rating,
        job_title: inputs.job_title,
        visa_type: inputs.visa_type,
        gender: inputs.gender,
        phone_number: inputs.phone_number,
        date_of_birth: mapBrowserDateStringToIso(inputs.date_of_birth) ?? undefined,
        location: inputs.location,
        nationality: inputs.nationality,
        primary_language: inputs.primary_language,
        other_languages: (inputs.other_languages ?? []).join(', '),
    };
}

export async function createUserJob(
    userId: number,
    createData: EditableUserJob,
    accessToken: string,
): Promise<UserJob> {
    const userCreateApi = mapUserJobToApiObject(createData);

    console.info('Creating user job...');

    const [userJob, _response] = await create(
        'create-user-job',
        async () => await postUserJob(userId, userCreateApi, accessToken),
    );

    return userJob;
}

export async function removeUserJob(
    userId: number,
    userJobId: number,
    accessToken: string,
): Promise<any> {
    return remove(
        'delete-user-job',
        async () => await deleteUserJob(userId, userJobId, accessToken),
    );
}

export async function updateUserJob(
    userId: number,
    userJobId: number,
    job: EditableUserJob,
    accessToken: string,
): Promise<void> {
    const data = mapUserJobToApiObject(job);

    console.info('Creating user job...');

    await update(
        'put-user-job',
        async () => await putUserJob(userId, userJobId, data, accessToken),
    );
}

export async function updateInferredUserJob(
    userId: number,
    id: number,
    updateData: InferredUserJob,
    accessToken: string,
): Promise<[InferredUserJob, UserJob | null]> {
    const maybeNewUserJob = await update<UserJob | any>(
        'inferred-user-job',
        async () => await putInferredUserJob(userId, id, updateData, accessToken),
    );

    const inferredUserJob = {
        ...updateData,
        user_id: userId,
        id: id,
    };

    if (updateData.state === InferredRecordState.Accepted) {
        verify(maybeNewUserJob, 'Accepted skill should return new canvas skill');
        return [inferredUserJob, maybeNewUserJob];
    } else {
        return [inferredUserJob, null];
    }
}
