<template>
    <app-guide-inline v-if="false" :closable="false">
        <template #title>Summary of your experience</template>
        <template #default="{ close }">
            <v-row class="font-weight-600 pt-0 pa-4">
                From your experiences, we've crafted this summary statement:
            </v-row>
            <v-row v-if="store.summary" class="pt-1 w-100 pa-4">
                {{ store.summary }}
            </v-row>
            <v-row v-else-if="store.learnedExperiences.length < 3" class="pt-1 w-100 pa-4">
                Please add some of your most memorable experiences so we can help you understand
                what you've learned.
            </v-row>
        </template>
    </app-guide-inline>
</template>

<script setup lang="ts">
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';

    const store = useLearnedExperiencesStore();
</script>

<style lang="scss" scoped>
    .inline-guide-body {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
</style>
/
