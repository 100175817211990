import type { User } from '@/api/types/user';
import type { UserInputs } from '@/store/profile/types';
import { clone } from 'lodash';
import { mapIsoDateToBrowserFormat } from '@/lib/dates';

export function mapUserApiObjectToUserInput(user: User): UserInputs {
    const copy = clone(user);

    return {
        ...copy,
        date_of_birth: mapIsoDateToBrowserFormat(copy.date_of_birth) ?? undefined,
        other_languages: (copy.other_languages ?? '')
            .split(',')
            .map((lang) => lang.trim())
            .filter((lang) => lang),
    };
}
