import {
    type CountMetric,
    type CreateUserActivityData,
    type UserActivity,
    UserActivityArea,
    UserActivityEvent,
} from '@/api/types/userActivity';
import {
    getUserActivity,
    getUserActivityCompleted,
    postUserActivity,
    postUserMetric,
} from '@/services/activity/api';
import { GuideSupportedScreen } from '@/store/guide/types';
import { create, fetch } from '@/services/base';

export async function addEntryActivity(
    user_id: number,
    area: UserActivityArea,
    accessToken: string,
): Promise<UserActivity> {
    return createUserActivity(
        user_id,
        { user_id: user_id, area: area, event: UserActivityEvent.Entered },
        accessToken,
    );
}

export async function addExitActivity(
    user_id: number,
    area: UserActivityArea,
    accessToken: string,
): Promise<UserActivity> {
    return createUserActivity(
        user_id,
        { user_id: user_id, area: area, event: UserActivityEvent.Exited },
        accessToken,
    );
}

export async function addCompletedActivity(
    user_id: number,
    area: UserActivityArea,
    accessToken: string,
): Promise<UserActivity> {
    return createUserActivity(
        user_id,
        { user_id: user_id, area: area, event: UserActivityEvent.Completed },
        accessToken,
    );
}

export async function addGuideOpenedActivity(
    user_id: number,
    area: UserActivityArea,
    accessToken: string,
): Promise<UserActivity> {
    return createUserActivity(
        user_id,
        { user_id: user_id, area: area, event: UserActivityEvent.GuideOpened },
        accessToken,
    );
}

export async function addGuideMessagedActivity(
    user_id: number,
    area: UserActivityArea,
    accessToken: string,
): Promise<UserActivity> {
    return createUserActivity(
        user_id,
        { user_id: user_id, area: area, event: UserActivityEvent.GuideMessaged },
        accessToken,
    );
}

export function getUserActivityAreaFromScreen(screen: GuideSupportedScreen): UserActivityArea {
    switch (screen) {
        case GuideSupportedScreen.ChallengePath:
            return UserActivityArea.ChallengePath;
        case GuideSupportedScreen.CurrentChallenges:
            return UserActivityArea.CurrentChallenges;
        case GuideSupportedScreen.CurrentPlan:
            return UserActivityArea.PlanCurrent;
        case GuideSupportedScreen.FutureAspirations:
            return UserActivityArea.FutureAspiration;
        case GuideSupportedScreen.FuturePlan:
            return UserActivityArea.PlanFuture;
        case GuideSupportedScreen.Intro:
            return UserActivityArea.Intro;
        case GuideSupportedScreen.LearnedExperiences:
            return UserActivityArea.LearnedExperiences;
        case GuideSupportedScreen.PersonalValues:
            return UserActivityArea.PersonalValues;
        case GuideSupportedScreen.PersonalStrengths:
            return UserActivityArea.PersonalStrengths;
        case GuideSupportedScreen.Plan:
            return UserActivityArea.Plan;
        case GuideSupportedScreen.Skills:
            return UserActivityArea.Skills;
        case GuideSupportedScreen.Story:
            return UserActivityArea.Story;
        case GuideSupportedScreen.UVP:
            return UserActivityArea.UniqueValueProposition;
        default:
            throw new Error(`wrong screen: ${screen}`);
    }
}

export async function fetchUserActivityCompleted(
    userId: number,
    accessToken: string,
): Promise<UserActivity[]> {
    return fetch('user-activity', async () => await getUserActivityCompleted(userId, accessToken));
}

export async function fetchUserActivity(
    userId: number,
    accessToken: string,
): Promise<UserActivity> {
    return fetch('user-activity', async () => await getUserActivity(userId, accessToken));
}

export async function createUserActivity(
    userId: number,
    createData: CreateUserActivityData,
    accessToken: string,
): Promise<UserActivity> {
    console.info('Creating user activity...');

    const [activity, _response] = await create<UserActivity>(
        'user-activity',
        async () => await postUserActivity(userId, createData, accessToken),
    );

    return activity;
}

export async function addCountMetricUser(
    userId: number,
    countMetric: CountMetric,
    accessToken: string,
): Promise<void> {
    console.info('Adding user metric');

    await create(
        'user-metrics',
        async () => await postUserMetric(userId, countMetric, accessToken),
    );
}
