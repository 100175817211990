<template>
    <StorySectionActivityList :items="items" class="fill-height" />
</template>

<script setup lang="ts">
    import { useFutureAspirationsStore } from '@/store/future-aspirations/store';
    import StorySectionActivityList from '@/components/story/section/activity/StorySectionActivityList.vue';
    import { computed } from 'vue';
    import { compact } from 'lodash';
    import type { StoryActivity } from '@/store/story/types';

    const props = defineProps<{
        item: StoryActivity;
    }>();

    const store = useFutureAspirationsStore();

    const items = computed(() => {
        return compact([store.value].map((item) => item?.description));
    });
</script>
