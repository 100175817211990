<template>
    <v-app-bar
        v-scroll="onScroll"
        elevation="0"
        color="custom-yellow"
        class="app-mobile-header"
        :class="denseView ? 'app-header-dense-view' : ''"
    >
        <template v-slot:prepend>
            <AppHeaderLogo />
        </template>
        <template v-slot:append>
            <AppHeaderUserAvatar
                v-if="userStore.hasProfileBeenSet"
                :user="userStore.user"
                :clickable="true"
            />
            <DebugDisplay />
        </template>
        <template v-slot:extension>
            <AppHeaderNavigation v-if="userStore.hasProfileBeenSet" />
        </template>
    </v-app-bar>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import DebugDisplay from '@/components/header/DebugDisplay.vue';
    import AppHeaderUserAvatar from '@/components/header/AppHeaderUserAvatar.vue';
    import { useUsersStore } from '@/store/user/store';
    import AppHeaderLogo from '@/components/header/AppHeaderLogo.vue';
    import AppHeaderNavigation from '@/components/header/AppHeaderNavigation.vue';

    const userStore = useUsersStore();

    const denseView = ref(false);
    const onScroll = (e: any) => {
        const scrollTop = e.target.scrollingElement.scrollTop;
        if (scrollTop > 50) {
            denseView.value = true;
        } else {
            denseView.value = false;
        }
    };
</script>

<style scoped lang="scss">
    .app-mobile-header {
        padding-top: 12px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .app-header-dense-view :deep(.v-toolbar__content) {
        display: none;
    }

    :deep(.v-toolbar__prepend) {
        margin-inline: -6px auto;
    }

    :deep(.v-toolbar__append) {
        margin-inline: auto 0px;
    }
</style>
