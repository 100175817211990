<template>
    <v-timeline side="end" align="start">
        <v-timeline-item
            v-for="(timelineItem, index) in store.timeline || []"
            :key="`${timelineItem.type}.${index}`"
            :dot-color="dotColor(timelineItem)"
            size="x-small"
            class="ml-n6 rounded-4"
            :class="timelineItem.type === 'job' ? 'mt-0 job' : 'experience'"
        >
            <LearnedExperienceTimelineCard
                v-if="timelineItem.type === 'experience'"
                class="w-100 rounded-4"
                :item="timelineItem"
                :index="index"
                :editable="editable"
            />
            <UserJobTimelineCard v-else :item="timelineItem" :editable="editable" />
        </v-timeline-item>
    </v-timeline>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
    import LearnedExperienceTimelineCard from '@/components/canvas/learned-experiences/LearnedExperienceTimelineCard.vue';
    import { getEnjoymentColor } from '@/components/canvas/learned-experiences/util';
    import type { LearnedExperienceTimelineItem } from '@/store/learned-experiences/types';
    import { useDisplay } from 'vuetify';
    import UserJobTimelineCard from '@/components/canvas/learned-experiences/UserJobTimelineCard.vue';

    const { name } = useDisplay();

    const props = withDefaults(
        defineProps<{
            editable?: boolean;
        }>(),
        { editable: true },
    );

    const store = useLearnedExperiencesStore();

    const dotColor = (timelineItem: LearnedExperienceTimelineItem) => {
        if (timelineItem.type === 'experience') {
            return getEnjoymentColor(timelineItem.enjoyment, 1);
        } else {
            return 'white';
        }
    };

    const minWidth = computed(() => {
        switch (name.value) {
            case 'xs':
                return '350px';
            case 'sm':
                return '400px';
            case 'md':
                return '600px';
            case 'lg':
                return '1000px';
            case 'xl':
                return '1300px';
            default:
                return '1000px';
        }
    });
</script>

<style lang="scss" scoped>
    :deep(.v-timeline-item__body) {
        // It seems the timeline item in vuetify is in some way buggy.
        // So adding a huge min-width makes it full width
        min-width: v-bind(minWidth);

        width: 100%;

        margin-left: -24px;
        margin-top: -4px;

        padding-inline-start: 0px !important;
    }

    :deep(.v-timeline-item__opposite) {
        padding: 0 !important;
    }

    .v-timeline-item.job:not(:first-child) :deep(.v-timeline-divider__dot) {
        //margin-top: 25px;
    }
</style>
