import { getCanvasChallengePath } from '@/services/challenge-path/api';
// import type { CanvasCurrentChallengeItem } from '@/store/current-challenges/types';
import { fetch } from '@/services/base';
import type { CanvasChallengePath } from '@/api/types/canvas/challengePath';
import { getPathwayChallenge } from '@/services/plan/current/challenge/api';
import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';

export async function fetchCanvasChallengePath(
    canvasId: number,
    challengeId: number,
    accessToken: string,
): Promise<CanvasChallengePath[]> {
    return fetch(
        'canvas-challenge-path',
        async () => await getCanvasChallengePath(canvasId, accessToken),
    );
}

export async function fetchPathwayChallenge(
    canvasId: number,
    challengeId: number,
    accessToken: string,
): Promise<CanvasCurrentChallenge> {
    return fetch(
        'pathway-challenge-path',
        async () => await getPathwayChallenge(canvasId, challengeId, accessToken),
    );
}

/*
export async function updateCanvasCurrentChallenge(
    canvasId: number,
    updateData: CanvasCurrentChallengeItem[],
    accessToken: string,
): Promise<void> {
    return update(
        'canvas-current-challenges',
        async () => await putCanvasCurrentChallenge(canvasId, updateData, accessToken),
    );
}
*/
