import { RoutesName } from '@/router/routesName';

export type DeclaredRoute = {
    name: RoutesName;
};

export const guideAdmin = { name: RoutesName.GuideAdmin };
export const profileRoute: DeclaredRoute = { name: RoutesName.Profile };
export const careerHistoryRoute: DeclaredRoute = { name: RoutesName.CareerHistory };
export const welcomeRoute: DeclaredRoute = { name: RoutesName.Welcome };
export const homeRoute: DeclaredRoute = { name: RoutesName.Home };

export const storyRoute: DeclaredRoute = { name: RoutesName.Story };
export const planRoute: DeclaredRoute = { name: RoutesName.Plan };
export const challengePathRoute: DeclaredRoute = { name: RoutesName.ChallengePath };
export const challengeStartRoute: (id: number) => DeclaredRoute = (id: number) => ({
    name: RoutesName.SingleChallengeStart,
    params: { id: id },
});

export const challengeEditRoute: (id: number) => DeclaredRoute = (id: number) => ({
    name: RoutesName.SingleChallengeUpdateEdit,
    params: { id: id },
});

export const challengeUpdateProgressRoute: (id: number) => DeclaredRoute = (id: number) => ({
    name: RoutesName.SingleChallengeUpdateProgress,
    params: { id: id },
});

export const challengeSequencingRoute: (id: number) => DeclaredRoute = (id: number) => ({
    name: RoutesName.SingleChallengeSequencing,
    params: { id: id },
});
export const currentChallengePlanRoute: DeclaredRoute = { name: RoutesName.CurrentChallengePlan };
export const futureAspirationPlanRoute: DeclaredRoute = { name: RoutesName.CareerPlan };
export const currentChallengeRoute: DeclaredRoute = { name: RoutesName.CanvasCurrentChallenges };
export const futureAspirationRoute: DeclaredRoute = { name: RoutesName.CanvasFutureAspirations };

export const personalValuesRoute = { name: RoutesName.CanvasPersonalValues };
export const skillsRoute = { name: RoutesName.CanvasSkills };
export const personalStrengthRoute = { name: RoutesName.CanvasPersonalStrength };


export const learnedExperienceRoute = { name: RoutesName.CanvasLearnedExperiences };

export const personalityTypeRoute = { name: RoutesName.CanvasPersonalityType };
export const alexRoute = { name: RoutesName.Alex };
export const howDoesItWork = { name: RoutesName.HowDoesItWork };
export const privacyRoute = { name: RoutesName.Privacy };
export const termsRoute = { name: RoutesName.Terms };
