<template>
    <app-page>
        <LearnedExperienceGuideModalExplanation v-model="showTutorial" />
        <LearnedExperienceGuideModalCompleteReminder v-model="showReminder" />

        <app-page-header title="Memorable Experiences" @show-tutorial="showTutorial = true" />

        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="Your experiences">
                    Now reflect back on your most memorable experiences in those jobs. What stood
                    out to you as a learning moment? Describe what happened and then honestly
                    appraise how difficult or rewarding this learned experience was.
                </app-page-content-intro>
            </template>

            <v-row class="w-100" no-gutters v-if="true">
                <LearnedExperienceSummary />
            </v-row>
            <LearnedExperienceContent />
        </app-page-content>

        <app-page-footer>
            <template v-slot:actions>
                <app-page-footer-save-to-story-actions :dummy="true" />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.LearnedExperiences" />
    </app-page>

    <LearnedExperienceGuide />
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted, ref } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
    import LearnedExperienceContent from '@/components/canvas/learned-experiences/LearnedExperienceContent.vue';
    import LearnedExperienceSummary from '@/components/canvas/learned-experiences/LearnedExperienceSummary.vue';
    import LearnedExperienceGuideModalExplanation from '@/components/canvas/learned-experiences/guide-modals/LearnedExperienceGuideModalExplanation.vue';
    import ContextualInlineGuide from '@/components/inline-guide/ContextualInlineGuide.vue';
    import { InlineGuideActionName } from '@/store/inline-guide/types';
    import LearnedExperienceGuideModalCompleteReminder from '@/components/canvas/learned-experiences/guide-modals/LearnedExperienceGuideModalCompleteReminder.vue';
    import LearnedExperienceGuide
        from '@/components/canvas/learned-experiences/guide-modals/LearnedExperienceGuide.vue';

    const showTutorial = ref(false);
    const showReminder = ref(false);

    const activityStore = useActivityStore();
    const canvasStore = useCanvasStore();
    const store = useLearnedExperiencesStore();

    await activityStore.load();
    await canvasStore.load();
    await store.load();

    await activityStore.addEntryActivity(UserActivityArea.LearnedExperiences);

    onMounted(() => {
        if (!store.hasLearnedExperiences) {
            showTutorial.value = true;
        } else if (!store.isAreaComplete) {
            showReminder.value = true;
        }
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.LearnedExperiences);
    });
</script>
