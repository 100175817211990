export enum PlanActionItemState {
    New = 'new',
    Edit = 'edit',
    Creating = 'creating',
    Deleting = 'deleting',
    Updating = 'updating',
    Error = 'error',
    Saved = 'saved',
}

export enum PlanActionItemType {
    ConnectActionType = '1',
    ExperienceActionType = '2',
    NewSkillActionType = '3',
}

export enum PlanType {
    Current = 'current',
    Career = 'career',
}

export type PlanStoreState = {
    preview: null | PlanType;
};

export interface EditablePlanAction {
    id?: number;
    prevState: PlanActionItemState | null;
    state: PlanActionItemState;
    title?: string;
    description?: string;
    action_type?: string;
    due_date: Date;
}

export interface PlanActionEditFormItem {
    id?: number;
    title: string;
    description?: string;
    action_type?: string;
    due_date: string;
}
