import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useLoadingStore = defineStore('loading', () => {
    const title = ref<string | null>(null);
    const subtitle = ref<string | null>(null);

    const setLoadingText = (_title: string, _subtitle?: string) => {
        title.value = _title;

        if (_subtitle) {
            subtitle.value = _subtitle;
        }
    };

    const clearLoadingText = () => {
        title.value = null;
        subtitle.value = null;
    };

    return { title, subtitle, setLoadingText, clearLoadingText };
});
