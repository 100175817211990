import { type ApiResponse, get } from '@/services/apiClient';
import type { InlineGuideMessage } from '@/api/types/inlineGuideMessage';

export const getInlineGuideStory = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<InlineGuideMessage>> {
    const request = get<InlineGuideMessage>(`canvas/${canvasId}/inline-guide/story`);
    return request(accessToken);
};
