<template>
    <v-container class="fill-height pa-4 ma-0" fluid :class="isDesktop ? 'px-0' : 'px-4'">
        <v-row class="fill-height w-100" no-gutters justify="start">
            <v-col
                class="fill-height"
                align-self="start"
                xs="12"
                :md="props.md ?? 9"
                :lg="props.lg ?? 9"
                :xl="props.xl ?? 9"
            >
                <v-container class="fill-height d-flex flex-column rounded-0 pa-0" fluid>
                    <v-row class="w-100" no-gutters>
                        <v-col>
                            <slot></slot>
                        </v-col>
                    </v-row>

                    <v-spacer />

                    <v-row align="end" class="w-100" no-gutters>
                        <v-col cols="12">
                            <slot
                                name="footer"
                                :md="props.md ?? 9"
                                :lg="props.lg ?? 9"
                                :xl="props.xl ?? 9"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { useSlots } from 'vue';
    import { useDisplay } from 'vuetify';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const slots = useSlots();
    const { lgAndUp } = useDisplay();

    const props = defineProps<{
        md?: string | number | undefined;
        lg?: string | number | undefined;
        xl?: string | number | undefined;
    }>();

    // const isDesktop = computed(() => {
    //     return lgAndUp.value;
    // });

    const { isDesktop } = useIsDesktop();
</script>
