import { PersonalValueCategory } from '@/api/types/personalValue';

export const getCategoryIcon = (category: PersonalValueCategory) => {
    switch (category) {
        case PersonalValueCategory.Independence:
            return 'mdi-flag';
        case PersonalValueCategory.Ambition:
            return 'mdi-elevation-rise';
        case PersonalValueCategory.Understanding:
            return 'mdi-handshake';
        case PersonalValueCategory.Adventure:
            return 'mdi-map';
        case PersonalValueCategory.Creative:
            return 'mdi-palette';
        case PersonalValueCategory.Caring:
            return 'mdi-hand-heart';
        default:
            break;
    }
};

export function standardCategory(value: string) {
    switch (value) {
        case PersonalValueCategory.Independence:
            return 'independence';
        case PersonalValueCategory.Ambition:
            return 'ambition';
        case PersonalValueCategory.Understanding:
            return 'understanding';
        case PersonalValueCategory.Adventure:
            return 'adventure';
        case PersonalValueCategory.Creative:
            return 'creative';
        case PersonalValueCategory.Caring:
            return 'authentic';
        default:
            return 'Unknown';
    }
}