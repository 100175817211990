<template>
    <v-container class="pa-0 pt-0 mt-12">
        <v-row class="pa-0 align-center d-flex" no-gutters>
            <v-col :cols="isDesktop ? 6 : 12" :class="isDesktop ? 'text-right' : 'text-center'">
                <app-button
                    :disabled="props.disabled"
                    class="text-custom-grey-900 text-paragraph-md bg-white"
                    :class="isDesktop ? 'mr-1' : 'mb-1'"
                    @click="emit('click-left')"
                >
                    <span class="font-weight-600"> {{ props.leftText }}</span>
                </app-button>
            </v-col>
            <v-col :cols="isDesktop ? 6 : 12" :class="isDesktop ? 'text-left' : 'text-center'">
                <app-button
                    :disabled="props.disabled"
                    class="text-custom-grey-900 text-paragraph-md bg-white ml-2"
                    :class="isDesktop ? 'ml-1' : 'mt-1'"
                    @click="emit('click-right')"
                >
                    <span class="font-weight-600">{{ props.rightText }}</span>
                </app-button>
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const props = defineProps<{
        disabled: boolean;
        leftText: string;
        rightText: string;
    }>();

    const { isDesktop } = useIsDesktop();

    const emit = defineEmits(['click-left', 'click-right']);
</script>
