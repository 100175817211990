import { defineStore } from 'pinia';
import type { EditableSkill, SkillStoreState } from '@/store/skills/types';
import { SkillType } from '@/store/skills/types';
import {
    createCanvasSkill,
    fetchCanvasSkills,
    fetchInferredSkills,
    removeSkill,
    updateCanvasSkill,
    updateInferredCanvasSkill,
} from '@/services/skills/service';
import type { CanvasSkill, InferredCanvasSkill } from '@/api/types/canvas/skills';
import {
    InferredRecordState,
    isCanvasSkill,
    isInferredCanvasSkill,
} from '@/api/types/canvas/skills';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, compact } from 'lodash';
import {
    byDescriptionAlphabetically,
    filterByType,
    makeEditableInferredSkill,
    makeEditableSkillItem,
    makeNewSkill,
} from '@/store/skills/utils';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
} from '@/store/common/dataState';
import { verify } from '@/store/verify';
import { useCanvasStore } from '@/store/canvas/store';

export type Skill = CanvasSkill | InferredCanvasSkill;

export const useSkillsStore = defineStore({
    id: 'skills-store',
    state: (): SkillStoreState => ({
        current: null,
        values: [],

        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        confirmedSkills(state): Skill[] {
            return state.values.filter((skill) => isCanvasSkill(skill));
        },
        allSkills(state): Skill[] {
            return state.values.sort(byDescriptionAlphabetically);
        },
        learnedSkills(state): Skill[] {
            return filterByType(state.values, SkillType.Learned);
        },
        technicalSkills(state): Skill[] {
            return filterByType(state.values, SkillType.Technical);
        },
        hasInferredSkills(state): boolean {
            return state.values.some((skill) => isInferredCanvasSkill(skill));
        },
        isAreaComplete(): boolean {
            const learnedSkills = this.confirmedSkills.filter(
                (skill) => skill.type === SkillType.Learned,
            );
            const technicalSkills = this.confirmedSkills.filter(
                (skill) => skill.type === SkillType.Technical,
            );

            // To complete this activity, you’ll need to add at least three skills
            // from Personal & Learned, and one in Technical
            // This was arbitrarily decided by the product owner at this moment
            if (learnedSkills.length > 2 && technicalSkills.length) {
                return true;
            }

            return false;
        },
        needsLoading(): boolean {
            // return state.values.length === 0;
            // TODO We need to get more clever in this store.
            //      The skills can change from the backend so if we want to improve performance we need:
            //      1. Change this store from getter/actions format to a setup store (see pinia docs)
            //      2. Watch for changes in the profile upload cv store and clear the skills store
            return true;
        },
        isAddingNew(): boolean {
            return !!this.current && !this.current.id;
        },
    },
    actions: {
        async load() {
            if (!this.needsLoading) {
                console.info('Personal values already loaded');
                return;
            }

            const { accessToken, canvasId } = await useCanvasStore().makeContext();

            console.info('Loading skills...');

            setInLoading(this);

            try {
                const inferredSkills = await fetchInferredSkills(canvasId, accessToken);
                const canvasSkills = await fetchCanvasSkills(canvasId, accessToken);

                const skills = [...inferredSkills, ...canvasSkills];
                this._setValues(skills);

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        setEdit(item: Skill) {
            if (isCanvasSkill(item)) {
                this.current = makeEditableSkillItem(item);
            } else if (isInferredCanvasSkill(item)) {
                this.current = makeEditableInferredSkill(item);
            }
        },

        addSkill(type: SkillType | null): void {
            this.current = makeNewSkill();
        },

        cancelEdit(): void {
            this.current = null;
        },
        async updateSkillType(item: Skill, type: SkillType): Promise<void> {
            item.type = type;

            await this.updateSkill(item);
        },
        async updateSkillTitle(item: Skill, title: string): Promise<void> {
            item.description = title;

            await this.updateSkill(item);
        },
        async updateSkill(skill: Skill | EditableSkill): Promise<void> {
            try {
                if (isCanvasSkill(skill)) {
                    console.log('Updating existing skill', skill.id);

                    const { canvasId, accessToken } = await useCanvasStore().makeContext();
                    const updated = await updateCanvasSkill(canvasId, skill.id, skill, accessToken);

                    this.values = this.values.map((existentEntry: Skill) => {
                        return existentEntry.id === updated.id ? updated : existentEntry;
                    });
                } else if (isInferredCanvasSkill(skill)) {
                    console.log('Accepting inferred skill', skill.id);
                    await this.acceptInferredSkill(skill);
                }

                this.current = null;
            } catch (error) {
                console.error(error instanceof Error ? error.message : error);
            }
        },
        async createSkill(skill: EditableSkill): Promise<void> {
            const { canvasId, accessToken } = await useCanvasStore().makeContext();

            try {
                const created = await createCanvasSkill(canvasId, skill, accessToken);

                this.values.push(created);
                this.current = null;
            } catch (error) {
                console.error(error instanceof Error ? error.message : error);
            }
        },
        async removeSkill(item: Skill): Promise<void> {
            if (isCanvasSkill(item)) {
                if (!item.id) {
                    console.log('Removing new skill (no id)', item.id);
                    this.current = null;
                    return;
                }
            }

            if (isCanvasSkill(item)) {
                console.log('Removing existing skill', item.id);
                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                await removeSkill(canvasId, item.id, accessToken);
                this._removeFromState(item);
            } else {
                await this.denyInferredSkill(item);
            }

            // // setEditableSkillState(form, SkillItemState.Deleting);
            //
            // try {
            //
            //     await removeSkill(canvasId, formId, accessToken);
            //
            //     this.values = compact(
            //         this.values.map((existentEntry: Skill) => {
            //             return existentEntry.id === form.id ? null : existentEntry;
            //         }),
            //     );
            //     this.current = null;
            //
            //     // setEditableSkillState(form, SkillItemState.Saved);
            // } catch (error) {
            //     console.error(error instanceof Error ? error.message : error);
            //     // setEditableSkillState(form, SkillItemState.Error);
            // }
        },
        // async deleteSkill(): Promise<void> {
        //     const { canvasId, accessToken } = await useUsersStore().makeContext();
        //
        //     const form = verify(this.current, 'No current skill');
        //     const formId = verify(form.id, 'No skill id');
        //
        //     setEditableSkillState(form, SkillItemState.Deleting);
        //
        //     try {
        //         await removeSkill(canvasId, formId, accessToken);
        //
        //         this.values = compact(
        //             this.values.map((existentEntry: Skill) => {
        //                 return existentEntry.id === form.id ? null : existentEntry;
        //             }),
        //         );
        //         this.current = null;
        //
        //         setEditableSkillState(form, SkillItemState.Saved);
        //     } catch (error) {
        //         console.error(error instanceof Error ? error.message : error);
        //         setEditableSkillState(form, SkillItemState.Error);
        //     }
        // },

        async acceptInferredSkill(inferredSkill: InferredCanvasSkill): Promise<void> {
            await this._updateInferredSkillState(inferredSkill, InferredRecordState.Accepted);
        },
        async denyInferredSkill(inferredSkills: InferredCanvasSkill): Promise<void> {
            await this._updateInferredSkillState(inferredSkills, InferredRecordState.Denied);
        },
        async _updateInferredSkillState(
            inferredSkill: InferredCanvasSkill,
            entryState: InferredRecordState,
        ): Promise<void> {
            verify(inferredSkill.state === InferredRecordState.Inferred, 'Skill is not inferred');

            const { canvasId, accessToken } = await useCanvasStore().makeContext();

            const form = verify(inferredSkill, 'No current skill');
            const formId = verify(form.id, 'No skill id');

            const [_inferredSkill, newSkill] = await updateInferredCanvasSkill(
                canvasId,
                formId,
                { ...form, state: entryState },
                accessToken,
            );

            this._mutateInferredSkill(inferredSkill, entryState, newSkill);
        },

        // ###############################
        //
        // Side - effects
        //
        // ###############################
        _setValues: function (rawValues: Skill[]) {
            this.values = rawValues.map((item) => {
                return {
                    ...cloneDeep(item),
                    uuid: uuidv4(),
                };
            });
        },
        /**
         * The intention of this method is to update the state of the inferred skill in the client, without the
         * need to reload the data from the server
         */
        _mutateInferredSkill: function (
            inferredSkill: InferredCanvasSkill,
            entryState: InferredRecordState,
            newSkill: CanvasSkill | null,
        ) {
            // No need to reload data, just update the state
            const found = verify(
                this.values.find(
                    (existentEntry: CanvasSkill | InferredCanvasSkill) =>
                        existentEntry.id === inferredSkill.id,
                ),
                'Skill not found',
            );

            if (found) {
                if (isInferredCanvasSkill(found)) {
                    if (entryState === InferredRecordState.Accepted) {
                        const skill = verify(
                            newSkill,
                            'Accepted skill should return new canvas skill',
                        );
                        // ts-ignore because we are sure that found is inferred skill
                        delete (found as Partial<InferredCanvasSkill>).state;

                        found.type = skill.type;
                        found.description = skill.description;
                        found.id = skill.id;
                        (found as unknown as CanvasSkill).inferred_skill_id = found.id;
                    } else {
                        found.state = InferredRecordState.Denied;
                    }
                } else {
                    throw new Error('Expected inferred skill');
                }
            }
        },
        _removeFromState: function (item: Skill): void {
            this.values = compact(
                this.values.map((existentEntry: Skill) => {
                    return existentEntry.id === item.id ? null : existentEntry;
                }),
            );
        },
    },
});
