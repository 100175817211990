<template>
    <v-input
        class="rounded-sm custom-checkbox pa-0 cursor-pointer"
        :style="{ width: '40px', height: '40px' }"
        :class="modelValue ? 'custom-checkbox-checked' : 'custom-checkbox-not-checked'"
        @click="emit('update:modelValue', !modelValue)"
    >
        <v-icon v-if="modelValue" class="mx-2 my-2" size="24"> mdi-check</v-icon>
    </v-input>
</template>

<script setup lang="ts">
    defineProps<{
        modelValue: boolean;
    }>();

    const emit = defineEmits(['update:modelValue']);
</script>

<style scoped lang="scss">
    .custom-checkbox {
        :deep(.v-input__control) {
            width: 40px;
            height: 40px;
        }
    }

    .custom-checkbox-checked {
        background-color: rgb(var(--v-theme-primary));
    }

    .custom-checkbox-not-checked {
        border: 1px solid;
        border-color: rgb(var(--v-theme-custom-neutral-grey-600));
        //background-color: rgb(var(--v-theme-primary));
    }
</style>
