<template>
    <v-file-input
        :model-value="files"
        :accept="ACCEPT_CONTENT_TYPES"
        prepend-icon=""
        prepend-inner-icon="mdi-paperclip"
        placeholder="Upload your curriculum"
        label="Curriculum Vitae"
        variant="outlined"
        :rules="[store.isValidFileTypeRule]"
        :loading="showUploading"
        :disabled="showUploading"
        @click:clear="onDiscard"
        hint="Only PDF files are allowed"
        @update:model-value="onUpdateCurriculumFile"
    >
        <template v-slot:selection="{ fileNames }">
            <template v-for="fileName in fileNames" :key="fileName">
                <v-chip size="small" :label="true" color="#1976D2" class="me-2">
                    {{ fileName }}
                </v-chip>
            </template>
        </template>
    </v-file-input>
</template>

<script setup lang="ts">
    import { computed, ref, watch } from 'vue';
    import { useUserProfileStore } from '@/store/profile/store';
    import { DataState } from '@/store/common/dataState';
    import { ACCEPT_CONTENT_TYPES } from '@/components/profile/curriculum/contentTypes';

    const emit = defineEmits(['valid', 'invalid']);

    const store = useUserProfileStore();

    const isFileInputDirty = computed(() => {
        return store.fileInput !== null;
    });

    const files = computed(() => {
        return store.curriculumFile ? [store.curriculumFile] : [];
    });

    const onDiscard = () => {
        store.setCurriculumFileInput(null);
    };

    const onUpdateCurriculumFile = (value: any) => {
        store.setCurriculumFileInput(value);
    };

    const showUploading = ref(false);
    watch(
        () => store.state,
        (value) => {
            if (value === DataState.Updating) {
                if (isFileInputDirty.value) {
                    showUploading.value = true;
                }
            } else {
                showUploading.value = false;
            }
        },
    );
</script>
