import { type GuideMessage, type GuideMessageEvent } from '@/store/guide/types';

/**
 * Parse the data, and assigns the to the existent event
 * @param events
 * @param data
 */
export const getMessageEvent = (data: any): GuideMessageEvent | null => {
    try {
        const messageEvent: GuideMessageEvent = JSON.parse(data);

        return messageEvent;
    } catch (e) {
        return null;
    }
};

/**
 * Filters adjacent duplicate messages based on their content.
 *
 * This function iterates through an array of `GuideMessage` objects and returns a new array
 * containing only the first message from each group of adjacent messages with the same content.
 *
 * For example:
 * - Given an input of [{ content: 'Hello' }, { content: 'Hello' }, { content: 'Goodbye' }],
 *   the output will be [{ content: 'Hello' }, { content: 'Goodbye' }].
 * - Non-adjacent duplicates will be retained.
 *
 * @param {GuideMessage[]} messages - An array of messages to filter.
 * @returns {GuideMessage[]} A new array containing messages with adjacent duplicates removed.
 *
 * @example
 * const messages = [
 *   { content: 'Hello' },
 *   { content: 'Hello' },
 *   { content: 'How are you?' },
 *   { content: 'Goodbye' },
 *   { content: 'Goodbye' }
 * ];
 * const filtered = filterMessages(messages);
 * // filtered will be:
 * // [
 * //   { content: 'Hello' },
 * //   { content: 'How are you?' },
 * //   { content: 'Goodbye' }
 * // ]
 */
export function filterMessages(messages: GuideMessage[]): GuideMessage[] {
    return messages.reduce(
        (filteredResults: GuideMessage[], message: GuideMessage, index: number) => {
            if (index === 0 || message.content !== messages[index - 1].content) {
                return [...filteredResults, message];
            }
            return filteredResults;
        },
        [],
    );
}
