import type { CanvasSkill, InferredCanvasSkill } from '@/api/types/canvas/skills';
import { InferredRecordState } from '@/api/types/canvas/skills';
import type {
    CanvasSkillUpdateData,
    EditableSkillItem,
    InferredSkillUpdateData,
} from '@/store/skills/types';
import { create, fetch, remove, update } from '@/services/base';
import { verify } from '@/store/verify';
import {
    deleteCanvasPersonalStrength,
    getCanvasPersonalStrengths,
    getInferredPersonalStrengths,
    postCanvasPersonalStrength,
    putCanvasPersonalStrength,
    putInferredPersonalStrength,
} from '@/services/personal-strengths/api';

export async function fetchCanvasPersonalStrengths(
    canvasId: number,
    accessToken: string,
): Promise<CanvasSkill[]> {
    return fetch(
        'canvas-skills',
        async () => await getCanvasPersonalStrengths(canvasId, accessToken),
    );
}

export async function fetchInferredPersonalStrengths(
    canvasId: number,
    accessToken: string,
): Promise<InferredCanvasSkill[]> {
    return fetch(
        'inferred-skills',
        async () => await getInferredPersonalStrengths(canvasId, accessToken),
    );
}

export async function updateCanvasPersonalStrength(
    canvasId: number,
    skillId: number,
    updateData: CanvasSkillUpdateData,
    accessToken: string,
): Promise<CanvasSkill> {
    await update(
        'canvas',
        async () => await putCanvasPersonalStrength(canvasId, skillId, updateData, accessToken),
    );

    return {
        ...updateData,
        canvas_id: canvasId,
        id: skillId,
    };
}

export async function updateInferredCanvasPersonalStrength(
    canvasId: number,
    skillId: number,
    updateData: InferredSkillUpdateData,
    accessToken: string,
): Promise<[InferredCanvasSkill, CanvasSkill | null]> {
    const maybeNewSkill = await update<CanvasSkill | any>(
        'inferred-skill',
        async () => await putInferredPersonalStrength(canvasId, skillId, updateData, accessToken),
    );

    const inferredSkill = {
        ...updateData,
        canvas_id: canvasId,
        id: skillId,
    };

    if (updateData.state === InferredRecordState.Accepted) {
        verify(maybeNewSkill, 'Accepted skill should return new canvas skill');
        return [inferredSkill, maybeNewSkill];
    } else {
        return [inferredSkill, null];
    }
}

function mapFromSkillToApiObject(skill: EditableSkillItem): CanvasSkillUpdateData {
    return {
        description: skill.description,
        type: verify(skill.type, 'no skill type'),
    };
}

export async function createCanvasPersonalStrength(
    canvasId: number,
    data: EditableSkillItem,
    accessToken: string,
): Promise<CanvasSkill> {
    const payload = mapFromSkillToApiObject(data);

    const [canvasSkillItem, _response] = await create(
        'canvas-personal-strength',
        async () => await postCanvasPersonalStrength(canvasId, payload, accessToken),
    );

    return canvasSkillItem;
}

export async function removePersonalStrength(
    canvasId: number,
    skillId: number,
    accessToken: string,
): Promise<any> {
    return remove(
        'canvas-personal-strength',
        async () => await deleteCanvasPersonalStrength(canvasId, skillId, accessToken),
    );
}
