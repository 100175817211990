import type { DataState } from '@/store/common/dataState';
import type { CanvasSkill, InferredCanvasSkill } from '@/api/types/canvas/skills';
import type { SkillItemState } from '@/store/skills/utils';
import { InferredRecordState } from '@/api/types/canvas/skills';

export enum SkillType {
    Personal = 'personal',
    Learned = 'learned',
    Technical = 'technical',
}

export type EditableSkillBase = {
    prevStatus: SkillItemState | null;
    status: SkillItemState;
};

export type EditableSkillItem = EditableSkillBase & {
    id?: number;
    description: string;
    type: null | SkillType;
    inferred_skill_id?: number;
};

export type EditableInferredSkillItem = (EditableSkillBase & InferredCanvasSkill);

export type EditableSkill = EditableSkillItem | EditableInferredSkillItem;

export type CanvasSkillUpdateData = {
    description: string;
    type: SkillType;
};
export type InferredSkillUpdateData = {
    description: string;
    type: SkillType;
    state: InferredRecordState;
};

export type SkillStoreState = {
    current: EditableSkillItem | null;
    values: (InferredCanvasSkill | CanvasSkill)[];

    state: DataState;
    error: any | null;
};

export type PersonalStrengthsStoreState = SkillStoreState;
