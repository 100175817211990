import { defineStore } from 'pinia';
import type {
    CanvasPersonalityCurrentType,
    PersonalTypeStoreState,
} from '@/store/personality-type/types';
import {
    type CanvasPersonalityType,
    PersonalityTypeMethod,
} from '@/api/types/canvas/personalityType';
import {
    createCanvasPersonalityType,
    fetchCanvasPersonalityType,
    removeCanvasPersonalityType,
    updateCanvasPersonalityType,
} from '@/services/personality-type/service';
import { getMBTIType, type PersonalityTypeSelectItem } from '@/store/personality-type/utils';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
    setInUpdating,
} from '@/store/common/dataState';
import { cloneDeep, isEqual } from 'lodash';
import { useCanvasStore } from '@/store/canvas/store';

export const usePersonalityTypeStore = defineStore({
    id: 'personality-type',
    state: (): PersonalTypeStoreState => ({
        original: null,
        mbtiType: null,

        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        isDirty(state): boolean {
            const comparable = (o: CanvasPersonalityCurrentType | null) => {
                return {
                    id: o?.id,
                    value: o?.value,
                    method: o?.method,
                };
            };
            return !isEqual(comparable(state.mbtiType), comparable(state.original));
        },
        isAreaComplete(): boolean {
            return this.mbtiType !== null;
        },
        needsLoading(state): boolean {
            return state.original === null;
        },
    },
    actions: {
        async load() {
            if (!this.needsLoading) {
                console.info('Personality type already loaded');
                return;
            }

            const { canvasId, accessToken } = await useCanvasStore().makeContext();

            console.info('Loading personality type...');

            setInLoading(this);

            try {
                const personalityTypes =
                    (await fetchCanvasPersonalityType(canvasId, accessToken)) ?? [];
                this._setPersonalityTypeFromList(personalityTypes);

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        onSelect(item: PersonalityTypeSelectItem | null): void {
            this.mbtiType = {
                id: this.mbtiType?.id ?? null,
                method: PersonalityTypeMethod.MBTI,
                value: item?.value ?? null,
            };
        },
        async save(): Promise<void> {
            const { canvasId, accessToken } = await useCanvasStore().makeContext();

            const currentValue = this.mbtiType?.value;

            setInUpdating(this);

            try {
                const mbtiType = this.mbtiType;
                const existingPersonalityTypeId = mbtiType?.id;
                if (currentValue) {
                    const payload = {
                        method: PersonalityTypeMethod.MBTI,
                        value: currentValue,
                    };

                    if (existingPersonalityTypeId) {
                        await updateCanvasPersonalityType(
                            canvasId,
                            existingPersonalityTypeId,
                            payload,
                            accessToken,
                        );

                        this._setPersonalityType(mbtiType);
                    } else {
                        const created = await createCanvasPersonalityType(
                            canvasId,
                            payload,
                            accessToken,
                        );

                        this._setPersonalityType(created);
                    }
                } else {
                    if (existingPersonalityTypeId) {
                        await removeCanvasPersonalityType(
                            canvasId,
                            existingPersonalityTypeId,
                            accessToken,
                        );

                        this._setPersonalityType(null);
                    } else {
                        console.info(
                            'nothing to do, no selection but not personality type neither ',
                        );
                    }
                }

                setInIdle(this);
            } catch (error: unknown) {
                console.error(error instanceof Error ? error.message : error);
                setInError(this, error);
            }
        },
        _setPersonalityTypeFromList: function (rawValues: CanvasPersonalityType[]) {
            const getCurrent = (): CanvasPersonalityType | null => {
                if (rawValues.length > 1) {
                    throw new Error('only one personality type allowed');
                } else if (rawValues.length === 1) {
                    return getMBTIType(rawValues);
                }

                return null;
            };

            const current = getCurrent();

            this._setPersonalityType(current);
        },
        _setPersonalityType: function (current: CanvasPersonalityCurrentType | null) {
            this.original = cloneDeep(current);
            this.mbtiType = cloneDeep(current);
        },
    },
});
