<template>
    <app-page>
        <SingleChallengePageHeader />

        <AppGuideModal
            v-model="showNewSuccess"
            title="Well done for taking this first step toward overcoming your challenge!"
            action-text="Continue"
            :action-to="challengePathRoute"
        >
            <template #default>
                Your challenge pathway is locked in! Remember to check back in often to reflect on
                the plan you’ve made and track your progress. Remember I’m always here if you need
                help with any of your activities. I’m excited to see how you get on.
            </template>
        </AppGuideModal>

        <AppGuideModal
            v-model="showUpdateSuccess"
            title="Well done for keeping yourself honest and updating your activities!"
            action-text="Continue"
            :action-to="challengePathRoute"
        >
            <template #default>
                Your challenge pathway has been updated! Remember to check back in often to reflect on
                the plan you’ve made and track your progress. Remember I’m always here if you need
                help with any of your activities. I’m excited to see how you get on.
            </template>
        </AppGuideModal>

        <app-page-content>
            <template #intro>
                <app-page-content-intro title="Now make the activities your own">
                    It’s important to set realistic goals for yourself, think about how much time
                    you can commit to each of these activities and when you’d like to aim to have
                    them complete.
                </app-page-content-intro>
            </template>

            <v-container
                class="pa-0 d-flex challenge-sequencing-container"
                v-for="(action, index) in selected || []"
                :key="`${index}`"
            >
                <app-sorting-controls
                    :position="index + 1"
                    :total="selected.length"
                    @move-up="store.moveActionUp(index)"
                    @move-down="store.moveActionDown(index)"
                />
                <SingleChallengeSequencingActionListItem :item="action" class="mb-2 w-100" />
            </v-container>
        </app-page-content>

        <app-page-footer>
            <template v-slot:actions>
                <CanvasSaveProgressActions
                    close-text="Back"
                    @on-back="onBack"
                    :title="store.isNew ? 'Save and begin' : 'Update challenge'"
                    @on-submit="onSave"
                />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.ChallengePath" />
    </app-page>

    <SingleChallengeSequencingGuide />
</template>

<script setup lang="ts">
    import { onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';

    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { challengeEditRoute, challengePathRoute, challengeStartRoute } from '@/router/routes';
    import { useActivityStore } from '@/store/activity/store';
    import SingleChallengePageHeader from '@/components/pathway/single-challenge/SingleChallengePageHeader.vue';
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';
    import { useRouter } from 'vue-router';
    import CanvasSaveProgressActions from '@/components/canvas/CanvasSaveProgressActions.vue';
    import SingleChallengeSequencingActionListItem from '@/components/pathway/single-challenge/sequencing/SingleChallengeSequencingActionListItem.vue';
    import AppSortingControls from '@/components/common/AppSortingControls.vue';
    import AppPageContentIntro from '@/components/common/app-page/AppPageContentIntro.vue';
    import SingleChallengeSequencingGuide from '@/components/pathway/single-challenge/sequencing/SingleChallengeSequencingGuide.vue';
    import { storeToRefs } from 'pinia';

    const store = useSingleChallengePathStore();
    const activityStore = useActivityStore();

    const { selected } = storeToRefs(store);

    const showNewSuccess = ref(false);
    const showUpdateSuccess = ref(false);
    const router = useRouter();

    const onSave = async () => {
        if (store.isNew) {
            await store.startChallenge();
            showNewSuccess.value = true;
        } else {
            await store.updateChallenge()
            showUpdateSuccess.value = true;
        }
    };

    const onBack = () => {
        // TODO ADD SIMPLE HISTORY BACK

        if (store.isNew) {
            router.push(challengeStartRoute(store.challenge!.id));
        } else if (store.isInProgress) {
            router.push(challengeEditRoute(store.challenge!.id));
        }

    };

    /**
     * Redirect to the start of the challenge if the challenge is not selected
     * This view is only accessible if the challenge was already loaded and activities were previously selected
     */
    onBeforeMount(() => {
        const challenge = store.challenge;

        if (!challenge) {
            router.push(challengePathRoute);
        } else {
            if (store.selected.length === 0) {
                router.push(challengeStartRoute(challenge.id));
            }
        }
    });

    onMounted(async () => {
        await activityStore.addEntryActivity(UserActivityArea.ChallengePath);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.ChallengePath);
    });
</script>

<style lang="scss" scoped>
    .challenge-sequencing-container {
        min-height: 300px;
        height: fit-content;
    }
</style>
