<template>
    <div>
        <v-card
            class="mx-auto my-2 pa-0"
            variant="outlined"
            :color="isAlexMessage ? 'custom-purple-deep' : 'custom-purple-mid'"
        >
            <!--            <v-card-title v-if="isUserMessage"> {{ username }}</v-card-title>-->

            <v-card-text class="text-paragraph-md px-4 py-2">
                <!--                <span-->
                <!--                    class="pa-0 typed"-->
                <!--                    v-if="props.value.displayed"-->
                <!--                    v-html="props.message.content"-->
                <!--                />-->
                <!--                <TypedMessage-->
                <!--                    v-if="props.message.animate"-->
                <!--                    :value="props.message.content"-->
                <!--                    :element-id="`response-${props.message._id}`"-->
                <!--                    @displayed="markAsDisplayed"-->
                <!--                />-->
                <!--                <span-->
                <!--                    class="pa-0 typed"-->
                <!--                    v-else-->
                <!--                    v-html="props.message.content"-->
                <!--                />-->
                <v-row no-gutters>
                    <span class="pa-0 typed" v-html="props.message.content" />
                </v-row>
                <!--                <v-row no-gutters justify="end">-->
                <!--                    <span class="text-caption text-end align-end">{{-->
                <!--                        props.message.timestamp-->
                <!--                    }}</span>-->
                <!--                </v-row>-->
            </v-card-text>
        </v-card>
    </div>
</template>

<script setup lang="ts">
    import { ALEX, type GuideMessage } from '@/store/guide/types';
    import { computed, toValue } from 'vue';
    import type { User } from '@/api/types/user';
    import { useAuth0 } from '@/auth/auth';
    import { displayName } from '@/components/header/username';

    const props = defineProps<{
        message: GuideMessage;
        user: User;
    }>();

    const { user: authUser } = useAuth0();
    const username = computed(() => displayName(toValue(authUser)));

    // const isQuestion = computed(
    //     () => (props.message.ctx as GuideEvent)?.type === GuideEventType.Question,
    // );
    // const isSuggestion = computed(
    //     () => (props.message.ctx as GuideEvent)?.type === GuideEventType.Suggestion,
    // );
    // const isSelection = computed(() => props.value.type === GuideEventType.Selection);
    // const isEnter = computed(() => props.value.type === GuideEventType.Enter);
    // const isLeave = computed(() => props.value.type === GuideEventType.Leave);

    const isUserMessage = computed(() => {
        return props.message.senderId === props.user.id.toString();
    });

    const isAlexMessage = computed(() => {
        return props.message.senderId === ALEX.id;
    });

    const markAsDisplayed = () => {
        // eslint-disable-next-line vue/no-mutating-props
        props.message.animate = true;
    };
</script>
