import { createAuth0 } from '@/auth/auth';
import { getQueryParam } from '@/lib/getQueryParam';

/**
 * When using 'email' as a connection, it means that the authentication method will be by email + OTP (one time password)
 * @see https://auth0.com/docs/authenticate/passwordless/authentication-methods/email-otp
 */
const EMAIL_CONNECTION_WITH_OTP = 'email';

const auth0Plugin = createAuth0({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,

    authorizationParams: {
        login_hint: getQueryParam('user') ?? '',
        connection: EMAIL_CONNECTION_WITH_OTP,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
    },
});

export default auth0Plugin;
