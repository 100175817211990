import { type FileInput, FileTypeAction, type UserInputs } from '@/store/profile/types';
import type { User } from '@/api/types/user';
import { areSameDate } from '@/lib/dates';
import { compact } from 'lodash';

export function curriculumHasChanges(fileInput: FileInput): boolean {
    return fileInput.action === FileTypeAction.New || fileInput.action === FileTypeAction.Delete;
}

export function userProfileHasChanges(user: User, userInputs: UserInputs): boolean {
    return (
        user.first_name !== userInputs?.first_name ||
        user.last_name !== userInputs?.last_name ||
        user.linkedin_profile !== userInputs?.linkedin_profile ||
        user.current_company !== userInputs?.current_company ||
        user.current_company_confidence_rating !== userInputs?.current_company_confidence_rating ||
        user.job_title !== userInputs?.job_title ||
        user.phone_number !== userInputs?.phone_number ||
        !areSameDate(user.date_of_birth, userInputs?.date_of_birth) ||
        user.gender !== userInputs?.gender ||
        user.visa_type !== userInputs?.visa_type ||
        user.location !== userInputs?.location ||
        user.nationality !== userInputs?.nationality ||
        user.primary_language !== userInputs?.primary_language ||
        !_compareLanguages(user.other_languages, userInputs?.other_languages)
    );
}

function _compareLanguages(commaSeparatedLanguages?: string, languageArray?: string[]): boolean {
    if (commaSeparatedLanguages === undefined || languageArray === undefined) {
        return false;
    }

    // Split the comma-separated string into an array of trimmed languages
    const languagesFromString = compact(
        commaSeparatedLanguages.split(',').map((lang) => {
            const language = lang.trim();
            return language ? language.toLowerCase() : null;
        }),
    );

    // Check if every language from the string exists in the array
    return languagesFromString.every((language) => languageArray.includes(language));
}
