import {
    getCanvasCurrentChallenges,
    putCanvasCurrentChallenge,
} from '@/services/current-challenges/api';
import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
import type { CanvasCurrentChallengeItem } from '@/store/current-challenges/types';
import { fetch, update } from '@/services/base';

export async function fetchCanvasCurrentChallenges(
    canvasId: number,
    accessToken: string,
): Promise<CanvasCurrentChallenge[]> {
    return fetch(
        'canvas-current-challenges',
        async () => await getCanvasCurrentChallenges(canvasId, accessToken),
    );
}

export async function updateCanvasCurrentChallenge(
    canvasId: number,
    updateData: CanvasCurrentChallengeItem[],
    accessToken: string,
): Promise<void> {
    return update(
        'canvas-current-challenges',
        async () => await putCanvasCurrentChallenge(canvasId, updateData, accessToken),
    );
}
