export interface CanvasPersonalValue {
    id: number;
    canvas_id: number;
    personal_value_id: number;
    type: PersonalValueSelectionType;

    sub_type?: PersonalValueSelectionSubType;

    /** If the sub_type is not set, the selected value MUST BE undefined */
    selected?: boolean;
    /** If the sub_type is not set, the order value MUST BE undefined */
    order?: number;
}

export type CanvasPersonalValueUpdateItem = Pick<
    CanvasPersonalValue,
    'personal_value_id' | 'type' | 'sub_type' | 'selected' | 'order'
>;

export enum PersonalValueSelectionType {
    Important = 'important',
    NotImportant = 'not_important',
}

export enum PersonalValueSelectionSubType {
    Core = 'core',
    Growth = 'growth',
}

export type CanvasPersonalValuesUpdateData = CanvasPersonalValueUpdateItem[];
