/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify';
import pinia from '../store';
import router from '../router';

// Types
import type { App, ComponentPublicInstance } from 'vue';
import authPlugin from '@/plugins/auth0';
import { registerAppComponents } from '@/plugins/appComponents';
import { useAppErrorStore } from '@/store/appErrorStore';

export function registerPlugins(app: App) {
    app.use(vuetify)
        .use(router)
        // Order matters!
        // The router needs to be registered before the Auth0 Vue.js plugin, or you we might
        // experience unexpected behavior. 🚨
        .use(authPlugin)
        .use(pinia)
        .use(registerAppComponents);
}

export function registerErrorHandling(app: App) {
    app.config.errorHandler = (
        err: unknown,
        instance: ComponentPublicInstance | null,
        info: string,
    ) => {
        useAppErrorStore().handleVueError(err, instance, info);
    };
}
