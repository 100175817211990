<template>
    <!-- The loaders is displayed until the user is created/fetched -->
    <Loader v-if="isLoading || userStore.isLoading" />

    <!--
      The slot depends only on the auth loading state.
      This serves the purpose of allowing to initialise our application state after auth0 has finished.
    -->
    <slot v-if="isAuthenticated" />
</template>

<script setup lang="ts">
    import Loader from '@/components/common/Loader.vue';
    import { useUsersStore } from '@/store/user/store';
    import { useAuth0 } from '@/auth/auth';

    const userStore = useUsersStore();

    const { isAuthenticated, isLoading } = useAuth0();
</script>
