<template>
    <v-container class="pa-0">
        <v-row v-if="!skillsToDisplay.length && !store.isAddingNew" no-gutters>
            <PersonalStrengthsListEmptyCard />
        </v-row>
        <v-row v-else no-gutters v-click-outside="onClickOutsideEditList">
            <v-col cols="12" class="mb-4" v-for="(item, index) in skillsToDisplay" :key="index">
                <PersonalStrengthsListItemNew
                    :item="item"
                    @edit-click="onEdit"
                    @delete-click="onDelete"
                    @change-type="onChangeType"
                />
            </v-col>
            <v-col cols="12" class="mb-4">
                <PersonalStrengthListItemForm v-if="store.isAddingNew" />
            </v-col>
        </v-row>
        <v-row no-gutters justify="start">
            <app-plus-button @click="store.addPersonalStrength()"
                >Add another strength
            </app-plus-button>
        </v-row>
    </v-container>

    <ActivityNotification
        :value="store.confirmed.length"
        :area="UserActivityArea.PersonalStrengths"
    />
</template>

<script setup lang="ts">
    import { type Skill } from '@/store/skills/store';
    import { UserActivityArea } from '@/api/types/userActivity';
    import ActivityNotification from '@/components/canvas/notifications/ActivityNotification.vue';
    import { computed } from 'vue';
    import {
        InferredRecordState,
        isCanvasSkill,
        isInferredCanvasSkill,
    } from '@/api/types/canvas/skills';
    import PersonalStrengthsListItemNew from '@/components/canvas/personal-strengths/PersonalStrengthsListItemNew.vue';
    import PersonalStrengthListItemForm from '@/components/canvas/personal-strengths/PersonalStrengthListItemForm.vue';
    import { usePersonalStrengthsStore } from '@/store/personal-strengths/store';
    import type { SkillType } from '@/store/skills/types';
    import PersonalStrengthsListEmptyCard from '@/components/canvas/personal-strengths/PersonalStrengthsListEmptyCard.vue';

    const store = usePersonalStrengthsStore();

    const onChangeType = ({ item, type }: { item: Skill; type: string }) => {
        store.updatePersonalStrengthType(item, type as SkillType);
    };

    const onEdit = (item: Skill) => {
        store.setEdit(item);
    };

    const onDelete = async (item: Skill) => {
        await store.removePersonalStrength(item);
    };

    const skillsToDisplay = computed(() => {
        return store.all.filter((skill) => {
            if (isCanvasSkill(skill)) {
                return skill;
            } else if (isInferredCanvasSkill(skill)) {
                return skill.state === InferredRecordState.Inferred;
            }
        });
    });

    const onClickOutsideEditList = async () => {
        if (store.isAddingNew) {
            return;
        } else {
            store.cancelEdit();
        }
    };
</script>

<style scoped lang="scss">
    .skills-divider {
        background: rgba(251, 134, 37, 1);
    }
</style>
