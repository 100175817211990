<template>
    <app-page>
        <app-page-header title="Personal strengths" @show-tutorial="showTutorial = true" />
        <PersonalStrengthsGuideModalExplanation v-model="showTutorial" />
        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="Identify your personal strengths">
                    Your personal strengths can sometimes be called your 'soft skills'. They are
                    different to your Learned & Technical skills in that they relate to how you work
                    and interact with others.
                    <br />
                    <br />
                    They often include traits and abilities that influence how you communicate,
                    collaborate, and manage your emotions.

                </app-page-content-intro>
            </template>

            <PersonalStrengthsContent />
        </app-page-content>

        <PersonalStrengthsGuide />

        <app-page-footer>
            <template v-slot:actions>
                <app-page-footer-save-to-story-actions :dummy="true" />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.PersonalStrengths" />
    </app-page>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted, ref } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import SkillsGuide from '@/components/canvas/skills/SkillsGuide.vue';
    import { useUVPStore } from '@/store/unique-value-proposition/store';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import PersonalStrengthsGuideModalExplanation from '@/components/canvas/personal-strengths/guide-modal/PersonalStrengthsGuideModalExplanation.vue';
    import { usePersonalStrengthsStore } from '@/store/personal-strengths/store';
    import PersonalStrengthsContent from '@/components/canvas/personal-strengths/PersonalStrengthsContent.vue';
    import PersonalStrengthsGuide from '@/components/canvas/personal-strengths/PersonalStrengthsGuide.vue';

    const showTutorial = ref(false);
    const showReminder = ref(false);

    const activityStore = useActivityStore();
    const canvasStore = useCanvasStore();
    const store = usePersonalStrengthsStore();
    const uvpStore = useUVPStore();

    await activityStore.load();
    await canvasStore.load();
    await store.load();
    await uvpStore.load();

    await activityStore.addEntryActivity(UserActivityArea.PersonalStrengths);

    onMounted(() => {
        if (!store.confirmed.length) {
            showTutorial.value = true;
        } else if (!store.isAreaComplete) {
            showReminder.value = true;
        }
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.PersonalStrengths);
    });
</script>
