export enum PersonalValueCategory {
    Independence = 'independence',
    Ambition = 'ambition',
    Understanding = 'understanding',
    Adventure = 'adventure',
    Creative = 'creative',
    Caring = 'caring',
}

export interface PersonalValue {
    id: number;
    title: string;
    description: string;
    category: PersonalValueCategory;
    is_active: boolean;
}

// export type UserCreateData = Pick<User, 'email' | 'first_name' | 'last_name' | 'auth0_id'>;
