import type { ActionFrequency } from '@/api/types/plan/currentChallengeAction';

export interface FrequencyOption {
    value: number;
    title: string;
    frequency: ActionFrequency;
    duration_min: number;
    duration_max?: number;
}

// Define the available options
export const FREQUENCY_OPTIONS: FrequencyOption[] = [
    { value: 1, title: '15 minutes a day', frequency: 'daily', duration_min: 15 },
    { value: 2, title: '15-30 minutes a day', frequency: 'daily', duration_min: 15, duration_max: 30 },
    { value: 3, title: '1 hour a day', frequency: 'daily', duration_min: 60 },
    { value: 11, title: 'once a week', frequency: 'weekly', duration_min: 1 },
    { value: 12, title: '2-3 times a week', frequency: 'weekly', duration_min: 2, duration_max: 3 },
    { value: 13, title: '3-5 times a week', frequency: 'weekly', duration_min: 3, duration_max: 5 },
    { value: 14, title: 'every day', frequency: 'daily', duration_min: 0 },
    { value: 20, title: 'once a month', frequency: 'monthly', duration_min: 1 },
    { value: 21, title: '2-4 times a month', frequency: 'monthly', duration_min: 2, duration_max: 4 },
];


export function findOrCreateFrequencyOption(
    frequencyOptions: FrequencyOption[],
    frequency: ActionFrequency,
    duration_min: number,
    duration_max?: number
): FrequencyOption {
    // Attempt to find an existing matching option
    const existingOption = frequencyOptions.find(
        (option) =>
            option.frequency === frequency &&
            option.duration_min === duration_min &&
            option.duration_max === duration_max
    );

    // If a matching option is found, return it
    if (existingOption) {
        return existingOption;
    }

    // If no matching option is found, create a new one
    const newOption: FrequencyOption = {
        value: frequencyOptions.length + 1, // Generate a unique value (e.g., using length + 1)
        title: `Do this ${duration_min}-${duration_max ?? ''} times ${frequency}`,
        frequency,
        duration_min,
        duration_max,
    };

    return newOption;
}
