import type { User } from '@/api/types/user';
import type { UserInputs } from '@/store/profile/types';

export function isComplete(user: UserInputs): boolean {
    const requiredFields = [
        'first_name',
        'last_name',
        'current_company',
        'job_title',
        'date_of_birth',
        'gender',
        'visa_type',
        'location',
        'nationality',
        'primary_language',
    ];

    return requiredFields.every((field) => !!user[field as keyof User]);
}
