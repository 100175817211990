<template>
    <v-card class="pa-5 cursor-pointer" elevation="0" :class="contentClass" :disabled="disabled">
        <v-card-text class="pa-0">
            <v-row no-gutters class="align-center">
                <v-col
                    cols="1"
                    class="d-flex align-self-start justify-start mr-5 select-column"
                >
                    <div class="">
                        <app-checkbox
                            @update:model-value="(v: boolean) => emit('onSelected', v)"
                            :model-value="isSelected"
                        />
                    </div>
                </v-col>
                <v-col class="pa-0">
                    <div class="w-100 d-flex" v-if="title">
                        <v-text-field
                            class="pa-0"
                            variant="outlined"
                            v-if="editMode"
                            :model-value="title"
                            @update:model-value="(v: string) => emit('title-change', v)"
                            v-click-outside="onTitleClickOutside"
                        />
                        <span
                            v-else-if="editable"
                            class="text-heading-h4 cursor-pointer"
                            @click="onEnableEditClick"
                        >
                            {{ title }}
                        </span>
                        <span
                            v-else
                            @click="emit('onSelected', !isSelected)"
                            class="text-heading-h4 cursor-pointer"
                            :class="
                                hasSlot('append-title') ? (isDesktop ? 'w-75' : 'w-66') : 'w-100'
                            "
                        >
                            {{ title }}
                        </span>

                        <span class="card-title-append" v-if="showAppendSection">
                            <slot name="append-title" v-if="hasSlot('append-title')"/>
                            <v-btn
                                v-if="!editMode && editable"
                                width="20"
                                size="small"
                                icon="mdi-pencil"
                                class="card-title-append pa-0 mt-n2"
                                variant="plain"
                                :ripple="false"
                                @click="onEnableEditClick"
                            />
                        </span>

                        <!--                        <v-btn-->
                        <!--                            icon="mdi-close"-->
                        <!--                            variant="plain"-->
                        <!--                            :ripple="false"-->
                        <!--                            v-if="editable && canDeleteAction"-->
                        <!--                            @click="$emit('removed')"-->
                        <!--                        />-->
                    </div>
                    <div class="my-3" v-if="description">
                        <span class="text-custom-neutral-grey-700 text-paragraph-sm w-100">
                            {{ description }}
                        </span>
                    </div>
                    <div v-if="hasSlot('extra')">
                        <slot name="extra" />
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed, ref, useSlots } from 'vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const props = withDefaults(
        defineProps<{
            title?: string;
            description?: string;
            isSelected: boolean;
            editable?: boolean;
            disabled?: boolean;
        }>(),
        { editable: false },
    );

    const emit = defineEmits(['onSelected', 'title-change']);

    const editMode = ref(false);

    const onEnableEditClick = () => {
        if (props.editable) {
            editMode.value = true;
        }
    };

    const onTitleClickOutside = () => {
        editMode.value = false;
    };

    const { isDesktop } = useIsDesktop();

    const contentClass = computed(() => {
        if (props.isSelected) {
            return 'card-selected';
        } else {
            return 'card-not-selected';
        }
    });

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };

    const showAppendSection = computed(() => {
        return hasSlot('append-title' ) || (!editMode.value && props.editable);
    });
</script>

<style scoped lang="scss">
    .card-selected {
        border-radius: 8px;
        border: 2px solid;
        border-color: rgb(var(--v-theme-primary));
    }

    .card-not-selected {
        border-radius: 8px;
        border: 2px solid;
        border-color: rgb(var(--v-theme-custom-neutral-grey-200));
    }

    .card-title-append {
        position: absolute;
        right: 20px;
    }

    .select-column {
        min-width: fit-content;
        max-width: 40px;
    }
</style>
