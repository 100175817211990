<template>
    <v-dialog :model-value="modelValue" :persistent="true" :fullscreen="true">
        <v-card class="fill-height pa-4 rounded-lg app-guide-modal-card text-white" align="center">
            <v-container
                class="pa-0 my-2 h-50"
                :class="`variant-${variant} navigation-${navigation}`"
            >
                <v-row
                    no-gutters
                    class="pa-0 w-100 h-25 justify-center align-center"
                    v-if="variant === 'short'"
                />
                <v-row no-gutters class="pa-0 w-100 justify-center align-center">
                    <app-guide-avatar size="96" :animate="animateAlex" />
                </v-row>
                <v-row no-gutters class="w-100 mt-4 justify-center align-center" v-if="sectionName">
                    <div
                        class="text-wrap px-0 pt-4 mb-4 text-uppercase text-center text-caption-subtitle"
                    >
                        {{ sectionName }}
                    </div>
                </v-row>
                <v-row
                    no-gutters
                    class="w-100 justify-center align-center text-heading-h1"
                    :class="sectionName ? 'mt-n4' : ''"
                >
                    <div class="font-weight-bold text-wrap px-0 pt-4 mb-4 text-heading-h1">
                        <slot name="title" :step="step" />
                    </div>
                </v-row>
                <v-row
                    no-gutters
                    class="content-container justify-center align-center"
                    :class="isDesktop ? 'w-50 px-4' : 'w-100'"
                >
                    <slot :step="step" />
                </v-row>

                <v-row no-gutters class="w-100 px-4 mt-8 navigation-container">
                    <v-col cols="3" :class="isDesktop ? 'text-center' : 'text-left'">
                        <app-button-icon
                            @click="previous"
                            v-show="step > 1"
                            size="x-small"
                            color="white"
                            icon="mdi-arrow-left"
                            variant="outlined"
                        />
                    </v-col>

                    <v-col cols="6">
                        <div
                            v-if="steps > 1"
                            class="navigation-circle mx-2"
                            v-for="s in range(0, steps)"
                            :key="s"
                            :class="{
                                'navigation-circle-done': s + 1 <= step,
                            }"
                            icon
                            @click="step = s + 1"
                        />
                    </v-col>

                    <v-col cols="3" :class="isDesktop ? 'text-center' : 'text-right'">
                        <slot
                            v-if="hasSlot('actions')"
                            name="actions"
                            :step="step"
                            :next="next"
                            :previous="previous"
                            :is-last="isLast"
                            :close="close"
                        />
                        <app-button
                            v-else-if="isLast"
                            @click="close"
                            color="primary"
                            variant="flat"
                            append-icon="mdi-arrow-right"
                            weight="bold"
                            :disabled="disabledNext"
                            >{{ actionText }}
                        </app-button>
                        <app-button-icon
                            v-else
                            @click="next"
                            size="x-small"
                            color="primary"
                            icon="mdi-arrow-right"
                            :disabled="disabledNext"
                            variant="flat"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { computed, onBeforeUnmount, ref, useSlots, watch } from 'vue';
    import { range } from 'lodash';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const step = ref(1);

    watch(
        () => step.value,
        (value) => {
            emit('change-step', value);
        },
    );

    const props = withDefaults(
        defineProps<{
            variant?: 'short' | 'long';
            sectionName?: string;
            actionText: string;
            modelValue: boolean;
            steps: number;
            animateAlex?: boolean;
            disabledNext?: boolean;
            navigation?: 'bottom' | 'relative';
        }>(),
        {
            actionText: 'Close',
            variant: 'short',
            steps: 1,
            disabledNext: false,
            navigation: 'relative',
        },
    );

    const { isDesktop } = useIsDesktop();

    const emit = defineEmits(['update:modelValue', 'change-step']);

    const close = () => {
        emit('update:modelValue', false);
    };

    const previous = () => {
        step.value -= 1;
    };

    const next = () => {
        step.value += 1;
    };

    const watcher = watch(
        () => props.modelValue,
        (value) => {
            if (value) {
                step.value = 1;
            }
        },
    );

    onBeforeUnmount(() => {
        watcher();
    });

    const isLast = computed(() => {
        return step.value === props.steps;
    });

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>

<style scoped lang="scss">
    .content-container {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.005em;
        text-align: center;

        :deep(p) {
            margin-bottom: 16px;
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .app-guide-modal-card {
        background: linear-gradient(
            93.38deg,
            rgb(var(--v-theme-secondary-blue)) 0%,
            rgb(var(--v-theme-tertiary-purple)) 100%
        );
    }

    .navigation-relative .navigation-container {
        position: absolute;
        left: 0px;
        bottom: 10%;
    }

    .navigation-bottom .navigation-container {
        position: relative;
    }

    .navigation-container {
        .navigation-circle {
            cursor: pointer;
            width: 15px; /* The width of the circle */
            height: 15px; /* The height of the circle */
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 50%; /* Makes it a circle */
            display: inline-block; /* Ensures the circle behaves like a block */

            &.navigation-circle-done {
                background-color: white;
            }
        }
    }
</style>
