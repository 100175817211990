import type { InlineGuideMessage } from '@/api/types/inlineGuideMessage';
import { getInlineGuideStory } from '@/services/inline-guide/api';
import { fetch } from '@/services/base';

export async function fetchInlineGuideStory(
    canvasId: number,
    accessToken: string,
): Promise<InlineGuideMessage> {
    return fetch(
        'canvas-inline-guide-story',
        async () => await getInlineGuideStory(canvasId, accessToken),
    );
}
