import type { InlineGuideMessage } from '@/api/types/inlineGuideMessage';
import { defineStore } from 'pinia';
import { DataState, makeDataState, makeDataStateGetters } from '@/store/common/dataState';
import { fetchInlineGuideStory } from '@/services/inline-guide/service';
import { useUsersStore } from '@/store/user/store';
import { useCanvasStore } from '@/store/canvas/store';
import type { InlineGuideActionKey } from '@/store/inline-guide/types';
import { isInlineGuideActionKey } from '@/store/inline-guide/types';
import { assertThat } from '@/store/verify';

export type InlineGuideStoreState = {
    message: InlineGuideMessage | null;
    state: DataState;
    error: any | null;
};

export const useInlineGuideStore = defineStore('inline-guide-store', {
    state: (): InlineGuideStoreState => ({
        message: null,

        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        content(state): string {
            return this.message?.content ?? '';
        },
        title(state): string {
            return this.message?.title ?? '';
        },
        subtitle(state): string {
            return this.message?.subtitle ?? '';
        },
        action(state): InlineGuideActionKey | null {
            const action = this.message?.action;
            if (action) {
                assertThat(isInlineGuideActionKey(action), `Invalid action key ${action}`);
                return action;
            }

            return null;
        },
        actionContext(state): string {
            return this.message?.actionContext ?? '';
        },
        hasMessage(state): boolean {
            return this.message != null;
        },
    },
    actions: {
        clear(): void {
            this.$reset();
        },
        async getStoryMessage(): Promise<void> {
            this.clear();

            const context = await useCanvasStore().makeContext();
            const { accessToken } = await useUsersStore().makeContext();

            const inlineGuideStoryMessage = await fetchInlineGuideStory(
                context.canvasId,
                accessToken,
            );

            this.message = {
                ...inlineGuideStoryMessage,
            };
        },
    },
});
