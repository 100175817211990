import { fetch } from '@/services/base';
import type { CanvasUniqueValueProposition } from '@/api/types/canvas/uniqueValueProposition';
import { getCanvasUniqueValuePropositions } from '@/services/unique-value-proposition/api';

export async function fetchCanvasUniqueValuePropositions(
    canvasId: number,
    accessToken: string,
): Promise<CanvasUniqueValueProposition[]> {
    return fetch(
        'canvas-unique-value-proposition',
        async () => await getCanvasUniqueValuePropositions(canvasId, accessToken),
    );
}
