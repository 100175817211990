import {
    type CanvasLearnedExperienceCreateData,
    type CanvasLearnedExperienceUpdateData,
    createUserExperience,
    deleteCanvasLearnedExperience,
    getCanvasUserExperience,
    postCanvasLearnedExperience,
    putCanvasLearnedExperience,
} from '@/services/learned-experiences/api';
import type { CanvasUserExperience } from '@/api/types/canvas/learnedExperience';
import type {
    CanvasLearnedExperience,
    EditableLearnedExperience,
} from '@/store/learned-experiences/types';
import { LearnedExperienceState } from '@/store/learned-experiences/types';
import { create, remove, update } from '@/services/base';
import type { ApiResponse } from '@/services/apiClient';
import { verify } from '@/store/verify';
import { isSuccessStatus } from '@/services/lib/util';

export async function fetchOrCreateUserExperience(
    canvasId: number,
    accessToken: string,
): Promise<[CanvasUserExperience | null, ApiResponse<CanvasUserExperience>]> {
    console.info('Getting user experience...');

    const response = await getCanvasUserExperience(canvasId, accessToken);

    if (isSuccessStatus(response.status) && response.data) {
        console.info('user experience loaded ...');
        return [response.data, response];
    } else {
        console.info(
            `User experience not found, 
            proceed to create user experience main record for user with email: ${canvasId}`,
        );

        return await create(
            'create-canvas-user-experience',
            async () => await createUserExperience(canvasId, accessToken),
        );
    }
}

export async function updateCanvasLearnedExperience(
    canvasId: number,
    experienceId: number,
    updateData: CanvasLearnedExperienceUpdateData,
    accessToken: string,
): Promise<void> {
    return update(
        'canvas-learned-experiences',
        async () =>
            await putCanvasLearnedExperience(canvasId, experienceId, updateData, accessToken),
    );
}

export async function createCanvasLearnedExperience(
    canvasId: number,
    data: CanvasLearnedExperienceCreateData,
    accessToken: string,
): Promise<[CanvasLearnedExperience, ApiResponse<CanvasLearnedExperience>]> {
    return await create(
        'canvas-learned-experience',
        async () => await postCanvasLearnedExperience(canvasId, data, accessToken),
    );
}

function mapFromExperienceToApiObject(
    experience: EditableLearnedExperience,
): CanvasLearnedExperienceUpdateData {
    return {
        title: verify(experience.title, 'no experience.title'),
        description: verify(experience.description, 'no experience.description'),
        user_job_id: verify(experience.user_job_id, 'no experience.user_job_id'),
        enjoyment: experience.enjoyment ?? 0,
        state: LearnedExperienceState.UploadedByUser,
    };
}

export async function createOrUpdateLearnedExperience(
    canvasId: number,
    experience: EditableLearnedExperience,
    accessToken: string,
): Promise<CanvasLearnedExperience> {
    const payload = mapFromExperienceToApiObject(experience);

    if (experience.id) {
        await updateCanvasLearnedExperience(canvasId, experience.id, payload, accessToken);

        return {
            ...payload,
            id: experience.id,
            created_at: verify(
                experience.createAt,
                'A createAt date is required when updating an experience',
            ),
        };
    } else {
        const [canvasLearnedExperience, _response] = await createCanvasLearnedExperience(
            canvasId,
            payload,
            accessToken,
        );

        return canvasLearnedExperience;
    }
}

export async function removeCanvasLearnedExperience(
    canvasId: number,
    experienceId: number,
    accessToken: string,
): Promise<any> {
    return remove(
        'canvas-learned-experience',
        async () => await deleteCanvasLearnedExperience(canvasId, experienceId, accessToken),
    );
}
