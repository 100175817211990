import { HttpStatusCode } from 'axios';
import {
    deleteCanvasPersonalityType,
    getCanvasPersonalityTypes,
    postCanvasPersonalityType,
    putCanvasPersonalityType,
} from '@/services/personality-type/api';
import type {
    CanvasPersonalityType,
    CanvasPersonalityTypeCreateData,
    CanvasPersonalityTypeUpdateData,
} from '@/api/types/canvas/personalityType';
import { create, remove } from '@/services/base';

export async function fetchCanvasPersonalityType(
    canvasId: number,
    accessToken: string,
): Promise<CanvasPersonalityType[]> {
    console.info('Fetching canvas personality type...');
    const response = await getCanvasPersonalityTypes(canvasId, accessToken);

    if (response.data) {
        console.info('Canvas personality type fetched...');
        return response.data;
    } else {
        throw new Error(response.error?.message);
    }
}

export async function updateCanvasPersonalityType(
    canvasId: number,
    personalityTypeId: number,
    updateData: CanvasPersonalityTypeUpdateData,
    accessToken: string,
): Promise<void> {
    console.info('Updating canvas personality type...');
    const updateResponse = await putCanvasPersonalityType(
        canvasId,
        personalityTypeId,
        updateData,
        accessToken,
    );

    if (updateResponse.data && updateResponse.status === HttpStatusCode.Ok) {
        console.info('Canvas personality type updated successfully...');
        return updateResponse.data;
    } else if (updateResponse.status === HttpStatusCode.UnprocessableEntity) {
        console.info('Likely we are missing some of the required data for the creation...');
        throw new Error(updateResponse.error?.message);
    } else if (updateResponse.error) {
        console.warn('Canvas personality type update failed to create...');
        throw new Error(updateResponse.error?.message);
    } else {
        console.warn('Could not update canvas');
    }
}

export async function createCanvasPersonalityType(
    canvasId: number,
    createData: CanvasPersonalityTypeCreateData,
    accessToken: string,
): Promise<CanvasPersonalityType> {
    console.info('Creating canvas personality type...');

    const [personalityType, _response] = await create(
        'create-canvas-personality-type',
        async () => await postCanvasPersonalityType(canvasId, createData, accessToken),
    );

    return personalityType;
}

export async function removeCanvasPersonalityType(
    canvasId: number,
    personalityTypeId: number,
    accessToken: string,
): Promise<void> {
    return remove(
        'canvas-personality-type',
        async () => await deleteCanvasPersonalityType(canvasId, personalityTypeId, accessToken),
    );
}
