import { type ApiResponse, get, post } from '@/services/apiClient';
import type { Canvas } from '@/api/types/canvas';

export const getCanvas = function (
    userId: number,
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<Canvas>> {
    const request = get<Canvas>(`users/${userId}/canvas/${canvasId}`);
    return request(accessToken);
};

export const createCanvas = function (
    userId: number,
    accessToken: string,
): Promise<ApiResponse<Canvas>> {
    const request = post<any, Canvas>(`users/${userId}/canvas/`);
    return request(accessToken, {});
};
