<template>
    <app-page-header section-title="Challenge pathway:" :title="title" @show-tutorial="showTutorial = true"></app-page-header>
    <ChallengePathTutorial v-model="showTutorial" />
</template>

<script setup lang="ts">
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';
    import { computed, ref } from 'vue';
    import ChallengePathTutorial from '@/components/pathway/ChallengePathTutorial.vue';

    const showTutorial = ref(false);

    const store = useSingleChallengePathStore();

    const title = computed(() => {
        return store.challenge?.description;
    });
</script>
