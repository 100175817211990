<template>
    <SecondaryButton :text="props.text || 'Back'" :to="props.to" />
</template>
<script setup lang="ts">
    import SecondaryButton from '@/components/footer-actions/SecondaryButton.vue';
    import { type RouteLocationRaw } from 'vue-router';

    const props = defineProps<{
        text?: string;
        to: RouteLocationRaw;
        submitDisabled?: boolean;
        submitHide?: boolean;
        submitLoading?: boolean;
        fullWidth?: boolean;
    }>();
</script>
