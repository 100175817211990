import {
    getCanvasFutureAspirations,
    putCanvasFutureAspirations,
} from '@/services/future-aspirations/api';
import type { CanvasFutureAspiration } from '@/api/types/canvas/futureAspiration';
import type { CanvasFutureAspirationItem } from '@/store/future-aspirations/types';
import { fetch, update } from '@/services/base';

export async function fetchCanvasFutureAspirations(
    canvasId: number,
    accessToken: string,
): Promise<CanvasFutureAspiration[]> {
    return fetch(
        'canvas-future-aspirations',
        async () => await getCanvasFutureAspirations(canvasId, accessToken),
    );
}

export async function updateCanvasFutureAspiration(
    canvasId: number,
    updateData: CanvasFutureAspirationItem[],
    accessToken: string,
): Promise<void> {
    return update(
        'canvas-future-aspirations',
        async () => await putCanvasFutureAspirations(canvasId, updateData, accessToken),
    );
}
