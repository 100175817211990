import { type ApiResponse, delete_, get, post, put } from '@/services/apiClient';
import type {
    CanvasPersonalityType,
    CanvasPersonalityTypeCreateData,
    CanvasPersonalityTypeUpdateData,
} from '@/api/types/canvas/personalityType';

export const getCanvasPersonalityTypes = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasPersonalityType[]>> {
    const request = get<CanvasPersonalityType[]>(`canvas/${canvasId}/personality-type/`);
    return request(accessToken);
};

export const putCanvasPersonalityType = function (
    canvasId: number,
    personalityTypeId: number,
    updateData: CanvasPersonalityTypeUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<CanvasPersonalityTypeUpdateData, any>(
        `canvas/${canvasId}/personality-type/${personalityTypeId}/`,
    );
    return request(accessToken, updateData);
};

export const postCanvasPersonalityType = function (
    canvasId: number,
    updateData: CanvasPersonalityTypeCreateData,
    accessToken: string,
): Promise<ApiResponse<CanvasPersonalityType>> {
    const request = post<CanvasPersonalityTypeCreateData, CanvasPersonalityType>(
        `canvas/${canvasId}/personality-type/`,
    );
    return request(accessToken, updateData);
};

export const deleteCanvasPersonalityType = function (
    canvasId: number,
    personalityTypeId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = delete_<CanvasPersonalityType>(
        `canvas/${canvasId}/personality-type/${personalityTypeId}/`,
    );
    return request(accessToken);
};
