import { defineStore } from 'pinia';
import { HttpStatusCode } from 'axios';
import {
    createOrUpdateAction,
    fetchOrCreateCanvasCareerPlan,
} from '@/services/plan/career/service';
import { useCanvasStore } from '@/store/canvas/store';
import { cloneDeep } from 'lodash';
import { removeCanvasFutureAspirationAction } from '@/services/plan/career/actions/service';
import { makeEditablePlan, makeNewEmptyAspirationEditableAction } from '@/store/plan/career/utils';
import {
    type CanvasCareerPlanStoreState,
    type CareerPlanStages,
    type EditableFutureAspiration,
    type EditableFutureAspirationAction,
    type FutureAspirationActionEditFormItem,
} from '@/store/plan/career/types';
import type { CanvasCareerPlan } from '@/api/types/plan/careerPlan';
import { FutureAspirationActionType } from '@/components/plan/career-plan/types';
import { verify } from '@/store/verify';
import { PlanActionItemState } from '@/store/plan/common/types';
import { setEditableActionState } from '@/store/plan/common/utils';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
} from '@/store/common/dataState';

export function filterByType(
    aspiration: EditableFutureAspiration | null,
    type: FutureAspirationActionType,
): EditableFutureAspirationAction[] {
    return aspiration
        ? aspiration.actions.filter((selection: EditableFutureAspirationAction) => {
              return selection.type === type;
          })
        : [];
}

export const useCareerPlanStore = defineStore({
    id: 'career-plan-store',
    state: (): CanvasCareerPlanStoreState => ({
        _plan: null,
        editablePlan: null,

        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        stages(): CareerPlanStages[] {
            return [
                {
                    type: FutureAspirationActionType.Planning,
                    title: 'Planning',
                    description:
                        'In this stage you are doing research to understand what resources or skills you want to have in place.',
                    actions: this.planningSkills,
                },
                {
                    type: FutureAspirationActionType.Preparing,
                    title: 'Preparing',
                    description:
                        'At this stage you are preparing to transition. This could include a new job, courses or training, or some volunteer or part time work.',
                    actions: this.preparingSkills,
                },
                {
                    type: FutureAspirationActionType.Transitioning,
                    title: 'Transitioning',
                    description:
                        'At this stage you will be prepared to move into the goal career. Set some activities here to work towards.',
                    actions: this.transitioningSkills,
                },
            ];
        },
        planningSkills(): EditableFutureAspirationAction[] {
            return filterByType(this.aspiration, FutureAspirationActionType.Planning);
        },
        preparingSkills(): EditableFutureAspirationAction[] {
            return filterByType(this.aspiration, FutureAspirationActionType.Preparing);
        },
        transitioningSkills(): EditableFutureAspirationAction[] {
            return filterByType(this.aspiration, FutureAspirationActionType.Transitioning);
        },
        aspiration(): EditableFutureAspiration | null {
            return this.aspirations[0] ?? null;
        },
        aspirations(): EditableFutureAspiration[] {
            return this.editablePlan?.aspirations ?? [];
        },
    },
    actions: {
        async load() {
            console.info('Loading career plan...');
            setInLoading(this);

            try {
                const { canvas, canvasId, accessToken } = await useCanvasStore().makeContext();
                const [plan, response] = await fetchOrCreateCanvasCareerPlan(canvas, accessToken);

                if (response.status === HttpStatusCode.Created) {
                    // Manually trigger a reload of the user resource, to keep FKs updates
                    await useCanvasStore().synchroniseCanvas(canvasId, accessToken);
                } else {
                    console.log('No need to synchronise user');
                }

                this._plan = plan;
                this._setEditablePlan(plan);

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        addAction(item: EditableFutureAspirationAction): void {
            const aspiration = verify(this.aspiration, 'No aspiration');
            aspiration.actions.push(item);
        },
        addNewStageAction(item: CareerPlanStages): void {
            const action = makeNewEmptyAspirationEditableAction(item.type);

            this.addAction(action);
        },
        async saveAction(
            item: EditableFutureAspirationAction,
            actionForm: FutureAspirationActionEditFormItem,
            accessToken: string,
        ): Promise<void> {
            const aspiration = verify(this.aspiration, 'No aspiration');
            const isUpdating = actionForm.id;
            setEditableActionState(
                item,
                isUpdating ? PlanActionItemState.Updating : PlanActionItemState.Creating,
            );

            try {
                const action = await createOrUpdateAction(
                    aspiration.canvas_id,
                    aspiration.id,
                    actionForm,
                    accessToken,
                );
                item.id = action.id;
                item.title = action.title;
                item.due_date = new Date(action.due_date);
                setEditableActionState(item, PlanActionItemState.Saved);
            } catch (error) {
                setEditableActionState(item, PlanActionItemState.Error);
            }
        },
        async removeAction(
            action: EditableFutureAspirationAction,
            accessToken: string,
        ): Promise<void> {
            setEditableActionState(action, PlanActionItemState.Deleting);

            const aspiration = verify(this.aspiration, 'No aspiration');

            try {
                if (action.id) {
                    await removeCanvasFutureAspirationAction(
                        aspiration.canvas_id,
                        aspiration.id,
                        action.id,
                        accessToken,
                    );
                }

                const index = aspiration.actions.indexOf(action);
                aspiration.actions.splice(index, 1);

                setEditableActionState(action, PlanActionItemState.Saved);
            } catch (error) {
                setEditableActionState(action, PlanActionItemState.Error);
            }
        },
        _setEditablePlan: function (plan: CanvasCareerPlan | null) {
            const clone = cloneDeep(plan);

            if (plan && clone) {
                this.editablePlan = makeEditablePlan(clone);
            }
        },
    },
});
