<template>
    <app-guide-inline
        v-if="userJobStore.hasNonActionedInferredUserJobs"
        title="How do these jobs look?"
        text="We have inferred some of your roles based on the curriculum you uploaded. Please review them."
        @close="showDummyGuide = false"
        @submit="showDummyGuide = false"
    >
        <template #actions="{ close }">
            <v-spacer />
            <app-button @click="close" color="primary" border="secondary" variant="flat">
                Close
            </app-button>
        </template>
    </app-guide-inline>
    <app-guide-chat-activator
        v-if="showDummyGuide && userJobStore.hasNonActionedInferredUserJobs"
    />
    <!--
        Note: There is no specific guide for this page, so we make the avatar dissapear as soon as the message is gone
        TODO: Make: guide for this page
     -->
    <!--    <app-guide-chat-dialog :screen="GuideSupportedScreen.Story" />-->
</template>

<script setup lang="ts">
    import { useUserJobStore } from '@/store/user-job/store';
    import { ref } from 'vue';

    const userJobStore = useUserJobStore();

    const showDummyGuide = ref(true);
</script>
