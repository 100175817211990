<template>
    <v-row no-gutters>
        <FreeTextInput :value="model" @update:model-value="onValueChange" />
    </v-row>

    <v-row no-gutters justify="end">
        <app-button class="mb-2" @click="setGoalClick">Set my goal</app-button>
    </v-row>
</template>

<script setup lang="ts">
    import FreeTextInput from '@/components/common/FreeTextInput.vue';
    import { useDisplay } from 'vuetify';
    import { ref, toValue } from 'vue';
    import { useFutureAspirationsStore } from '@/store/future-aspirations/store';

    const { mdAndUp } = useDisplay();

    const store = useFutureAspirationsStore();

    const model = ref('');

    const emit = defineEmits(['see-goal']);

    const onValueChange = (value: string) => {
        model.value = value;
    };
    const setGoalClick = () => {
        const text = toValue(model);
        if (text) {
            store.onSetMyGoal({
                description: text,
                timeframe: '',
                location: '',
                whats_required: '',
                expected_gain: '',
                summary_statement: '',
            });
            model.value = '';
        }
    };

    const onSeeMyGoal = () => {
        emit('see-goal');
    };
</script>
