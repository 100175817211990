import { toValue } from 'vue';
import type { MaybeRefOrGetter } from 'vue';

export function verify<T>(object: MaybeRefOrGetter<T>, msg: string): NonNullable<T> {
    const value = toValue(object);
    if (value) {
        return value;
    }
    console.warn(`Failed to verify: ${msg}`);
    throw new Error(msg);
}

/**
 * Named assertThat instead assert, so does not clash with global assert and does not bother with imports
 */
export function assertThat(condition: any, message?: string): asserts condition {
    if (!condition) {
        throw new Error(`Assertion failed${message ? ': ' + message : ''}`);
    }
}
