<template>
    <span class="text-caption" v-if="isDevelopment">(display: {{ name }})</span>
</template>

<script setup lang="ts">
    import { useDisplay } from 'vuetify';
    import { computed } from 'vue';

    const { name } = useDisplay();

    const isDevelopment = computed(() => {
        const mode = import.meta.env.MODE;
        return mode === 'development';
    });
</script>
