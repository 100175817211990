import { type ApiResponse, get, put } from '@/services/apiClient';
import { type ApiStory } from '@/api/types/canvas/apiStory';
import type { CanvasCurrentChallengeUpdateData } from '@/store/current-challenges/types';
import type { StoryFeedbackUpdateData } from '@/services/story/service';

export const getCanvasStory = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<ApiStory>> {
    const request = get<ApiStory>(`canvas/${canvasId}/story/`);
    return request(accessToken);
};


/**
 * TODO: This is a first attempt to provide feedback for a story section.
 *       There is an obvious symptom that this can go wrong very easily
 *       as the sections are part of the story and not a separate table.
 *       The url includes a sectionId (who i am, what i offer, what i am working on) to identify the section,
 *       but it instead should provide the ID of the statement which should be a separate table linked to the story.
 */
export const putStoryFeedback = function (
    canvasId: number,
    updateData: StoryFeedbackUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<StoryFeedbackUpdateData, any>(
        `canvas/${canvasId}/story/feedback`,
    );
    return request(accessToken, updateData);
};
