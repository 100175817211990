<template>
    <v-container class="story-container" fluid :class="isDesktop ? 'desktop' : 'mobile'">
        <StoryContent />
        <!--            <template v-slot:footer="props">-->
        <!--                <MainContentFooter v-bind="props">-->
        <!--                    <template v-slot:actions>-->
        <!--                        <SaveProgressButtons-->
        <!--                            class="pa-3"-->
        <!--                            :close-hide="true"-->
        <!--                            submit-text="Create Pathways"-->
        <!--                            :submit-disabled="true"-->
        <!--                            :submit-to="planRoute"-->
        <!--                            :full-width="smAndDown"-->
        <!--                        >-->
        <!--                            <template v-slot:submit-prepend>-->
        <!--                                <StoryPrintPdfButton />-->
        <!--                            </template>-->
        <!--                        </SaveProgressButtons>-->
        <!--                    </template>-->

        <!--                    <template v-slot:guide>-->
        <!--                        <StoryGuide />-->
        <!--                    </template>-->
        <!--                    &lt;!&ndash;            <template v-slot:guide> TODO GUIDE STORY</template>&ndash;&gt;-->
        <!--                </MainContentFooter>-->

        <PageActivity :area="UserActivityArea.Story" />
    </v-container>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import { useStoryStore } from '@/store/story/store';
    import StoryContent from '@/components/story/StoryContent.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useUVPStore } from '@/store/unique-value-proposition/store';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();
    const storyStore = useStoryStore();
    const uvpStore = useUVPStore();

    const { isDesktop } = useIsDesktop();

    activityStore.$reset();
    storyStore.$reset();

    await canvasStore.load();
    await activityStore.load();
    await uvpStore.load();
    await storyStore.load();

    onMounted(async () => {
        await activityStore.addEntryActivity(UserActivityArea.Story);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.Story);
    });
</script>

<style scoped class="scss">
    .story-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        align-self: stretch;

        &.desktop {
            padding: 40px 120px;
            gap: 80px;
        }

        &.mobile {
            padding: 64px 20px;
            gap: 64px;
        }


    }
</style>
