<template>
    <StorySectionActivityInlineList :items="items" />
</template>

<script setup lang="ts">
    import { useStoryStore } from '@/store/story/store';
    import { computed } from 'vue';
    import StorySectionActivityInlineList from '@/components/story/section/activity/StorySectionActivityInlineList.vue';
    import { capitalize } from 'lodash';
    import type { StoryActivity } from '@/store/story/types';

    defineProps<{
        item: StoryActivity;
    }>();

    const store = useStoryStore();

    const items = computed(() => {
        return store.personalStrengths.map((item) => capitalize(item.description));
    });
</script>
