<template>
    <app-page>
        <app-page-header title="Profile" hide-tutorial></app-page-header>
        <app-page-content class="align-center justify-center d-flex">
            <v-form ref="formRef">
                <v-row no-gutters class="w-100">
                    <UserDetails />
                </v-row>
                <v-row no-gutters class="w-100 mb-4">
                    <v-divider></v-divider>
                </v-row>
                <v-row no-gutters class="w-100">
                    <CurrentEmployment />
                </v-row>
                <v-row no-gutters class="w-100 mb-4">
                    <v-divider></v-divider>
                </v-row>
                <v-row no-gutters class="w-100">
                    <CurriculumVitaeSection />
                </v-row>
                <v-row no-gutters class="w-100 mb-4">
                    <v-divider></v-divider>
                </v-row>
                <v-row no-gutters class="w-100">
                    <v-card elevation="0" class="text-custom-grey-900 w-100">
                        <v-card-title class="text-h5 font-weight-bold text-wrap px-0 py-2">
                            Career history
                        </v-card-title>

                        <v-card-text class="px-0 text-wrap text-subtitle-1">
                            <app-button
                                class="text-custom-purple-deep bg-white"
                                variant="outlined"
                                :to="careerHistoryRoute"
                            >
                                See my career history
                            </app-button>
                        </v-card-text>
                    </v-card>
                </v-row>
                <v-row no-gutters class="w-100 mb-4">
                    <v-divider></v-divider>
                </v-row>
                <v-row no-gutters class="w-100">
                    <CareerConfidence />
                </v-row>
                <v-row no-gutters class="w-100 mb-4">
                    <v-divider></v-divider>
                </v-row>

                <!--            <v-row no-gutters class="w-100" v-if="!firstProfileView">-->
                <!--                &lt;!&ndash;-->
                <!--                    Hide the change password option on the profile until after-->
                <!--                    the user has first logged in and set the profile-->
                <!--                 &ndash;&gt;-->
                <!--                <ChangePassword />-->
                <!--            </v-row>-->

                <v-row no-gutters class="w-100 mt-16" v-if="!firstProfileView">
                    <v-card elevation="0" class="text-custom-grey-900 w-100">
                        <v-card-title class="text-h5 font-weight-bold text-wrap px-0 py-2">
                            Logout
                        </v-card-title>
                        <LogoutButton class="justify-center" />
                    </v-card>
                </v-row>

                <v-row no-gutters class="w-100" v-if="firstProfileView">
                    <v-btn
                        prepend-icon="mdi-open-in-new"
                        variant="text"
                        href="https://app.termly.io/document/terms-of-service/f7e48b2a-0683-4ba4-a380-c66deb159bb8"
                        target="_blank"
                    >
                        ACTVO's Terms and Conditions
                    </v-btn>
                </v-row>
                <v-row no-gutters class="w-100" v-if="firstProfileView">
                    <v-btn
                        prepend-icon="mdi-security"
                        variant="text"
                        @click="goPrivacy"
                        target="_blank"
                    >
                        ACTVO's Privacy Policy
                    </v-btn>
                </v-row>
                <v-row no-gutters class="w-100" v-if="firstProfileView">
                    <v-banner
                        color="yellow"
                        class="text-subtitle-1"
                        icon="mdi-text-box-check-outline"
                        text="By clicking save below you are confirming you agree to our Terms and Services and Privacy Policy"
                        :stacked="true"
                    >
                    </v-banner>
                </v-row>
            </v-form>
        </app-page-content>

        <app-page-footer>
            <template v-slot:errors>
                <SubmitNotification :store="store" :activity-area="UserActivityArea.Profile" />
            </template>
            <template v-slot:actions>
                <SaveProgressButtons
                    :close-to="homeRoute"
                    close-variant="flat"
                    v-if="firstProfileView"
                    submit-text="Save and get started"
                    :submit-loading="store.isUpdating"
                    :submit-disabled="!store.isDirty"
                    @on-submit="onFirstSaveClick"
                />
                <SaveProgressButtons
                    :close-to="homeRoute"
                    close-variant="flat"
                    v-if="!firstProfileView"
                    submit-text="Update user profile"
                    :submit-loading="store.isUpdating"
                    :submit-disabled="!store.isDirty || !store.isComplete"
                    @on-submit="onSaveClick"
                />
                <WarningChangesWillBeLost :value="store.isDirty" @on-submit="onSaveClick" />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.Profile" />
        <ProfileGuide />
    </app-page>
</template>

<script setup lang="ts">
    import { useUsersStore } from '@/store/user/store';
    import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';
    import { useUserProfileStore } from '@/store/profile/store';
    import CurriculumVitaeSection from '@/components/profile/curriculum/CurriculumVitaeSection.vue';
    import CurrentEmployment from '@/components/profile/CurrentEmployment.vue';
    import SaveProgressButtons from '@/components/footer-actions/SaveProgressButtons.vue';
    import SubmitNotification from '@/components/footer-actions/SubmitNotification.vue';
    import UserDetails from '@/components/profile/UserDetails.vue';
    import { useRouter } from 'vue-router';
    import { careerHistoryRoute, homeRoute } from '@/router/routes';
    import WarningChangesWillBeLost from '@/components/footer-actions/WarningChangesWillBeLost.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import LogoutButton from '@/components/buttons/LogoutButton.vue';
    import { useActivityStore } from '@/store/activity/store';
    import CareerConfidence from '@/components/profile/CareerConfidence.vue';
    import ProfileGuide from '@/components/profile/ProfileGuide.vue';

    const store = useUserProfileStore();
    const usersStore = useUsersStore();
    const activityStore = useActivityStore();

    const router = useRouter();

    onBeforeMount(async () => {
        store.$reset();
    });

    onMounted(async () => {
        store.init();

        // Trigger validation on load to display error messages of required fields if needed
        // TODO: review this UX decision
        const form = formRef.value;
        // @ts-ignore not sure which type this should be
        if (form?.validate()) {
            // Form is valid, submit data
            console.log('Form submitted:', store.userInputs);
        } else {
            // Form is invalid
            console.log('Form validation failed');
        }

        await activityStore.addEntryActivity(UserActivityArea.Profile);
    });

    const formRef = ref(null);

    const onSaveClick = async () => {
        await store.save();
    };

    const onFirstSaveClick = async () => {
        await onSaveClick();
        await router.push('/');
    };

    const firstProfileView = computed(() => {
        return !usersStore.hasProfileBeenSet;
    });

    const goPrivacy = async () => {
        await router.push('/privacy-policy');
    };

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.Profile);
    });
</script>

<style scoped lang="scss">
    :deep(.v-messages__message) {
        margin-top: 8px;
        padding-left: 0;
        padding-bottom: 16px;
    }
</style>
