import {
    type CanvasPersonalityType,
    PersonalityTypeMethod,
    PersonalityTypeValues,
} from '@/api/types/canvas/personalityType';
import { values } from 'lodash';
import adventurerSrc from '@/assets/images/personality-types/adventurer.png';
import advocateSrc from '@/assets/images/personality-types/advocate.png';
import architectSrc from '@/assets/images/personality-types/architect.png';
import campaignerSrc from '@/assets/images/personality-types/campaigner.png';
import commanderSrc from '@/assets/images/personality-types/commander.png';
import consulSrc from '@/assets/images/personality-types/consul.png';
import debaterSrc from '@/assets/images/personality-types/debater.png';
import defenderSrc from '@/assets/images/personality-types/defender.png';
import entertainerSrc from '@/assets/images/personality-types/entertainer.png';
import entrepreneurSrc from '@/assets/images/personality-types/entrepreneur.png';
import executiveSrc from '@/assets/images/personality-types/executive.png';
import logicianSrc from '@/assets/images/personality-types/logician.png';
import logisticianSrc from '@/assets/images/personality-types/logistician.png';
import mediatorSrc from '@/assets/images/personality-types/mediator.png';
import protagonistSrc from '@/assets/images/personality-types/protagonist.png';
import virtuosoSrc from '@/assets/images/personality-types/virtuoso.png';

export type PersonalityTypeSelectItem = {
    title: string;
    value: PersonalityTypeValues;
};

export const makeSelectItem = (
    personalityType: PersonalityTypeValueDefinition,
): PersonalityTypeSelectItem => {
    return {
        title: `${personalityType.label} (${personalityType.value})`,
        value: personalityType.value,
    };
};

export type PersonalityTypeValueDefinition = {
    label: string;
    value: PersonalityTypeValues;
    description: string;
    longDescription: string[];
    image: string;
};

export const PERSONALITY_TYPES_DEFINITION: Record<
    PersonalityTypeValues,
    PersonalityTypeValueDefinition
> = {
    [PersonalityTypeValues.ESTJ]: {
        label: 'Executive',
        value: PersonalityTypeValues.ESTJ,
        description: 'Excellent administrators, unsurpassed at managing things – or people.',
        longDescription: [
            "ESTJs are classic images of the model citizen: they help their neighbors, uphold the law, and " +
            "try to make sure that everyone participates in the communities and organizations that they " +
            "hold so dear.",

            "ESTJ (Executive) is a personality type with the Extraverted, Observant, Thinking, and Judging " +
            "traits. They possess great fortitude, emphatically following their own sensible judgment. " +
            "They often serve as a stabilizing force among others, able to offer solid direction amid " +
            "adversity.",

            "People with the ESTJ personality type (Executives) are representatives of tradition and order, " +
            "utilizing their understanding of what is right, wrong, and socially acceptable to bring " +
            "families and communities together.",

            "Embracing the values of honesty and dedication, ESTJs are valued for their mentorship mindset " +
            "and their ability to create and follow through on plans in a diligent and efficient manner. " +
            "They will happily lead the way on difficult paths, and they won’t give up when things become " +
            "stressful."
        ],
        image: executiveSrc,
    },
    [PersonalityTypeValues.ENTJ]: {
        label: 'Commander',
        value: PersonalityTypeValues.ENTJ,
        description:
            'Bold, imaginative and strong-willed leaders, always finding a way – or making one.',
        longDescription: [
            "ENTJ personalities perceive themselves to be a strong positive influence on others.",

            "ENTJ (Commander) is a personality type with the Extraverted, Intuitive, Thinking, and Judging " +
            "traits. They are decisive people who love momentum and accomplishment. They gather information " +
            "to construct their creative visions but rarely hesitate for long before acting on them.",

            "People with the ENTJ personality type (Commanders) are natural-born leaders. Embodying the " +
            "gifts of charisma and confidence, ENTJs project authority in a way that draws crowds together " +
            "behind a common goal.",

            "However, these personalities are also characterized by an often ruthless level of rationality, " +
            "using their drive, determination, and sharp mind to achieve whatever objectives they’ve set for " +
            "themselves."
        ],
        image: commanderSrc,
    },
    [PersonalityTypeValues.ESFJ]: {
        label: 'Consul',
        value: PersonalityTypeValues.ESFJ,
        description: 'Extraordinarily caring, social and popular people, always eager to help.',
        longDescription: [
            "ESFJs have a talent for making the people in their lives feel supported, cared for, and secure. " +
            "ESFJ (Consul) is a personality type with the Extraverted, Observant, Feeling, and Judging traits.",

            "They are attentive and people-focused, and they enjoy taking part in their social community. " +
            "Their achievements are guided by decisive values, and they willingly offer guidance to others.",

            "For people with the ESFJ personality type (Consuls), life is sweetest when it’s shared with " +
            "others. These social individuals form the bedrock of many communities, opening their homes – " +
            "and their hearts – to friends, loved ones, and neighbors. This doesn’t mean that they are " +
            "saints or that they like everyone. In fact, they are much more likely to form close bonds " +
            "with people who share their same values and opinions.",

            "But regardless of other people’s beliefs, ESFJ personalities still strongly believe in the " +
            "power of hospitality and good manners, and they tend to feel a sense of duty to those around " +
            "them. Generous and reliable, they often take it upon themselves – in ways both large and " +
            "small – to hold their families and their communities together."
        ],
        image: consulSrc,
    },
    [PersonalityTypeValues.ENFJ]: {
        label: 'Protagonist',
        value: PersonalityTypeValues.ENFJ,
        description: 'Charismatic and inspiring leaders, able to mesmerize their listeners.',
        longDescription: [
            "ENFJs possess the unique ability to remain hopeful in the face of difficulties, always " +
            "remembering that there is something to be grateful for.",

            "ENFJ (Protagonist) is a personality type with the Extraverted, Intuitive, Feeling, and Judging " +
            "traits. These warm, forthright types love helping others, and they tend to have strong ideas " +
            "and values. They back their perspective with the creative energy to achieve their goals.",

            "People with the ENFJ personality type (Protagonists) feel called to serve a greater purpose in " +
            "life. Thoughtful and idealistic, ENFJs strive to have a positive impact on other people and " +
            "the world around them.",

            "These personalities rarely shy away from an opportunity to do the right thing, even when doing " +
            "so is far from easy. ENFJs are born leaders, which explains why these personalities can be " +
            "found among many notable politicians, coaches, and teachers."
        ],
        image: protagonistSrc,
    },
    [PersonalityTypeValues.ISTJ]: {
        label: 'Logistician',
        value: PersonalityTypeValues.ISTJ,
        description: 'Practical and fact-minded individuals, whose reliability cannot be doubted.',
        longDescription: [
            "In a world where many people shirk their responsibilities or say what they think others " +
            "want to hear, ISTJs stand out as dedicated, responsible, and honest.",

            "ISTJ (Logistician) is a personality type with the Introverted, Observant, Thinking, and " +
            "Judging traits. These people tend to be reserved yet willful, with a rational outlook on " +
            "life. They compose their actions carefully and carry them out with methodical purpose.",

            "People with the ISTJ personality type (Logisticians) mean what they say and say what they " +
            "mean, and when they commit to doing something, they make sure to follow through.",

            "With their responsible and dependable nature, it might not be so surprising that ISTJ " +
            "personalities also tend to have a deep respect for structure and tradition. They are often " +
            "drawn to organizations, workplaces, and educational settings that offer clear hierarchies " +
            "and expectations."
        ],
        image: logisticianSrc,
    },
    [PersonalityTypeValues.ISFJ]: {
        label: 'Defender',
        value: PersonalityTypeValues.ISFJ,
        description: 'Very dedicated and warm protectors, always ready to defend their loved ones.',
        longDescription: [
            "ISFJs are true altruists, meeting kindness with kindness-in-excess and engaging with the " +
            "work and people they believe in with enthusiasm and generosity.",

            "ISFJ (Defender) is a personality type with the Introverted, Observant, Feeling, and Judging " +
            "traits. These people tend to be warm and unassuming in their own steady way. They’re " +
            "efficient and responsible, giving careful attention to practical details in their daily lives.",

            "This is a capable, can-do personality type with a wealth of versatile gifts. Though " +
            "sensitive and caring, ISFJs also have excellent analytical abilities and an eye for detail. " +
            "And despite their reserve, they tend to have well-developed people skills and robust social " +
            "relationships.",

            "These personalities are truly more than the sum of their parts, and their varied strengths " +
            "shine in even the most ordinary aspects of their daily lives."
        ],
        image: defenderSrc,
    },
    [PersonalityTypeValues.INTJ]: {
        label: 'Architect',
        value: PersonalityTypeValues.INTJ,
        description: 'Imaginative and strategic thinkers, with a plan for everything.',
        longDescription: [
            "People with the INTJ personality type, independent to the core, want to shake off other " +
            "people’s expectations and pursue their own ideas.",

            "INTJ (Architect) is a personality type with the Introverted, Intuitive, Thinking, and " +
            "Judging traits. These thoughtful tacticians love perfecting the details of life, applying " +
            "creativity and rationality to everything they do. Their inner world is often a private, " +
            "complex one.",

            "People with the INTJ personality type (Architects) are intellectually curious individuals " +
            "with a deep-seated thirst for knowledge. INTJs tend to value creative ingenuity, " +
            "straightforward rationality, and self-improvement.",

            "They consistently work toward enhancing intellectual abilities and are often driven by an " +
            "intense desire to master any and every topic that piques their interest."
        ],
        image: architectSrc,
    },
    [PersonalityTypeValues.INFJ]: {
        label: 'Advocate',
        value: PersonalityTypeValues.INFJ,
        description: 'Quiet and mystical, yet very inspiring and tireless idealists.',
        longDescription: [
            "INFJ personalities might come across as somewhat reserved, but they are fueled by a profound " +
            "internal passion. Stimulated by deep, reflective thought and an enormous amount of empathy, " +
            "they dedicate themselves to the pursuit of purpose.",

            "INFJ (Advocate) is a personality type with the Introverted, Intuitive, Feeling, and Judging traits. " +
            "They tend to approach life with deep thoughtfulness and imagination.",

            "Their inner vision, personal values, and a quiet, principled version of humanism guide them in " +
            "all things. Idealistic and principled, people with the INFJ personality type (Advocates) aren’t " +
            "content to coast through life – they want to stand up and make a difference.",

            "For these compassionate personalities, success doesn’t come from money or status but from seeking " +
            "fulfillment, helping others, and being a force for good in the world."
        ],
        image: advocateSrc,
    },
    [PersonalityTypeValues.ESTP]: {
        label: 'Entrepreneur',
        value: PersonalityTypeValues.ESTP,
        description:
            'Smart, energetic and very perceptive people, who truly enjoy living on the edge.',
        longDescription: [
            "ESTPs are a bold and brave bunch who are not afraid to take chances or act on their impulses. " +
            "They live in the moment and dive into the action with an open mind and outward confidence.",

            "ESTP (Entrepreneur) is a personality type with the Extraverted, Observant, Thinking, and " +
            "Prospecting traits. They tend to be energetic and action-oriented, deftly navigating whatever " +
            "is in front of them. They love uncovering life’s opportunities, whether socializing with others " +
            "or in more personal pursuits.",

            "People with the ESTP personality type (Entrepreneurs) are vibrant individuals brimming with an " +
            "enthusiastic and spontaneous energy. They tend to be on the competitive side, often assuming " +
            "that a competitive mindset is a necessity in order to achieve success in life.",

            "With their driven, action-oriented attitudes, they rarely waste time thinking about the past. " +
            "In fact, they excel at keeping their attention rooted in their present – so much so that they " +
            "rarely find themselves fixated on the time throughout the day. They keep their conversations " +
            "energetic, with a good dose of intelligence, but they like to talk about what is – or better yet, " +
            "to just go out and do it."
        ],
        image: entrepreneurSrc,
    },
    [PersonalityTypeValues.ESFP]: {
        label: 'Entertainer',
        value: PersonalityTypeValues.ESFP,
        description:
            'Spontaneous, energetic and enthusiastic people – life is never boring around them.',
        longDescription: [
            "ESFPs truly enjoy the simplest things, and there’s no greater joy for them than just having fun " +
            "with a good group of friends. People with this personality type would almost always choose to be " +
            "with friends over spending time alone.",

            "ESFP (Entertainer) is a personality type with the Extraverted, Observant, Feeling, and " +
            "Prospecting traits. These people love vibrant experiences, engaging in life eagerly and taking " +
            "pleasure in discovering the unknown.",

            "They can be very social, often encouraging others into shared activities. If anyone is to be found " +
            "spontaneously breaking into song and dance, it is people with the ESFP personality type " +
            "(Entertainers).",

            "They get caught up in the excitement of the moment and want everyone else to feel that way too. " +
            "No other type is as generous with their time and energy when it comes to encouraging others, and " +
            "no other type does it with such irresistible style."
        ],
        image: entertainerSrc,
    },
    [PersonalityTypeValues.ENTP]: {
        label: 'Debater',
        value: PersonalityTypeValues.ENTP,
        description: 'Smart and curious thinkers who cannot resist an intellectual challenge.',
        longDescription: [
            "ENTP personalities are the ultimate devil’s advocates, thriving on the process of shredding " +
            "people’s arguments to pieces. Sometimes they even rebel against their own beliefs by arguing " +
            "the opposing viewpoint – just to see how the world looks from the other side.",

            "ENTP (Debater) is a personality type with the Extraverted, Intuitive, Thinking, and Prospecting " +
            "traits. They tend to be bold and creative, deconstructing and rebuilding ideas with great " +
            "mental agility. They pursue their goals vigorously despite any resistance they might encounter.",

            "Quick-witted and audacious, people with the ENTP personality type (Debaters) aren’t afraid to " +
            "disagree with the status quo. In fact, they’re not afraid to disagree with pretty much anything " +
            "or anyone.",

            "Few things light up these personalities more than a bit of verbal sparring – and if the " +
            "conversation veers into controversial terrain, so much the better. It would be a mistake, " +
            "though, to think of ENTPs as disagreeable or mean-spirited. Instead, people with this personality " +
            "type are knowledgeable and curious with a playful sense of humor, and they can be incredibly " +
            "entertaining."
        ],
        image: debaterSrc,
    },
    [PersonalityTypeValues.ENFP]: {
        label: 'Campaigner',
        value: PersonalityTypeValues.ENFP,
        description:
            'Enthusiastic, creative and sociable free spirits, who can always find a reason to smile.',
        longDescription: [
            "ENFPs are independent and creative, always on the lookout for the magic and meaning in " +
            "everyday life. They can’t help but ponder the deeper significance of life – even when they " +
            "should be paying attention to something else.",

            "ENFP (Campaigner) is a personality type with the Extraverted, Intuitive, Feeling, and " +
            "Prospecting traits. These people tend to embrace big ideas and actions that reflect their " +
            "sense of hope and goodwill toward others. Their vibrant energy can flow in many directions.",

            "People with the ENFP personality type (Campaigners) are true free spirits – outgoing, " +
            "openhearted, and open-minded. With their lively, upbeat approach to life, ENFPs stand out " +
            "in any crowd.",

            "But even though they can be the life of the party, they don’t just care about having a good " +
            "time. These personalities have profound depths that are fueled by their intense desire for " +
            "meaningful, emotional connections with others."
        ],
        image: campaignerSrc,
    },
    [PersonalityTypeValues.ISTP]: {
        label: 'Virtuoso',
        value: PersonalityTypeValues.ISTP,
        description: 'Bold and practical experimenters, masters of all kinds of tools.',
        longDescription: [
            "ISTPs act spontaneously on a new interest, exploring its viability after they’ve shifted gears.",

            "ISTP (Virtuoso) is a personality type with the Introverted, Observant, Thinking, and Prospecting traits.",

            "They tend to have an individualistic mindset, pursuing goals without needing much external connection. " +
            "They engage in life with inquisitiveness and personal skill, varying their approach as needed.",

            "People with the ISTP personality type (Virtuosos) love to explore with their hands and their eyes, " +
            "touching and examining the world around them with an impressive diligence, a casual curiosity, " +
            "and a healthy dose of skepticism. They are natural makers, moving from project to project, " +
            "building the useful and the superfluous for the fun of it and learning from their environment as " +
            "they go.",

            "They find no greater joy than in getting their hands dirty pulling things apart and putting them " +
            "back together, leaving them just a little bit better than they were before."
        ],
        image: virtuosoSrc,
    },
    [PersonalityTypeValues.ISFP]: {
        label: 'Adventurer',
        value: PersonalityTypeValues.ISFP,
        description:
            'Flexible and charming artists, always ready to explore and experience something new.',
        longDescription: [
        "ISFPs want to live in a world where they – and everyone else – have the freedom to live as they " +
        "see fit, without judgment.",

        "ISFP (Adventurer) is a personality type with the Introverted, Observant, Feeling, and Prospecting " +
        "traits. They tend to have open minds, approaching life, new experiences, and people with grounded " +
        "warmth. Their ability to stay in the moment helps them uncover exciting potentials.",

        "People with the ISFP personality type (Adventurers) are true artists – although not necessarily in " +
        "the conventional sense. For these types, life itself is a canvas for self-expression. From what " +
        "they wear to how they spend their free time, they act in ways that vividly reflect who they are " +
        "as unique individuals.",

        "With their exploratory spirit and their ability to find joy in everyday life, ISFPs can be among " +
        "the most interesting people you’ll ever meet."
    ],
        image: adventurerSrc,
    },
    [PersonalityTypeValues.INTP]: {
        label: 'Logician',
        value: PersonalityTypeValues.INTP,
        description: 'Innovative inventors with an unquenchable thirst for knowledge.',
        longDescription: [
            "Imaginative and curious, people with the INTP personality type can find endless fascination " +
            "in the workings of their own mind. INTP (Logician) is a personality type with the " +
            "Introverted, Intuitive, Thinking, and Prospecting traits.",

            "These flexible thinkers enjoy taking an unconventional approach to many aspects of life. " +
            "They often seek out unlikely paths, mixing willingness to experiment with personal " +
            "creativity.",

            "People with the INTP personality type (Logicians) pride themselves on their unique " +
            "perspective and vigorous intellect. They can’t help but puzzle over the mysteries of the " +
            "universe – which may explain why some of the most influential philosophers and scientists " +
            "of all time have been INTPs.",

            "People with this personality type tend to prefer solitude, as they can easily become " +
            "immersed in their thoughts when they are left to their own devices."
        ],
        image: logicianSrc,
    },
    [PersonalityTypeValues.INFP]: {
        label: 'Mediator',
        value: PersonalityTypeValues.INFP,
        description: 'Poetic, kind and altruistic people, always eager to help a good cause.',
        longDescription: [
            "Idealistic and empathetic, people with the INFP personality type long for deep, soulful " +
            "relationships, and they feel called to help others.",

            "INFP (Mediator) is a personality type with the Introverted, Intuitive, Feeling, and Prospecting " +
            "traits. These rare personality types tend to be quiet, open-minded, and imaginative, and they " +
            "apply a caring and creative approach to everything they do.",

            "Although they may seem quiet or unassuming, people with the INFP personality type (Mediators) " +
            "have vibrant, passionate inner lives. Creative and imaginative, they happily lose themselves " +
            "in daydreams, inventing all sorts of stories and conversations in their mind.",

            "INFPs are known for their sensitivity – these personalities can have profound emotional " +
            "responses to music, art, nature, and the people around them. They are known to be extremely " +
            "sentimental and nostalgic, often holding onto special keepsakes and memorabilia that brighten " +
            "their days and fill their hearts with joy."
        ],
        image: mediatorSrc,
    },
};

export const selectItems = values(PERSONALITY_TYPES_DEFINITION).map(makeSelectItem);

export function getMBTIType(personalityTypes: CanvasPersonalityType[]) {
    return (
        personalityTypes.find((type: CanvasPersonalityType) => {
            return type.method === PersonalityTypeMethod.MBTI;
        }) ?? null
    );
}
