<template>
    <v-container class="fill-height pa-0" fluid>
        <v-row class="w-100" no-gutters>
            <v-col cols="4">
                <CloseButton
                    v-if="!props.closeHide"
                    :variant="props.closeVariant"
                    :text="props.closeText"
                    :to="props.closeTo"
                    @click="emit('on-back')"
                    :class="props.fullWidth ? 'w-100 mb-1' : ''"
                />
            </v-col>

            <v-spacer />

            <v-col cols="8" class="text-right">
                <!--
                    1. Note: The location='parent' approach on a v-col does not work as expected
                    2. Also v-tooltip does not show on disabled button, so add extra inline div
                -->
                <v-tooltip
                    v-if="submitDisabled && submitTooltip && !submitHide"
                    location="top"
                    :text="submitTooltip"
                >
                    <template v-slot:activator="{ props }">
                        <div v-bind="props" style="display: inline-block">
                            <SubmitButton
                                :text="submitText"
                                @click="emit('on-submit')"
                                :loading="submitLoading"
                                :disabled="submitDisabled"
                                :to="submitTo"
                                :class="fullWidth ? 'w-100 mt-1' : ''"
                                v-bind="props"
                            />
                        </div>
                    </template>
                </v-tooltip>
                <SubmitButton
                    v-else-if="!submitHide"
                    :text="submitText"
                    @click="emit('on-submit')"
                    :disabled="submitDisabled"
                    :loading="submitLoading"
                    :to="submitTo"
                    :class="fullWidth ? 'w-100 mt-1' : ''"
                />
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
    import SubmitButton from '@/components/footer-actions/SubmitButton.vue';
    import { type RouteLocationRaw } from 'vue-router';
    import CloseButton from '@/components/footer-actions/CloseButton.vue';
    import type { BtnVariant } from '@/components/buttons/types';

    const emit = defineEmits(['on-submit', 'on-back']);

    const props = defineProps<{
        closeTo?: RouteLocationRaw;
        closeVariant?: BtnVariant;
        closeText?: string;
        closeHide?: boolean;
        submitDisabled?: boolean;
        submitTooltip?: string;
        submitHide?: boolean;
        submitLoading?: boolean;
        submitText?: string;
        submitTo?: RouteLocationRaw;
        fullWidth?: boolean;
    }>();
</script>
