import { type ApiResponse, get, put } from '@/services/apiClient';
import type { CanvasFutureAspirationUpdateData } from '@/store/future-aspirations/types';
import type { CanvasFutureAspiration } from '@/api/types/canvas/futureAspiration';

export const getCanvasFutureAspirations = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasFutureAspiration[]>> {
    const request = get<CanvasFutureAspiration[]>(`canvas/${canvasId}/future-aspirations/`);
    return request(accessToken);
};

export const putCanvasFutureAspirations = function (
    canvasId: number,
    updateData: CanvasFutureAspirationUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<CanvasFutureAspirationUpdateData, any>(
        `canvas/${canvasId}/future-aspirations/`,
    );
    return request(accessToken, updateData);
};
