<template>
    <vue-advanced-chat
        v-if="user?.is_superuser"
        :current-user-id="currentUserId"
        :rooms="JSON.stringify(store.rooms)"
        :rooms-loaded="store.roomsLoaded"
        :rooms-loading="store.roomsLoading"
        :messages="JSON.stringify(store.currentGuideChatMesages)"
        :messages-loaded="store.messagesLoaded"
        @fetch-messages="store.fetchMessages($event.detail[0])"
        @send-message="store.sendMessage($event.detail[0])"
        show-add-room="false"
        show-audio="false"
        show-files="false"
        :username-options="JSON.stringify(usernameOptions)"
    >
    </vue-advanced-chat>
    <div v-else>You are not authorized to view this chat.</div>
</template>

<script setup lang="ts">
    import { onMounted } from 'vue';

    import { useAdminGuideStore } from '@/store/admin/guide/store';
    import { useUsersStore } from '@/store/user/store';
    import { verify } from '@/store/verify';
    import { useAuth0 } from '@/auth/auth';
    import { register } from 'vue-advanced-chat';

    const { getAccessTokenSilently } = useAuth0();

    const user = verify(useUsersStore().user, 'No user found');

    const store = useAdminGuideStore();
    const currentUserId = user?.id;

    const usernameOptions = { minUsers: 2, currentUser: true };

    register();
    onMounted(async () => {
        const accessToken = await getAccessTokenSilently();
        await store.loadGuideChats(accessToken);
    });
</script>
