<template>
    <app-guide-chat-activator />
    <app-guide-chat-dialog :screen="GuideSupportedScreen.CurrentChallenges">
        <template v-slot:suggestion="{ message }">
            <CurrentChallengesGuideSuggestionMessage
                @submit="() => guideStore.hideDialog()"
                :message="message"
            />
        </template>
    </app-guide-chat-dialog>
</template>

<script setup lang="ts">
    import { GuideSupportedScreen } from '@/store/guide/types';
    import { useGuideStore } from '@/store/guide/store';
    import CurrentChallengesGuideSuggestionMessage from '@/components/canvas/current-challenges/CurrentChallengesGuideSuggestionMessage.vue';

    const guideStore = useGuideStore();
</script>
