import type { CanvasFutureAspiration } from '@/api/types/canvas/futureAspiration';
import type { CanvasCareerPlan } from '@/api/types/plan/careerPlan';

import {
    type EditableFutureAspiration,
    type EditableFutureAspirationAction,
} from '@/store/plan/career/types';
import type { FutureAspirationAction } from '@/api/types/plan/futureAspirationAction';
import type { FutureAspirationActionType } from '@/components/plan/career-plan/types';
import {
    byCreateDateComparator,
    makeEditableAction,
    makeNewEmptyEditableAction,
} from '@/store/plan/common/utils';

export function makeNewEmptyAspirationEditableAction(
    type: FutureAspirationActionType,
): EditableFutureAspirationAction {
    return { ...makeNewEmptyEditableAction(), type: type };
}

function makeEditableAspirationAction(action: FutureAspirationAction) {
    return { ...makeEditableAction(action), type: action.type };
}

function makeEditableFutureAspiration(
    aspiration: CanvasFutureAspiration,
): EditableFutureAspiration {
    return {
        id: aspiration.id,
        canvas_id: aspiration.canvas_id,
        description: aspiration.description,
        timeframe: aspiration.timeframe,
        location: aspiration.location,
        expected_gain: aspiration.expected_gain,
        whats_required: aspiration.whats_required,
        summary_statement: aspiration.summary_statement,
        created_at: aspiration.created_at,
        actions: aspiration.actions.map(makeEditableAspirationAction),
    };
}

export function makeEditablePlan(plan: CanvasCareerPlan) {
    return {
        id: plan.id,
        canvas_id: plan.canvas_id,
        aspirations: plan.aspirations
            .sort(byCreateDateComparator)
            .map(makeEditableFutureAspiration),
    };
}
