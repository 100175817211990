import { createRouter, createWebHashHistory, type RouteLocationNormalized } from 'vue-router';
import CallbackView from '@/views/auth/CallbackView.vue';
import { authGuard } from '@/auth/auth';
import { RoutesName } from '@/router/routesName';
import PersonalValuesView from '@/views/canvas/PersonalValuesView.vue';
import PersonalityTypeView from '@/views/canvas/PersonalityTypeView.vue';
import ProfileView from '@/views/ProfileView.vue';
import GuideAdmin from '@/views/admin/GuideAdmin.vue';
import CurrentChallengesView from '@/views/canvas/CurrentChallengesView.vue';
import SkillsView from '@/views/canvas/SkillsView.vue';
import FutureAspirationsView from '@/views/canvas/FutureAspirationsView.vue';
import StoryView from '@/views/StoryView.vue';
import PlanView from '@/views/PlanView.vue';
import ChallengePathView from '@/views/pathways/ChallengePathView.vue';
import SingleChallengeStartView from '@/views/pathways/SingleChallengeNewView.vue';
import SingleChallengeViewSequencing from '@/views/pathways/SingleChallengeSequencingView.vue';
import CurrentChallengePlanView from '@/views/plan/_LEGACY_CurrentChallengePlanView.vue';
import CareerPlanView from '@/views/plan/CareerPlanView.vue';
import IntroAlexView from '@/views/IntroAlexView.vue';
import TermsAndConditionsView from '@/views/TermsAndConditionsView.vue';
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue';
import { storyRoute } from '@/router/routes';
import HowDoesItWorkView from '@/views/HowDoesItWorkView.vue';
import LearnedExperienceView from '@/views/canvas/LearnedExperienceView.vue';
import CareerHistoryView from '@/views/profile/CareerHistoryView.vue';
import SingleChallengeUpdateProgressView from '@/views/pathways/SingleChallengeUpdateProgressView.vue';
import PersonalStrengthsView from '@/views/canvas/PersonalStrengthsView.vue';
import WelcomeView from '@/views/WelcomeView.vue';
import { ref } from 'vue';
import SingleChallengeEditView from '@/views/pathways/SingleChallengeEditView.vue';

export const routes = [
    // To support users that have bookmarked this page.
    // The home page does not exist anymore. The home is the story
    {
        path: '/',
        name: 'home',
        redirect(to: any) {
            return storyRoute;
        },
        beforeEnter: authGuard,
    },
    {
        path: '/welcome',
        name: RoutesName.Welcome,
        component: WelcomeView,
        beforeEnter: authGuard,
    },
    {
        path: '/profile',
        name: RoutesName.Profile,
        component: ProfileView,
        beforeEnter: authGuard,
    },
    {
        path: '/profile/career',
        name: RoutesName.CareerHistory,
        component: CareerHistoryView,
        beforeEnter: authGuard,
    },
    {
        path: '/admin/guide',
        name: RoutesName.GuideAdmin,
        component: GuideAdmin,
        beforeEnter: authGuard,
    },
    {
        path: '/auth-callback',
        name: RoutesName.AuthCallback,
        component: CallbackView,
    },
    {
        path: '/about',
        name: RoutesName.About,
        component: () => import('../views/AboutView.vue'),
        meta: {
            includeFooter: true,
        },
    },

    //
    //
    // Canvas related
    //
    //

    {
        path: '/canvas',
        name: RoutesName.Canvas,
        // To support users that have bookmarked this page.
        redirect(to: any) {
            return storyRoute;
        },
        beforeEnter: authGuard,
    },
    {
        path: '/canvas/personality-type',
        name: RoutesName.CanvasPersonalityType,
        component: PersonalityTypeView,
        beforeEnter: authGuard,
    },
    {
        path: '/canvas/personal-strengths',
        name: RoutesName.CanvasPersonalStrength,
        component: PersonalStrengthsView,
        beforeEnter: authGuard,
    },
    {
        path: '/canvas/personal-values',
        name: RoutesName.CanvasPersonalValues,
        component: PersonalValuesView,
        beforeEnter: authGuard,
    },
    {
        path: '/canvas/skills',
        name: RoutesName.CanvasSkills,
        component: SkillsView,
        beforeEnter: authGuard,
    },
    {
        path: '/canvas/current-challenges',
        name: RoutesName.CanvasCurrentChallenges,
        component: CurrentChallengesView,
        beforeEnter: authGuard,
    },
    {
        path: '/canvas/future-aspirations',
        name: RoutesName.CanvasFutureAspirations,
        component: FutureAspirationsView,
        beforeEnter: authGuard,
    },
    {
        path: '/canvas/learned-experiences',
        name: RoutesName.CanvasLearnedExperiences,
        component: LearnedExperienceView,
        beforeEnter: authGuard,
    },

    //
    //
    // Story
    //
    //
    {
        path: '/story',
        name: RoutesName.Story,
        component: StoryView,
        beforeEnter: authGuard,
    },

    {
        path: '/plan',
        name: RoutesName.Plan,
        component: PlanView,
        beforeEnter: authGuard,
        meta: {
            includeFooter: true,
        },
    },
    {
        path: '/challenge-path',
        name: RoutesName.ChallengePath,
        component: ChallengePathView,
        beforeEnter: authGuard,
        meta: {
            includeFooter: false,
        },
    },
    {
        path: '/challenge/:id/new',
        name: RoutesName.SingleChallengeStart,
        component: SingleChallengeStartView,
        beforeEnter: authGuard,
        meta: {
            includeFooter: false,
        },
    },
    {
        path: '/challenge/:id/edit',
        name: RoutesName.SingleChallengeUpdateEdit,
        component: SingleChallengeEditView,
        beforeEnter: authGuard,
        meta: {
            includeFooter: false,
        },
    },
    {
        path: '/challenge/:id/update-progress',
        name: RoutesName.SingleChallengeUpdateProgress,
        component: SingleChallengeUpdateProgressView,
        beforeEnter: authGuard,
        meta: {
            includeFooter: false,
        },
    },
    {
        path: '/challenge/:id/sequencing',
        name: RoutesName.SingleChallengeSequencing,
        component: SingleChallengeViewSequencing,
        beforeEnter: authGuard,
        meta: {
            includeFooter: false,
        },
    },
    {
        path: '/current-challenge-plan',
        name: RoutesName.CurrentChallengePlan,
        component: CurrentChallengePlanView,
        beforeEnter: authGuard,
    },
    {
        path: '/career-plan',
        name: RoutesName.CareerPlan,
        component: CareerPlanView,
        beforeEnter: authGuard,
    },
    {
        path: '/terms-and-conditions',
        name: RoutesName.Terms,
        component: TermsAndConditionsView,
        beforeEnter: authGuard,
        meta: {
            includeFooter: true,
        },
    },
    {
        path: '/privacy-policy',
        name: RoutesName.Privacy,
        component: PrivacyPolicyView,
        beforeEnter: authGuard,
        meta: {
            includeFooter: true,
        },
    },
    {
        path: '/intro-alex',
        name: RoutesName.Alex,
        component: IntroAlexView,
        beforeEnter: authGuard,
        meta: {
            includeFooter: true,
        },
    },
    {
        path: '/how-does-it-work',
        name: RoutesName.HowDoesItWork,
        component: HowDoesItWorkView,
        beforeEnter: authGuard,
        meta: {
            includeFooter: true,
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: routes,
});

export const previousRoute = ref<RouteLocationNormalized | null>(null);
// Track the previous route during navigation
router.beforeEach((to, from, next) => {
    previousRoute.value = from; // Capture the previous route name reactively
    next(); // Proceed with navigation
});

export default router;
