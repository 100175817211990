<template>
    <app-button
        :prepend-icon="props.prependIcon"
        :append-icon="props.appendIcon"
        :variant="props.variant"
        :to="props.to"
    >
        {{ props.text ?? 'Close' }}
    </app-button>
</template>
<script setup lang="ts">
    import { type RouteLocationRaw } from 'vue-router';
    import type { BtnVariant } from '@/components/buttons/types';

    const props = defineProps<{
        prependIcon?: string;
        appendIcon?: string;
        variant?: BtnVariant;
        to?: RouteLocationRaw;
        text?: string;
    }>();
</script>
