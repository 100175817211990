<template>
    <div class="text-heading-h3">Final couple of questions</div>
    <div class="w-100 mt-8">
        <v-combobox
            label="NZ Region"
            variant="outlined"
            :items="nzRegionOptions"
            :return-object="false"
            :chips="true"
            :auto-select-first="true"
            :clearable="true"
            v-model="userInputs.location"
            :rules="[requiredRule('NZ Region')]"
            class="mb-2"
            item-title="title"
            item-value="value"
            validate-on="input"
        />

        <v-combobox
            class="mb-2"
            label="Visa Status"
            variant="outlined"
            :chips="true"
            :auto-select-first="true"
            :clearable="true"
            :items="visaOptions"
            v-model="userInputs.visa_type"
            :rules="[requiredRule('Visa')]"
            :return-object="false"
            item-title="title"
            item-value="value"
            validate-on="input"
        />

        <v-autocomplete
            class="mb-2"
            label="Primary language"
            variant="outlined"
            :chips="true"
            :auto-select-first="true"
            :clearable="true"
            :items="primaryLanguages"
            v-model="userInputs.primary_language"
            :rules="[requiredRule('Primary language')]"
            :return-object="false"
            item-title="title"
            item-value="value"
            validate-on="input"
        />

        <v-autocomplete
            class="mb-2"
            label="Other languages"
            variant="outlined"
            :chips="true"
            :auto-select-first="true"
            :clearable="true"
            :multiple="true"
            :items="otherLanguages"
            v-model="userInputs.other_languages"
            :return-object="false"
            item-title="title"
            item-value="value"
        />
    </div>

    <div class="w-100 mt-4">
        <p>
            Please also take a moment to read and agree to our Terms and Conditions and Privacy
            Policy
        </p>
    </div>

    <v-row no-gutters class="w-100 px-2 text-paragraph-md">
        <v-checkbox
            style="height: 40px"
            density="compact"
            v-model="localTermsAndConditionModel"
            class="opacity-100"
        >
            <template v-slot:label>
                Accept &nbsp;
                <router-link :to="termsRoute" target="_blank" class="text-custom-neutral-black"
                    >Terms and Conditions
                </router-link>
            </template>
        </v-checkbox>
    </v-row>
    <v-row no-gutters class="w-100 px-2 text-paragraph-md">
        <v-checkbox style="height: 40px" density="compact" v-model="localPrivacyModel">
            <template v-slot:label>
                Accept &nbsp;
                <router-link :to="privacyRoute" target="_blank" class="text-custom-neutral-black"
                    >Privacy Policy
                </router-link>
            </template>
        </v-checkbox>
    </v-row>
</template>

<script setup lang="ts">
    import { computed, watch } from 'vue';
    import { privacyRoute, termsRoute } from '@/router/routes';
    import HowDoesItWorkContent from '@/components/how-does-it-work/HowDoesItWorkContent.vue';
    import { requiredRule } from '@/components/profile/rules';
    import CurriculumVitaeInput from '@/components/profile/curriculum/CurriculumVitaeInput.vue';
    import { useUserProfileStore } from '@/store/profile/store';
    import { storeToRefs } from 'pinia';
    import {
        familiarLanguageOptions,
        nzRegionOptions,
        otherLanguageOptions,
        visaOptions,
    } from '@/components/profile/userInputOptions';

    const props = defineProps({
        termsAndConditionModel: {
            type: Boolean,
            required: true,
        },
        privacyModel: {
            type: Boolean,
            required: true,
        },
    });
    const emit = defineEmits(['update:termsAndConditionModel', 'update:privacyModel', 'next']);

    // Computed properties for two-way binding
    const localTermsAndConditionModel = computed({
        get: () => props.termsAndConditionModel,
        set: (value) => emit('update:termsAndConditionModel', value),
    });

    const localPrivacyModel = computed({
        get: () => props.privacyModel,
        set: (value) => emit('update:privacyModel', value),
    });

    const store = useUserProfileStore();

    const { userInputs } = storeToRefs(store);

    const primaryLanguages = computed(() => [...familiarLanguageOptions, ...otherLanguageOptions]);

    watch(
        () => userInputs.value.primary_language,
        (newValue) => {
            if (newValue && userInputs.value.other_languages?.includes(newValue)) {
                userInputs.value.other_languages = userInputs.value.other_languages.filter(
                    (language: string) => language !== newValue,
                );
            }
        },
    );

    const otherLanguages = computed(() => {
        const options = [...familiarLanguageOptions, ...otherLanguageOptions];

        if (userInputs.value.primary_language) {
            return options.filter((option) => option.value !== userInputs.value.primary_language);
        }

        return options;
    });
</script>

<style scoped lang="scss">
    :deep(.v-expansion-panel-text__wrapper) {
        padding-left: 16px;
        padding-right: 16px;
    }
</style>
