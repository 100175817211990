<template>
    <PersonalValueButtons
        left-text="Add to core values"
        right-text="Add to growth values"
        :disabled="!store.areThereMoreValuesInSecondStep"
        @click-left="onSubTypeClick(PersonalValueSelectionSubType.Core)"
        @click-right="onSubTypeClick(PersonalValueSelectionSubType.Growth)"
    />
</template>
<script setup lang="ts">
    import { PersonalValueSelectionSubType } from '@/api/types/canvas/personalValue';
    import { usePersonalValuesStore } from '@/store/personal-values/store';
    import { useGuideStore } from '@/store/guide/store';
    import PersonalValueButtons from '@/components/canvas/personal-values/upload/PersonalValueButtons.vue';

    const store = usePersonalValuesStore();
    const guideStore = useGuideStore();

    const onSubTypeClick = (subType: PersonalValueSelectionSubType) => {
        const current = store.current;

        store.onSubTypeSelection(subType);
        guideStore.onUserPersonalSubTypeSelection(current, subType);
    };
</script>
