<template>
    <v-card variant="plain" class="opacity-1 bg-white px-5 pa-4 w-100 rounded-12">
        <div class="pa-0">
            <span
                class="text-heading-h4 font-weight-700 mr-6"
                :class="isDesktop ? '' : 'mb-2 d-block'"
            >
                Does this sound like you?
            </span>
            <!--                <app-fa-icon icon="thumbs-up"></app-fa-icon>-->
            <!--                <app-fa-icon :icon="['far', 'thumbs-up']"></app-fa-icon>-->
            <!--                <font-awesome-icon :icon="['far', 'thumbs-up']" />-->
            <!--                <app-fa-icon icon="thumbs-down"></app-fa-icon>-->
            <app-button
                prepend-icon="mdi-thumb-up-outline"
                class="mr-2"
                @click="emit('click', true)"
            >
                <span class="text-paragraph-sm font-weight-600">Yes</span>
            </app-button>
            <app-button
                prepend-icon="mdi-thumb-down-outline"
                @click="emit('click', false)"
                ><span class="text-paragraph-sm font-weight-600">No</span>
            </app-button>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { isDesktop } = useIsDesktop();

    const emit = defineEmits(['click']);
</script>

<style scoped lang="scss"></style>
