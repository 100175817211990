import { type ApiResponse, get, put } from '@/services/apiClient';
import type { PersonalValue } from '@/api/types/personalValue';
import type {
    CanvasPersonalValue,
    CanvasPersonalValuesUpdateData,
} from '@/api/types/canvas/personalValue';

export const getPersonalValues = get<PersonalValue[]>('personal-values/');
export const getCanvasPersonalValues = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasPersonalValue[]>> {
    const request = get<CanvasPersonalValue[]>(`canvas/${canvasId}/personal-values/`);
    return request(accessToken);
};

export const putCanvasPersonalValues = function (
    canvasId: number,
    updateData: CanvasPersonalValuesUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<CanvasPersonalValuesUpdateData, any>(`canvas/${canvasId}/personal-values/`);
    return request(accessToken, updateData);
};
