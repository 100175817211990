<template>
    <div
        v-if="enableAnimation"
        :class="cssClass"
        ref="animatedText"
        class="story-section-animated-text w-100"
    >
        {{ props.section.statement }}
    </div>
    <div v-else class="not-animated-statement w-100" :class="cssClass">
        {{ props.section.statement }}
    </div>

    <StorySectionStatementFeedback v-if="section.showFeedbackQuestion" class="mt-4" @click="onFeedback" />
</template>

<script setup lang="ts">
    import { computed, nextTick, onMounted, ref } from 'vue';
    import { gsap } from 'gsap';
    import { ScrollTrigger } from 'gsap/ScrollTrigger';
    import StorySectionStatementFeedback from '@/components/story/section/statement/StorySectionStatementFeedback.vue';

    import type { StorySectionItem } from '@/store/story/types';
    // =================================================================================================
    // Only uncomment this while developing. In stagign or production it will fail until decide to buy the license
    // or not
    // If the import is executed, the app in staging will fail, therefore keep it commented when pushing
    // =================================================================================================
    // import { SplitText } from 'gsap-trial/SplitText';

    const props = defineProps<{
        section: StorySectionItem;
    }>();

    const emit = defineEmits(['on-feedback']);

    const onFeedback = (value: boolean) => {
        emit('on-feedback', value);
    };

    const cssClass = computed(() => {
        return {
            [props.section.id]: true,
        };
    });

    const enableAnimation = ref(false);

    /**
     * Show markers on the scroll trigger timeline when developing
     */
    const showMarkers = false;

    const animatedText = ref(null);

    onMounted(() => {
        if (enableAnimation.value) {
            nextTick(() => {
                // gsap.config({ trialWarn: false });
                console.clear();
                gsap.registerPlugin(ScrollTrigger, SplitText);
                const split = new SplitText(animatedText.value, { type: 'lines' });

                split.lines.forEach((target) => {
                    target.classList.add('story-section-animated-text-line');

                    gsap.to(target, {
                        backgroundPositionX: 0,
                        ease: 'none',
                        scrollTrigger: {
                            trigger: target,
                            markers: showMarkers,
                            scrub: 1,
                            start: 'top center',
                            end: 'bottom center',
                        },
                    });
                });
            });
        }
    });

    // function splitTextByCharacters(text: string, maxCharacters = 25) {
    //     // Split by spaces but keep punctuation attached to words
    //     const words = text.match(/[\w,]+[.,]?|\s+/g) || [];
    //     let line = '';
    //     const lines = [];
    //
    //     words.forEach((word: string) => {
    //         // If adding the next word doesn't exceed maxCharacters, add it to the current line
    //         if (line.length + word.length <= maxCharacters || !line) {
    //             line += word;
    //         } else {
    //             // Push the current line and start a new one
    //             lines.push(line.trim());
    //             line = word;
    //         }
    //     });
    //
    //     // Push the last line if it exists
    //     if (line) lines.push(line.trim());
    //
    //     return lines;
    // }

    // Usage example
    // const text = "As a working professional, I offer businesses a unique value by utilizing my personal, learned, and technical skills.";
    // console.log(splitTextByCharacters(text));
    // onMounted(() => {
    //     nextTick(() => {
    //         // gsap.config({ trialWarn: false });
    //         console.clear();
    //         gsap.registerPlugin(ScrollTrigger, SplitText);
    //         // const split = new SplitText(, { type: 'lines' });
    //
    //         if (animatedText.value) {
    //             // const paragraph = document.querySelector("p.animated-text");
    //             const lines = splitTextByCharacters(animatedText.value.innerHTML, 25).map(
    //                 (line: string) => `<div style="display: block; text-align: start; position: relative; background-position-x: 100%;" class="story-section-animated-text-line">${line}</div>`,
    //             );
    //             animatedText.value.innerHTML = lines.join('');
    //
    //             lines.forEach((target) => {
    //                 // target.classList.add('story-section-animated-text-line');
    //
    //                 gsap.to(target, {
    //                     backgroundPositionX: 0,
    //                     ease: 'none',
    //                     scrollTrigger: {
    //                         trigger: target,
    //                         markers: showMarkers,
    //                         scrub: 1,
    //                         start: 'top center',
    //                         end: 'bottom center',
    //                     },
    //                 });
    //             });
    //         }
    //     });
    // });
</script>

<style scoped lang="scss">
    .what-i-offer {
        --section-text-gradient: linear-gradient(
            to right,
            rgb(var(--v-theme-secondary-blue)) 50%,
            white 50%
        );

        --not-animated-text-color: rgb(var(--v-theme-secondary-blue));
    }

    .who-i-am {
        --section-text-gradient: linear-gradient(
            to right,
            rgb(var(--v-theme-secondary-purple)) 50%,
            white 50%
        );

        --not-animated-text-color: rgb(var(--v-theme-secondary-purple));
    }

    .what-i-am-working-on {
        --section-text-gradient: linear-gradient(
            to right,
            rgb(var(--v-theme-secondary-pink)) 50%,
            white 50%
        );

        --not-animated-text-color: rgb(var(--v-theme-secondary-pink));
    }

    .not-animated-statement,
    .story-section-animated-text {
        font-size: 36px;
        font-weight: 700;

        color: var(--not-animated-text-color);
    }

    :deep(.story-section-animated-text-line) {
        background: var(--section-text-gradient);
        background-size: 200% 100%;
        background-position-x: 100%;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        width: 100% !important;
        /*   margin-bottom: -250px; */
        //margin-left: 50px;
        line-height: 1.2;
        /*   outline: 6px dotted teal; */
    }
</style>
