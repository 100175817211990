<template>
    <v-card color="white" min-height="80" variant="flat" class="rounded-0 pa-4">
        <v-card-title class="px-0">
            <v-text-field variant="outlined" v-model="title" label="Title" class="px-2" />
        </v-card-title>
        <v-card-text class="mx-0 px-2 text-paragraph-md">
            <v-text-field
                variant="outlined"
                v-model="dueDate"
                type="date"
                label="Due date"
                :min="minimumDueDate"
            />
        </v-card-text>

        <v-card-actions>
            <app-button @click="emit('edit-cancel-click', props.item)"> Cancel </app-button>

            <v-spacer />

            <SubmitButton
                text="Save"
                :disabled="!isSubmitEnabled"
                @click="onSaveClick"
                :loading="props.item.state === PlanActionItemState.Updating"
            />
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
    import SubmitButton from '@/components/footer-actions/SubmitButton.vue';
    import { computed, onMounted, type Ref, ref, type UnwrapRef } from 'vue';
    import { useCurrentPlanStore } from '@/store/plan/current/store';
    import { type EditablePlanAction, PlanActionItemState } from '@/store/plan/common/types';
    import { mapIsoDateToBrowserFormat, toBrowserFormat } from '@/lib/dates';

    const props = defineProps<{
        item: EditablePlanAction;
        index: number;
    }>();

    const title = ref('');
    const dueDate: Ref<UnwrapRef<string | null>> = ref(null);

    const store = useCurrentPlanStore();

    onMounted(() => {
        title.value = props.item.title ?? '';

        dueDate.value = toBrowserFormat(props.item.due_date);
    });

    const isSubmitEnabled = computed(() => {
        return title.value.length > 0 && dueDate.value && dueDate.value.length > 0;
    });

    /** * The minimum due date is defaulted to the date of the creation plan */
    const minimumDueDate = mapIsoDateToBrowserFormat(store._plan?.created_at);

    const emit = defineEmits(['edit-save-click', 'edit-cancel-click']);

    const onSaveClick = () => {
        emit('edit-save-click', props.item, {
            ...props.item,
            title: title.value,
            due_date: dueDate.value,
        });
    };
</script>

<style scoped lang="scss"></style>
