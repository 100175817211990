import type { GenericAbortSignal } from 'axios';
import { abortReason } from '@/lib/genericAbortSignal';

/**
 * Create a promise that will complete after a delay or reject if aborted
 *
 * @param delay the time to wait, in milliseconds
 * @param signal optional signal to abort the timeout.
 * @returns a promise that will fulfil when the timeout completes or rejects when aborted
 */
export function asyncTimeout(delay: number, signal?: GenericAbortSignal): Promise<void> {
    if (signal) {
        return new Promise<void>((resolve, reject) => {
            if (signal.aborted) {
                reject(abortReason(signal));
            }
            const timerId = setTimeout(() => {
                resolve();
            }, delay);
            if (signal.addEventListener) {
                signal.addEventListener('abort', () => {
                    clearTimeout(timerId);
                    reject(abortReason(signal));
                });
            }
        });
    } else {
        return new Promise<void>((resolve) => setTimeout(() => resolve(), delay));
    }
}
