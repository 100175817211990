<template>
    <app-guide-modal-multiple-steps
        :model-value="modelValue"
        @update:model-value="(v) => emit('update:modelValue', v)"
        section-name="Learned Experiences"
        action-text="Let's go"
        :steps="2"
    >
        <template v-slot:title>Welcome to Learned Experiences!</template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    This activity is about exploring what you've learned through your career - both
                    what works for you, and things you don't want to repeat!
                </p>
                <p>
                    Each job will have had highs and lows, and from those experiences, you will have
                    learned some new skills and what you enjoy.
                </p>
            </div>
            <div v-else-if="step === 2">
                <p>
                    We've taken in some of your jobs from your CV to make a start - feel free to add
                    anything that's missed.
                </p>
                <p>
                    Recall the most memorable moments at each job and what went well, or not so
                    well, and what you learned.
                </p>
                <p>
                    If you need help - ask Alex, especially if trying to understand what skills or
                    knowledge you learned from an experience.
                </p>
            </div>
        </template>
    </app-guide-modal-multiple-steps>
</template>
<script setup lang="ts">
    import AppGuideModalMultipleSteps from '@/components/guide/modal/AppGuideModalMultipleSteps.vue';

    const props = defineProps<{
        modelValue: boolean;
    }>();

    const emit = defineEmits(['update:modelValue']);
</script>
