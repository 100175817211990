<template>
    <v-card
        color="custom-grey-300"
        min-height="160"
        width="340"
        class="rounded-lg box-shadow-grey pl-8 pt-8 pr-4 pb-4"
    >
        <v-card-item class="pa-0">
            <template v-slot:prepend v-if="props.index !== undefined">
                <span class="text-h6 text-custom-grey-700"> #{{ props.index + 1 }} </span>
            </template>
            <span class="text-h5 font-weight-regular text-use-heading-font mt-8">
                {{ props.item.description }}
            </span>
        </v-card-item>
        <v-card-actions class="py-4 pb-0">
            <v-spacer />
            <app-button class="px-2 text-paragraph-md" :to="futureAspirationRoute"
                >Update</app-button
            >
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
    import { futureAspirationRoute } from '@/router/routes';

    import type { EditableFutureAspiration } from '@/store/plan/career/types';

    const props = defineProps<{
        item: EditableFutureAspiration;
        index?: number;
    }>();
</script>
