<template>
    <app-page>
        <app-page-header
            section-title="Challenge pathway:"
            :title="store.challenge?.description"
            @show-tutorial="showTutorial = true"
        />
        <ChallengePathTutorial v-model="showTutorial" />

        <app-page-content>
            <template #intro>
                <app-page-content-intro
                    title="Choose activities that will help with your challenge"
                >
                    We recommend you start with three activities. If you don't solve the challenge
                    you can always come back and try some other activities later.

                    <br />
                    <div class="my-2">
                        <ChallengeActionTypeLabel
                            class="mr-3"
                            :type="PlanActionItemType.ExperienceActionType"
                        />
                        <span>Things you can do on your own</span>
                    </div>
                    <div class="my-2">
                        <ChallengeActionTypeLabel
                            class="mr-3"
                            :type="PlanActionItemType.ConnectActionType"
                        />
                        <span>Work with or learn from others</span>
                    </div>
                    <div class="my-2">
                        <ChallengeActionTypeLabel
                            class="mr-3"
                            :type="PlanActionItemType.NewSkillActionType"
                        />
                        <span>Up-skill or build a new capability</span>
                    </div>
                </app-page-content-intro>
            </template>

            <SingleChallengeNewActionListItem
                v-for="(action, index) in store.selectableActions"
                :key="index"
                :item="action"
                class="mb-2 w-100"
                @read-more-click="onReadMore(action)"
            />
        </app-page-content>

        <v-dialog close-on-back width="500" v-model="showReadModal">
            <SingleChallengeNewActionListItemReadMore
                v-if="current"
                :item="current"
                class="mb-2 w-100 fill-height"
                @close="showReadModal = false"
            />
        </v-dialog>

        <app-page-footer>
            <template v-slot:actions>
                <CanvasSaveProgressActions
                    close-text="Back"
                    :close-to="challengePathRoute"
                    @on-submit="onContinueToSequencing"
                    title="Continue"
                    :submit-disabled="store.selected.length < 3"
                    :submit-tooltip="'Please select at least 3 actions'"
                />
                <v-spacer />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.ChallengePath" />
    </app-page>

    <SingleChallengeNewGuide />
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted, ref } from 'vue';

    import { useCanvasStore } from '@/store/canvas/store';

    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { challengePathRoute, challengeSequencingRoute } from '@/router/routes';
    import { useActivityStore } from '@/store/activity/store';
    import {
        type SelectableChallengeAction,
        useSingleChallengePathStore,
    } from '@/store/challenge-path/singleChallengeStore';
    import { useRoute, useRouter } from 'vue-router';
    import { verify } from '@/store/verify';
    import { PlanActionItemType } from '@/store/plan/common/types';
    import ChallengeActionTypeLabel from '@/components/pathway/ui/ChallengeActionTypeLabel.vue';
    import SingleChallengeNewActionListItem from '@/components/pathway/single-challenge/new/SingleChallengeNewActionListItem.vue';
    import CanvasSaveProgressActions from '@/components/canvas/CanvasSaveProgressActions.vue';
    import ChallengePathTutorial from '@/components/pathway/ChallengePathTutorial.vue';
    import AppPageContentIntro from '@/components/common/app-page/AppPageContentIntro.vue';
    import SingleChallengeNewActionListItemReadMore from '@/components/pathway/single-challenge/new/SingleChallengeNewActionListItemReadMore.vue';
    import { previousRoute } from '@/router';
    import { RoutesName } from '@/router/routesName';
    import SingleChallengeNewGuide from '@/components/pathway/single-challenge/new/SingleChallengeNewGuide.vue';

    const current = ref<SelectableChallengeAction | null>(null);
    const showReadModal = ref(false);
    const showTutorial = ref(false);

    const store = useSingleChallengePathStore();
    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();
    const router = useRouter();

    const previous = previousRoute.value;
    const challengeId = Number(verify(useRoute().params.id, 'No challenge id'));

    // TODO: Consider moving this logic to something more generic
    // Load the challenge and canvas if the previous route was not
    // the single challenge sequencing for the same challenge id
    const needsLoad =
        previous?.name !== RoutesName.SingleChallengeSequencing &&
        previous?.params?.id !== challengeId.toString();
    if (needsLoad) {
        console.log(`Loading single challenge ${challengeId}. Previous route is: ${previous?.fullPath}`);
        await canvasStore.load();
        await store.loadNew(challengeId);
    } else {
        console.log(`Avoid loading single challenge ${challengeId}. Previous route is ${previous?.fullPath}`);
    }

    onMounted(async () => {
        await activityStore.addEntryActivity(UserActivityArea.ChallengePath);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.ChallengePath);
    });

    const onReadMore = (item: SelectableChallengeAction) => {
        showReadModal.value = true;
        current.value = item;
    };

    const onContinueToSequencing = () => {
        router.push(challengeSequencingRoute(challengeId));
    };
</script>
