<template>
    <v-row class="w-100 text-center text-paragraph-md" justify="center" no-gutters>
        <div class="pa-4">Here’s a planning suggestion to start:</div>
    </v-row>
    <v-row class="w-100" no-gutters>
        <CareerPlanStageSuggestionCard
            :value="suggestion"
            @on-remove="(action) => emit('on-remove', action)"
            @on-add-new="(action) => emit('on-add-new', action)"
        />
    </v-row>
</template>

<script setup lang="ts">
    import type { CareerPlanStages } from '@/store/plan/career/types';
    import CareerPlanStageSuggestionCard from '@/components/plan/career-plan/CareerPlanStageSuggestionCard.vue';
    import { computed } from 'vue';
    import { FutureAspirationActionType } from '@/components/plan/career-plan/types';
    import { type EditablePlanAction } from '@/store/plan/common/types';
    import { makeNewEmptyAspirationEditableAction } from '@/store/plan/career/utils';

    const props = defineProps<{
        value: CareerPlanStages;
    }>();

    const emit = defineEmits(['on-add-new', 'on-remove']);

    const suggestion = computed((): EditablePlanAction => {
        const editableAction = makeNewEmptyAspirationEditableAction(props.value.type);
        switch (props.value.type) {
            case FutureAspirationActionType.Planning:
                return {
                    ...editableAction,
                    title: 'Research industry and market',
                };
            case FutureAspirationActionType.Preparing:
                return {
                    ...editableAction,
                    title: 'Build connections',
                };
            case FutureAspirationActionType.Transitioning:
                return {
                    ...editableAction,
                    title: 'Prepare C.V.',
                };
        }
    });
</script>
