import { defineStore } from 'pinia';
import type { UserInputs, UserProfileStoreState } from '@/store/profile/types';
import { FileTypeAction } from '@/store/profile/types';
import { createCurriculum } from '@/store/profile/update';
import { useUsersStore } from '@/store/user/store';
import { createChangePasswordLink, updateUser } from '@/services/user/service';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
    setInUpdating,
} from '@/store/common/dataState';
import { curriculumHasChanges, userProfileHasChanges } from '@/store/profile/comparison';
import { verify } from '@/store/verify';
import { useAppErrorStore } from '@/store/appErrorStore';
import { ACCEPT_CONTENT_TYPES } from '@/components/profile/curriculum/contentTypes';
import { mapUserUpdateDataToApiObject } from '@/services/profile/service';
import { mapUserApiObjectToUserInput } from '@/store/profile/mapping';
import { isComplete } from '@/store/profile/isComplete';

export const useUserProfileStore = defineStore({
    id: 'user-profile',
    state: (): UserProfileStoreState => ({
        userInputs: {} as UserInputs,
        fileInput: null,

        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        isDirty(): boolean {
            const user = useUsersStore().user;
            const fileInput = this.fileInput;
            const needsUpdateCV = !!fileInput && curriculumHasChanges(fileInput);
            const userInputs = this.userInputs;
            const needsUpdateUser =
                !!user && !!userInputs && userProfileHasChanges(user, userInputs);

            return needsUpdateCV || needsUpdateUser;
        },
        curriculumFile(): File | null {
            if (this.fileInput) {
                return this.fileInput.file;
            } else {
                return null;
            }
        },
        isComplete(): boolean {
            return isComplete(this.userInputs);
        },
        hasCurriculum(): boolean {
            return useUsersStore().hasCurriculum;
        },
        isValidFileType(): boolean {
            return this.isValidFileTypeRule === true;
        },
        isValidFileTypeRule(state): boolean | string {
            const file = state.fileInput?.file;
            if (!file) return true; // No files selected, so it's valid
            const allowedTypes = ACCEPT_CONTENT_TYPES.split(',');

            // Check each file's type against allowed types
            const isInvalid = !allowedTypes.includes(file.type);
            if (isInvalid) {
                return `Unsupported file type(s): ${
                    file.name
                } Please upload files of type: ${allowedTypes.join(', ')}.`;
            }

            return true; // Return true if all files are valid
        },
    },
    actions: {
        init(): void {
            console.info('Init user profile...');
            const user = verify(useUsersStore().user, 'No user');

            // Clone the user, so it does not reference the original, and we can distinct if it is dirty, etc
            this.userInputs = mapUserApiObjectToUserInput(user);
            setInLoading(this);

            try {
                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        setCurriculumFileInput(value: File | null): void {
            this.error = null;
            if (value) {
                this.fileInput = { file: value, action: FileTypeAction.New };
            } else {
                this.fileInput = null;
            }
        },
        async save(forceUpdateUser?: boolean): Promise<void> {
            this.error = null;

            await useAppErrorStore().catchErrors(async () => {
                const { user, accessToken } = await useUsersStore().makeContext();
                setInUpdating(this);

                const fileInput = this.fileInput;
                const needsUpdateCV = !!fileInput && curriculumHasChanges(fileInput);
                const needsUpdateUser = userProfileHasChanges(user, this.userInputs);

                if (needsUpdateUser || forceUpdateUser) {
                    const inputs = this.userInputs;
                    await updateUser(user.id, mapUserUpdateDataToApiObject(inputs), accessToken);
                }

                if (needsUpdateCV) {
                    await createCurriculum(fileInput, user, accessToken);
                }

                await this._reloadState();

                if (needsUpdateCV) {
                    // only clear input after reloading state
                    this.fileInput = null;
                }

                setInIdle(this);
            });
        },
        async saveCurriculumOnly(): Promise<void> {
            this.error = null;

            const { user, accessToken } = await useUsersStore().makeContext();

            await useAppErrorStore().catchErrors(async () => {
                setInUpdating(this);

                const fileInput = verify(this.fileInput, 'no file to upload as cv');
                await createCurriculum(fileInput, user, accessToken);

                await this._reloadState();

                this.fileInput = null;

                setInIdle(this);
            });
        },

        async changePassword(): Promise<string> {
            const { user, accessToken } = await useUsersStore().makeContext();
            return await createChangePasswordLink(user.id, accessToken);
        },

        _reloadState: async function () {
            // Manually trigger a reload of the user resource, to keep FKs updates
            await useUsersStore().synchroniseCurrentUser();

            const user = useUsersStore().user;
            this.userInputs = mapUserApiObjectToUserInput(verify(user, 'no user to clone'));
        },
    },
});
