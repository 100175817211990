<template>
    <v-container class="pa-0 mb-15">
        <v-row no-gutters class="d-flex">
            <PersonalValueSubTypeSelected
                title="Core"
                :sub-type="PersonalValueSelectionSubType.Core"
                :items="store.coreList"
                class="pa-1"
            />
            <PersonalValueSubTypeSelected
                title="Growth"
                :sub-type="PersonalValueSelectionSubType.Growth"
                :items="store.growthList"
                class="pa-1"
            />
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
    import { usePersonalValuesStore } from '@/store/personal-values/store';
    import PersonalValueSubTypeSelected from '@/components/canvas/personal-values/upload/second-step/PersonalValueSubTypeSelected.vue';
    import { PersonalValueSelectionSubType } from '@/api/types/canvas/personalValue';

    const store = usePersonalValuesStore();
</script>
