import { defineStore } from 'pinia';
import { HttpStatusCode } from 'axios';
import {
    // createOrUpdateAction,
    fetchOrCreateCanvasCurrentPlan,
} from '@/services/plan/current/service';
import type { CanvasCurrentPlan } from '@/api/types/plan/currentPlan';
import { useCanvasStore } from '@/store/canvas/store';
import { cloneDeep } from 'lodash';
import { removeCanvasCurrentChallengeAction } from '@/services/plan/current/actions/service';
import { makeEditablePlan } from '@/store/plan/current/utils';
import {
    type CanvasCurrentPlanStoreState,
    type EditableCurrentChallenge,
} from '@/store/plan/current/types';
import {
    type EditablePlanAction,
    type PlanActionEditFormItem,
    PlanActionItemState,
} from '@/store/plan/common/types';
import { makeNewEmptyEditableAction, setEditableActionState } from '@/store/plan/common/utils';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
} from '@/store/common/dataState';

export const useCurrentPlanStore = defineStore({
    id: 'current-plan-store',
    state: (): CanvasCurrentPlanStoreState => ({
        _plan: null,
        editablePlan: null,
        item: null,

        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        hasChallenges(): boolean {
            return this.challenges.length > 0;
        },
        challenges(): EditableCurrentChallenge[] {
            return this.editablePlan?.challenges ?? [];
        },
    },
    actions: {
        async load() {
            console.info('Loading current plan...');
            setInLoading(this);

            try {
                const { canvas, canvasId, accessToken } = await useCanvasStore().makeContext();
                const [plan, response] = await fetchOrCreateCanvasCurrentPlan(canvas, accessToken);

                if (response.status === HttpStatusCode.Created) {
                    // Manually trigger a reload of the user resource, to keep FKs updates
                    await useCanvasStore().synchroniseCanvas(canvasId, accessToken);
                } else {
                    console.log('No need to synchronise user');
                }

                this._plan = plan;
                this._setEditablePlan(plan);

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        addAction(item: EditableCurrentChallenge): void {
            const action = makeNewEmptyEditableAction();

            item.actions.push(action);
        },
        async saveAction(
            challenge: EditableCurrentChallenge,
            item: EditablePlanAction,
            actionForm: PlanActionEditFormItem,
            accessToken: string,
        ): Promise<void> {
            // const isUpdating = actionForm.id;
            // setEditableActionState(
            //     item,
            //     isUpdating ? PlanActionItemState.Updating : PlanActionItemState.Creating,
            // );
            //
            // try {
            //     const action = await createOrUpdateAction(
            //         challenge.canvas_id,
            //         challenge.id,
            //         actionForm,
            //         accessToken,
            //     );
            //     item.id = action.id;
            //     item.title = action.title;
            //     item.due_date = new Date(action.due_date);
            //     setEditableActionState(item, PlanActionItemState.Saved);
            // } catch (error) {
            //     setEditableActionState(item, PlanActionItemState.Error);
            // }
        },
        async removeAction(
            challenge: EditableCurrentChallenge,
            action: EditablePlanAction,
            accessToken: string,
        ): Promise<void> {
            setEditableActionState(action, PlanActionItemState.Deleting);

            try {
                if (action.id) {
                    await removeCanvasCurrentChallengeAction(
                        challenge.canvas_id,
                        challenge.id,
                        action.id,
                        accessToken,
                    );
                }

                const index = challenge.actions.indexOf(action);
                challenge.actions.splice(index, 1);

                setEditableActionState(action, PlanActionItemState.Saved);
            } catch (error) {
                setEditableActionState(action, PlanActionItemState.Error);
            }
        },
        _setEditablePlan: function (plan: CanvasCurrentPlan | null) {
            const clone = cloneDeep(plan);

            if (plan && clone) {
                this.editablePlan = makeEditablePlan(clone);
            }
        },
    },
});
