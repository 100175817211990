import type { User } from '@/api/types/user';
import type { DataState } from '@/store/common/dataState';

export enum FileTypeAction {
    New = 'new',

    /**
     * @deprecated Option disabled at the moment. We need to think what does it mean to delete a cv record in the api
     * and how that cascade through the inferred records. At the moment it will fail with a FK constraint
     */
    Delete = 'delete',
}

export type FileInput = {
    file: File | null;
    action: FileTypeAction;
};
export type UserProfileStoreState = {
    userInputs: UserInputs;
    fileInput: FileInput | null;
    state: DataState;
    error: any | null;
};

export enum ProfileErrorType {
    Userdata = 'saving-user-data',
    Curriculum = 'saving-curriculum',
    UserJob = 'saving-user-job',
}

export type UserInputs = Omit<User, 'other_languages'> & {
    other_languages: string[];
};
