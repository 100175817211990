import { type ApiResponse, delete_, get, post, put } from '@/services/apiClient';
import {
    type CanvasSkill,
    type CanvasSkillCreateData,
    type InferredCanvasSkill,
} from '@/api/types/canvas/skills';
import type { CanvasSkillUpdateData, InferredSkillUpdateData } from '@/store/skills/types';

export const getCanvasPersonalStrengths = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasSkill[]>> {
    const request = get<CanvasSkill[]>(`canvas/${canvasId}/personal-strengths/`);
    return request(accessToken);
};

export const postCanvasPersonalStrength = function (
    canvasId: number,
    createData: CanvasSkillCreateData,
    accessToken: string,
): Promise<ApiResponse<CanvasSkill>> {
    const request = post<CanvasSkillCreateData, CanvasSkill>(`canvas/${canvasId}/personal-strengths/`);
    return request(accessToken, createData);
};

export const putCanvasPersonalStrength = function (
    canvasId: number,
    experienceId: number,
    updateData: CanvasSkillUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<CanvasSkillUpdateData, any>(personalStrengthPath(canvasId, experienceId));
    return request(accessToken, updateData);
};

export const deleteCanvasPersonalStrength = function (
    canvasId: number,
    skillId: number,
    accessToken: string,
): Promise<ApiResponse<any>> {
    const request = delete_<CanvasSkill>(personalStrengthPath(canvasId, skillId));
    return request(accessToken);
};

function personalStrengthPath(canvasId: number, personalStrengthId: number) {
    return `canvas/${canvasId}/personal-strengths/${personalStrengthId}`;
}

export const getInferredPersonalStrengths = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<InferredCanvasSkill[]>> {
    const request = get<InferredCanvasSkill[]>(`canvas/${canvasId}/personal-strengths/inferred`);
    return request(accessToken);
};

export const putInferredPersonalStrength = function (
    canvasId: number,
    experienceId: number,
    updateData: InferredSkillUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<InferredSkillUpdateData, any>(inferredPersonalStrengthsPath(canvasId, experienceId));
    return request(accessToken, updateData);
};

function inferredPersonalStrengthsPath(canvasId: number, personalStrengthId: number) {
    return `canvas/${canvasId}/personal-strengths/inferred/${personalStrengthId}`;
}
