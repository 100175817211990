<template>
    <v-timeline side="end" align="start" class="">
        <v-timeline-item
            v-for="(timelineItem, index) in store.timeline || []"
            :key="`${timelineItem}.${index}`"
            size="x-small"
            :hide-dot="true"
            class="ml-6 rounded-4 border-dashed-custom-grey-400"
        >
            <CareerHistoryTimelineInferredUserJobCard
                v-if="isInferredUserJob(timelineItem)"
                class="w-100 rounded-4"
                :item="timelineItem"
                :index="index"
                :editable="editable"
            />
            <CareerHistoryTimelineUserJobCard v-else :item="timelineItem" :editable="editable" />
        </v-timeline-item>
    </v-timeline>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useDisplay } from 'vuetify';
    import CareerHistoryTimelineUserJobCard from '@/components/profile/career-history/CareerHistoryTimelineUserJobCard.vue';
    import { isInferredUserJob } from '@/api/types/userJob';
    import CareerHistoryTimelineInferredUserJobCard from '@/components/profile/career-history/CareerHistoryTimelineInferredUserJobCard.vue';
    import { byFromDateComparatorDesc } from '@/store/learned-experiences/utils';
    import { type ExperienceTimelineJob, useUserJobStore } from '@/store/user-job/store';
    import { InferredRecordState } from '@/api/types/canvas/skills';

    const { name } = useDisplay();

    const props = withDefaults(
        defineProps<{
            editable?: boolean;
        }>(),
        { editable: true },
    );

    const store = useUserJobStore();

    const minWidth = computed(() => {
        switch (name.value) {
            case 'xs':
                return '350px';
            case 'sm':
                return '400px';
            case 'md':
                return '600px';
            case 'lg':
                return '1000px';
            case 'xl':
                return '1300px';
            default:
                return '1000px';
        }
    });
</script>

<style lang="scss" scoped>
    :deep(.v-timeline-item__body) {
        // It seems the timeline item in vuetify is in some way buggy.
        // So adding a huge min-width makes it full width
        min-width: v-bind(minWidth);

        width: 100%;

        margin-left: 0px;
        margin-top: -4px;

        border: 1px dashed;
        border-color: rgb(var(--v-theme-custom-grey-400));
        border-image-slice: 2;

        padding-inline-start: 0px !important;
    }

    :deep(.v-timeline-item__opposite) {
        padding: 0 !important;
    }

    :deep(.v-timeline-divider) {
        display: none;
    }

    //.v-timeline-item:not(:first-child) :deep(.v-timeline-divider__dot) {
    //    //margin-top: 25px;
    //}
</style>
