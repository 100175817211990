<template>
    <v-card elevation="0" class="text-custom-grey-900">
        <v-card-title class="text-heading-h4 font-weight-700 text-wrap px-0 py-2">
            Career confidence rating
        </v-card-title>

        <v-card-subtitle class="px-0">
            Thinking about your current role, how confident are you in it today?
        </v-card-subtitle>

        <v-card-text class="px-0 text-wrap text-paragraph-md">
            <v-rating
                v-model="userInputs.current_company_confidence_rating"
                :max="5"
                :step="0.5"
                :readonly="false"
                half-increments
                hover
                size="x-large"
                color="primary"
                :rules="[requiredRule('Current Company Career Confidence')]"
                :hide-details="false"
                validate-on="input"
                label="Current Company Career Confidence"
                class="border-1-custom-grey-400 rounded-4"
            />
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { useUserProfileStore } from '@/store/profile/store';
    import { storeToRefs } from 'pinia';
    import { requiredRule } from '@/components/profile/rules';

    const store = useUserProfileStore();

    const { userInputs } = storeToRefs(store);
</script>
