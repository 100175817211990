import type { ApiStory } from '@/api/types/canvas/apiStory';
import type { Story } from '@/store/story/types';
import { StorySectionState } from '@/store/story/types';

export function mapApiStory(story: ApiStory): Story {
    return {
        id: story.id,
        canvasId: story.canvas_id,
        whoIAm: {
            statement: story.who_i_am,
            updatedAt: story.who_i_am_updated_at,
            isPublished: story.who_i_am_is_published,
            feedback: story.who_i_am_is_feedback ?? null,
            isProcessing: false,
        },
        whatIOffer: {
            statement: story.what_i_offer,
            updatedAt: story.what_i_offer_updated_at,
            isPublished: story.what_i_offer_is_published,
            feedback: story.what_i_offer_feedback ?? null,
            isProcessing: false,
        },
        whatIAmWorkingOn: {
            statement: story.what_i_am_working_on,
            updatedAt: story.what_i_am_working_on_updated_at,
            isPublished: story.what_i_am_working_on_is_published,
            feedback: story.what_i_am_working_feedback ?? null,
            isProcessing: false,
        },
        createdAt: story.created_at,
        updatedAt: story.updated_at,
    };
}
