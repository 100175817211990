import axios, { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';

const Config = {
    exponentialBackoffMultiplier: 1000,
    requestTimeoutLimit: 1000 * 30,
};

const apiServerUrl = import.meta.env.VITE_API_SERVER_URL;

export const client = axios.create({
    baseURL: apiServerUrl,
    timeout: Config.requestTimeoutLimit,
    headers: { 'Content-Type': 'application/json' },
    validateStatus: (status: number) => status >= 200 && status < 500,
});

/** Failed requests will retry up to three times */
client.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
        const { config }: any = error;

        if (!config) {
            console.error(`No config set. No retry after failed request.`);
            return Promise.reject(error);
        }

        if (config.method.toLowerCase() !== 'get') {
            console.error(`Request ${config.method} is not retryable.`);
            // Do not retry POST, PUT, DELETE, PATCH requests
            // In theory PUT, DELETE, PATCH requests should be idempotent, but we don't want to retry them
            // Some of the PUT in the API are not idempotent
            // e.g.: PUT /canvas/:id/skills/inferred
            return Promise.reject(error);
        }

        config.retryAttempt ??= 1;

        console.warn(`Retrying request for ${config.retryAttempt} time...`)

        if (config.retryAttempt === 3) {
            return Promise.reject(error);
        }

        config.retryAttempt += 1;
        const increasingBackoff = Config.exponentialBackoffMultiplier * config.retryAttempt;
        const delayedRequest = new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, increasingBackoff);
        });
        return delayedRequest.then(() => client(config));
    },
);

export const callExternalApi = async <T extends any>(options: {
    config: AxiosRequestConfig<T>;
}) => {
    try {
        const response: AxiosResponse<T> = await client(options.config);
        const { data } = response;

        return {
            data,
            error: null,
            status: response.status,
        };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error;

            const { response } = axiosError;

            let message = 'http request failed';

            if (response && response.statusText) {
                message = response.statusText;
            }

            if (axiosError.message) {
                message = axiosError.message;
            }

            if (response && response.data && response.data.message) {
                message = response.data.message;
            }

            return {
                data: null,
                error: {
                    message,
                },
                status: response?.status ?? null,
            };
        }

        return {
            data: null,
            error: {
                message: error instanceof Error ? error.message : error,
            },
            status: null,
        };
    }
};
