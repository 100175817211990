import { type ApiResponse, get, put } from '@/services/apiClient';
import { type CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
import type { CanvasCurrentChallengeUpdateData } from '@/store/current-challenges/types';

export const getCanvasCurrentChallenges = function (
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasCurrentChallenge[]>> {
    const request = get<CanvasCurrentChallenge[]>(`canvas/${canvasId}/current-challenges/`);
    return request(accessToken);
};

export const putCanvasCurrentChallenge = function (
    canvasId: number,
    updateData: CanvasCurrentChallengeUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<CanvasCurrentChallengeUpdateData, any>(
        `canvas/${canvasId}/current-challenges/`,
    );
    return request(accessToken, updateData);
};
