<template>
    <v-card variant="flat" class="px-6 py-6">
        <v-card-title class="pl-0 pt-4 mb-6">
            <span class="text-h5 font-weight-700" v-if="value">What does success look like?</span>
        </v-card-title>
        <v-card-text class="pl-0 pt-2">
            <span class="text-h5" v-if="value">You want to</span>
        </v-card-text>
        <v-card-text class="pl-0 pt-2">
            <FutureAspirationCardItem v-if="value" :item="value" class="mb-4" @removed="onRemove" />
        </v-card-text>
    </v-card>
    <v-row no-gutters class="px-6 py-4">
        <span class="text-h6" v-if="value"
            >And to help us activate a plan for you, can you give us a bit more context:</span
        >
    </v-row>
    <v-row no-gutters class="px-6 mb-4">
        <span class="text-subtitle-1" v-if="value"
            >What timeframe do you want to achieve this by?</span
        >
    </v-row>
    <v-row no-gutters class="px-6">
        <v-text-field label="Timeframe" variant="outlined" v-model="value.timeframe" />
    </v-row>
    <v-row no-gutters class="px-6 mb-4">
        <span class="text-subtitle-1" v-if="value"
            >What country do you want to achieve this in?</span
        >
    </v-row>
    <v-row no-gutters class="px-6">
        <v-text-field label="Target location" variant="outlined" v-model="value.location" />
    </v-row>
    <v-row no-gutters class="px-6 mb-4">
        <span class="text-subtitle-1" v-if="value"
            >What specifically do you hope to gain from making this change?</span
        >
    </v-row>
    <v-row no-gutters class="px-6">
        <v-text-field label="Expected benefit" variant="outlined" v-model="value.expected_gain" />
    </v-row>
    <v-row no-gutters class="px-6 mb-4">
        <span class="text-subtitle-1" v-if="value"
            >What will you need to put in place (either funding, additional projects or gaining new
            skills) to achieve this?</span
        >
    </v-row>
    <v-row no-gutters class="px-6">
        <v-text-field label="What's required" variant="outlined" v-model="value.whats_required" />
    </v-row>
</template>

<script setup lang="ts">
    import { useFutureAspirationsStore } from '@/store/future-aspirations/store';
    import FutureAspirationCardItem from '@/components/canvas/future-aspirations/FutureAspirationCardItem.vue';
    import type { CanvasFutureAspirationItem } from '@/store/future-aspirations/types';

    const store = useFutureAspirationsStore();

    const props = defineProps<{
        value: CanvasFutureAspirationItem;
    }>();

    const onRemove = () => {
        store.clearGoal();
    };
</script>
