import { defineStore } from 'pinia';
import { toValue } from 'vue';
import type { PersonalValue } from '@/api/types/personalValue';
import {
    fetchCanvasPersonalValues,
    fetchPersonalValues,
    updateCanvasPersonalValues,
} from '@/services/personal-values/service';
import type {
    PersonalValueSelection,
    PersonalValueStoreState,
} from '@/store/personal-values/types';
import {
    filterBySubType,
    filterByType,
    makeUpdateData,
    mapCanvasPersonalValuesToStateSelection,
} from '@/store/personal-values/utils';
import {
    type CanvasPersonalValue,
    PersonalValueSelectionSubType,
    PersonalValueSelectionType,
} from '@/api/types/canvas/personalValue';
import { mapToObjectById } from '@/lib/map';
import { cloneDeep, find, isEqual, keys, remove, shuffle, values } from 'lodash';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
    setInUpdating,
} from '@/store/common/dataState';
import { useCanvasStore } from '@/store/canvas/store';
import { verify } from '@/store/verify';
import { useUsersStore } from '@/store/user/store';

export const CORE_OR_GROWTH_AMOUNT_REQUIRED = 6;

export const usePersonalValuesStore = defineStore({
    id: 'personal-value',
    state: (): PersonalValueStoreState => ({
        values: null,
        alreadyTagged: {},
        originalTagged: {},

        carouselItems: [],
        currentIndex: 0,

        coreList: [],
        growthList: [],

        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        isDirty(): boolean {
            const tagged = makeUpdateData(toValue(this.alreadyTagged));
            const originalTagged = makeUpdateData(toValue(this.originalTagged));

            return !isEqual(tagged, originalTagged);
        },
        current(state): PersonalValue {
            return state.carouselItems[state.currentIndex];
        },
        total(state): number {
            return values(state.values).length;
        },
        totalTagged(state): number {
            return values(state.alreadyTagged).length;
        },
        totalImportantTaggedValues(): number {
            return this.coreList.length + this.growthList.length;
        },
        isFirstStepCompleted(): boolean {
            return !!this.totalTagged && !!this.total && this.total === this.totalTagged;
        },
        selectedCoreValues(): PersonalValueSelection[] {
            return this.coreList.filter((i) => {
                return i.selected;
            });
        },
        selectedGrowthValues(): PersonalValueSelection[] {
            return this.growthList.filter((i) => {
                return i.selected;
            });
        },
        isAreaComplete(): boolean {
            return (
                this.selectedCoreValues.length === CORE_OR_GROWTH_AMOUNT_REQUIRED &&
                this.selectedGrowthValues.length === CORE_OR_GROWTH_AMOUNT_REQUIRED
            );
        },
        isAreaStarted(): boolean {
            return values(this.alreadyTagged).length > 0;
        },
        /** The personal values ids that have not been tagged with important / not important */
        notTaggedByTypeIds(state): string[] {
            return keys(state.values).filter((n) => !keys(state.alreadyTagged).includes(n));
        },
        areThereMoreValuesInSecondStep(): boolean {
            return this.carouselItems.length > 0;
        },
        importantSelected(state): PersonalValueSelection[] {
            return filterByType(values(state.alreadyTagged), PersonalValueSelectionType.Important);
        },
        notImportantSelected(state): PersonalValueSelection[] {
            return filterByType(
                values(state.alreadyTagged),
                PersonalValueSelectionType.NotImportant,
            );
        },
        needsLoading(state): boolean {
            return state.values === null;
        },
    },
    actions: {
        async load() {
            if (!this.needsLoading) {
                console.info('Personal values already loaded');
                return;
            }

            const { accessToken, canvasId } = await useCanvasStore().makeContext();

            console.info('Loading personal values...');

            setInLoading(this);

            try {
                const rawValues = await fetchPersonalValues(accessToken);
                const canvasPersonalValues = await fetchCanvasPersonalValues(canvasId, accessToken);

                this._setInitialState(rawValues, canvasPersonalValues);

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        removeCardFromSecondStep(): void {
            throw new Error('Method temporarily disabled.');
            // const personalValue = this._removeCurrentFromCarousel();
            // this._updateSelectionWithType(personalValue, PersonalValueSelectionType.NotImportant);
        },
        onSubTypeRemoved(selection: PersonalValueSelection): void {
            this._updateSelectionWithSubType(selection.personalValue, false, undefined);

            this.carouselItems.push(selection.personalValue);
        },
        onSubTypeSelection(value: PersonalValueSelectionSubType): void {
            const personalValue = this._removeCurrentFromCarousel();

            this._updateSelectionWithType(personalValue, PersonalValueSelectionType.Important);

            const list = this._getSubtypeList(value);
            const totalSelected = list.filter((i) => i.selected).length;
            const selected = totalSelected < CORE_OR_GROWTH_AMOUNT_REQUIRED;
            this._updateSelectionWithSubType(personalValue, selected, value, totalSelected);
        },
        /**
         * Update the core/growth list, and the data supporting it
         *
         * Note: With the introduction of the vuedraggable library, the core/growth values can
         * be dragged to other list. This could generate a conflict with the type they were
         * already tagged. To ensure values are tagged correctly, we set them again.
         *
         * e.g: The core values had 5 values, and a growth value is dragged into the core list.
         * This will end with a core list of 6 items, which 5 have the sub_type: 'core'
         * and one has the 'sub_type': growth
         *
         * @param list The new values sorted
         * @param type the type of the value selection
         */
        updateSubTypeList(
            list: PersonalValueSelection[],
            type: PersonalValueSelectionSubType,
            selected: boolean,
        ) {
            list.forEach((item, index) => {
                this._updateSelectionWithSubType(item.personalValue, selected, type, index);
            });
        },
        async saveProgress(): Promise<void> {
            console.info('Trigger personal values actual save...');
            const { accessToken } = await useUsersStore().makeContext();
            const canvasId = verify(useCanvasStore().canvas?.id, 'No canvas id');

            const alreadyTagged = this.alreadyTagged;

            setInUpdating(this);
            try {
                const updateData = makeUpdateData(toValue(alreadyTagged));

                await updateCanvasPersonalValues(canvasId, updateData, accessToken);

                // Update tagged values for comparison of dirty state
                this.originalTagged = cloneDeep(alreadyTagged);

                console.info('Personal values saved successfully...');
                setInIdle(this);
            } catch (error: unknown) {
                console.error(error instanceof Error ? error.message : error);
                setInError(this, error);
            }
        },
        setCurrentIndex: function (value: number): void {
            this.currentIndex = value;
        },
        // ###############################
        //
        // Side - effects
        //
        // ###############################
        _setInitialState: function (
            rawValues: PersonalValue[],
            canvasPersonalValues: CanvasPersonalValue[],
        ) {
            this.values = mapToObjectById(rawValues);

            // TODO: HACk To account for the fact that the API returns all values tagged with important or
            // non important. This is how the activity used to work with two steps
            // Now it is only one step, but the decision to see if we need to keep taggin 'non-important' values
            // has not been decided, so for now the API/DB stills keeps the important/non-important columns
            const onlyWithSubType = canvasPersonalValues.filter((v) => v.sub_type);

            this.alreadyTagged = mapCanvasPersonalValuesToStateSelection(
                mapToObjectById(rawValues),
                onlyWithSubType,
            );

            this.originalTagged = cloneDeep(this.alreadyTagged);

            this.coreList = filterBySubType(
                this.importantSelected,
                PersonalValueSelectionSubType.Core,
            );
            this.growthList = filterBySubType(
                this.importantSelected,
                PersonalValueSelectionSubType.Growth,
            );

            // init carousel state
            const alreadyTaggedKeys = keys(this.alreadyTagged);
            const carouselItems = rawValues.filter(
                (v) => !alreadyTaggedKeys.includes(v.id.toString()),
            );
            this.carouselItems = shuffle(carouselItems);

            // This is for a better UX, so there cards left and right on the carousel
            this.currentIndex = getMiddleIndex(this.carouselItems);
        },
        _removeCurrentFromCarousel: function (): PersonalValue {
            const [removedItem] = this.carouselItems.splice(this.currentIndex, 1);

            if (removedItem) {
                const totalItems = this.carouselItems.length;
                if (this.currentIndex === totalItems) {
                    this.setCurrentIndex(totalItems - 1);
                }

                return removedItem;
            } else {
                throw new Error('personal card removed but did not exist');
            }
        },
        _updateSelectionWithType: function (
            personalValue: PersonalValue,
            value: PersonalValueSelectionType,
        ) {
            const existingSelection = this.alreadyTagged[personalValue.id];

            if (existingSelection) {
                existingSelection.type = value;
                existingSelection.sub_type = undefined;
                existingSelection.selected = undefined;
                existingSelection.order = undefined;
            } else {
                this.alreadyTagged[personalValue.id] = {
                    personal_value_id: personalValue.id,
                    type: value,
                    personalValue: personalValue,
                };
            }
        },
        _getSubtypeList: function (value: PersonalValueSelectionSubType): PersonalValueSelection[] {
            return value === PersonalValueSelectionSubType.Growth ? this.growthList : this.coreList;
        },
        _getOppositeSubtypeList: function (value: PersonalValueSelectionSubType) {
            return value === PersonalValueSelectionSubType.Growth ? this.coreList : this.growthList;
        },
        _addToSublistIfNotExist: function (
            value: PersonalValueSelectionSubType,
            existingSelection: PersonalValueSelection,
        ) {
            const list = this._getSubtypeList(value);
            const oppositeList = this._getOppositeSubtypeList(value);

            const found = find(list, (i) => {
                return i.personal_value_id === existingSelection.personal_value_id;
            });

            if (!found) {
                list.push(existingSelection);
            }

            this._removeFromSubTypeList(oppositeList, existingSelection.personalValue);
        },
        _removeFromSubTypeList: function (
            list: PersonalValueSelection[],
            personalValue: PersonalValue,
        ) {
            remove(list, (item) => {
                return item.personal_value_id === personalValue.id;
            });
        },
        _updateSelectionWithSubType: function (
            personalValue: PersonalValue,
            selected: boolean,
            value: PersonalValueSelectionSubType | undefined,
            order?: number,
        ) {
            const existingSelection = this.alreadyTagged[personalValue.id];

            if (existingSelection) {
                if (value) {
                    this._addToSublistIfNotExist(value, existingSelection);
                } else {
                    const subType = existingSelection.sub_type;
                    if (subType) {
                        this._removeFromSubTypeList(this._getSubtypeList(subType), personalValue);
                    }
                }

                existingSelection.sub_type = value;
                existingSelection.selected = value === undefined ? undefined : selected;
                existingSelection.order = selected ? order ?? undefined : undefined;
            } else {
                throw new Error('a selection must already exist');
            }
        },
    },
});

const getMiddleIndex = (list: any[]) => {
    return list.length ? Math.floor(list.length / 2) : 0;
};
