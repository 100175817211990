import type { PersonalValueSelection } from '@/store/personal-values/types';

export enum DraggableListItemType {
    Item = 'item',
    MaxAlert = 'max-alert',
    Placeholder = 'placeholder',
}

export type MaxAlertListItem = {
    itemType: DraggableListItemType.MaxAlert;
};

export type PlaceholderListItem = {
    itemType: DraggableListItemType.Placeholder;
};

export type DraggablePersonalValueSelection = PersonalValueSelection & {
    itemType: DraggableListItemType.Item;
};

export type DraggableListItem =
    | DraggablePersonalValueSelection
    | MaxAlertListItem
    | PlaceholderListItem;

export const makeListItem = (item: PersonalValueSelection): DraggablePersonalValueSelection => ({
    ...item,
    itemType: DraggableListItemType.Item,
});

export function makeMaxAlertItem(): MaxAlertListItem {
    return {
        itemType: DraggableListItemType.MaxAlert,
    };
}

export function makePlaceholderItem(): PlaceholderListItem {
    return {
        itemType: DraggableListItemType.Placeholder,
    };
}
