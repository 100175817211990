<template>
    <v-container class="pa-0 mb-10">
        <div class="text-heading-h2">Take the test</div>

        <div class="text-paragraph-md mt-2 mb-6">
            The MBTI Test is free and only takes about 10 minutes. Here is a link to their online
            test. Remember to take note of your results, and return to this page to enter it below.
        </div>

        <div>
            <app-external-button
                color="primary"
                variant="flat"
                href="https://www.16personalities.com/free-personality-test"
            >
                Start
            </app-external-button>
        </div>
    </v-container>

    <v-card-text class="pa-0 text-paragraph-md">
        <v-card class="pa-0 rounded-0" variant="flat">
            <div class="pa-0 text-heading-h2"
                >Once you have your type select it below:
            </div>
            <v-card-text class="px-0 py-6 w-100">
                <v-select
                    class="w-100"
                    :model-value="mbtiValue"
                    label="Your personality type"
                    variant="outlined"
                    :items="items"
                    item-value="value"
                    return-object
                    :clearable="true"
                    :persistent-clear="true"
                    :open-on-clear="true"
                    @update:model-value="onSelect"
                    menu-icon="mdi-chevron-down"
                >
                </v-select>
            </v-card-text>
            <v-card-text class="align-end pa-4 w-100 border-2-grey-200 rounded-4" v-if="mbtiValue">
                <v-row align="center" class="w-100" no-gutters>
                    <v-col class="text-h2 justify-end align-center" :cols="isDesktop ? 4 : 12">
                        <v-img
                            :height="smAndDown ? 140 : 200"
                            :width="smAndDown ? 140 : 200"
                            :src="imageSrc"
                            v-if="imageSrc"
                            variant="flat"
                        ></v-img>
                    </v-col>
                    <v-spacer />
                    <v-col class="text-right" :cols="isDesktop ? 8 : 12">
                        <v-card
                            class="pa-4 text-paragraph-md text-custom-purple-deep w-100 fill-height"
                            variant="flat"
                        >
                            <div class="text-left text-caption-subtitle text-custom-grey-700">
                                {{ mbtiCode }}
                            </div>
                            <div class="text-left text-heading-h2 mt-2">
                                {{ mbtiLabel }}
                            </div>
                            <div class="text-paragraph-sm text-custom-grey-700 text-left my-6">
                                <p v-for="(item, index) in mbtiValueDescription" :key="index">
                                    {{ item }}
                                    <br />
                                    <br />
                                </p>
                            </div>

                            <div class="text-left">
                                <app-button
                                    append-icon="mdi-open-in-new"
                                    class="text-custom-grey-900 font-weight-600 opacity-1 cursor-pointer ml-n4"
                                    variant="plain"
                                    @click="onClickReadMore"
                                >
                                    Read more about this type
                                </app-button>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-card-text>
</template>

<script setup lang="ts">
    import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
    import {
        makeSelectItem,
        PERSONALITY_TYPES_DEFINITION,
        type PersonalityTypeSelectItem,
        selectItems,
    } from '@/store/personality-type/utils';
    import { UserActivityArea } from '@/api/types/userActivity';
    import { useCanvasStore } from '@/store/canvas/store';
    import { useActivityStore } from '@/store/activity/store';
    import { usePersonalityTypeStore } from '@/store/personality-type/store';
    import { useDisplay } from 'vuetify';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { mt } from 'date-fns/locale';

    const items = selectItems;
    const showTutorial = ref(false);

    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();
    const store = usePersonalityTypeStore();

    await activityStore.load();
    await canvasStore.load();
    await store.load();

    const { isDesktop } = useIsDesktop();

    await activityStore.addEntryActivity(UserActivityArea.PersonalityType);

    onMounted(() => {
        if (!store.original) {
            showTutorial.value = true;
        }
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.PersonalityType);
    });

    const { smAndDown } = useDisplay();

    const currentDefinition = computed(() => {
        return store.mbtiType?.value ? PERSONALITY_TYPES_DEFINITION[store.mbtiType.value] : null;
    });

    const mbtiValueDescription = computed(() => {
        const typeDefinition = currentDefinition.value;
        return typeDefinition ? typeDefinition.longDescription : [];
    });

    const mbtiValue = computed(() => {
        const typeDefinition = currentDefinition.value;
        return typeDefinition ? makeSelectItem(typeDefinition) : null;
    });

    const imageSrc = computed(() => {
        const typeDefinition = currentDefinition.value;
        return typeDefinition ? typeDefinition.image : null;
    });

    const mbtiCode = computed(() => {
        const typeDefinition = currentDefinition.value;
        return typeDefinition ? typeDefinition.value : null;
    });

    const mbtiLabel = computed(() => {
        const typeDefinition = currentDefinition.value;
        return typeDefinition ? typeDefinition.label : null;
    });

    const onSelect = (item: PersonalityTypeSelectItem | null) => {
        store.onSelect(item);
    };

    const onDiscardChanges = async () => {
        // This is done to reset the store, so changes are discarded, so next time the data is loaded from scratch
        // This is only done on stores that are not save on change, and have an explicit save button
        store.$reset();
    };

    const onSaveClick = async () => {
        await store.save();
    };

    const onClickReadMore = () => {
        window.open(`https://www.16personalities.com/${mbtiCode.value}-personality`, '_blank');
    };
</script>

<style scoped lang="scss">
    .image-container {
        position: absolute;
        right: 30px;
        bottom: 10px;
    }
</style>
