<template>
    <ActivityConfettiExplosion v-if="showCompletionExplosion" />
    <ActivitySnackbarNotification :model="model" v-if="model.show" />
</template>
<script setup lang="ts">
    import { nextTick, onBeforeUnmount, ref, watch } from 'vue';
    import { useActivityStore } from '@/store/activity/store';
    import ActivitySnackbarNotification from '@/components/canvas/notifications/ActivitySnackbarNotification.vue';
    import ActivityConfettiExplosion from '@/components/canvas/notifications/ActivityConfettiExplosion.vue';
    import { NotificationType } from '@/components/canvas/notifications/notifications';
    import { UserActivityArea } from '@/api/types/userActivity';

    const props = defineProps<{
        /** The value to watch for changes */
        value: any;
        area: UserActivityArea;
    }>();

    const showCompletionExplosion = ref(false);

    const model = ref({
        show: false,
        type: NotificationType.Success,
        message: '',
    });

    const explode = async () => {
        showCompletionExplosion.value = false;
        await nextTick();
        showCompletionExplosion.value = true;
    };

    const activityStore = useActivityStore();

    const onSuccessHandler = async () => {
        console.info(`ActivityNotification ${props.area}: onSuccessHandler`);
        const activityArea = props.area;
        const checkIsCompletedFirstTime = activityArea
            ? !activityStore.completed.map((a) => a.area).includes(activityArea)
            : false;

        if (checkIsCompletedFirstTime) {
            // only fetch activities if needed
            const refreshed = await activityStore.refresh();
            if (refreshed.changed && activityArea === refreshed.activity.area) {
                console.info(
                    `ActivityNotification ${props.area}: onSuccessHandler is completed for first time`,
                );
                await explode();
                model.value = {
                    ...model.value,
                    show: true,
                    type: NotificationType.FirstTimeCompleted,
                };
            } else {
                // TODO: Consider handle this case when summarised activities is implemented
                console.info(
                    `ActivityNotification ${props.area}: onSuccessHandler is not completed yet`,
                );
            }
        } else {
            console.info(
                `ActivityNotification ${props.area}: onSuccessHandler is already completed`,
            );
            // TODO: Consider handle this case when summarised activities is implemented
        }
    };

    const successWatcher = watch(() => props.value, onSuccessHandler);

    onBeforeUnmount(() => {
        successWatcher();
    });
</script>
