<template>
    <v-sheet class="pa-1 empty-sheet">
        <v-card class="px-6 py-8 empty-card" variant="flat">
            <slot/>
        </v-card>
    </v-sheet>
</template>

<style scoped lang="scss">
    .empty-sheet {
        border-radius: 16px;
    }

    .empty-card {
        border-radius: 12px;
        border: 2px dashed rgb(var(--v-theme-secondary-purple));
    }
</style>
