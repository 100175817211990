import { type ApiResponse, get, post, put } from '@/services/apiClient';

import type { User, UserCreateData, UserUpdateData } from '@/api/types/user';

export const getCurrentUser = get<User>('users/me');
export const createUser = post<UserCreateData, User>('users/');
export const putUser = function (
    userId: number,
    updateData: UserUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<UserUpdateData, any>(`users/${userId}/`);
    return request(accessToken, updateData);
};

export type ChangePasswordResponse = {
    /**
     * A url provided by auth0 where to perform the change password ticket.
     * This is similar to a reset link sent by email
     */
    redirect_to: string;
};

export const postChangePasswordTicket = function (
    userId: number,
    accessToken: string,
): Promise<ApiResponse<ChangePasswordResponse>> {
    const request = post<any, ChangePasswordResponse>(`users/${userId}/change-password`);
    return request(accessToken, {});
};
