<template>
    <app-guide-inline
        v-if="store.hasInferred"
        text="I found some strengths in your CV and have gone ahead and added them here for you!
                    Feel free to edit and prune these as much as you need. 
                    Try to just record your most current and relevant skills for your working future."
    />

    <app-guide-chat-activator />
    <app-guide-chat-dialog :screen="GuideSupportedScreen.PersonalStrengths" />
</template>

<script setup lang="ts">
    import { GuideSupportedScreen } from '@/store/guide/types';
    import { usePersonalStrengthsStore } from '@/store/personal-strengths/store';

    const store = usePersonalStrengthsStore();
</script>
