<template>
    <v-row class="mb-4">
        <v-col v-for="(pdc, index) in store.predefined" :cols="isDesktop ? 12 : 12" :key="index">
            <app-selectable-card
                @on-selected="onPredefinedSelected(pdc)"
                :is-selected="isPredefinedSelected(pdc)"
                :title="pdc.title"
                min-width="250"
                class="w-100 pdc-trigger"
                :key="pdc.id"
                :disabled="isPredefinedDisabled(pdc)"
            >
                <!--                <template #append-title>-->
                <!--                    <app-label text="predefined" />-->
                <!--                </template>-->
            </app-selectable-card>
            <v-tooltip v-if="isPredefinedDisabled(pdc)" activator="parent" location="top left">
                This challenge is already in your challenge pathway.
            </v-tooltip>
        </v-col>

        <v-col cols="12" v-for="(item, index) in store.customChallenges" :key="index">
            <app-selectable-card
                @on-selected="() => onRemovedCustom(item)"
                :is-selected="true"
                :title="item.description"
                @title-change="(title: string) => (item.description = title)"
                :editable="true"
                min-width="250"
                class="mb-4 w-100 pdc-trigger"
                :disabled="isCustomDisabled(item)"
            >
            </app-selectable-card>
        </v-col>
    </v-row>

    <!--    <v-card variant="flat" class="px-6 py-6">-->
    <!--        <v-card-title class="pl-0 pt-4 mb-6">-->
    <!--            <span class="text-h5" v-if="store.selected.length">My challenges</span>-->
    <!--        </v-card-title>-->
    <!--        <v-card-text class="pl-0 pt-2">-->
    <!--            <CurrentChallengeListItem-->
    <!--                v-for="(item, index) in store.selected"-->
    <!--                :key="index"-->
    <!--                :item="item"-->
    <!--                class="mb-4"-->
    <!--                @removed="() => onRemovedCustom(index)"-->
    <!--            />-->
    <!--        </v-card-text>-->
    <!--    </v-card>-->

    <v-row no-gutters>
        <FreeTextInput :value="model" @update:model-value="onValueChange" />
    </v-row>

    <v-row no-gutters justify="start">
        <app-plus-button @click="onAddNew">Add my own</app-plus-button>
    </v-row>
</template>

<script setup lang="ts">
    import FreeTextInput from '@/components/common/FreeTextInput.vue';
    import { isSameChallenge, useCurrentChallengeStore } from '@/store/current-challenges/store';
    import { ref, toValue } from 'vue';
    import type { PredefinedChallenge } from '@/api/types/predefinedChallenge';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import type { EditableCurrentChallenge } from '@/store/current-challenges/types';

    const model = ref('');

    const { isDesktop } = useIsDesktop();

    const emit = defineEmits(['see-list']);

    const store = useCurrentChallengeStore();
    await store.load();

    const onValueChange = (value: string) => {
        model.value = value;
    };
    const onAddNew = () => {
        const text = toValue(model);
        if (text) {
            store.onAddChallenge(text);
            model.value = '';
        }
    };

    const onRemovedCustom = (item: EditableCurrentChallenge) => {
        store.onRemove(item);
    };

    const onPredefinedSelected = (pdc: PredefinedChallenge) => {
        store.togglePredefined(pdc);
    };

    const isPredefinedSelected = (pdc: PredefinedChallenge) => {
        return store.predefinedSelected.includes(pdc);
    };

    // const onNewSelected = (pdc: PredefinedChallenge) => {
    //     store.toggleSelected(pdc);
    // };

    const isPredefinedDisabled = (pdc: PredefinedChallenge) => {
        if (isPredefinedSelected(pdc)) {
            const challenge = store.selected.find((c) => isSameChallenge(pdc, c));
            const hasPlannedActions = challenge?.actions.length;
            return !!hasPlannedActions;
        }
    };

    const isCustomDisabled = (item: EditableCurrentChallenge) => {
        return item.actions.length > 0;
    };
</script>

<style>
    .pdc-trigger:hover .hidden-pdc-desc {
        display: block;
    }
</style>
