<template>
    <div class="text-heading-h3">First, introductions</div>

    <div class="mt-10">
        <v-text-field
            label="First name"
            variant="outlined"
            v-model="userInputs.first_name"
            :rules="[requiredRule('First name')]"
            required
            validate-on="input"
        />

        <v-text-field
            label="Last name"
            variant="outlined"
            v-model="userInputs.last_name"
            :rules="[requiredRule('Last name')]"
            validate-on="input"
        />

        <v-text-field
            prepend-inner-icon="mdi-email"
            label="Email"
            variant="outlined"
            readonly
            v-model="userInputs.email"
        />

        <v-text-field
            label="Phone Number"
            v-model="userInputs.phone_number"
            :rules="[phoneNumberRules]"
            :hide-details="false"
            placeholder="+64212345678"
            validate-on="input"
        />

        <v-combobox
            label="Gender"
            variant="outlined"
            class="mb-2"
            v-model="userInputs.gender"
            :hide-details="false"
            :return-object="false"
            :items="genderOptions"
            :rules="[requiredRule('Gender')]"
            item-title="title"
            item-value="value"
            validate-on="input"
        />

        <v-text-field
            label="Date of Birth"
            variant="outlined"
            :rules="[requiredRule('Date of Birth')]"
            type="date"
            :model-value="dateValue"
            @update:model-value="onChangeDate"
            validate-on="input"
        />

        <v-autocomplete
            label="Nationality"
            variant="outlined"
            :chips="true"
            :hide-details="false"
            :clearable="true"
            :auto-select-first="true"
            v-model="userInputs.nationality"
            :items="nationalityOptions"
            :return-object="false"
            :rules="[requiredRule('Nationality')]"
            class="mb-2"
            item-title="title"
            item-value="value"
            validate-on="input"
        />
    </div>
</template>

<script setup lang="ts">
    import { useUserProfileStore } from '@/store/profile/store';
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import { phoneNumberRules, requiredRule } from '@/components/profile/rules';
    import { genderOptions, nationalityOptions } from '@/components/profile/userInputOptions';

    const store = useUserProfileStore();

    const { userInputs } = storeToRefs(store);

    const dateValue = computed(() => {
        return userInputs?.value.date_of_birth ?? null;
    });

    const onChangeDate = (value: string) => {
        userInputs.value.date_of_birth = value;
    };
</script>
