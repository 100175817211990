<template>
    <BeforeMainContent>
        <v-row no-gutters class="mx-0 mb-1 w-100">
            <StoryProgress />
        </v-row>

        <v-row no-gutters class="mx-0 mb-1 w-50" v-if="activityStore.lastCompleted">
            <span class="mt-4 mb-2 mr-8">
                <span class="font-weight-regular text-subtitle-2 text-custom-grey-700">
                    Last activity
                </span>
                <div class="text-subtitle-1 font-weight-600 w-100">
                    {{ lastCompletedDate }}
                </div>
            </span>
            <span class="mt-4 mb-2">
                <span class="font-weight-regular text-subtitle-2 text-custom-grey-700">
                    Last completed
                </span>
                <div class="text-subtitle-1 font-weight-600 w-100">
                    {{ lastCompletedActivityLabel }}
                </div>
            </span>
        </v-row>
    </BeforeMainContent>
</template>

<script setup lang="ts">
    import { useStoryStore } from '@/store/story/store';
    import StoryProgress from '@/components/story/StoryProgress.vue';
    import { computed } from 'vue';
    import { useActivityStore } from '@/store/activity/store';
    import { format } from 'date-fns';
    import { UserActivityArea } from '@/api/types/userActivity';
    import BeforeMainContent from '@/components/layout/BeforeMainContent.vue';

    const store = useStoryStore();
    const activityStore = useActivityStore();

    const lastCompletedDate = computed(() => {
        const createdAt = activityStore.lastCompleted?.created_at;
        return createdAt ? format(new Date(createdAt), 'dd/MM/yy') : null;
    });

    const lastCompletedActivityLabel = computed(() => {
        const activity = activityStore.lastCompleted;

        switch (activity?.area) {
            case UserActivityArea.CurrentChallenges:
                return 'Current challenges';
            case UserActivityArea.FutureAspiration:
                return 'Future aspiration';
            case UserActivityArea.LearnedExperiences:
                return 'Learned Experiences';
            case UserActivityArea.PersonalStrengths:
                return 'Personal strengths';
            case UserActivityArea.PersonalValues:
                return 'Personal values';
            case UserActivityArea.PersonalityType:
                return 'Personality type';
            case UserActivityArea.Skills:
                return 'Skills';
            case UserActivityArea.UniqueValueProposition:
                return 'Unique Value Proposition';
            default:
                break;
        }
    });
</script>
