import { ref, watch } from 'vue';

export enum DataState {
    Idle = 'idle',

    Loading = 'loading',

    Updating = 'updating',
    Syncing = 'syncing',
    Error = 'error',
}

export type StateHolder = {
    state: DataState;
    error: any | null;
};

export const makeDataState = (): StateHolder => {
    return {
        state: DataState.Loading,
        error: null,
    };
};

export const makeDataStateGetters = () => {
    return {
        isLoaded(): boolean {
            return !this.isLoading;
        },
        isError(state: StateHolder): boolean {
            return state.state === DataState.Error;
        },
        isLoading(state: StateHolder): boolean {
            return state.state === DataState.Loading;
        },
        isUpdating(state: StateHolder): boolean {
            return state.state === DataState.Updating;
        },
    };
};

export function setInLoading(storeState: StateHolder): void {
    storeState.error = null;
    storeState.state = DataState.Loading;
}

export function setInUpdating(storeState: StateHolder): void {
    storeState.error = null;
    storeState.state = DataState.Updating;
}

export function setInIdle(storeState: StateHolder): void {
    storeState.error = null;
    storeState.state = DataState.Idle;
}

export function setInSyncing(storeState: StateHolder): void {
    storeState.error = null;
    storeState.state = DataState.Syncing;
}

export function setInError(storeState: StateHolder, error: any | null): void {
    storeState.error = error;
    storeState.state = DataState.Error;
}

export function useSavingHooks() {
    const isSaving = ref(false);

    const onSuccessHandler = (cb: () => Promise<void>) => async (value: DataState) => {
        if (value === DataState.Updating) {
            isSaving.value = true;
        } else if (value === DataState.Idle) {
            // If transitioned from updating state to idle state
            if (isSaving.value) {
                await cb();
                isSaving.value = false;
            }
        }
    };

    const onErrorHandler =
        (cb: (newError: unknown) => Promise<void>) => async (newError: unknown) => {
            if (newError) {
                await cb(newError);
            }
        };
    const watchSuccessOnUpdate = (store: any, cb: () => Promise<void>) => {
        return watch(() => store.$state.state, onSuccessHandler(cb));
    };

    const watchErrorOnUpdate = (store: any, cb: (newError: unknown) => Promise<void>) => {
        return watch(() => store.$state.error, onErrorHandler(cb));
    };

    return { watchSuccessOnUpdate, watchErrorOnUpdate };
}
