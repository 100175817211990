<template>
    <ActivityConfettiExplosion v-if="showCompletionExplosion" />
    <ActivitySnackbarNotification :model="model" v-if="model.show" />
</template>
<script setup lang="ts">
    import { nextTick, onBeforeUnmount, ref } from 'vue';
    import { type StateHolder, useSavingHooks } from '@/store/common/dataState';
    import { useActivityStore } from '@/store/activity/store';
    import type { UserActivityArea } from '@/api/types/userActivity';
    import ActivitySnackbarNotification from '@/components/canvas/notifications/ActivitySnackbarNotification.vue';
    import ActivityConfettiExplosion from '@/components/canvas/notifications/ActivityConfettiExplosion.vue';
    import { NotificationType } from '@/components/canvas/notifications/notifications';

    const showCompletionExplosion = ref(false);

    const model = ref({
        show: false,
        type: NotificationType.Success,
        message: '',
    });

    const explode = async () => {
        showCompletionExplosion.value = false;
        await nextTick();
        showCompletionExplosion.value = true;
    };

    const props = defineProps<{
        store: { $state: StateHolder };
        activityArea?: UserActivityArea;
    }>();

    const activityStore = useActivityStore();
    const { watchSuccessOnUpdate, watchErrorOnUpdate } = useSavingHooks();

    const onErrorHandler = async (newError: unknown) => {
        model.value = {
            show: true,
            type: NotificationType.Error,
            message:
                newError instanceof Error
                    ? newError.message
                    : 'An error has occurred. Please try again',
        };
    };

    const onSuccessHandler = async () => {
        const checkIsCompletedFirstTime = props.activityArea
            ? !activityStore.completed.map((a) => a.area).includes(props.activityArea)
            : false;

        if (checkIsCompletedFirstTime) {
            // only fetch activities if needed
            const refreshed = await activityStore.refresh();
            if (refreshed.changed && props.activityArea === refreshed.activity.area) {
                await explode();
                model.value = {
                    ...model.value,
                    show: true,
                    type: NotificationType.FirstTimeCompleted,
                };
            } else {
                model.value = {
                    ...model.value,
                    show: true,
                    type: NotificationType.Success,
                };
            }
        } else {
            model.value = {
                ...model.value,
                show: true,
                type: NotificationType.Success,
            };
        }
    };

    const successWatcher = watchSuccessOnUpdate(props.store, onSuccessHandler);
    const errorWatcher = watchErrorOnUpdate(props.store, onErrorHandler);

    onBeforeUnmount(() => {
        successWatcher();
        errorWatcher();
    });
</script>
