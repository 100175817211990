<template>
    <v-card
        variant="outlined"
        class="personal-value-selection w-100"
        :class="`personal-value-selection-${name} ${formattedCategory}`"
    >
        <v-card-item class="text-capitalize text-heading-h4 font-weight-700" :class="dynamicClass">
            <template v-slot:prepend>
                <v-icon size="40" class="card-icon">
                    {{ icon }}
                </v-icon>
            </template>
            <div class="w-75">
                {{ props.value.title }}
            </div>
            <template v-slot:append>
                <v-btn
                    class="opacity-1 fill-height"
                    v-if="showRemove"
                    variant="plain"
                    @click.capture.stop.prevent="$emit('on-remove', props.value)"
                >
                    <v-icon size="24" color="custom-neutral-black">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-card-item>
    </v-card>
</template>
<script setup lang="ts">
    import { type PersonalValue } from '@/api/types/personalValue';
    import { computed, ref } from 'vue';
    import { PersonalValueSelectionSubType } from '@/api/types/canvas/personalValue';
    import {
        getCategoryIcon,
        standardCategory,
    } from '@/components/canvas/personal-values/upload/util';
    import { useDisplay } from 'vuetify';

    const { name, mdAndUp } = useDisplay();
    const showRemove = ref(true);

    const props = defineProps<{
        value: PersonalValue;
        subType: PersonalValueSelectionSubType;
    }>();

    // const color = computed(() => {
    //     return props.subType === PersonalValueSelectionSubType.Core
    //         ? 'white'
    //         : 'white';
    // });

    const formattedCategory = computed(() => {
        return standardCategory(props.value.category);
    });

    const icon = computed(() => {
        return getCategoryIcon(props.value.category);
    });

    const dynamicClass = computed(() => {
        return {
            // 'text-paragraph-md px-2 py-6 justify-center': !mdAndUp.value && !showRemove.value,
            // 'text-paragraph-md px-4 py-6 justify-left': !mdAndUp.value && showRemove.value,
        };
    });
</script>

<style scoped lang="scss">
    .independence {
        --color: rgb(var(--v-theme-custom-independence-value));
    }

    .ambition {
        --color: rgb(var(--v-theme-custom-ambition-value));
    }

    .understanding {
        --color: rgb(var(--v-theme-custom-understanding-value));
    }

    .adventure {
        --color: rgb(var(--v-theme-custom-adventure-value));
    }

    .creative {
        --color: rgb(var(--v-theme-custom-creative-value));
    }

    .authentic {
        --color: rgb(var(--v-theme-custom-caring-value));
    }

    .personal-value-selection {
        &.personal-value-selection-xs {
            :deep(.v-card-item__append) {
                position: absolute;
                right: 0px;
                top: 20px;
            }
        }

        &.personal-value-selection-sm,
        &.personal-value-selection-md,
        &.personal-value-selection-lg,
        &.personal-value-selection-xl,
        &.personal-value-selection-xxl {
            :deep(.v-card-item__append) {
                position: absolute;
                right: 20px;
                top: 20px;
            }
        }

        .card-icon {
            color: var(--color) !important;
        }

        //
        //    color: #262626;
        //    background: var(--color);
        //
        //    :deep(.v-chip__content) {
        //        color: #262626;
        //        mix-blend-mode: overlay;
        //    }
        //}
    }
</style>
