<template>
    <app-guide-inline :title="title">
        <template #title>{{ title }}</template>
        <template #default="{ close }">
            {{ text }}
            <br />

            <v-row no-gutters class="w-100 mt-8">
                <CurriculumVitaeInput />
            </v-row>
        </template>

        <template #actions>
            <v-spacer />
            <app-button
                v-if="store.curriculumFile && store.isValidFileType"
                @click="onUploadCV"
                :loading="store.isUpdating"
                height="50"
                color="primary"
                variant="flat"
                class="font-weight-light m4-8 m4-8"
            >
                Upload
            </app-button>
        </template>
    </app-guide-inline>
</template>

<script setup lang="ts">
    import CurriculumVitaeInput from '@/components/profile/curriculum/CurriculumVitaeInput.vue';
    import { useUserProfileStore } from '@/store/profile/store';
    import { useDisplay } from 'vuetify';

    const emit = defineEmits(['after-upload']);

    withDefaults(
        defineProps<{
            title?: string;
            text?: string;
        }>(),
        {
            title: 'Upload your CV',
            text: 'By uploading your CV we can get a head start on the process for you.',
        },
    );

    const store = useUserProfileStore();
    const { lgAndUp } = useDisplay();

    const onUploadCV = async () => {
        await store.saveCurriculumOnly();

        emit('after-upload');
    };
</script>

<style lang="scss" scoped>
    :deep(.v-messages),
    :deep(.v-field-label) {
        opacity: 1;
    }
</style>
