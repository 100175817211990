import type { User } from '@/auth/auth';

export const displayName = (user?: User): string => {
    if (user) {
        return user.nickname ?? user.name ?? user.email ?? '';
    }

    return '';
};

export function getUserInitials(user?: User): string {
    if (user) {
        const firstNameInitial = user.given_name ? user.given_name.charAt(0) : '';
        const lastNameInitial = user.family_name ? user.family_name.charAt(0) : '';

        const initials = (firstNameInitial + lastNameInitial).toUpperCase();

        if (initials) {
            return initials;
        } else {
            return user.nickname?.substring(0, 3) ?? '';
        }
    }

    return '';
}
