<template>
    <div />
</template>

<script setup lang="ts">
    import { usePageActivityStore } from '@/store/page-activity/store';
    import { onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
    import { useAuth0 } from '@/auth/auth';
    import type { UserActivityArea } from '@/api/types/userActivity';

    const props = defineProps<{
        area: UserActivityArea;
    }>();

    const { getAccessTokenSilently } = useAuth0();

    const store = usePageActivityStore();

    onBeforeMount(() => {
        store.$reset();
        store.start(props.area, getAccessTokenSilently);
    });

    onBeforeUnmount(() => {
        store.stop();
    });
</script>
