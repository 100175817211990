<template>
    <v-dialog
        :model-value="props.value"
        :max-width="lgAndUp ? 600 : '92%'"
        :fullscreen="true"
        :scrollable="true"
        :close-on-back="false"
        content-class="right-side-dialog"
    >
        <slot></slot>
    </v-dialog>
</template>

<script setup lang="ts">
    import { useDisplay } from 'vuetify';

    const props = defineProps<{
        value: boolean;
    }>();

    const { lgAndUp } = useDisplay();
</script>

<style lang="scss">
    .right-side-dialog {
        position: absolute;
        bottom: 0;
        right: 0;
        left: unset !important;
        margin: 0 !important;
    }
</style>
