import {
    type GuideEnterEvent,
    GuideEventType,
    type GuideLeaveEvent,
    type GuidePersonalValueSelectionSubTypeEvent,
    type GuidePersonalValueSelectionTypeEvent,
    type GuideQuestionEvent,
    type GuideSuggestionEvent,
    GuideSupportedScreen,
} from '@/store/guide/types';
import { values } from 'lodash';
import { EventFactory } from '@/store/guide/event-factory/eventFactory';
import { type PersonalValueStoreState } from '@/store/personal-values/types';
import type { PersonalValue } from '@/api/types/personalValue';
import type { PersonalValueSelectionType } from '@/api/types/canvas/personalValue';
import { PersonalValueSelectionSubType } from '@/api/types/canvas/personalValue';

export class PersonalValuesEventFactory extends EventFactory<PersonalValueStoreState> {
    screen = GuideSupportedScreen.PersonalValues;

    public enter(): GuideEnterEvent {
        return this.make<GuideEnterEvent>(
            GuideEventType.Enter,

            { context: values(this.contextState.alreadyTagged) },
        );
    }

    leave(): GuideLeaveEvent {
        return this.make<GuideLeaveEvent>(GuideEventType.Leave, {
            context: values(this.contextState.alreadyTagged),
        });
    }

    question(message: string): GuideQuestionEvent {
        return this.make<GuideQuestionEvent>(GuideEventType.Question, {
            context: values(this.contextState.alreadyTagged),
            q: message,
        });
    }

    // selection(): GuidePersonalValueSelectionTypeEvent {
    //     const selected = verify(this.contextState.firstStepPrevious, 'no previous selection');
    //     const existingSelection = this.contextState.alreadyTagged[selected.id];
    //     return this.make<GuidePersonalValueSelectionTypeEvent>(GuideEventType.Selection, {
    //         context: values(this.contextState.alreadyTagged),
    //         selected: existingSelection,
    //         value: existingSelection.type,
    //     });
    // }

    typeSelection(
        selected: PersonalValue,
        value: PersonalValueSelectionType | null,
    ): GuidePersonalValueSelectionTypeEvent {
        return this.make<GuidePersonalValueSelectionTypeEvent>(GuideEventType.Selection, {
            context: values(this.contextState.alreadyTagged),
            selected: selected,
            value: value,
        });
    }

    subTypeSelection(
        selected: PersonalValue,
        value: PersonalValueSelectionSubType | null,
    ): GuidePersonalValueSelectionSubTypeEvent {
        return this.make<GuidePersonalValueSelectionSubTypeEvent>(GuideEventType.Selection, {
            context: values(this.contextState.alreadyTagged),
            selected: selected,
            value: value,
        });
    }

    suggestion(): GuideSuggestionEvent {
        return this.make<GuideSuggestionEvent>(
            GuideEventType.Suggestion,
            values(this.contextState.alreadyTagged),
        );
    }
}
